export class Operation{
    public display = {
        brackets : false,
        bracketsHidden: false,
        operator: '',
        invisible: false
    }
    public wanted = false; //ob der Operator gesucht ist
    public operands;
    public sign = "+";
    constructor(public id?){
        this.id = id || Date.now() + '' + Math.random();
    }
    get nameOfResult(){
        return new Noun('Needs to be implemented by ChildClass', '', '', '');
    }
    get nameOfProcess(){
        return new Noun('Needs to be implemented by ChildClass', '', '', '');
    }
    get verb(){
        return new Verb('Needs to be implemented by ChildClass');
    }
    get namesOfOperands(){
        return [new Noun('Needs to be implemented by ChildClass', '', '', '')];//returns Array of Nouns
    }
    get angularString(){
        return `([bracket${this.id},${Number(this.display.brackets)},${Number(this.display.bracketsHidden)},operation${this.id}]CHILD_CLASS_CONTENT)[bracket${this.id},${Number(this.display.brackets)},${Number(this.display.bracketsHidden)},operation${this.id}]`;
    }
    get value(){
        return;
    }
    get copy(){
        return 'Needs to be implemented by ChildClass';
    }
    walkDown(fnc, acc){
        acc.data = fnc(this, acc.data);
        this.operands.forEach(element => element.walkDown ? element.walkDown(fnc, acc) : fnc(element, acc.data));
        return acc.data;
    }
    string(content){
        return (this.sign === "-" ? "-" : "") + (this.display.brackets ? '(' : '') + content + (this.display.brackets ? ')' : '');
    }
}