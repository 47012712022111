import { Task } from "../DBTask";
import { randomInt, randomItem } from '../general';
import { units, orderedUnits } from '../Measureunits';
import { Quantity } from '../DBQuantity';
export class TaskRectangles extends Task{
    static difficulties = {
        1 : ()=>{
            let a = randomInt(10,100);
            let b = randomInt(10,100);
            let u = randomItem(orderedUnits(units['lengths']));
            console.log('HUHHIHIHHUOOOOOOO')
            return new TaskRectangles(1, new Quantity(a,u, units['lengths']), new Quantity(b,u, units['lengths']));
        },
        2 : ()=>{
            let a = randomInt(10,100);
            let b = randomInt(10,100);
            let u = randomItem(orderedUnits(units['lengths']).slice(1));
            let c = new Quantity(a,u, units['lengths']);
            let d = new Quantity(b,u, units['lengths']);
            d = d.convert(d.nextSmallerUnit());
            return new TaskRectangles(2, c, d);
        },
    }

    public result;
    public guess;
    constructor(public difficulty, public r1, public r2){
        super();
        console.log(this.r1, this.r2)
        let r = r1.convert(r2.unit);
        this.result = new RegExp((2*r.value + 2*r2.value) + "\\s*(|" + r2.unit + ")$\\s*");
    }
    check(){
        if ( this.result.test(this.guess)  ){
            console.log("genau")
            this.emit('correct');  
        }

        else {
          console.log(this.result, this.guess);
          console.log("leider nicht")
          this.emit('wrong');  
        }
    }


}