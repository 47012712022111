import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gefuehlskarten',
  templateUrl: './gefuehlskarten.component.html',
  styleUrls: ['./gefuehlskarten.component.css']
})
export class GefuehlskartenComponent {
  public t;
  constructor() { 
    this.t = ['Einsam','Verletzlich','Verzweiflung','Schuldbewusst','Depressiv','Verletzt','Abgestossen','Furchtbar','Enttäuscht','Missbilligend','Kritisch','Distanziert','Frustriert','Aggressiv','Verärgert','Verbittert','Gedemütigt','Allein gelassen','Bedroht','Abgelehnt','Schwach','Unsicher','Beklommen','Eingeschüchtert','Verdutzt','Verwirrt','Verblüfft','Aufgeregt','Verspielt','Zufrieden','Interessiert','Stolz','Angenommen','Leistungsfähig','Friedvoll','Vertrauend', 'Optimistisch', 'Isoliert','Verlassen','Schikaniert','Zerbrechlich','Trauernd','Macht- und Kraftlos','Reumütig','Beschämt','Minderwertig','Leer','Beschämt','Enttäuscht','Zögernd','Entsetzt','Angeekelt','Abscheu','Abgestoßen','Erschüttert','Widerwillig','Ablehnend','Skeptisch','Abweisend','Empfindungslos','Verschlossen','Verärgert','Wütend','Feindlich','Gereizt','Eifersüchtig','Rasend','Gestört','Empört','Verhöhnt','Verachtet','Gekränkt','Verraten','Nervös','Ungeschützt','Ausgeschlossen','Verfolgt','Wertlos','Unbedeutend','Unzulänglich','Unterlegen','Überfordert','Besorgt','Hilflos','Verängstigt','Schockiert','Bestürzt','Desillusioniert','Fassungslos','Erstaunt','Ehrfürchtig','Ungeduldig','Energiegeladen','Erregt','Frech','Frei','Freudig','Neugierig','Wissbegierig','Erfolgreich','Zuversichtlich','Respektiert','Wertgeschätzt','Mutig','Kreativ','Liebend','Dankbar','Offen','Vertrauend', 'Hoffnungsvoll', 'Inspiriert'];
    
  }

}
