import { Task } from "../DBTask";
import {  } from '../general';
import { Operation } from '../Algebra/Operation';

export class TaskEquivalencetransformation extends Task{
    /*static options = {
        addition: {
            model: true,
            type: 'bool',
            label: 'Addition',
            disabled: false,
            relations: (o, v)=>{
                if (!v) {
                    o.subtraction.model = true;            
                    o.type.model = '';
                }
            }
        },
        subtraction : {
            model: true,
            type: 'bool',
            label: 'Subtraktion',
            disabled: false,
            relations: (o, v)=>{
                if (!v){
                     o.addition.model = true;
                     o.type.model = '';
                }            
            }
        },
        type : {
            hidden: false,
            model: '',
            type: 'string',
            label: 'Typ',
            disabled: true,
            relations: (o, v)=>{ 
                if (v.length){
                    if (/^\-+$/.test(v)) o.subtraction.model = true;
                    if (/^\++$/.test(v)) o.addition.model = true;
                    else {
                        o.subtraction.model = true;
                        o.addition.model = true;
                    }
                }
            }
        }
    }*/

    simplify = (op)=>{
        //alle Operationen sollten als Vorzeichen + haben, d.h. jede Operation mit negativem Vorzeichen wird ersetzt durch eine Multiplikation von -1 mit ihr selbst
        //alle Multiplikationen, die mindestens eine Summe oder eine Subtraktion als Operand haben sollen aufgelöst werden
        //alle Divisionen durch Multiplikationen mit Kehrwert ersetzen
        //alle Subtraktionen durch Additionen mit Kehrwert ersetzen
        //alle Multiplikationen ordnen (Assoz und Kummu)
        //alle Additionen ordnen (Assoz und Kummu)
        //was an Multiplikationen nur Zahlen als Operanden hat durch den Wert ersetzen
        //was an Additionen nur Zahlen als Operanden hat durch den Wert ersetzen
    }

    embed = (object : Operation, target: Operation)=>{
        //target muss eine Variable mit name "§" haben
        //§ wird durch object ersetzt
    }

    static difficulties = {
        1 : ()=>{
            //Randomnumber n (ganzzahlig)
            //Gleichung g (Comparison) "x=n"
            //Randomnumber m (ganzzahlig, ungleich Null)
            //g verändern, auf beiden Seiten entweder +,-,:,• m ==> embedd(g, "§+"+m) --> x+m=n+m
            //beides Seiten von g vereinfachen ==> g.operands[1] = simplify(g.operands[1]) -->  Ergebnis von n+m
            return new TaskEquivalencetransformation(1, );
        }
    }

    public result;
    public guess;
    constructor(public difficulty){
        super();
    }
    check(){
        if ( this.result === this.guess  ){
            console.log("genau")
            this.emit('correct');  
        }

        else {
          console.log(this.result, this.guess, this)
          console.log("leider nicht")
          this.emit('wrong');  
        }
    }


}