export class Node{
    public title;
    public inputs;
    constructor(...a){
        if (a){
            this.title = a.pop();
            this.inputs = a;
        }
    }

}
export class Lamp extends Node{

    
    get value(){
        return this.inputs[0].value;
    }
}

export class Switch extends Node{

    public value = false;

    

}


export class LogicGate extends Node{

    
    transform(...a){}

    get value(){
        return this.transform(...this.inputs.map(x=>x.value));
    }
}

export class OrGate extends LogicGate{
    transform(...a){
        return a.reduce( (x,y)=> x||y, false);    
    }
}

export class AndGate extends LogicGate{
    transform(...a){
        return a.reduce( (x,y)=> x&&y, true);    
    }
}

export class XorGate extends LogicGate{
    transform(...a){
        return a.reduce( (x,y)=> (x||y) && !(x&&y), false);    
    }
}

export class NotGate extends LogicGate{
    transform(...a){
        return !a[0];  
    }
}