import { Component, OnInit, AfterViewInit, OnChanges, Input, ViewChildren, ViewContainerRef, ComponentFactoryResolver } from '@angular/core';

@Component({
  selector: 'app-help-task-item',
  templateUrl: './help-task-item.component.html',
  styleUrls: ['./help-task-item.component.css']
})
export class HelpTaskItemComponent implements AfterViewInit, OnChanges {
  @Input() public task;
  @Input() public taskType;
  @Input() public component;
  
  @ViewChildren('container', { read: ViewContainerRef }) public container;

  public componentRef;

  constructor(private resolver: ComponentFactoryResolver ) { }

  createComponent() {
   this.container.last.clear();
   const factory = this.resolver.resolveComponentFactory(this.component)
   this.componentRef = this.container.last.createComponent(factory);
   this.componentRef.instance.task = this.task;
   if (this.taskType)    this.componentRef.instance.taskType = this.taskType;

   console.log(this.componentRef.instance)

   this.componentRef.instance.init.bind(this.componentRef.instance)();
  }

  ngAfterViewInit() {
    setTimeout(x=>this.createComponent(),100)
  }

  ngOnChanges(){
    setTimeout(x=>this.createComponent(),100)
  }

}
