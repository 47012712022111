import { Component, OnInit,Input } from '@angular/core';
import { TaskRegistryService } from 'src/app/Services/task-registry.service';
import { paths } from '../../app.module';
import { walkTree } from '../../general';
@Component({
  selector: 'app-class-tile',
  templateUrl: './class-tile.component.html',
  styleUrls: ['./class-tile.component.css']
})
export class ClassTileComponent implements OnInit {
  @Input() public myClass;
  public display;
  constructor(public tr : TaskRegistryService) { 

  }
  
  ngOnInit() {
    let z = paths.filter(x=>x.display === 'Klasse ' + this.myClass); 
    this.display = [] 
    walkTree(z, (x, level)=>{
      console.log(x);
      this.display.push({data: x, level: level})
    })
  }


}
