import { units, orderedUnits, toString, fromString, complexConvert, simpleConvert } from '../Measureunits';
import { Task } from "../DBTask";
import { randomItem, equal, randomIndex, randomInt, numbers, shakeTo } from '../general';
import { shuffle } from 'lodash';
import { HelpTaskUnitConversionComponent } from '../Components/help-task-unit-conversion/help-task-unit-conversion.component';

export class TaskUnitConversion extends Task{
    public name = "TaskUnitConversion";
    static options = {
        lengths: {
            model: false,
            type: 'bool',
            label: 'Längen',
            disabled: false,
            relations: (o, v)=>{            }
        },
        weights: {
            model: false,
            type: 'bool',
            label: 'Gewichte',
            disabled: false,
            relations: (o, v)=>{            }
        },
        area: {
            model: true,
            type: 'bool',
            label: 'Flächen',
            disabled: false,
            relations: (o, v)=>{            }
        },
    }
    public helpComponent = HelpTaskUnitConversionComponent;

    static difficulties = {
        1 : ()=>{
            let u = units[randomItem(['lengths', 'weights', 'area'].filter(x => TaskUnitConversion.options[x].model))];
            let p = randomIndex(orderedUnits(u).slice(1))+1;
            let q = randomItem(orderedUnits(u).slice(0,p));
            return new TaskUnitConversion(2, randomInt(1,100) + orderedUnits(u)[p], q, u, true)

            /*let p = randomIndex(orderedUnits.slice(1))+1;
            return new TaskUnitConversion(1, randomInt(1,100) + orderedUnits[p], orderedUnits[p-1], true)
            */
        },
        2 : ()=>{
            let u = units[randomItem(['lengths', 'weights', 'area'].filter(x => TaskUnitConversion.options[x].model))];
            let p = randomIndex(orderedUnits(u).slice(0,orderedUnits(u).length-1));
            let q = randomItem(orderedUnits(u).slice(p+1));
            console.log(orderedUnits(u).slice(1), orderedUnits(u).slice(p+1))
            return new TaskUnitConversion(2, randomInt(1,100) + orderedUnits(u)[p], q, u, true)

            /*let p = randomIndex(orderedUnits.slice(1))+1;
            let q = randomItem(orderedUnits.slice(0,p));
            return new TaskUnitConversion(2, randomInt(1,100) + orderedUnits[p], q, true)
        */},
        3 : ()=>{
            let u = units[randomItem(['lengths', 'weights', 'area'].filter(x => TaskUnitConversion.options[x].model))];
            let from = [];
            let p = orderedUnits(u).length;
            for (let i of numbers(4)){
                p = randomIndex(orderedUnits(u).slice(3-i, p))+(3-i);
                from.push({value: randomInt(1,10), unit: orderedUnits(u)[p]});               
            }
            from = from.sort((a,b)=>u[b.unit].factor-u[a.unit].factor);
            from = shakeTo(2, from)
            from = toString(from);
            //return new TaskUnitConversion(from, smallestUnit(from), true);
            return new TaskUnitConversion(3, from, randomItem(Object.keys(u)),u, true);
        },
        4 : ()=>{
            let u = units[randomItem(['lengths', 'weights', 'area'].filter(x => TaskUnitConversion.options[x].model))];
            let from = [];
            let p = orderedUnits(u).length;
            for (let i of numbers(4)){
                p = randomIndex(orderedUnits(u).slice(3-i, p))+(3-i);
                from.push({value: randomInt(1,1000), unit: orderedUnits(u)[p]});               
            }
            from = from.sort((a,b)=>u[b.unit].factor-u[a.unit].factor);
            from = shakeTo(2, from)
            from = toString(from);
//            return new TaskUnitConversion(from, smallestUnit(from), true);
            return new TaskUnitConversion(4, from, randomItem(Object.keys(u)), u,true);
        },
        5 : ()=>{
            let u = units[randomItem(['lengths', 'weights', 'area'].filter(x => TaskUnitConversion.options[x].model))];
            let from = [];
            let p = orderedUnits(u).length;
            for (let i of numbers(4)){
                p = randomIndex(orderedUnits(u).slice(3-i, p))+(3-i);
                from.push({value: randomInt(1,1000), unit: orderedUnits(u)[p]});               
            }
            from = from.sort((a,b)=>u[b.unit].factor-u[a.unit].factor);
            from = shakeTo(3, from)
            from = toString(from);
//            return new TaskUnitConversion(from, smallestUnit(from), true);
            return new TaskUnitConversion(5, from, randomItem(Object.keys(u)), u, true);

        },
        6 : ()=>{
            let u = units[randomItem(['lengths', 'weights', 'area'].filter(x => TaskUnitConversion.options[x].model))];
            let from = [];
            let p = orderedUnits(u).length;
            for (let i of numbers(4)){
                p = randomIndex(orderedUnits(u).slice(3-i, p))+(3-i);
                from.push({value: randomInt(1,1000), unit: orderedUnits(u)[p]});               
            }
            from = from.sort((a,b)=>u[b.unit].factor-u[a.unit].factor);
            from = toString(from);
//            return new TaskUnitConversion(from, smallestUnit(from), true);
            return new TaskUnitConversion(6, from, randomItem(Object.keys(u)),u, true);

        },
        7 : ()=>{
            let u = units[randomItem(['lengths', 'weights', 'area'].filter(x => TaskUnitConversion.options[x].model))];
            let from = [];
            let p = orderedUnits(u).length;
            for (let i of numbers(4)){
                p = randomIndex(orderedUnits(u).slice(3-i, p))+(3-i);
                from.push({value: randomInt(1,1000), unit: orderedUnits(u)[p]});               
            }
            from = shuffle(from);
            from = toString(from);
//            return new TaskUnitConversion(from, smallestUnit(from), true);
            return new TaskUnitConversion(7, from, randomItem(Object.keys(u)),u, true);

        },
        8 : ()=>{
            let u = units[randomItem(['lengths', 'weights', 'area'].filter(x => TaskUnitConversion.options[x].model))];
            let from = [];
            let p = orderedUnits(u).length;
            for (let i of numbers(5)){
                p = randomIndex(orderedUnits(u).slice(4-i, p))+(4-i);
                from.push({value: randomInt(1,1000), unit: orderedUnits(u)[p]});               
            }
            from = shuffle(from);
            from = toString(from);
//            return new TaskUnitConversion(from, smallestUnit(from), true);
            return new TaskUnitConversion(8, from, randomItem(Object.keys(u)),u, true);
        }
    } 

    public helpGuess = [];
    public helpResult;
    public result;
    public guess;
    public from;
    constructor(public difficulty, public _from, public to, public units, public unitGiven){//Beispiel: 100km 3dm --> m cm oder 32km 100m --> mm
        super();
        this.from = fromString(_from, this.units);
        this.result = complexConvert(_from, this.to, this.units);
        if (this.difficulty < 3) this.helpComponent = undefined;
    }
    check(){
        let g;
        if (!this.unitGiven) g = fromString(this.guess, this.units)[0];
        if (this.unitGiven && equal(+this.result.value, +this.guess, 0.00000001)){
            console.log("genau")
            this.emit('correct');  
        }
        else if (!this.unitGiven &&  equal(+this.result.value, +g.guess, 0.00000001) && this.result.unit == g.unit){
            console.log("genau")
            this.emit('correct');          
        }
        else{
          console.log("leider nicht", +this.guess, +this.result.value, )
          this.emit('wrong');  
        }
    }
}