import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-rectangle-shape',
  templateUrl: './rectangle-shape.component.html',
  styleUrls: ['./rectangle-shape.component.css']
})
export class RectangleShapeComponent implements OnInit {
  @Input() public shape;
  constructor() { }

  ngOnInit() {
  }

}
