import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { shuffle } from 'lodash';
import { intermix, equal } from '../../general'
import { Equation } from 'src/app/DBEquation';
import { units, orderedUnits, toString, fromString, complexConvert, simpleConvert, smallestUnit } from '../../Measureunits';

@Component({
  selector: 'app-help-task-unit-conversion',
  templateUrl: './help-task-unit-conversion.component.html',
  styleUrls: ['./help-task-unit-conversion.component.css']
})
export class HelpTaskUnitConversionComponent implements OnChanges {
  @Input() public task = {'_from' : undefined, from:[], units: undefined, to : undefined};
  public leftUnits;
  public rightUnit;
  public unitSymbols;
  public leftSymbols;
  public smallestUnit;
  public firstTransform = [];
  public preCalculation;
  public finalTransform;

  public guesses = {
    leftUnits : [],
    firstTransform : [],
    smallestUnit : '',
    preCalculation : '',
    finalTransform: ''

  }
  public results = {
    leftUnits : [],
    firstTransform : [],
    smallestUnit : '',
    preCalculation: '',
    finalTransform: undefined

  }

  public checks = {
    leftUnits : [],
    leftUnitsComplete : undefined,
    firstTransform : [],
    smallestUnit : undefined,
    preCalculation : undefined,
    finalTransform: undefined
  }
  public step = 1;

  ngOnChanges() {
    this.init();
  }
  init(){
    this.unitSymbols = shuffle(Object.keys(this.task.units));
    let u : any= this.task.from.map(x=>this.task.units[x.unit].name);
    u = u.length>1 ? u.slice(0,-1).join(', ') + ' oder ' + u[u.length-1] : u[0];

    this.leftUnits = u;
    this.smallestUnit = smallestUnit(this.task.from.map(x=>x.unit) , this.task.units)

    this.guesses.firstTransform = this.task.from.map(x=>'');

    this.results.leftUnits = u;
    this.results.smallestUnit = smallestUnit(this.task.from.map(x=>x.unit) , this.task.units);
    this.results.firstTransform = this.task.from.map(x=> simpleConvert(x.value, x.unit, this.smallestUnit, this.task.units).value)
    this.results.preCalculation = this.results.firstTransform.map(x=>x.value).reduce((a,b)=>a+b);
    this.results.finalTransform = simpleConvert(this.results.preCalculation, this.smallestUnit, this.task.to, this.task.units)

    this.firstTransform = this.task.from.map((x,i)=> new Equation(x.value + 'unit[' + x.unit + ']' + '=§number[guesses.firstTransform.' + i + ']' + 'unit[' + this.smallestUnit + ']', this));
    this.preCalculation = new Equation(this.guesses.firstTransform.join('unit[' + this.results.smallestUnit + ']+') +  'unit[' + this.results.smallestUnit + ']= §number[guesses.preCalculation]unit[' + this.smallestUnit + ']',this);
    this.finalTransform = new Equation(this.guesses.preCalculation + 'unit[' + this.results.smallestUnit + '] = §number[guesses.finalTransform] unit[' + this.task.to + ']',this);
  }
  toggleGuessFirstTransform(u){
    if (this.guesses.firstTransform.includes(u)){
      this.guesses.firstTransform.splice(this.guesses.firstTransform.indexOf(u),1);
    }
    else {
      this.guesses.firstTransform.push(u)
    }
  }
  check(step){
    switch(this.step){
      case 1 : {
        this.checks.leftUnits = this.guesses.leftUnits.map(x=>this.results.leftUnits.includes(x));
        this.checks.leftUnitsComplete = this.guesses.leftUnits.length == this.results.leftUnits.length;
        break;
      }
      case 2 : {
        this.checks.smallestUnit = this.guesses.smallestUnit == this.results.smallestUnit;
        break;
      }
      case 3 : {
        this.checks.firstTransform = this.results.firstTransform.map((x,i)=>equal(+x, +this.guesses.firstTransform[i], 0.00000001));
        break;
      }
      case 4 : {
        this.checks.preCalculation = equal(+this.results.preCalculation, +this.guesses.preCalculation,0.00000001);
        break;
      }
      case 5 : {
        this.checks.finalTransform = equal(+this.results.finalTransform, +this.guesses.finalTransform,0.00000001);
        break;
      }    }
  }

}
