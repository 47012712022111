import { Component, OnInit } from '@angular/core';
import { TaskComponent } from '../task/task.component';

@Component({
  selector: 'app-task-rectangles',
  templateUrl: './task-rectangles.component.html',
  styleUrls: ['./task-rectangles.component.css']
})
export class TaskRectanglesComponent extends TaskComponent {


  ngOnInit() {
  }

}
