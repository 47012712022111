import { Operator } from './LexedArray';

export let invisibleTimesAdder = (arr) => {
    for (let i=0; i<arr.length;i++){
        if (Array.isArray(arr[i])){
            arr.splice(i,1,invisibleTimesAdder(arr[i]));
        }
        if (!(arr[i] instanceof Operator) && arr[i+1] && !(arr[i+1] instanceof Operator)){
            arr.splice(i+1, 0, new Operator('•', false));
        }
/*        if (!(arr[i] instanceof Operator) && Array.isArray(arr[i+1])){
            arr.splice(i+1, 0, new Operator('•', false));
        }*/
    }
    return arr;
}