import { Task } from "../DBTask";
import { randomBool, randomItem, equal, randomIndex, randomInt, numbers, shakeTo } from '../general';
import { shuffle } from 'lodash';
import { Fraction } from "../Fraction";

export class TaskFractionExpansion extends Task{
    public name = "TaskFractionExpansion";
    static difficulties = {
        1 : ()=>{
            let t = randomInt(2, 10);
            return randomBool() ? 
                    new TaskFractionExpansion(1, new Fraction( randomInt(1,t), t), '', randomInt(3,6)* t)
                :   new TaskFractionExpansion(1, new Fraction(t, randomInt(t+1, t+5)), randomInt(3,6)* t, '');
        },
        2 : ()=>{
            let t = randomInt(2, 10);
            return randomBool() ? 
                    new TaskFractionExpansion(1, new Fraction( randomInt(1,t), t), '', randomInt(6,20)* t)
                :   new TaskFractionExpansion(1, new Fraction(t, randomInt(t+1, t+10)), randomInt(6,20)* t, '');
        },
    }


    public result;
    public guess;
    constructor(public difficulty, public fraction, public enumerator, public denominator){
        super();
        let factor = this.denominator != '' 
                    ?    this.denominator/this.fraction.denominator
                    :    this.enumerator/this.fraction.enumerator;
        this.result = this.fraction.expandBy(factor);
        this.guess = {enumerator: this.enumerator, denominator: this.denominator, sign:'+'}
    }
    check(){
        console.log(this.result, this.guess)
        if (this.result.identical(this.guess)){
            console.log("genau")
            this.emit('correct');  
        }

        else{
          console.log("leider nicht")
          this.emit('wrong');  
        }
    }
}