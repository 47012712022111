import { Component, OnInit, Input } from '@angular/core';
import { TaskComponent } from '../task/task.component';
import { TaskRegistryService } from 'src/app/Services/task-registry.service';
import { TaskButtonsServiceService } from 'src/app/task-buttons-service.service';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/internal/operators/map';

@Component({
  selector: 'app-student-test',
  templateUrl: './student-test.component.html',
  styleUrls: ['./student-test.component.css']
})
export class StudentTestComponent {
  @Input() public tasks;
  @Input() public estimatedTime = 40 * 60 * 1000; //für 7er 20 Minuten
  @Input() public rewards;
  constructor(public ts : TaskRegistryService, public tbs : TaskButtonsServiceService, public ar : ActivatedRoute){
    this.tasks = this.ar.data['getValue']().tasks;
//    this.tasks = Object.keys(this.tasks).map(x=>this.tasks[x]);
    this.estimatedTime = this.ar.data['getValue']().time;
    this.rewards = this.ar.data['getValue']().rewards;
    console.log(Array.isArray(this.tasks));
    tbs.buttonsText = 'nächste Aufgabe'
  }
  
  public currentTask;
  public currentTaskComponent;
  public cursor = 0;
  public startTime;
  public endTime;
  public solved = [];
  start(){
    this.startTime = Date.now();
    this.next();
  }
  round(e){return Math.round(e)}
  next(){
    console.log('NEXT')
    let taskDataContainer = this.ts.getTask(this.tasks[this.cursor].task);
    this.currentTaskComponent = taskDataContainer.component;
    if (this.tasks[this.cursor]['options']){
      Object.keys(this.tasks[this.cursor]['options']).forEach(x=>{
        taskDataContainer.type.options[x]['model'] = this.tasks[this.cursor]['options'][x]; 
      })
    }
    this.currentTask = (taskDataContainer.type.withDifficulty || taskDataContainer.type.difficulties[this.tasks[this.cursor].difficulty])(this.tasks[this.cursor].difficulty)
  console.log(this.solved)
    this.cursor++;
    let id = Date.now();
    let fnc = (e)=>{
      return ()=>{
        this.currentTask.off('wrong', id);
        this.currentTask.off('correct', id);
        this.solved.push({
          task: this.currentTask,
          correct: e == 'wrong' ? false : true
        });
        console.log(this.cursor, this.tasks)
        if (this.cursor < this.tasks.length){
          this.next();
        } else {
          this.endTime = Date.now();
        }
      }
    }
    this.currentTask.on('wrong',fnc('wrong'), id)
    this.currentTask.on('correct',fnc('correct'), id)
  }
  get correct (){return this.solved.filter(x=>x.correct)}
  get wrong (){return this.solved.filter(x=>!x.correct)}
  
  skip(){

  }

}
