import { Task } from "../DBTask";
import {  } from '../general';
import * as _ from 'lodash';
import { EquationWordproblemsService } from '../Services/equation-wordproblems.service' 

const tasks = new EquationWordproblemsService().tasks;
let pointer = -1;

let quickEval = (t, x)=>eval(t.replace(/(\d)x/g, '$1*x').replace(/x/g, x.replace(/,/g, '.')).replace(/,/g, '.').replace(/•/g, '*').replace(/:/g, '/',))

export class TaskEquationsGraphs extends Task{
    static difficulties = {
        1 : ()=>{
            pointer = (pointer + 1) % tasks.length;
            return new TaskEquationsGraphs(1, tasks[pointer]);
        }
    }
    public equation;
    public result;
    public guess = {
        pointsForLeft : [],
        pointsForRight : [],
        solution : undefined
    };
    public guessState = {
        pointsForLeft : undefined,
        pointsForRight : undefined,
        solution : undefined
    };
    constructor(public difficulty, public task){
        super();
        let e = this.task.solutions[0].split('=');
        this.equation = {
            left: e[0], 
            right: e[1]
        }
    }
    testGuess(){
        this.guessState ={
            pointsForLeft : this.guess.pointsForLeft.length > 1 && this.guess.pointsForLeft.every(p=>quickEval(this.equation.left, p.x) == p.y),
            pointsForRight : this.guess.pointsForRight.length > 1 && this.guess.pointsForRight.every(p=>quickEval(this.equation.right, p.x) == p.y),
            solution : quickEval(this.equation.right, this.guess.solution) == quickEval(this.equation.left, this.guess.solution)
        }
    }
    check(){
        this.testGuess();
        if ( this.guessState.pointsForLeft && this.guessState.pointsForRight && this.guessState.solution  ){
            console.log("genau")
            this.emit('correct');  
        }

        else {
          console.log(this.result, this.guess, this)
          console.log("leider nicht")
          this.emit('wrong');  
        }
    }


}