import { MixedNumber } from "../MixedNumber";
import { Fraction } from '../Fraction';
export const NUMBER_DISPLAY = {
    SIGNED_FRACTION : "signed-fraction",
    INTEGER: "integer",
    UNSIGNED_FRACTION : "unsigned-fraction",
    MIXEDNUMBER: "mixed-number", 
    DECIMAL: "decimal"
}
export class RationalNumber{
    public display = NUMBER_DISPLAY.INTEGER;
    public fractionValue : Fraction;
    constructor(value){
        if (value instanceof Fraction) {
            this.fractionValue = value;
            this.sign = value.sign;
        }
        else if (value instanceof MixedNumber) this.fractionValue = value.fraction;
        else if (!Number.isNaN(value)){
            if(Number.isInteger(value)) this.fractionValue = new Fraction(Math.abs(value), 1, value<0 ? '-' : '+');
            else {
                let t = Number(String(value).replace(/\./, ''))
                this.fractionValue = new Fraction(Math.abs(t)  , Math.pow(10, String(value).split('.')[1] ? String(value).split('.')[1].length: 0), t<0 ? '-' : '+');
            }
        }
    }
    get sign() {return this.fractionValue.sign}
    set sign(a) {this.fractionValue.sign = a;}
    get mixedNumber(){
        return MixedNumber.fromFraction(this.fractionValue);
    }
    get fraction(){
        return this.fractionValue;
    }
    get appropriate(){
        if (this.fractionValue.reduce().denominator === 1) return this.number; 
        if (this.fractionValue.enumerator > this.fractionValue.denominator) return this.mixedNumber;
        else return this.fractionValue;
    }
    decimal(afterDecimalPointMin, afterDecimalPointMax){
        /*
        let e = this.fractionValue.enumerator;
        let d = this.fractionValue.denominator;
        let before = Math.floor(e/d);
        let es = String((e/d) - before).replace(/$0./, '').split('');
        */
        return String(this.number);
    }
    germanDecimal(afterDecimalCommaMin, afterDecimalCommaMax){
        return this.decimal(afterDecimalCommaMin, afterDecimalCommaMax).replace(/\./, ',');
    }
    get number(){
        return this.fractionValue.signValue * this.fractionValue.enumerator/this.fractionValue.denominator;
    }
    get value(){
        return this;
    }
    add(r : RationalNumber){
        //console.log(this,r)
        return new RationalNumber(this.fractionValue.add(r.fractionValue));
    }
    subtract(r : RationalNumber){
        return new RationalNumber(this.fractionValue.subtract(r.fractionValue));

    }
    divideBy(r : RationalNumber){
        return new RationalNumber(this.fractionValue.divideBy(r.fractionValue));

    }
    multiply(r : RationalNumber){
        return new RationalNumber(this.fractionValue.multiply(r.fractionValue));

    }
    power(r : RationalNumber){
        return new RationalNumber(this.fractionValue.power(r.fractionValue));

    }
    string(){
        //!!!!!!!ACHTUNG DAS IST NUR FÜR TESTS IMPLEMENTIERT
        switch(this.display){
            case NUMBER_DISPLAY.SIGNED_FRACTION : return 'sfraction(' + [this.fractionValue.enumerator, this.fractionValue.denominator, this.fractionValue.sign].join(',') + ')'
            case NUMBER_DISPLAY.INTEGER : return (this.fractionValue.sign === '-' ? '-' : '' ) + this.fractionValue.enumerator;
            case NUMBER_DISPLAY.DECIMAL : return this.germanDecimal;
            case NUMBER_DISPLAY.UNSIGNED_FRACTION : return 'fraction(' + [this.fractionValue.enumerator, this.fractionValue.denominator].join(',') + ')'
            case NUMBER_DISPLAY.MIXEDNUMBER : return 'mixedNumber(' + [this.mixedNumber.number, this.mixedNumber.fraction.enumerator, this.mixedNumber.fraction.denominator ].join(',') + ')'
        }
    }
    texString(){
        //!!!!!!!ACHTUNG DAS IST NUR FÜR TESTS IMPLEMENTIERT
        switch(this.display){
            case NUMBER_DISPLAY.SIGNED_FRACTION : return `${this.fractionValue.sign === "-" ? '-' : ''}{${this.fractionValue.enumerator}} \\over {${this.fractionValue.denominator}}`
            case NUMBER_DISPLAY.INTEGER : return (this.fractionValue.sign === '-' ? '-' : '' ) + this.fractionValue.enumerator;
            case NUMBER_DISPLAY.DECIMAL : return this.germanDecimal;
            case NUMBER_DISPLAY.UNSIGNED_FRACTION : return `{${this.fractionValue.enumerator}} \\over {${this.fractionValue.denominator}}`
            case NUMBER_DISPLAY.MIXEDNUMBER : return `${this.mixedNumber.number}{${this.mixedNumber.fraction.enumerator}} \\over {${this.mixedNumber.fraction.denominator}}`;
        }
    }
    get angularString(){
        return 
    }
}