import { Task } from "../DBTask";
import { randomItem, equal, randomIndex, randomInt, numbers, shakeTo } from '../general';
import { shuffle } from 'lodash';
import { Fraction } from "../Fraction";

export class TaskFractionExpandWith extends Task{
    public name = "TaskFractionExpandWith";
    static difficulties = {
        1 : ()=>{
            return new TaskFractionExpandWith(Fraction.true(), randomInt(3,6));
        },
        2 : ()=>{
            return new TaskFractionExpandWith(Fraction.true(), randomInt(6,20));
        },
        3 : ()=>{
            return new TaskFractionExpandWith(Fraction.true(), randomInt(20,100));
        },
    }

    public difficulty;
    public result;
    public guess;
    constructor(public fraction, public factor){
        super();
        this.result = this.fraction.expandBy(factor);
        this.guess = {enumerator: '', denominator: '', sign: '+'}
    }
    check(){
        console.log(this.result, this.guess)
        if (this.result.identical(this.guess)){
            console.log("genau")
            this.emit('correct');  
        }

        else{
          console.log("leider nicht")
          this.emit('wrong');  
        }
    }
}