import { Component } from '@angular/core';

import { sortableItems } from './sortable-items';
import { SortableItem } from '../../core/models';

@Component({
  selector: 'sortable-items-list',
  template: `
    <sortable-item *ngFor="let item of sortableItems; let index = index"
        dragSortable
        [item]="item"
        [itemIndex]="index"
        [sortableElSelector]="'sortable-item'"
        (dropSortable)="onDropSortable($event)"></sortable-item>
  `,
    styleUrls: ['./sortable-items-list.component.css']
})
export class SortableItemsListComponent {
  sortableItems: SortableItem[] = sortableItems;

  onDropSortable(event) {
    console.log(event);
  }
}