import { Component, OnInit } from '@angular/core';
import { TaskComponent } from '../task/task.component';
import { Equation } from 'src/app/DBEquation';

@Component({
  selector: 'app-task-compare-units',
  templateUrl: './task-compare-units.component.html',
  styleUrls: ['./task-compare-units.component.css']
})
export class TaskCompareUnitsComponent extends TaskComponent {
  public equation;
  ngOnChanges() {
    this.equation = new Equation(this.task.q1.value + 'unit[' + this.task.q1.unit + ']' + '§comparison[guess]' + this.task.q2.value + 'unit[' + this.task.q2.unit + ']' , this.task);
  }
}
