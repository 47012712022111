import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-task-number-sets',
  templateUrl: './task-number-sets.component.html',
  styleUrls: ['./task-number-sets.component.css']
})
export class TaskNumberSetsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
