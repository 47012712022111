import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[FlexTextHeight]'
})
export class FlexTextHeightDirective {

  constructor(el: ElementRef) {
    window['flex-heights'] = window['flex-heights'] ? window['flex-heights'].concat([el.nativeElement]) : [el.nativeElement];
  }

}
