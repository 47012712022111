import { Component, OnChanges } from '@angular/core';
import { TaskComponent } from '../task/task.component';

@Component({
  selector: 'app-task-fraction-expansion',
  templateUrl: './task-fraction-expansion.component.html',
  styleUrls: ['./task-fraction-expansion.component.css']
})
export class TaskFractionExpansionComponent extends TaskComponent implements OnChanges {
  public name = "TaskFractionExpansion";

  ngOnChanges() {
  }

}
