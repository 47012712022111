import { Component } from '@angular/core';
import { TaskComponent } from '../task/task.component';

@Component({
  selector: 'app-task-fraction-cancel',
  templateUrl: './task-fraction-cancel.component.html',
  styleUrls: ['./task-fraction-cancel.component.css']
})
export class TaskFractionCancelComponent  extends TaskComponent {
  public name = "TaskFractionCancel";
 }