import { Task } from "../DBTask";
import { numbers, randomInt } from '../general';
import { DBPlaceValueRepresentation } from "../DBPlaceValueRepresentation";

export class TaskDecimalComparisonCards extends Task{
    static difficulties = {
        1 : ()=>{
            let n = numbers(6).map(x=>{
                let d = DBPlaceValueRepresentation.random(1,1,0,0);
                return d;
            });
            return new TaskDecimalComparisonCards(1, n);
        },
        2 : ()=>{
            let i = randomInt(1,20)
            let n = numbers(6).map(x=>{
                let d = DBPlaceValueRepresentation.random(''+i,1,0,0);
                return d;
            });
            return new TaskDecimalComparisonCards(2, n);
        },
        3 : ()=>{
            let i = randomInt(1,20)
            let n = numbers(6).map(x=>{
                let d = DBPlaceValueRepresentation.random(''+i,3,0,0);
                return d;
            });
            return new TaskDecimalComparisonCards(3, n);
        },
        4 : ()=>{
            let i = randomInt(1,999)
            let n = numbers(6).map(x=>{
                let d = DBPlaceValueRepresentation.random(''+i,5,0,0);
                return d;
            });
            return new TaskDecimalComparisonCards(4, n);
        }
    }
    static deserializers = {
        numbers: function(x){
        return x.map(y=>DBPlaceValueRepresentation.fromNumber(y))
    }}
    public result;
    public guess;

    constructor(public difficulty, public numbers){
        super();
        console.log(this.difficulty, this.numbers)
        this.guess = this.numbers.map((x,i)=>{return{id: i, content: []}})
        this.result = Array.from(numbers).sort( (a,b)=>{
            if (a['lessThan'](b)) return -1;
            if (a['equals'](b)) return 0;
            return 1;
        })
    }
    check(){
        console.log(this.result.map(x=>x.string), this.guess.sort((a,b)=>a.id<b.id ? -1 : (a.id==b.id ? 0 : 1)))
        if (this.guess.sort((a,b)=>a.id<b.id ? -1 : (a.id==b.id ? 0 : 1)).map(x=>{console.log(x.content[0]);return x.content[0]}).filter((x,i)=>this.result[i].equals(x)).length === this.result.length){
            console.log("genau");
            this.emit('correct');  
        }

        else {
          console.log("leider nicht")
          this.emit('wrong');  
        }
    }
}