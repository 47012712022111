import { BrowserModule } from '@angular/platform-browser';
import { NgModule,Injector } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { FunctionSetCardComponent } from './Components/Function-Setgame/function-set-card/function-set-card.component';
import { FunctionDescriptionComponent } from './Components/Function-Setgame/function-description/function-description.component';
import { FunctionPlotComponent } from './Components/Function-Setgame/function-plot/function-plot.component';
import { FunctionValueTableComponent } from './Components/Function-Setgame/function-value-table/function-value-table.component';
import { KhanTexSpanComponent } from './Components/khan-tex-span/khan-tex-span.component';
import { FunctionSetGameComponent } from './Components/Function-Setgame/function-set-game/function-set-game.component';
import { FormsModule } from '@angular/forms';
import { createCustomElement } from '@angular/elements';
import { ExercisePaperComponent } from './Components/exercise-paper/exercise-paper.component';
import { ExercisePapersComponent } from './Components/exercise-papers/exercise-papers.component';
import { TaskProportionalIsitComponent } from './Components/task-proportional-isit/task-proportional-isit.component';
import { TutorComponent } from './Components/tutor/tutor.component';
import { TaskProportionalCompletionComponent } from './Components/task-proportional-completion/task-proportional-completion.component';
import { TaskMentalArithmeticComponent } from './Components/task-mental-arithmetic/task-mental-arithmetic.component';
import { CommatizePipe } from './Pipes/commatize.pipe';
import { TaskRuleOfThreeComponent } from './Components/task-rule-of-three/task-rule-of-three.component';
import { PointSetsComponent } from './Components/point-sets/point-sets.component';
import { PieSlicesComponent } from './Components/pie-slices/pie-slices.component';
import { FractionCircleComponent } from './Components/fraction-circle/fraction-circle.component';
import { FractionComponent } from './Components/fraction/fraction.component';
import { FractionRectangleComponent } from './Components/fraction-rectangle/fraction-rectangle.component';
import { NumberLineComponent } from './Components/number-line/number-line.component';
import { TaskFractionRecognizeComponent } from './Components/task-fraction-recognize/task-fraction-recognize.component';
import { TaskFractionCancelComponent } from './Components/task-fraction-cancel/task-fraction-cancel.component';
import { TaskFractionToPercentComponent } from './Components/task-fraction-to-percent/task-fraction-to-percent.component';
import { TaskPercentToFractionComponent } from './Components/task-percent-to-fractions/task-percent-to-fractions.component';
import { DurationPipe } from './Pipes/duration.pipe';
import { FlexTextHeightDirective } from './Directives/flex-text-height.directive';
import { PercentageBarComponent } from './Components/percentage-bar/percentage-bar.component';
import 'lodash';
import { TaskComponent } from './Components/task/task.component';
import { FlexWidthDirective } from './Directives/flex-witdh.directive';
import { TaskPercentCalculationsComponent } from './Components/task-percent-calculations/task-percent-calculations.component';
import { TaskPercentConceptsComponent } from './Components/task-percent-concepts/task-percent-concepts.component';
import { RewardSelectorComponent } from './Components/reward-selector/reward-selector.component';
import { CoordinateSystemComponent } from './Components/coordinate-system/coordinate-system.component';
import { TaskUnitConversionComponent } from './Components/task-unit-conversion/task-unit-conversion.component';
import { TaskRoundComponent } from './Components/task-round/task-round.component';
import { DigitsPipe } from './Pipes/digits.pipe';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DragModule } from './Drag/app.module';
import { MatButtonModule, 
  MatCheckboxModule, 
  MatToolbarModule,
  MatMenuModule,
  MatIconModule,
  MatSliderModule,
  MatFormFieldModule,
  MatInputModule,
  MatTooltipModule,
  MatSidenavModule,
  MatTooltip,
  MatSnackBarModule,
  MatSlideToggleModule,
  MatSelectModule,
} from '@angular/material';
import { SafeUrlPipe } from './Pipes/safe-url.pipe';
import { AnswerCheckButtonComponent } from './Components/answer-check-button/answer-check-button.component';
import { NumberInputComponent } from './Components/number-input/number-input.component';
import { TaskListsComponent } from './Components/task-lists/task-lists.component';
import { EquationComponent } from './Components/equation/equation.component';
import { NumberPlaceValueBoardComponent } from './Components/number-place-value-board/number-place-value-board.component';
import { SafeHtmlPipe } from './Pipes/safe-html.pipe';
import { TaskCommaSlidingComponent } from './Components/task-comma-sliding/comma-sliding.component';
import { TaskMarkPointComponent } from './Components/task-mark-point/task-mark-point.component';
import { OptionItemComponent } from './Components/option-item/option-item.component';
import { HttpClientModule } from '@angular/common/http';
import { TaskFractionExpansionComponent } from './Components/task-fraction-expansion/task-fraction-expansion.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DragDropTestComponent } from './drag-drop-test/drag-drop-test.component';
import { DragAreaDirective } from './DragnDrop/drag-area.directive';
import { DraggableDirective } from './DragnDrop/draggable.directive';
import { DropAreaDirective } from './DragnDrop/drop-area.directive';
import { DragService } from './DragnDrop/drag.service';
import { DragDisplayItemDirective } from './DragnDrop/drag-display-item.directive';
import { DragHandleDirective } from './DragnDrop/drag-handle.directive';
import { WorksheetGeneratorComponent } from './Components/worksheet-generator/worksheet-generator.component';
import { WorksheetGeneratorConfigurationPanelComponent } from './Components/worksheet-generator-configuration-panel/worksheet-generator-configuration-panel.component';
import { WorksheetGeneratorConfigurationItemComponent } from './Components/worksheet-generator-configuration-item/worksheet-generator-configuration-item.component';
import { TaskNumberlineComponent } from './Components/task-numberline/task-numberline.component';
import { TaskNegativeAdditionComponent } from './Components/task-negative-addition/task-negative-addition.component';
import { ToolbarMenusComponent } from './Components/toolbar-menus/toolbar-menus.component';
import { TutorToolbarComponent } from './Components/tutor-toolbar/tutor-toolbar.component';
import { DifficultyAndOptionsComponent } from './Components/difficulty-and-options/difficulty-and-options.component';
import { TaskCompareNegativeNumbersComponent } from './Components/task-compare-negative-numbers/task-compare-negative-numbers.component';
import { TaskNegativeNumbersWithoutParenthesesComponent } from './Components/task-negative-numbers-without-parentheses/task-negative-numbers-without-parentheses.component';
import { ComparisonSelectorComponent } from './Components/comparison-selector/comparison-selector.component';
import { StringInputComponent } from './Components/string-input/string-input.component';
import { PinBoardComponent } from './Components/pin-board/pin-board.component';
import { FractionRectangleTriangulatedComponent } from './Components/fraction-rectangle-triangulated/fraction-rectangle-triangulated.component';
import { TaskFractionWordProblemSharingComponent } from './Components/task-fraction-word-problem-sharing/task-fraction-word-problem-sharing.component';
import { EigthGradeComponent } from './eigth-grade/eigth-grade.component';
import { TaskFractionOfNumberComponent } from './Components/task-fraction-of-number/task-fraction-of-number.component';
import { TaskFractionComparisonComponent } from './Components/task-fraction-comparison/task-fraction-comparison.component';
import { DataProtectionComponent } from './Components/data-protection/data-protection.component';
import { CookieLawModule } from 'angular2-cookie-law';
import { FractionWallComponent } from './Components/fraction-wall/fraction-wall.component';
import { TaskFractionExpandWithComponent } from './Components/task-fraction-expand-with/task-fraction-expand-with.component';
import { TaskFractionExpansionFactorComponent } from './Components/task-fraction-expansion-factor/task-fraction-expansion-factor.component';
import { TaskFractionChooseIdenticalComponent } from './Components/task-fraction-choose-identical/task-fraction-choose-identical.component';
import { TaskTermSimplificationComponent } from './Components/task-term-simplification/task-term-simplification.component';
import { PageOrigamiComponent } from './Components/page-origami/page-origami.component';
import { TaskRegistryService } from './Services/task-registry.service';
import { TaskFlashcardTermsComponent } from './Components/task-flashcard-terms/task-flashcard-terms.component';
import { TasksMenuComponent } from './Components/tasks-menu/tasks-menu.component';
import { TaskItemComponent } from './Components/task-item/task-item.component';
import { DominoComponent } from './domino/domino.component';
import { MultiSectorBandDiscComponent } from './multi-sector-band-disc/multi-sector-band-disc.component';
import { TaskDecimalWriteComponent } from './Components/task-decimal-write/task-decimal-write.component';
import { TaskDecimalComparisonComponent } from './Components/task-decimal-comparison/task-decimal-comparison.component';
import { TaskDecimalComparisonCardsComponent } from './Components/task-decimal-comparison-cards/task-decimal-comparison-cards.component';
import { TaskDecimalsComparisonInbetweenComponent } from './Components/task-decimals-comparison-inbetween/task-decimals-comparison-inbetween.component';
import { WelcomePageComponent } from './Components/welcome-page/welcome-page.component';
import { ClassTileComponent } from './Components/class-tile/class-tile.component';
import { TaskDecimalsNumberlineFindComponent } from './Components/task-decimals-numberline-find/task-decimals-numberline-find.component';
import { TaskDecimalsNumberlineTellComponent } from './Components/task-decimals-numberline-tell/task-decimals-numberline-tell.component';
import { GefuehlskartenComponent } from './Components/gefuehlskarten/gefuehlskarten.component';
import { TaskDecimalRoundComponent } from './Components/task-decimal-round/task-decimal-round.component';
import { TaskDecimalNamePlacesComponent } from './Components/task-decimal-name-places/task-decimal-name-places.component';
import { MathGridComponent } from './Components/math-grid/math-grid.component';
import { TokenHolderComponent } from './Components/token-holder/token-holder.component';
import { TokenHoldersContainerComponent } from './Components/token-holders-container/token-holders-container.component';
import { PlaceValueTokenSystemComponent } from './Components/place-value-token-system/place-value-token-system.component';
import { KlasseSechsPageComponent } from './Components/klasse-sechs-page/klasse-sechs-page.component';
import { KlasseSiebenPageComponent } from './Components/klasse-sieben-page/klasse-sieben-page.component';
import { HelpTaskUnitConversionComponent } from './Components/help-task-unit-conversion/help-task-unit-conversion.component';
import { HelpTaskItemComponent } from './Components/help-task-item/help-task-item.component';
import { TaskCompareUnitsComponent } from './Components/task-compare-units/task-compare-units.component';
import { TaskFractionAdditionComponent } from './Components/task-fraction-addition/task-fraction-addition.component';
import { HelpTaskFractionAdditionComponent } from './Components/help-task-fraction-addition/help-task-fraction-addition.component';
import { TaskNumberSetsComponent } from './Components/task-number-sets/task-number-sets.component';
import { TaskFractionToMixedNumberComponent } from './Components/task-fraction-to-mixed-number/task-fraction-to-mixed-number.component';
import { MixedNumberComponent } from './Components/mixed-number/mixed-number.component';
import { TaskRectangleShapesComponent } from './Components/task-rectangle-shapes/task-rectangle-shapes.component';
import { RectangleShapeComponent } from './Components/rectangle-shape/rectangle-shape.component';
import { TaskRectanglesComponent } from './Components/task-rectangles/task-rectangles.component';
import { TaskNegativeNumbersMultiplicationComponent } from './Components/task-negative-numbers-multiplication/task-negative-numbers-multiplication.component';
import { StudentTestComponent } from './Components/student-test/student-test.component';
import { environment } from '../environments/environment';
import { TaskEquationsWordproblemComponent } from './Components/task-equations-wordproblem/task-equations-wordproblem.component';
import { SheetEquationWordproblemsComponent } from './Components/sheet-equation-wordproblems/sheet-equation-wordproblems.component';
import { TaskEquationsGraphsComponent } from './Components/task-equations-graphs/task-equations-graphs.component';
import { TaskEquivalenceTransformationComponent } from './Components/task-equivalence-transformation/task-equivalence-transformation.component';
import { TaskNumberBaseChangeComponent } from './Components/task-number-base-change/task-number-base-change.component';
import { OptionsTaskNumberBaseChangeComponent } from './Components/options-task-number-base-change/options-task-number-base-change.component';
import { KlasseZehnCitPageComponent } from './Components/klasse-zehn-cit-page/klasse-zehn-cit-page.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { LogicGateComponent } from './logic-gate/logic-gate.component';
import { BinarySwitchComponent } from './binary-switch/binary-switch.component';
import { BinaryLampComponent } from './binary-lamp/binary-lamp.component';
import { LogicGatesPageComponent } from './logic-gates-page/logic-gates-page.component';
import { AdderPageComponent } from './adder-page/adder-page.component';

/**
 * Anlegen einer neuen Task:
 * Eine Task besteht aus:
 * -- einem TaskComponent
 * -- einem TaskModel
 * - TaskModels erben von Task aus DBTask.ts (am besten "!mode-task-template" kopieren)
 * - TaskModels sind im Ordner Task-Models
 * - TaskModels heißen model-task-*.ts
 * 
 * - TaskComponents erben von TaskComponent
 * - TaskComponents sind im Ordner Components (Das lässt sich schon bei der Generierung mit ng g component Components/... machen)
 * - TaskComponents heißen task-*
 * - TaskComponent muss dann zusätzlich HIER unter entryComponents eingetragen werden (das geht scheinbar auch nicht dynamisch)
 * 
 * - Unter Tasks in TaskRegistry werden url, TaskModel und Component zusammengeklebt
 * 
 * - In dieser Datei unter "paths" muss es dann noch eingetragen werden, damit es im Navigationsmenü auftaucht
 */
/**
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
ALLE AUFGABEN MIT FRACTIONS BRAUCHEN NOCH DESERIALIZERS!!!!!!
also: (x)=>new Fraction(...x)
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 */


export const paths =     [ 
{display: "Klasse 7" ,
  children: [
/*    { path: 'dreisatz'},
    { path: 'proportionale-zuordnungen'},
  { display: "Prozentrechnung", children: [
    {path: 'prozente-berechnen'},
    {path: 'bruch-prozent'},
    {path: 'prozent-bruch'},
    {path: 'prozent-begriffe'},
    ]},  
  { display: "Bruchrechnung", children: [
    {path: 'bruch-erkennen'},
    {path: 'bruch-kuerzen'},
    {path: 'bruch-erweitern'},
    {path: 'prozente-berechnen'},
    {path: 'bruch-prozent'},
    {path: 'prozent-bruch'},
  ]},  */      
  {display: "Negative Zahlen", children: [
    {path: 'negative-zahlen-vergleichen'},
    {path: 'negative-zahlen-ohne-klammern'},   
    {path: 'negative-zahlen-addieren-und-subtrahieren'},
    {path: 'negative-zahlen-zahlenstrahl-finden'},
    {path: 'negative-zahlen-zahlenstrahl-benennen'},
    {path: 'negative-zahlen-multiplizieren'},
  ]},
  {display: "Gleichungen und Terme", children: [
    {path: 'gleichungen-textaufgaben'},
    //{path: 'gleichungen-graphisch'}
  ]}]},   /*  ]},   /*     
  {display: "Terme", children: [
    {path: 'terme-vereinfachen'}
  ]},  
  
  {path: 'kopfrechnen'},
  {path: 'zehnerpotenzen-kommas-schieben'},*/
  /*]},*/
  {path: 'kopfrechnen'},
  {display: "Klasse 10 (CIT)", children: [
        {path: 'zahlen-umwandeln'}
                /*    {path: 'prozente-berechnen'},
    {path: 'bruch-prozent'},
    {path: 'prozent-bruch'},*/
  ]},
  {display: "Klasse 6", children: [
    {path: 'karteikarten-begriffe'},
      { display: "Bruchrechnung", children: [
        {path: 'bruch-erweitern'},
        {path: 'bruch-erweitern-mit'},
        {path: 'bruch-erweitern-3'},
        {path: 'bruch-erkennen'},
        {path: 'bruch-gleichwertig'},
        {path: 'bruch-textaufgaben-teilen'},
        {path: 'brueche-vergleichen'},
        {path: 'anteile'},
        {path: 'gemischte-zahlen'},
        {path: 'mit-bruechen-rechnen'}
        /*    {path: 'prozente-berechnen'},
    {path: 'bruch-prozent'},
    {path: 'prozent-bruch'},*/
  ]},
{ display: "Dezimalzahlen", children : [
    {path: 'dezimalzahlen-stellen-nennen'},
    {path: 'dezimalzahlen-schreiben'},
    {path: 'dezimalzahlen-vergleichen'},
    //{path: 'dezimalzahlen-vergleichen-karten'},
    {path: 'dezimalzahlen-vergleichen-zwischenzahl'},
    {path: 'dezimalzahlen-zahlenstrahl-finden'},
    {path: 'dezimalzahlen-zahlenstrahl-benennen'},
    {path: 'dezimalzahlen-runden'},
    {path: 'zehnerpotenzen-kommas-schieben'},
  ]}
]},
{display: "Klasse 5b", children: [
  /*{display: "Karteikartentest", children: [
    {path: 'karteikarten-begriffe'}
    ]},  
  {display: "Geometrie", children: [
    {path: 'falten'}
    ]},
  {display: "Brüche", children: [
      {path: 'bruch-erweitern'},
      {path: 'bruch-erweitern-mit'},
      {path: 'bruch-erweitern-3'},
      {path: 'bruch-erkennen'},
      {path: 'bruch-gleichwertig'},
      {path: 'bruch-textaufgaben-teilen'},
      {path: 'brueche-vergleichen'},
      {path: 'anteile'},
    ]},
    */
  /*,
  {path: 'runden'},*/
  {path: 'umfang'},
  {path: 'einheiten'},
  {path: 'einheiten-vergleichen'},
  {path: 'zehnerpotenzen-kommas-schieben'},

  //{path: 'markiere-den-punkt'},
  ]},
]
export const routes: Routes = [
  { path: 'test', component: StudentTestComponent, data: {
    time: 45 * 60 * 1000,
    rewards: [
      {time: 45 * 60 * 1000, percentage: 93, url: 'assets/erfolg.png'}
    ],
    tasks : [
    {task: 'einheiten', difficulty: 1, options: {lengths : false, area : false, weights: true}},
    {task: 'einheiten', difficulty: 1, options: {lengths : true, area : false, weights: false}},
    {task: 'einheiten', difficulty: 1, options: {lengths : false, area : true, weights: false}},
    {task: 'einheiten', difficulty: 2, options: {lengths : false, area : false, weights: true}},
    {task: 'einheiten', difficulty: 2, options: {lengths : true, area : false, weights: false}},
    {task: 'einheiten', difficulty: 2, options: {lengths : false, area : true, weights: false}},
    {task: 'einheiten', difficulty: 3, options: {lengths : false, area : false, weights: true}},
    {task: 'einheiten', difficulty: 3, options: {lengths : true, area : false, weights: false}},
    {task: 'einheiten', difficulty: 3, options: {lengths : false, area : true, weights: false}},
    {task: 'einheiten-vergleichen', difficulty: 1, options: {lengths : false, area : false, weights: true}},
    {task: 'einheiten-vergleichen', difficulty: 1, options: {lengths : true, area : false, weights: false}},
    {task: 'einheiten-vergleichen', difficulty: 1, options: {lengths : false, area : true, weights: false}},
    {task: 'einheiten-vergleichen', difficulty: 1, options: {lengths : false, area : false, weights: true}},
    {task: 'einheiten-vergleichen', difficulty: 1, options: {lengths : true, area : false, weights: false}},
    {task: 'einheiten-vergleichen', difficulty: 1, options: {lengths : false, area : true, weights: false}},
    {task: 'umfang', difficulty: 1},
    {task: 'umfang', difficulty: 2},

  ]},

},
{ path: 'test-7-gleichungen-aufstellen', component: StudentTestComponent, data: {
  time: 45 * 60 * 1000,
  rewards: [
    {time: 45 * 60 * 1000, percentage: 93, url: 'assets/erfolg.png'}
  ],
  tasks : [
  {task: 'gleichungen-textaufgaben', difficulty: 1},
  {task: 'gleichungen-textaufgaben', difficulty: 1},
  {task: 'gleichungen-textaufgaben', difficulty: 1},
  {task: 'gleichungen-textaufgaben', difficulty: 1},
  {task: 'gleichungen-textaufgaben', difficulty: 1},
  {task: 'gleichungen-textaufgaben', difficulty: 1},
  {task: 'gleichungen-textaufgaben', difficulty: 1},
  {task: 'gleichungen-textaufgaben', difficulty: 1},
  {task: 'gleichungen-textaufgaben', difficulty: 1},
  {task: 'gleichungen-textaufgaben', difficulty: 1},
  {task: 'gleichungen-textaufgaben', difficulty: 1},
  {task: 'gleichungen-textaufgaben', difficulty: 1},
  {task: 'gleichungen-textaufgaben', difficulty: 1}
]}
},
/*

        {path: 'bruch-erweitern'}, 2diff
        {path: 'bruch-erweitern-mit'}, 3diff
        {path: 'bruch-erweitern-3'}, 3diff
        {path: 'bruch-erkennen'}, 1diff
        {path: 'bruch-gleichwertig'}, 2diff
        {path: 'bruch-textaufgaben-teilen'}, 1diff
        {path: 'brueche-vergleichen'}, 3diff
        {path: 'anteile'}, 2diff
        {path: 'mit-bruechen-rechnen'} 3diff options: '+' '-' '•' ':' 'streng' (<-- muss gekürzt werden)
 
{ path: 'test-6-Bruchrechnung', component: StudentTestComponent, data: {
  time: 45 * 60 * 1000,
  rewards: [
    {time: 45 * 60 * 1000, percentage: 93, url: 'assets/erfolg.png'}
  ],
  tasks : [
  {task: 'einheiten', difficulty: 1, options: {lengths : false, area : false, weights: true}},
]}
},
*/
  { path: 'test-7-rationale-zahlen', component: StudentTestComponent, data: {
    time: 20 * 60 * 1000,
    rewards: [
      {time: 20 * 60 * 1000, percentage: 95, url: 'assets/e.png'}
    ],
    tasks: [
    {task: 'negative-zahlen-vergleichen', difficulty: 1},
    {task: 'negative-zahlen-vergleichen', difficulty: 1},
    {task: 'negative-zahlen-vergleichen', difficulty: 2},
    {task: 'negative-zahlen-vergleichen', difficulty: 2},
    {task: 'negative-zahlen-vergleichen', difficulty: 2},
    {task: 'negative-zahlen-vergleichen', difficulty: 2},
    {task: 'negative-zahlen-ohne-klammern', difficulty: 1, options: {addition : true, subtraction : false}},
    {task: 'negative-zahlen-ohne-klammern', difficulty: 1, options: {addition : false, subtraction : true}},
    {task: 'negative-zahlen-ohne-klammern', difficulty: 1, options: {addition : false, subtraction : true}},
    {task: 'negative-zahlen-addieren-und-subtrahieren', difficulty: 1, options: {addition : false, subtraction : true}},
    {task: 'negative-zahlen-addieren-und-subtrahieren', difficulty: 1, options: {addition : true, subtraction : false}},
    {task: 'negative-zahlen-addieren-und-subtrahieren', difficulty: 1, options: {addition : false, subtraction : true}},
    {task: 'negative-zahlen-addieren-und-subtrahieren', difficulty: 5, options: {addition : false, subtraction : true}},
    {task: 'negative-zahlen-addieren-und-subtrahieren', difficulty: 5, options: {addition : true, subtraction : false}},
    {task: 'negative-zahlen-addieren-und-subtrahieren', difficulty: 5, options: {addition : false, subtraction : true}},
    {task: 'negative-zahlen-addieren-und-subtrahieren', difficulty: 1, options: {addition : false, subtraction : true}},
    {task: 'negative-zahlen-addieren-und-subtrahieren', difficulty: 1, options: {addition : true, subtraction : false}},
    {task: 'negative-zahlen-zahlenstrahl-finden', difficulty: 1},
    {task: 'negative-zahlen-zahlenstrahl-finden', difficulty: 2},
    {task: 'negative-zahlen-zahlenstrahl-finden', difficulty: 2},
    {task: 'negative-zahlen-zahlenstrahl-benennen', difficulty: 1},
    {task: 'negative-zahlen-zahlenstrahl-benennen', difficulty: 2},
    {task: 'negative-zahlen-zahlenstrahl-benennen', difficulty: 2},
    {task: 'negative-zahlen-zahlenstrahl-finden', difficulty: 1},
    {task: 'negative-zahlen-zahlenstrahl-finden', difficulty: 2},
    {task: 'negative-zahlen-multiplizieren', difficulty: 1},
    {task: 'negative-zahlen-multiplizieren', difficulty: 2},
    {task: 'negative-zahlen-multiplizieren', difficulty: 3},
    {task: 'negative-zahlen-multiplizieren', difficulty: 4},
    {task: 'negative-zahlen-multiplizieren', difficulty: 5},
    {task: 'negative-zahlen-multiplizieren', difficulty: 5},
    {task: 'negative-zahlen-multiplizieren', difficulty: 5}
  ]
  }
},
  { path: 'aufgabe', component: TutorComponent},
  { path: 'datenschutz-erklaerung', component: DataProtectionComponent},
  { path: 'arbeitsblatt-erstellen', component: WorksheetGeneratorComponent},
  { path: 'klasse-6',component: KlasseSechsPageComponent},
  { path: 'klasse-7',component: KlasseSiebenPageComponent},
  { path: 'CIT', children: [
    { path: 'klasse-10', component: KlasseZehnCitPageComponent}
  ]},
  {path: 'logic-gates', component: LogicGatesPageComponent},
  {path: '3BA', component: AdderPageComponent},

  { path: '',component: WelcomePageComponent},
  { path: 'arbeitsblatt-7-1', component: SheetEquationWordproblemsComponent},
  { path: '**', 
    redirectTo: '/',
 }
]

@NgModule({
  declarations: [
    ClassTileComponent,
    WelcomePageComponent,
    AppComponent,
    FunctionSetCardComponent,
    FunctionDescriptionComponent,
    FunctionPlotComponent,
    FunctionValueTableComponent,
    KhanTexSpanComponent,
    FunctionSetGameComponent,
    ExercisePaperComponent,
    ExercisePapersComponent,
    TaskProportionalIsitComponent,
    TutorComponent,
    TaskProportionalCompletionComponent,
    TaskMentalArithmeticComponent,
    CommatizePipe,
    TaskRuleOfThreeComponent,
    PointSetsComponent,
    PieSlicesComponent,
    FractionCircleComponent,
    FractionComponent,
    FractionRectangleComponent,
    NumberLineComponent,
    TaskFractionRecognizeComponent,
    TaskFractionCancelComponent,
    TaskFractionToPercentComponent,
    TaskPercentToFractionComponent,
    DurationPipe,
    FlexTextHeightDirective,
    PercentageBarComponent,
    TaskComponent,
    FlexWidthDirective,
    TaskPercentCalculationsComponent,
    TaskPercentConceptsComponent,
    RewardSelectorComponent,
    CoordinateSystemComponent,
    TaskUnitConversionComponent,
    TaskRoundComponent,
    DigitsPipe,
    SafeUrlPipe,
    AnswerCheckButtonComponent,
    NumberInputComponent,
    TaskListsComponent,
    EquationComponent,
    NumberPlaceValueBoardComponent,
    SafeHtmlPipe,
    TaskCommaSlidingComponent,
    TaskMarkPointComponent,
    OptionItemComponent,
    TaskFractionExpansionComponent,
    DragDropTestComponent,
    DragAreaDirective,
    DraggableDirective,
    DropAreaDirective,
    DragDisplayItemDirective,
    DragHandleDirective,
   WorksheetGeneratorComponent,
   WorksheetGeneratorConfigurationPanelComponent,
   WorksheetGeneratorConfigurationItemComponent,
    TaskNumberlineComponent,
    TaskNegativeAdditionComponent,
    ToolbarMenusComponent,
    TutorToolbarComponent,
    DifficultyAndOptionsComponent,
    TaskCompareNegativeNumbersComponent,
    TaskNegativeNumbersWithoutParenthesesComponent,
    ComparisonSelectorComponent,
    StringInputComponent,
    PinBoardComponent,
    FractionRectangleTriangulatedComponent,
    TaskFractionWordProblemSharingComponent,
    EigthGradeComponent,
    TaskFractionOfNumberComponent,
    TaskFractionComparisonComponent,
    DataProtectionComponent,
    FractionWallComponent,
    TaskFractionExpandWithComponent,
    TaskFractionExpansionFactorComponent,
    TaskFractionChooseIdenticalComponent,
    TaskTermSimplificationComponent,
    PageOrigamiComponent,
    TaskFlashcardTermsComponent,
    TasksMenuComponent,
    TaskItemComponent,
    DominoComponent,
    MultiSectorBandDiscComponent,
    TaskDecimalWriteComponent,
    TaskDecimalComparisonComponent,
    TaskDecimalComparisonCardsComponent,
    TaskDecimalsComparisonInbetweenComponent,
    TaskDecimalsNumberlineFindComponent,
    TaskDecimalsNumberlineTellComponent,
    GefuehlskartenComponent,
    TaskDecimalRoundComponent,
    TaskDecimalNamePlacesComponent,
    MathGridComponent,
    TokenHolderComponent,
    TokenHoldersContainerComponent,
    PlaceValueTokenSystemComponent,
    KlasseSechsPageComponent,
    KlasseSiebenPageComponent,
    HelpTaskUnitConversionComponent,
    HelpTaskItemComponent,
    TaskCompareUnitsComponent,
    TaskFractionAdditionComponent,
    OptionsTaskNumberBaseChangeComponent,
    HelpTaskFractionAdditionComponent,
    TaskNumberSetsComponent,
    TaskFractionToMixedNumberComponent,
    MixedNumberComponent,
    TaskRectangleShapesComponent,
    RectangleShapeComponent,
    TaskRectanglesComponent,
    TaskNegativeNumbersMultiplicationComponent,
    StudentTestComponent,
    TaskEquationsWordproblemComponent,
    SheetEquationWordproblemsComponent,
    TaskEquationsGraphsComponent,
    TaskEquivalenceTransformationComponent,
    TaskNumberBaseChangeComponent,
    OptionsTaskNumberBaseChangeComponent,
    KlasseZehnCitPageComponent,
    LogicGateComponent,
    BinarySwitchComponent,
    BinaryLampComponent,
    LogicGatesPageComponent,
    AdderPageComponent

  ],
  entryComponents: [
    OptionsTaskNumberBaseChangeComponent,
    TaskNumberBaseChangeComponent,
    TaskEquivalenceTransformationComponent,
    TaskFractionToMixedNumberComponent,
    TaskNegativeNumbersMultiplicationComponent,
    TaskRectanglesComponent,
    TaskRectangleShapesComponent,
    HelpTaskFractionAdditionComponent,    
    TaskFractionAdditionComponent,    
    TaskCompareUnitsComponent,    
    HelpTaskUnitConversionComponent,
    TaskRuleOfThreeComponent,
    TaskProportionalCompletionComponent,
    TaskDecimalNamePlacesComponent,    
    TaskDecimalRoundComponent,    
    TaskDecimalsNumberlineFindComponent,
    TaskDecimalsNumberlineTellComponent,    
    TaskDecimalsComparisonInbetweenComponent,
    TaskDecimalComparisonCardsComponent,
    TaskDecimalComparisonComponent,
    TaskDecimalWriteComponent,
    FunctionSetCardComponent,
    FunctionDescriptionComponent,
    FunctionPlotComponent,
    FunctionValueTableComponent,
    KhanTexSpanComponent,
    FunctionSetGameComponent,
    ExercisePaperComponent,
    ExercisePapersComponent,
    TaskProportionalIsitComponent,
    TutorComponent,
    TaskProportionalCompletionComponent,
    TaskMentalArithmeticComponent,
    TaskRuleOfThreeComponent,
    PointSetsComponent,
    PieSlicesComponent,
    FractionCircleComponent,
    FractionComponent,
    FractionRectangleComponent,
    NumberLineComponent,
    TaskFractionRecognizeComponent,
    TaskFractionCancelComponent,
    TaskFractionToPercentComponent,
    TaskPercentToFractionComponent,
    PercentageBarComponent,
    TaskComponent,
    TaskPercentCalculationsComponent,
    TaskPercentConceptsComponent,
    RewardSelectorComponent,
    CoordinateSystemComponent,
    TaskUnitConversionComponent,
    TaskRoundComponent,
    AnswerCheckButtonComponent,
    NumberInputComponent,
    TaskListsComponent,
    EquationComponent,
    NumberPlaceValueBoardComponent,
    TaskCommaSlidingComponent,
    TaskMarkPointComponent,
    OptionItemComponent,
    TaskFractionExpansionComponent,
    DragDropTestComponent,
   WorksheetGeneratorComponent,
   WorksheetGeneratorConfigurationPanelComponent,
   WorksheetGeneratorConfigurationItemComponent,
    TaskNumberlineComponent,
    TaskNegativeAdditionComponent,
    ToolbarMenusComponent,
    TutorToolbarComponent,
    DifficultyAndOptionsComponent,
    TaskCompareNegativeNumbersComponent,
    TaskNegativeNumbersWithoutParenthesesComponent,
    ComparisonSelectorComponent,
    StringInputComponent,
    PinBoardComponent,
    FractionRectangleTriangulatedComponent,
    TaskFractionWordProblemSharingComponent,
    EigthGradeComponent,
    TaskFractionOfNumberComponent,
    TaskFractionComparisonComponent,
    DataProtectionComponent,
    FractionWallComponent,
    TaskFractionExpandWithComponent,
    TaskFractionExpansionFactorComponent,
    TaskFractionChooseIdenticalComponent,
    TaskTermSimplificationComponent,
    PageOrigamiComponent,    
    TaskFlashcardTermsComponent,
    TaskEquationsWordproblemComponent,
    SheetEquationWordproblemsComponent,
  ],
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    RouterModule.forRoot(
      routes,
      { enableTracing: false } // <-- debugging purposes only
    ),
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    DragModule,
    MatButtonModule, 
    MatCheckboxModule, 
    MatToolbarModule,
    MatMenuModule,
    MatIconModule,
    MatSliderModule,
    MatFormFieldModule,
    MatInputModule,
    MatTooltipModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    MatSelectModule,
    HttpClientModule,
    DragDropModule,   
    CookieLawModule, // import Angular's CookieLaw modules


  ],
  providers: [
    DragService,
    TaskRegistryService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { 
  constructor(private injector: Injector){
    const customEl : any= createCustomElement(KhanTexSpanComponent, { injector });
    customElements.define('dhb-khan-tex-span', customEl);
  }
}

//aufgaben zu groß firefox
