import { Component, OnInit } from '@angular/core';
import { Switch, XorGate, AndGate, Lamp } from '../logic-gate';

@Component({
  selector: 'app-adder-page',
  templateUrl: './adder-page.component.html',
  styleUrls: ['./adder-page.component.css']
})
export class AdderPageComponent implements OnInit {
  public logicGates = [];
  public switches = [];
  public lamps = [];
  
  constructor() { 

    let A1er = new Switch();
    A1er.title = 'A1er';
    let A2er = new Switch();
    A2er.title = 'A2er';
  
    let A3er = new Switch();
    A3er.title = 'A4er';
  
    let B1er = new Switch();
    B1er.title = 'B1er';
  
    let B2er = new Switch();
    B2er.title = 'B2er';
  
    let B3er = new Switch('B4er');
  
    let a = new XorGate();
    a.inputs = [A1er, B1er];
    a.title = 'a';
  
    let b = new AndGate();
    b.inputs = [A1er, B1er];
    b.title = 'b';
  
    let c = new XorGate();
    c.inputs = [A2er, B2er];
    c.title = 'c';
  
    let d = new AndGate();
    d.inputs = [A2er, B2er];
    d.title = 'd';
  
    let e = new XorGate();
    e.inputs = [c,b];
    e.title = 'e';
  
    let f = new AndGate();
    f.inputs = [c,b];
    f.title = 'f';
  
    let g = new XorGate();
    g.inputs = [d,f];
    g.title = 'g';
  
  
    let h = new XorGate();
    h.inputs = [A3er, B3er];
    h.title = 'h';
  
    let i = new AndGate();
    i.inputs = [A3er, B3er];
    i.title = 'i';
  
    let j = new XorGate();
    j.inputs = [h,g];
    j.title = 'j';
  
    let k = new AndGate();
    k.inputs = [h,g];
    k.title = 'k';
  
    let l = new XorGate();
    l.inputs = [k,i];
    l.title = 'l';
  
    let E1er = new Lamp();
    E1er.inputs = [a];
    E1er.title = 'E1er';
  
    let E2er = new Lamp();
    E2er.inputs = [e];
    E2er.title = 'E2er';
  
    let E3er = new Lamp();
    E3er.inputs = [j];
    E3er.title = 'E4er';
  
    let Carry = new Lamp();
    Carry.inputs = [l];
    Carry.title = 'Carry';
  
    
  
    this.logicGates = [a,b,c,  d,e,f,  g,h,i,   j,k,l]; //12
    this.switches = [A1er, A2er, A3er,   B1er, B2er, B3er]; //6
    this.lamps = [E1er, E2er, E3er, Carry]; //4
    //=22
  }

  ngOnInit() {
  }

}
