import { Operation } from './Operation';
import { RationalNumber } from './RationalNumber';
import { Fraction } from '../Fraction';

export class Addition extends Operation{
    static rex: any =  /^\+$/;

    constructor(public operands, public id){
        super(id);
    }
    get value(){
        let r = this.operands.reduce((a,b)=> {
            return a.value.add(b.value)}, new RationalNumber(0)
        );
        r.sign = Fraction.computeSign(this, r);
        return r;

    }

    get nameOfResult(){
        return new Noun(Genera.FEMININE, 'Summe', 'Summen', 'Summe');
    }
    get nameOfProcess(){
        return new Noun(Genera.FEMININE, 'Addition', 'Additionen', 'Addition');
    }
    get verb(){
        return new Verb('addier');
    }
    get namesOfOperands(){
        return this.operands.map(x=>new Noun(Genera.MASCULINE, 'Summand', 'Summanden', 'Summand'));
    }
    get angularString(){
        return super.angularString.replace(/CHILD_CLASS_CONTENT/, 
            this.operands.map(x=>x.angularString).join(`operator(+)[${this.id}]`))
    }
    string(){
        return super.string(this.operands.map(x=>x.string()).join('+'));
    }
}