import { Component, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { numbers, linearScale, equal } from '../../general';

@Component({
  selector: 'app-number-line',
  templateUrl: './number-line.component.html',
  styleUrls: ['./number-line.component.css']
})
export class NumberLineComponent implements OnChanges {
  @Output() selectionChanged = new EventEmitter();
  
  @Input() public clickable = true;
  @Input() public start = 0;
  @Input() public end = 0.0002;
  @Input() public smallestStep = "0.000001";
  @Input() public bigSteps = {intervals: [{value: 10, marked: false}, {value: 100, marked: true}], offset: 0}; //every 5th smallestStep,every 10th smalleststep
  @Input() public marks = [
    {text: '\u2B07', place: 0.000061, position: 'above'}, 
    {text: '\u2B06', place: 0.00012, position: 'below'},
  ];
  public ticks = [];
  public differences = this.bigSteps.intervals.length;

  init(){
    let integerize = (d:string)=>{
      let t = d.split('.')
      if (t[1]){
        return {number: Number(t.join('')), factor: Math.pow(10,t[1].length)};
      }
      return {number: Number(d), factor: 1};
    }
    let o = integerize(this.smallestStep);
    let length = this.end - this.start;
    let arr = this.bigSteps.intervals.slice().reverse();
    this.ticks = numbers(length / Number(this.smallestStep))
      .map((x,i)=>{
        let cat = (arr.findIndex(y=> ((x+this.bigSteps.offset) % y.value) === 0));
        cat = cat != -1 ? cat : this.differences;
        let ms = this.marks.filter(y=>equal(x*Number(this.smallestStep) + this.start, y.place, 0.00000000001));
        let value = (x*o.number/o.factor)+ this.start;
        return {
          value: value,
          showValue: (''+value).replace(/\./,','),
          displayValue: i * (200/(length / Number(this.smallestStep))),
          category: cat,
          marked: arr[cat] && arr[cat].marked,
          textMarks: ms
        }
      })
  }

  constructor() { 
    this.init();
  }

  test(tick){
    console.log(tick.value);
    if (this.clickable){
      this.selectionChanged.emit(tick);
      this.ticks.forEach(x=>x.selected = false);
      tick.selected = true;  
    }
  }

  ngOnChanges() {
    this.init();
    console.log('CHANGE')
  }
}
