// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase : {
    apiKey: "AIzaSyBIGQzJXrNy0-lowgi4mX6L8Yj14lUcyeU",
    authDomain: "luminous-heat-681.firebaseapp.com",
    databaseURL: "https://luminous-heat-681.firebaseio.com",
    projectId: "luminous-heat-681",
    storageBucket: "luminous-heat-681.appspot.com",
    messagingSenderId: "476217173763",
    appId: "1:476217173763:web:a94de343d30c0d7fd02b36"
  }
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
