import { Operation } from './Operation';

export class Comparison extends Operation{
    static rex: any =  /^\=$/;

    constructor(public operands, public id){
        super(id);
    }
    /*get value(){
        return this.summands.reduce((a,b)=> a?.getValue + b?.getValue);
    }*/

    get nameOfResult(){
        return new Noun(Genera.MASCULINE, 'Vergleich', 'Vergleiche', 'Vergleichs');
    }
    get nameOfProcess(){
        return new Noun(Genera.MASCULINE, 'Vergleich', 'Vergleiche', 'Vergleichs');
    }
    get verb(){
        return new Verb('vergleich');
    }
    get namesOfOperands(){
        return [
            new Noun(Genera.FEMININE, 'linke Seite', 'linke Seiten', 'linken Seite'),
            new Noun(Genera.FEMININE, 'rechte Seite', 'rechte Seiten', 'rechten Seite'),
    ];
    }
    get angularString(){
        return super.angularString.replace(/CHILD_CLASS_CONTENT/, 
            this.operands.map(x=>x.angularString).join(`operator(:)[${this.id}]`))
    }
    string(){
        return super.string(this.operands.map(x=>x.string()).join('='));
        }    
}