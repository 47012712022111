import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-option-item',
  templateUrl: './option-item.component.html',
  styleUrls: ['./option-item.component.css']
})
export class OptionItemComponent implements OnInit {
  @Input() public tasktype;
  @Output() public hasChanged = new EventEmitter();
  
  @Input() public items = [];
  constructor() { }

  ngOnInit() {
    if (!this.items.length){
      this.items = Object.values(this.tasktype.options).filter(x=>!x['hidden']);
    }
  }

}
