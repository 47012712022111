import { Component, OnInit } from '@angular/core';
import { TaskComponent } from '../task/task.component';
import { Equation } from '../../DBEquation';

@Component({
  selector: 'app-task-negative-numbers-without-parentheses',
  templateUrl: './task-negative-numbers-without-parentheses.component.html',
  styleUrls: ['./task-negative-numbers-without-parentheses.component.css']
})
export class TaskNegativeNumbersWithoutParenthesesComponent extends TaskComponent {
  public name = "TaskNegativeNumbersWithoutParentheses"
  public equation;
  ngOnChanges() {
    this.equation = new Equation(this.task.string + '=§string[guess]', this.task)
  }
}
