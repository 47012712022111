import { Task } from "../DBTask";
import { randomInt, randomBool, randomItem, equal } from '../general';
export class TaskNegativeAddition extends Task{
    public name = "TaskNegativeAddition";
    static options = {
        addition: {
            model: true,
            type: 'bool',
            label: 'Addition',
            disabled: false,
            relations: (o, v)=>{
                if (!v) {
                    o.subtraction.model = true;            
                    o.type.model = '';
                }
            }
        },
        subtraction : {
            model: false,
            type: 'bool',
            label: 'Subtraktion',
            disabled: false,
            relations: (o, v)=>{
                if (!v){
                     o.addition.model = true;
                     o.type.model = '';
                }            
            }
        },
        type : {
            hidden: false,
            model: '',
            type: 'string',
            label: 'Typ',
            disabled: false,
            relations: (o, v)=>{ 
                if (v.length){
                    if (/^\-+$/.test(v)) o.subtraction.model = true;
                    if (/^\++$/.test(v)) o.addition.model = true;
                    else {
                        o.subtraction.model = true;
                        o.addition.model = true;
                    }
                }
            }
        }
    }

    static difficulties = {
        1 : ()=>{ 
            let operands = [randomInt(0,6), randomInt(0,6)];
            return new TaskNegativeAddition(1, operands)
        },
        2 : ()=>{
            let operands = [randomInt(0,30), randomInt(0,30)]; 
            return new TaskNegativeAddition(2, operands)
        },
        3 : ()=>{
            let operands = [randomInt(0,100), randomInt(0,100)]; 
            return new TaskNegativeAddition(3, operands)
        },
        4 : ()=>{
            let operands = [randomInt(0,100)+ (randomInt(0,9) * 0.1), randomInt(0,100)]; 
            return new TaskNegativeAddition(4, operands)
        },
        5 : ()=>{
            let operands = [randomInt(0,100) + (randomInt(0,9) * 0.1), randomInt(0,100) + (randomInt(0,9) * 0.1)]; 
            return new TaskNegativeAddition(5, operands)
        },
        6 : ()=>{
            let operands = [randomInt(0,100) + (randomInt(0,9) * 0.1), randomInt(0,100) + (randomInt(0,9) * 0.1), randomInt(0,100) + (randomInt(0,9) * 0.1)]; 
            return new TaskNegativeAddition(6, operands)
        },
    }

    public result;
    public guess;
    public helpHtml = `
    <h3>Beispiele</h3>
    <ul>
        <li>3 + (-7) = 3 - 7</li>
        <li>(-3) + 7 = 7 - 3</li>
        <li>(-3) + (-7) = -(3 + 7)</li>
    </ul>
    <ul>
        <li>3 - (-7) = 3 + 7</li>
        <li>-3 - 7 = -(3 + 7)</li>
        <li>(-3) - (-7) = 7 - 3</li>
    </ul>
    `;
    constructor(public difficulty:number, public operands, public operation?){
        super();
        if (TaskNegativeAddition.options.subtraction.model){
            if (TaskNegativeAddition.options.addition.model){
                this.operation = randomItem(['+', '-']);
            }
            else {
                this.operation = '-';
            }
        }
        else {
            this.operation = '+';
        }
        this.operands = this.operands.map((x,i)=>{
            if (!TaskNegativeAddition.options.type.model[i]){
                return -1 * x;
            }
            else {
                return x * Number(TaskNegativeAddition.options.type.model[i] + '1');
            }
        })
        this.result = this.operands.reduce((a,b)=>this.operation == '-' ? a-b : a+b);
    }
    get type(){
        return this.operands.map(x=>x<0 ? '-' : '+')
    }
    check(){
        console.log(this.result, this.guess)
        if (equal(this.guess, this.result, 0.001)){
            console.log("genau")
            this.emit('correct');  
        }
        else{
          console.log("leider nicht")
          this.emit('wrong');  
        }
    }
}