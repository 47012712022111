import { randomInt, randomItem, PRIMES, numberWithFactors, shakeTo, without } from './general';

let primeFactors = (n)=>{
    let i = 0;
    let result = [];
    while (PRIMES[i]<= Math.sqrt(n)){
        if (n % PRIMES[i] === 0){
            result.push(PRIMES[i]);
            n = n/PRIMES[i];
        }
        else {
            i++;
        }
    }
    if (n!=1)result.push(n);
    return result;
}
let coprimeNumber = (n, min?, max=100)=>{
    let pfs = primeFactors(n);
    let result = min + 1;
    let t = 1;
    while (result <= max){
        t = randomItem(PRIMES.filter(x=> !pfs.includes(x)).slice(0, 5))
        result *= t;
    }
    result /= t;
    return result;
}

export class Fraction{

    static coprimeNumber(n,min?,max?){return coprimeNumber(n,min,max)}
    static computeSign = (a,b) => ((a.sign === '-' ? -1 : 1) * (b.sign == '-' ? -1 : 1)  === -1 ? '-' : '+')

    static simple(){
        return new Fraction(1, randomItem([2,3,4,5,6,7,8,9,10]), '+')
    }
    static true(minD = 1, maxD=10){
        let den = randomInt(minD + 1, maxD);
        return new Fraction(randomInt(minD,den), den, '+');
    }
    static reduced(l1,l2, max){
         let enumeratorFs = shakeTo(l1-1, PRIMES.slice(0,l1));
         let enumerator = numberWithFactors(enumeratorFs, 0, max);
         let denominator = numberWithFactors(without(PRIMES.slice(0,l1), enumeratorFs).concat(PRIMES.slice(l1,l2)), enumerator, max*2);
         return new Fraction(enumerator, denominator,'+');
    }
    static getSign(n) {return n<0 ? '-' : '+'}

    static fromInteger(n){
        return new Fraction(Math.abs(n), 1, Fraction.getSign(n))
    }
    static fromDecimalString(str){
        let a = 1;
        let z = str.split(/(\.|,)/)[1];
        if (z){
            a = z.length;
        }
        str = str.replace(/(\.|,)/, '');
        let sign = '+';
        if (['-', '+'].includes(str.slice(0,1))) {
            sign = str.slice(0,1);
            str = str.slice(1);
        }
        return new Fraction(Number(str), Math.pow(10, a), sign);
    }


    constructor(public enumerator, public denominator, public sign='+'){
        this.enumerator = Number(this.enumerator);
        this.denominator = Number(this.denominator);
    }
    /*commonDenominatorPrimes(frac){
        let p1 = this.primeFactorsDenominator;
        let p2 = frac.primeFactorsDenominator;
        let p = p1.slice(0);
        for (let e=0; e<p2.length; e++){
            let i = p1.indexOf(p2[e]);
            if (i!=-1){
                p1.splice(i,1);
                p2.splice(e,1);
            }
        }
        p = p.concat(p2);
        p.sort();
        return p;
    }*/
    get signValue() {return this.sign == '-' ? -1 : 1}
    commonDenominatorPrimes(...fracs){
        let ps = [this.primeFactorsDenominator].concat(fracs.map(x=>x.primeFactorsDenominator));
        //console.log('COMMONDENOMINATORPRIMES1: ', ps)
        let p = ps.map(x=>x.slice(0));
        let commonPrimes = [];
        for (let e=0; e<p[0].length; e++){
            let i = ps.map(x=>x.indexOf(p[0][e]))
            let t = i.map(x=>x != -1).reduce((a,b)=> a && b, true);
            console.log("T: ", t)
            if (t){
                commonPrimes.push(p[0][e]);
                ps = ps.map((x,j)=>{
                    //console.log(x,j,i[j],x[i[j]]);
                    x.splice(i[j],1);
                    return x;
                });
                console.log("PS: ", ps)
            }
        }
        let result = commonPrimes.concat(ps.reduce((a,b)=>a.concat(b), [])).sort();
        //console.log('COMMONDENOMINATORPRIMES: ', result)
        return result;
    }
    commonDenominator(...frac){
        let result = this.commonDenominatorPrimes(...frac).reduce((a,b)=>a*b,1);
        //console.log('COMMONDENOMINATOR: ', result)
        return result;
    }
    expandToDenominator(d){
        let result = this.expandBy(this.expandToDenominatorFactor(d));
        //console.log('EXPANDTOOMMONDENOMINATOR: ', result)
        return result;
    }
    expandToDenominatorFactor(d){
        //console.log('EXPANDTOOMMONDENOMINATOR: ', d/this.denominator)
        return d/this.denominator

    }

    add(...fracs){
        let cd = this.commonDenominator(...fracs);
        let es = [this].concat(fracs).map(x=>x.expandToDenominator(cd)).map(x=>x.signValue * x.enumerator);
        //console.log('ADD1: ', es);
        //console.log('ADD1: ', new Fraction(es.reduce((a,b)=>a+b,0), cd));
        let e = es.reduce((a,b)=>a+b,0);
        return new Fraction(Math.abs(e), cd, Fraction.getSign(e));
    }
    subtract(f){
        let cd = this.commonDenominator(f);
        let es = [this].concat([f]).map(x=>x.expandToDenominator(cd)).map(x=>x.signValue * x.enumerator);

        return new Fraction(es[0]-es[1], cd, );
    }
    multiply(...fracs){
        return new Fraction([this].concat(fracs).map(x=>x.enumerator).reduce((a,b)=>a*b,1),[this].concat(fracs).map(x=>x.denominator).reduce((a,b)=>a*b,1), [this].concat(fracs).map(x=>{return {'-': -1, '+': 1}[x.sign]}).reduce((a,b)=>a*b) == -1 ? '-' : '+');
    }
    get inverse(){
        return new Fraction(this.denominator, this.enumerator, this.sign);
    }
    divideBy(frac){
        return this.multiply(frac.inverse);
    }
    get primeFactorsEnumerator(){
        return primeFactors(this.enumerator);
    }
    get primeFactorsDenominator(){
        return primeFactors(this.denominator);
    }
    serialize(){
        return [this.enumerator, this.denominator, this.sign];
    }
    copy(){
        return new Fraction(this.enumerator, this.denominator, this.sign);
    }
    identical(f){
        return f.denominator == this.denominator && f.enumerator == this.enumerator && this.sign == f.sign;
    }
    
    increaseEnumeratorBy(v){
        return new Fraction(this.enumerator + v, this.denominator, this.sign);
    }
    
    increaseDenominatorBy(v){
        return new Fraction(this.enumerator, this.denominator+v, this.sign);
    }
    
    expandBy(v){
        return new Fraction(this.enumerator * v, this.denominator*v, Fraction.computeSign(this.sign, v<0 ? '-' : '+'));
    }

    cancelBy(v){
        if (this.denominator % v === 0 && this.enumerator % v === 0) return new Fraction(this.enumerator/v, this.denominator/v, this.sign);
        else return this;
    }

    reduce(){
        let f = this.copy();
        let i = 0;
        while (PRIMES[i]<=(Math.min(this.enumerator, this.denominator))){
            if (f.denominator % PRIMES[i] === 0){
                if (f.enumerator % PRIMES[i] === 0){
                    f = f.cancelBy(PRIMES[i]);
                }
                else {
                    i++;
                }
            }
            else {
                i++;
            }
        }
        return f;
    }
    power(n){
        if (n instanceof Fraction && n.denominator === 1 && n.sign === "+") n = n.enumerator;
        return new Fraction(Math.pow(this.enumerator,n), Math.pow(this.denominator,n), Fraction.getSign(Math.pow(this.signValue,n)));
    }
    equal(f){
        return this.denominator != 0 && f.denominator != 0 && (((''+f.enumerator).length) && (''+f.denominator).length) &&
         this.denominator/f.denominator === this.enumerator/f.enumerator && this.sign ==f.sign;
    }
    lessThen(f){
        return (this.enumerator/this.denominator < f.enumerator/f.denominator && this.sign == f.sign) || this.sign == '-';
    }
    greaterThen(f){
        return (this.enumerator/this.denominator > f.enumerator/f.denominator && this.sign == f.sign) || this.sign == '+';
    }
    toString(){
        return 'fraction(' + this.enumerator + ',' + this.denominator + ',' + this.sign + ')';
    }
}