import { Term } from './Equationparser/Term';
import { Fraction } from './Fraction';
import { MixedNumber } from './MixedNumber';

'fraction(1,2)+fraction(3,4)=§fraction("guess.path.and.so.on", someotherinformation, someotherinformation)'
'3unit("km") = §number("guess") unit("m")'

class EquationPart{
    constructor(public value, public type, public path){ }
}

const types = [
    {rex: "((\\(|\\)))" , type:                             'parenthesis'},
    {rex: "((\\+|\\-|\\*|\\:|·|•))" , type:                   'operator'},//muss geändert werden auf operator(.*?)
    {rex: "((\\d+|,|\\.))", type:                           'number'},
    {rex: "(unit\\[(.+?)\\])", type:                        'unit'},
    {rex: "((=))", type:                                    'equalsign'},
    {rex: "((§number)(\\[(.*?)\\])?)", type:               'wanted-number'},
    {rex: "((§fraction)(\\[(.*?)\\])?)", type:             'wanted-fraction'},
    {rex: "((§sfraction)(\\[(.*?)\\])?)", type:             'wanted-signed-fraction'},
    {rex: "((§mixedNumber)(\\[(.*?)\\])?)", type:             'wanted-mixedNumber'},
    {rex: "((§comparison)(\\[(.*?)\\])?)", type:           'wanted-comparison'},
    {rex: "((§string)(\\[(.*?)\\])?)", type:               'wanted-string'},
    {rex: "(sfraction\\((\\d+),(\\d+),(\\+|\\-)\\))", type:          'signed-fraction'},
    {rex: "(fraction\\((\\d+),(\\d+),(\\+|\\-)\\))", type:          'fraction'},
    {rex: "(mixedNumber\\((\\d+),(\\d+),(\\d+)\\))", type:          'mixedNumber'},
    {rex: "(([^0-9()=§+\\-*:·]+))", type:                     'words'}
]

const types2 = [
    {rex: "(operator(\\(.+?)\\)))(\\[(\\d+?),(\\d+?),(\\d+?),(\\d+?)\\])?)" , type:  'operator'},
    {rex: "((\\(|\\)))(\\[(\\d+?),(\\d+?),(\\d+?),(\\d+?)\\])?)" , type:  'bracket'},

]
export class Equation{
    public parts = [];
    constructor(str, public task, parse2=false){
        let i = 0;
        if (!parse2){
            
        while (str.length && i < 100){
            i++;
            for (let k of types){
                let m = str.match(new RegExp('^' + k.rex + '\\s*'));
                if (m){
                    let path;
                    if ((/^\(?§/).test(m[1])){
                        path = m[4];
                        console.log(m)
                        this.parts.push(new EquationPart(m[2], k.type, path));
                    }
                    else {
                        if(k.type === 'fraction'){
                            this.parts.push(new EquationPart(new Fraction(+m[2], +m[3], m[4]), k.type, path));
                        }
                        else if(k.type === 'signed-fraction'){
                            this.parts.push(new EquationPart(new Fraction(+m[2], +m[3], m[4]), k.type, path));
                        }
                        else if(k.type === 'mixedNumber'){
                            this.parts.push(new EquationPart(new MixedNumber(+m[2], new Fraction(+m[3], +m[4])), k.type, path));
                        }                        
                        else {
                            this.parts.push(new EquationPart(m[2], k.type, path));
                        }
                    }

                    str = str.slice(m[1].length);
                    break;
                }
            }
        }

    } else if (parse2){
        while (str.length && i < 100){
            i++;
            for (let k of types){
                let m = str.match(new RegExp('^' + k.rex + '\\s*'));
            }
        }
    }
//    console.log(this.parts, str)
    }
}