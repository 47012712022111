import { randomInt, randomItem, randomItemsUnique, numbers, without, numberWithFactors, PRIMES } from '../general';
import { Task } from '../DBTask';
import { list as prices } from '../Data/Fruitprices';
import { list as cities } from '../Data/GermanCities19702017'
const firstNames = ['Wanja', 'Toni', 'Kim', 'Ali', 'Alex', 'Conni', 'Chris'];
const lastNames = ['Meier', 'Geier', 'Jablonski', 'Peter', 'Speeter', 'Yıldırım'];
const names = firstNames.concat(lastNames.map(x=>randomItem(['Frau', 'Herr']) + ' ' + x))
const cheers = ['Klasse!', 'Prima!', 'Toll!', 'Jippie!', 'Ja!', 'Wie wunderbar!', 'Was ein Glück!', 'Welch\' glückliche Fügung!', 'Juhu!', 'Hurra!', 'Super!', 'Wie schön!', 'Halleluja!', 'Sapperlot!', 'Potzblitz!', 'Yeah!']
const agoes = ['gestern', 'vor ' + randomInt(2,5) + ' Tagen', 'letzte Woche'];
const thinks = ['überlegt', 'denkt nach', 'rechnet', 'schätzt'];
const stores = ['Ilda', 'Ladl', 'Raufland', 'Were', 'Brutto', 'Mark', 'Ormal', 'Imaginär', 'Trackdorf'];
export class TaskPercentConcepts extends Task{
    public name = "TaskPercentConcepts";
    static get maxDifficulty(){ return Math.max(...Object.keys(this.difficulties).map(Number))};
    static difficulties = {
        1: (wanted)=>{
            let percent = randomItem(['%', ' Prozent']);
            let coinflip = randomItem(['cities', 'prices']);
            if (coinflip == 'prices'){
                let o = randomItem(prices);
                let p = randomInt(80,95);
                
                let actor = randomItem(names);
                let cheer = randomItem(cheers);
                let bv = String(o.price).replace(/\./, ',');
                let pv = (o.price * p/100).toFixed(2).replace(/\./, ',');
                let ago = randomItem(agoes);
                let think = randomItem(thinks);
                let item = o.name
                let storess = randomItemsUnique(2, stores);
                let texts = [
                    `Im Prospekt steht: "${o.name}!  Heute statt ${bv}€ nur ${pv}€." Das entspricht etwa ${p}${percent} des ursprünglichen Preises.`,
                    `${actor} sieht im Supermarkt: "${o.name}: ${pv}€", ${ago} waren es noch ${bv}€. Heute sind das nur noch ${p}${percent}!`,
                    `${actor} sieht ein Schild: "Heute alles nur ${p}${percent}" ${actor} ${think}: "${cheer} Dann also ${pv}€, statt ${bv}€,  für ${o.name}!"`,
                    `${actor} möchte noch ${item} kaufen. Bei ${storess[0]} waren das ${ago} ${bv}€. Aber heute sind es bei ${storess[1]} nur ${pv}€, also ${p}${percent} des ${storess[0]}-Preises.`,
                ]
                let result = new TaskPercentConcepts({baseValue: o.price, percentage: p, percentageValue: (o.price * p/100).toFixed(2)}, wanted || randomItem(['baseValue', 'percentage', 'percentageValue']), randomItem(texts), '€'  )
                result.difficulty = 1;
                return result;
            } 
            else if (coinflip == 'cities'){
                let city = randomItem(cities.filter(city=>Math.abs(Math.ceil(100*((city.population2017-city.population1970)/city.population1970)))< 100));
                let grew = city.population1970 < city.population2017;
                let actor = randomItem(names);
                let bv = String(city.population1970);
                let pv = Math.abs(city.population2017-city.population1970);
                let p = Math.abs(Math.ceil(100*((city.population2017-city.population1970)/city.population1970)));
                let ago1970 = randomItem(['1970', 'vor ' + ((new Date()).getFullYear() - 1970) + ' Jahren'])
                let growTexts = [
                    `Als ${actor} ${ago1970} nach ${city.city} zog, wohnten dort ${bv} Menschen. Im Jahr 2017 waren es bereits ${city.population2017}, also ${pv} mehr. Oder etwa ${p}${percent} mehr als damals.`,
                    `"Mensch", denkt ${actor}, "heute leben ${city.population2017} Menschen hier in ${city.city}, ${ago1970} waren es ${pv} weniger. Ein Zuwachs von ${p}${percent}..."`,
                    `${pv} Menschen  zählt die Stadt ${city.city} heute mehr als ${ago1970}, als es ${bv} Einwohner gab. Immerhin ${p}${percent} mehr.`
                ];
                let shrinkTexts = [
                    `Als ${actor} ${ago1970} nach ${city.city} zog, wohnten dort noch ${bv} Menschen. Im Jahr 2017 waren es nur noch ${city.population2017}, also ${pv} weniger. Oder etwa ${p}${percent} weniger als damals.`,
                    `"Mensch", denkt ${actor}, "heute leben nur noch ${city.population2017} Menschen hier in ${city.city}, ${ago1970} waren es ${pv} mehr! Eine Abwanderungsrate von ${p}${percent}..."`,
                    `${pv} Menschen  zählt die Stadt ${city.city} heute weniger als ${ago1970}, als es ${bv} Einwohner gab. Immerhin ${p}${percent} weniger.`
                ]
                let result = new TaskPercentConcepts({baseValue: bv, percentage: p, percentageValue: pv}, wanted || randomItem(['baseValue', 'percentage', 'percentageValue']), randomItem(grew ? growTexts : shrinkTexts), ''  )
                result.difficulty = 1;
                return result;
            }


        }
    }
    static withDifficulty(difficulty, wanted?){
        return this.difficulties[difficulty](wanted);
    }
    public helpHtml = `
    <ul>
    <li>Beim <em>Prozentsatz (p)</em> steht immer "Prozent" bzw. %.</li>
    <li>Der <em>Grundwert (G)</em> entspricht immer 100%.</li>
    <li>Der <em>Prozentwert (W)</em> hat die gleiche (oder eine ähnliche) Einheit wie der Grundwert.</li>
    </ul>
    `;
    public difficulty;
    public text;
    public unit;
    public wanted;
    public percentage;
    public percentageValue;
    public baseValue;
    public result;
    public guess;
    constructor(pairObject, wanted, text, unit?){
        super();
        this.text = text;
        this.unit = unit;
        this.wanted = wanted;
        this.percentage = pairObject.percentage ? pairObject.percentage : pairObject.baseValue/pairObject.percentageValue;
        this.baseValue = pairObject.baseValue ? pairObject.baseValue : (pairObject.percentageValue/pairObject.percentage)*100;
        this.percentageValue = pairObject.percentageValue ? pairObject.percentageValue : pairObject.baseValue*(pairObject.percentage/100);
        this.result = this[this.wanted];
    }

    check(){
        if (this.wanted == 'percentage' && this.guess.replace(/,/, '.') === this.percentage + '%'){
          console.log("genau")
          this.emit('correct');
        }
        else if (this.wanted == 'percentageValue'  && this.guess.replace(/,/, '.') === this.percentageValue + this.unit){
            console.log("genau")
            this.emit('correct');
          }
        else if (this.wanted == 'baseValue'  && this.guess.replace(/,/, '.') === this.baseValue + this.unit){
            console.log("genau")
            this.emit('correct');
          }
        else{
          console.log("leider nicht");
          console.log(this)
          this.emit('wrong');  
        }
      }
}
