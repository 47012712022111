export let list = [
{"name":"Aglio Olio mit Chili","price":"1.39","weight":"95 g"},
{"name":"Ajvar mild","price":"1.49","weight":"340 ml"},
{"name":"Ajvar scharf","price":"1.49","weight":"340 ml"},
{"name":".Apfel Rotkohl","price":"0.55","weight":"720 ml"},
{"name":".Apfelrotkohl portionierbar","price":"0.99","weight":"450 g"},
{"name":".Artischockenherzen","price":"1.99","weight":"425 ml"},
{"name":"Babykarotten Gemüse pur","price":"0.99","weight":"200 g"},
{"name":".Baked Beans","price":"0.89","weight":"400 g"},
{"name":".Bambussprossen","price":"0.99","weight":"370 ml"},
{"name":"Blattspinat","price":"0.99","weight":"450 g"},
{"name":".Blattspinat portionierbar","price":"1.99","weight":"1000 g"},
{"name":".Blumenkohl","price":"1.29","weight":"1000 g"},
{"name":"Bohnen im Speckmantel","price":"1.99","weight":"240 g"},
{"name":"Braune Linsen","price":"0.79","weight":"400 g"},
{"name":"Brechbohnen","price":"0.99","weight":"660 g"},
{"name":".Brechbohnen, fein und jung","price":"0.69","weight":"370 ml"},
{"name":"Broccoli","price":"1.49","weight":"1000 g"},
{"name":"Broccoli","price":"0.99","weight":"300 g"},
{"name":".Buttergemüse","price":"0.49","weight":"300 g"},
{"name":".Buttergemüse","price":"0.99","weight":"300 g"},
{"name":"Caristica - Salatmix","price":"1.29","weight":"80 g"},
{"name":".Champignons","price":"1.39","weight":"450 g"},
{"name":"Champignons, 1. Wahl, ganze Köpfe","price":"0.95","weight":"425 ml"},
{"name":".Champignons, 1. Wahl, Scheiben","price":"0.89","weight":"280 g"},
{"name":".Champignons, 1. Wahl, Scheiben","price":"0.99","weight":"314 ml"},
{"name":".Champignons, 1.Wahl, ganze Köpfe","price":"1.29","weight":"370 ml"},
{"name":".Champignons, 3. Wahl, Scheiben","price":"0.59","weight":"290 g"},
{"name":".Champignons, Mini, 1.Wahl, ganze Köpfe","price":"2.99","weight":"850 ml"},
{"name":"Chili Mix","price":"0.99","weight":"400 g"},
{"name":"Chili-Bohnen","price":"0.89","weight":"400 g"},
{"name":".Chinagemüse","price":"1.99","weight":"750 g"},
{"name":".Cornichons","price":"1.09","weight":"670 g"},
{"name":"Cornichons mit Chili","price":"0.95","weight":"370 ml"},
{"name":"Cornichons mit Honig","price":"0.95","weight":"370 ml"},
{"name":"Cornichons mit Kräutern","price":"0.95","weight":"370 ml"},
{"name":".Dill","price":"0.79","weight":"50 g"},
{"name":".Erbsen extra fein","price":"0.59","weight":"425 ml"},
{"name":".Erbsen extra fein","price":"1.29","weight":"720 ml"},
{"name":".Erbsen extra fein","price":"0.79","weight":"370 ml"},
{"name":".Erbsen mit Möhren, extra fein","price":"0.99","weight":"850 ml"},
{"name":".Erbsen mit Paiser Karotten extra fein","price":"0.79","weight":"370 ml"},
{"name":".Erbsen mit Pariser Karotten extra fein","price":"1.29","weight":"720 ml"},
{"name":".Feines Chinagemüse","price":"0.99","weight":"370 ml"},
{"name":"Feldsalat - Salat Pur","price":"1.29","weight":"80 g"},
{"name":".Fingermöhrchen, extra fein","price":"0.79","weight":"370 ml"},
{"name":"Gegrillte Paprika","price":"1.29","weight":"320 ml"},
{"name":"Gemüsemais","price":"1.49","weight":"450 g"},
{"name":".Gemüsemais supersweet","price":"0.59","weight":"212 ml"},
{"name":".Gemüsemais supersweet","price":"0.79","weight":"425 ml"},
{"name":".Gemüseplatte","price":"1.79","weight":"1000 g"},
{"name":"Getrocknete Tomaten","price":"1.29","weight":"280 g"},
{"name":".Gewürzgurken","price":"0.89","weight":"720 ml"},
{"name":"Grandiosa - Salatmix","price":"1.29","weight":"80 g"},
{"name":".Grüner Spargel","price":"2.29","weight":"330 g"},
{"name":"Grünkohl","price":"0.69","weight":"450 g"},
{"name":"Grillgemüse","price":"1.99","weight":"350 g"},
{"name":"Gurkensticks","price":"0.89","weight":"670 g"},
{"name":"Gurkentopf","price":"1.59","weight":"1550 g"},
{"name":".Junge Brechbohnen","price":"0.69","weight":"850 ml"},
{"name":".Junge Brechbohnen","price":"1.65","weight":"1000 g"},
{"name":"Junge Erbsen","price":"1.49","weight":"1000 g"},
{"name":".Junge Erbsen","price":"1.49","weight":"450 g"},
{"name":"Junge Erbsen extra fein 3x200g","price":"1.49","weight":"600 g"},
{"name":"Junge Erbsen mit Möhrchen sehr fein","price":"0.59","weight":"425 ml"},
{"name":"Junge Erbsen mit Möhrchen, extra fein","price":"0.59","weight":"425 g"},
{"name":".Junge Erbsen mit Möhrchen, extra fein","price":"0.99","weight":"850 ml"},
{"name":"Junge Erbsen sehr fein","price":"0.59","weight":"425 ml"},
{"name":"Junge Erbsen und Möhrchen extra fein 3x200g","price":"1.49","weight":"600 g"},
{"name":"Kaisergemüse","price":"1.49","weight":"1000 g"},
{"name":".Kaisergemüse","price":"1.59","weight":"450 g"},
{"name":"Kapern surfines","price":"0.99","weight":"106 ml"},
{"name":"Karottensalat","price":"0.69","weight":"330 g"},
{"name":".Kartoffeln fein","price":"0.99","weight":"720 ml"},
{"name":"Kidneybohnen","price":"0.39","weight":"425 ml"},
{"name":".Leipziger Allerlei","price":"2.49","weight":"1000 g"},
{"name":".Mischpilze hell","price":"1.59","weight":"400 g"},
{"name":"Misticanza - Salatmix","price":"1.29","weight":"80 g"},
{"name":"Mixed Pickles","price":"0.79","weight":"330 g"},
{"name":".Mungobohnenkeime","price":"0.99","weight":"370 ml"},
{"name":"Oldenburger Grünkohl","price":"1.99","weight":"1000 g"},
{"name":"Oliven geschwärzt, entsteint","price":"0.79","weight":"340 g"},
{"name":".Passierte Tomaten","price":"0.35","weight":"500 g"},
{"name":".Petersilie","price":"0.79","weight":"50 g"},
{"name":".Pfannengemüse Asiatische Art","price":"1.29","weight":"750 g"},
{"name":".Pfannengemüse französche Art","price":"1.29","weight":"750 g"},
{"name":".Pfannengemüse Italenische Art","price":"1.29","weight":"750 g"},
{"name":".Pfannengemüse Mexikanische Art","price":"1.29","weight":"750 g"},
{"name":"Pfifferlinge","price":"3.99","weight":"300 g"},
{"name":".Pfifferlinge ausgesucht-klein","price":"2.49","weight":"180 g"},
{"name":".Pfifferlinge, unsortiert","price":"1.69","weight":"314 ml"},
{"name":".Pilztopf","price":"1.89","weight":"530 g"},
{"name":"Prinzessbohnen","price":"1.49","weight":"1000 g"},
{"name":".Prinzessbohnen","price":"0.65","weight":"425 ml"},
{"name":".Rahm-Blumenkohl","price":"1.49","weight":"750 g"},
{"name":".Rahm-Kaisergemüse","price":"1.49","weight":"750 g"},
{"name":".Rahm-Rosenkohl","price":"1.49","weight":"750 g"},
{"name":"Rahmspinat","price":"0.49","weight":"450 g"},
{"name":".Rosenkohl","price":"1.29","weight":"1000 g"},
{"name":".Rosenkohl","price":"0.99","weight":"450 g"},
{"name":".Rote Bete Kugeln","price":"0.69","weight":"330 g"},
{"name":"Rote Bete Scheiben","price":"0.69","weight":"370 ml"},
{"name":".Rote Kidney Bohnen dunkelrot","price":"1.29","weight":"850 g"},
{"name":"Rucola - Salat Pur","price":"1.29","weight":"80 g"},
{"name":"Salatmischung","price":"1.29","weight":"200 g"},
{"name":"Salatmischung Blattsalat-Mix","price":"0.79","weight":"150 g"},
{"name":"Salatmischung Eissalat-Mix","price":"0.79","weight":"150 g"},
{"name":"Salatmischung Rohkost-Mix","price":"0.79","weight":"200 g"},
{"name":".Sauerkraut","price":"0.39","weight":"520 g"},
{"name":".Schlesische Gurkenhappen","price":"0.85","weight":"530 g"},
{"name":".Schnittbohnen","price":"0.99","weight":"720 g"},
{"name":".Schwarzwurzeln","price":"1.39","weight":"580 ml"},
{"name":"Selleriesalat","price":"0.59","weight":"330 g"},
{"name":"Silberzwiebeln","price":"0.59","weight":"320 g"},
{"name":".Sonnenmais","price":"0.49","weight":"425 ml"},
{"name":"Sonnenmais","price":"1.39","weight":"450 g"},
{"name":".Spargelabschnitte weiss","price":"1.49","weight":"314 ml"},
{"name":".Spargelköpfe weiss, geschält 7cm","price":"1.99","weight":"212 ml"},
{"name":"Spargelspitzen","price":"2.49","weight":"280 g"},
{"name":"Spinat - Salat Pur","price":"1.29","weight":"80 g"},
{"name":".Stangenspargel","price":"0.99","weight":"330 g"},
{"name":".Stangenspargel","price":"3.29","weight":"580 ml"},
{"name":"Steinpilze","price":"4.49","weight":"300 g"},
{"name":".Suppengemüse","price":"0.99","weight":"1000 g"},
{"name":".Suppengemüse","price":"0.79","weight":"450 g"},
{"name":".Tomaten, geschält","price":"0.69","weight":"800 g"},
{"name":"Tomaten, geschält und gehackt","price":"0.39","weight":"400 g"},
{"name":".Tomatenmark","price":"0.49","weight":"200 g"},
{"name":".Tomatenmark, 3-fach konzentriert","price":"0.39","weight":"70 g"},
{"name":".Tomatenmark, 3-fach konzentriert, Tube","price":"0.99","weight":"200 g"},
{"name":"Tomatenpaprika","price":"0.69","weight":"320 g"},
{"name":".Wachsbrechbohnen","price":"0.99","weight":"720 ml"},
{"name":".Zuckerschoten","price":"1.99","weight":"450 g"},
{"name":".Zwiebeln","price":"0.99","weight":"450 g"},
{"name":"Cashewkerne naturell","price":"3.99","weight":"200 g"},
{"name":"Paranusskerne","price":"5.99","weight":"200 g"},
{"name":".Pinienkerne","price":"2.49","weight":"50 g"},
{"name":"Pistazienkerne","price":"2.49","weight":"50 g"},
{"name":".Salatkerne Mix","price":"0.99","weight":"75 g"},
{"name":".5 Fruchtcocktail","price":"1.29","weight":"410 g"},
{"name":"5-Fruchtcocktail","price":"0.89","weight":"225 g"},
{"name":".5-Fruchtcocktail","price":"2.19","weight":"850 ml"},
{"name":"5-Fruchtcocktail","price":"1.59","weight":"850 ml"},
{"name":"5-Fruchtcocktail 4x120g","price":"1.59","weight":"480 ml"},
{"name":"Ananas","price":"1.79","weight":"300 g"},
{"name":".Ananas Dessert-Stücke","price":"1.49","weight":"446 ml"},
{"name":".Ananas ganze Scheiben","price":"2.49","weight":"850 ml"},
{"name":"Ananas ganze Scheiben","price":"0.99","weight":"220 g"},
{"name":".Ananas ganze Scheiben","price":"1.49","weight":"446 ml"},
{"name":".Ananas ganze Scheiben","price":"1.79","weight":"580 g"},
{"name":"Ananas ganze Scheiben","price":"1.49","weight":"850 ml"},
{"name":"Ananas Scheiben","price":"0.99","weight":"580 ml"},
{"name":".Ananas Stücke","price":"0.89","weight":"580 ml"},
{"name":"Ananas-Dessertstücke","price":"0.89","weight":"230 g"},
{"name":"Ananas-Dessertstücke","price":"1.79","weight":"567 g"},
{"name":"Ananasringe, getrocknet, geschwefelt","price":"3.99","weight":"100 g"},
{"name":".Apfelkompott","price":"1.19","weight":"365 g"},
{"name":"Apfelmark","price":"1.59","weight":"720 ml"},
{"name":"Apfelmus","price":"0.89","weight":"360 g"},
{"name":".Apfelmus","price":"0.69","weight":"710 g"},
{"name":".Apfelmus","price":"1.39","weight":"720 g"},
{"name":"Apfelpüree im Becher 'Apfelpause', 4x100g","price":"1.19","weight":"400 g"},
{"name":".Apfelringe, geschwefelt, getrocknet","price":"1.39","weight":"100 g"},
{"name":"Aprikosen","price":"0.99","weight":"225 g"},
{"name":"Aprikosen","price":"1.29","weight":"410 g"},
{"name":".Aprikosen","price":"1.99","weight":"850 ml"},
{"name":".Aprikosen, ganz, getrocknet","price":"1.49","weight":"200 g"},
{"name":".Aprikosen, getrocknet","price":"4.99","weight":"500 g"},
{"name":".Bananenchips","price":"1.49","weight":"200 g"},
{"name":"Beerenmischung","price":"2.49","weight":"750 g"},
{"name":".Beerenmischung","price":"1.49","weight":"300 g"},
{"name":"Brombeeren","price":"1.59","weight":"300 g"},
{"name":"Bunter Früchtekorb","price":"2.49","weight":"750 g"},
{"name":".Cranberries getrocknet","price":"1.79","weight":"175 g"},
{"name":"Cranberry-Beeren-Mix","price":"1.49","weight":"300 g"},
{"name":"Datteln","price":"2.99","weight":"200 g"},
{"name":".Erdbeeren","price":"1.39","weight":"300 g"},
{"name":".Erdbeeren, gezuckert","price":"1.89","weight":"680 g"},
{"name":"Fruchtmix exotisch 4x120ml","price":"1.59","weight":"480 ml"},
{"name":"Fruchtmus Apfel-Banane 4x100g","price":"1.29","weight":"400 g"},
{"name":"Fruchtmus Apfel-Erdbeere 4x100g","price":"1.29","weight":"400 g"},
{"name":".Grüne Feigen, ganz, gezuckert","price":"1.39","weight":"425 ml"},
{"name":".Heidelbeeren","price":"2.69","weight":"300 g"},
{"name":".Himbeeren","price":"3.75","weight":"750 g"},
{"name":"Kirschen getrocknet","price":"3.99","weight":"125 g"},
{"name":"Kokosnussmilch","price":"0.99","weight":"400 ml"},
{"name":"Kokoswürfel","price":"1.49","weight":"150 g"},
{"name":".Korinthen getrocknet","price":"0.69","weight":"125 g"},
{"name":".Lychees","price":"1.49","weight":"425 g"},
{"name":".Mandarin-Orangen","price":"2.79","weight":"850 g"},
{"name":"Mandarin-Orangen in Saft","price":"1.19","weight":"300 g"},
{"name":".Mandarin-Orangen, leicht gezuckert","price":"0.95","weight":"314 ml"},
{"name":".Mango, gewürfelt","price":"1.79","weight":"300 g"},
{"name":".Mangos geviertelt, geschält und gezuckert","price":"1.59","weight":"425 ml"},
{"name":".Mischobst 5-Frucht","price":"2.39","weight":"250 g"},
{"name":".Mischobst, getrocknet","price":"3.99","weight":"500 g"},
{"name":"Papayastücke getrocknet","price":"1.99","weight":"100 g"},
{"name":".Pfirsiche","price":"1.89","weight":"820 g"},
{"name":".Pfirsiche, halbe Frucht, leicht gezuckert","price":"0.99","weight":"850 ml"},
{"name":".Pflaumen","price":"1.69","weight":"680 g"},
{"name":".Pflaumen entsteint","price":"1.99","weight":"250 g"},
{"name":"Pflaumen getrocknet & entsteint","price":"2.99","weight":"500 g"},
{"name":"Rote Johannisbeeren","price":"1.49","weight":"300 g"},
{"name":".Sauerkirschen, ohne Stein, gezuckert","price":"1.39","weight":"680 g"},
{"name":".Schattenmorellen","price":"2.29","weight":"680 g"},
{"name":".Tortenpfirsiche","price":"1.89","weight":"850 ml"},
{"name":".Trockenpflaumen","price":"2.89","weight":"500 g"},
{"name":".Tropische Fruchtmischung","price":"2.49","weight":"750 g"},
{"name":".Waldheidelbeeren","price":"1.39","weight":"340 g"},
{"name":".Wild-Preiselbeeren","price":"0.99","weight":"400 g"},
{"name":".Williams-Christ-Birnen","price":"1.99","weight":"850 ml"}]
.map(x=>{
    return {
        name: x.name.replace(/^\./, ''),
        price: +x.price,
        weight: {
            unit : x.weight.split(' ')[1],
            amount: +x.weight.split(' ')[0]
        }
    }
})