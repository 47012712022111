import { Component, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { numbers } from '../../general';
@Component({
  selector: 'app-pie-slices',
  templateUrl: './pie-slices.component.html',
  styleUrls: ['./pie-slices.component.css']
})
export class PieSlicesComponent implements OnChanges {
  @Input() public nOfSlices;
  @Input() public markedOnes;
  
  @Output() public clickedSlice = new EventEmitter();

  public slices;
  
  constructor() { }
  
  sliceClicked(slice, index){
    this.clickedSlice.emit({slice: slice, index: index});
  }

  ngOnChanges() {
    this.slices = numbers(+this.nOfSlices+1).map(n=>{
      const a = Math.PI * 2 * (n/this.nOfSlices);
      let x = (Math.cos(a) * 95).toFixed(3);
      let y = (Math.sin(a) * 95).toFixed(3);
      y = y === '-0.000' ? '0.000' : y;
      x = x === '-0.000' ? '0.000' : x;
      return {x: x, y: y, marked: false, selected: false}
    });
    this.markedOnes.forEach((x)=>this.slices[x+1].marked = true);
  }

}
