import { shakeTo, randomBool, numberSort, PRIMES, randomIndex, randomItems, randomItem, digitsum, without, numbers, randomInt } from '../general';
import { Task } from '../DBTask';

let examples = [
    {
        description: `Alex zahlt §1 Euro für §2 Liter Benzin. Die nächste Woche tankt er wieder zum gleichen Preis. Diesmal zahlt er §3 Euro.`,
        question: `Wie viele Liter Benzin hat Alex beim zweiten Mal getankt?`,
        possibleCorrectHeaders: [["Preis (€)"], ["Benzin (l)"]],
        possibleIncorrectHeaders: ["Autos", "Tankvorgänge"],
        known1: 10,
        known2: 5,
        known3: 41
    },
/*    {
        description: `Tobi muss seinem Chinchilla Tropfen geben. Auf der Packung steht: §1 ml je kg Körpergewicht. Das Chinchilla wiegt §3 g.`,
        question: `Wie viele ml Tropfen muss Tobi dem Chinchilla geben?`,
        possibleCorrectHeaders: [["Tropfen (ml)"], ["Gewicht (g)"]],
        possibleIncorrectHeaders: ["Chinchillas", "Packungen"],
        known1: 100,
        known2: 1000,
        known3: 340
    },*/
    {
        description: `Eine Plakatwerbung kostet §2 Euro für §1 Tage.`,
        question: `Wieviel sind für §3 Tage zu bezahlen`,
        possibleCorrectHeaders: [["Tage"], ["Preis (€)"]],
        possibleIncorrectHeaders: ["", ""],
        known1: 60,
        known2: 600,
        known3: 80,
        acknowledgement: 'https://www.bommi2000.de/mathematik/dreisatz_abl.pdf'
    },
    {
        description: `Für eine Sendung Waren im Wert von §1 € wurden §2 € Zoll gezahlt.`,
        question: `Wieviel Zoll fallen bei einem Wert von §3 € an?`,
        possibleCorrectHeaders: [["Warenwert (€)"], ["Zollgebühren (€)"]],
        possibleIncorrectHeaders: ["", ""],
        known1: 16000,
        known2: 3200,
        known3: 7000,
        acknowledgement: 'https://www.bommi2000.de/mathematik/dreisatz_abl.pdf'

    },
   /* {
        description: `Ein Vertreter erhält im April §1 € Umsatzprämie bei §2 € Umsatz`,
        question: `Errechne die Prämie bei §3 € Umsatz.`,
        possibleCorrectHeaders: [["Umsatz (€)"], ["Prämie (€)"]],
        possibleIncorrectHeaders: ["", ""],
        known1: 1200,
        known2: 24000,
        known3: 30000,
        acknowledgement: 'https://www.bommi2000.de/mathematik/dreisatz_abl.pdf'

    },*/
    {
        description: ` Für das Eindecken eines Daches von §1 m² werden §2 Platten benötigt`,
        question: `Wie viele Platten benötigt ein §3 m² großes Dach?`,
        possibleCorrectHeaders: [["Dachfläche (m²)"], ["Platten (Anzahl)"]],
        possibleIncorrectHeaders: ["", ""],
        known1: 400,
        known2: 8000,
        known3: 200,
        acknowledgement: 'https://www.bommi2000.de/mathematik/dreisatz_abl.pdf'

    },
    {
        description: ` Für einen Ballen Kleiderstoff von §1 m Länge zahlt ein Betrieb §2 €`,
        question: `Wieviel kostet ein §3 m langer Kleiderballen?`,
        possibleCorrectHeaders: [["Stoff (m)"], ["Preis (€)"]],
        possibleIncorrectHeaders: ["", ""],
        known1: 32,
        known2: 640,
        known3: 4,
        acknowledgement: 'https://www.bommi2000.de/mathematik/dreisatz_abl.pdf'

    },
    /*{
        description: `§2 Angestellte vermessen monatlich §1 Stichproben.`,
        question: `Wie viele Angestellte müssen bei §3 Stichproben je Monat eingesetzt werden?`,
        possibleCorrectHeaders: [["Stichproben"], ["Angestellte"]],
        possibleIncorrectHeaders: ["", ""],
        known1: 4200,
        known2: 3,
        known3: ,
        acknowledgement: 'https://www.bommi2000.de/mathematik/dreisatz_abl.pdf'

    },*/
    /*{
        description: `Die Frachtkosten für §1 t Ladung betragen §2 €.`,
        question: `Wie hoch ist der Frachtkostenanteil für eine Teilladung mit §3 t Gewichtsanteil?`,
        possibleCorrectHeaders: [["Ladungsgewicht (t)"], ["Frachtkostenanteil (€)"]],
        possibleIncorrectHeaders: ["", ""],
        known1: 855,
        known2: 63270,
        known3: 210,
        acknowledgement: 'https://www.bommi2000.de/mathematik/dreisatz_abl.pdf'
    },*/
    /*,
    {
        description: `Wanja kauft §1 kg Äpfel für §2 Euro auf dem Markt. Auf dem Weg nach Hause sieht er im Supermarkt ein Angebot: §3 kg für 4 Euro.`,
        question: `Welches Angebot ist besser?`,
        possibleCorrectHeaders: [["Äpfel (kg)"], ["Preis (€)"]],
        possibleIncorrectHeaders: ["Äpfel (Anzahl)"],
        knownPair: [3, 2.59],
        known: 4.5
    }*/
]
export class TaskRuleOfThree extends Task{
    static maxDifficulty = examples.length;

    public name = "TaskRuleOfThree";
    public known1;
    public known2;
    public known3;
    public description;
    public question;
    public possibleCorrectHeaders;
    public possibleIncorrectHeaders;
    public one = 1;
    public acknowledgement;
    static withDifficulty(d){
        return new TaskRuleOfThree(d);
    }
    constructor(public difficulty){
        super();
        let x = examples[difficulty-1];
        this.known1 = x.known1;
        this.known2 = x.known2;
        this.known3 = x.known3;
        this.possibleCorrectHeaders = x.possibleCorrectHeaders;
        this.acknowledgement = x.acknowledgement;
        this.possibleIncorrectHeaders = x.possibleIncorrectHeaders;
        this.description = x.description
            .replace(/§1/g, String(this.known1).replace(/\./, ','))
            .replace(/§2/g, String(this.known2).replace(/\./, ','))
            .replace(/§3/g, String(this.known3).replace(/\./, ','))

        this.question = x.question
            .replace(/§1/g, String(this.known1).replace(/\./, ','))
            .replace(/§2/g, String(this.known2).replace(/\./, ','))
            .replace(/§3/g, String(this.known3).replace(/\./, ','))
    }

    get wanted(){
        return this.intermediate * this.known3;
    }
    get intermediate(){
        return this.known2 / this.known1;
    }
}