import { RationalNumber } from './RationalNumber';
import { Variable } from './Variable';

export class Bracket{
    constructor(public type, public open){}
}
export class Operator{
    constructor(public type, public visible = true){}
}
const Types = [
    {
        name: 'Number',
        rex: "\\d+(\\.\\d\\d\\d)*(,\\d)?\\d*",
        fnc: (m, lex, i)=> new RationalNumber(m[0].replace(/\./g, '').replace(/,/,'.'))
    },
    {
        name: 'Bracket',
        rex: "\\(",
        fnc: (m, lex, i)=> {
            //lex.temp[lex.counter] =  [i];
            //lex.counter++;
            return new Bracket('()', true);
        }
    },
    {
        name: 'Bracket',
        rex: "\\)",
        fnc: (m, lex, i)=> {
            /*lex.counter--;
            lex.temp[lex.counter].push(i);
            lex.temp[lex.counter].push(lex.counter);
            lex.brackets.push(lex.temp[lex.counter]);
            lex.deepestLevel = Math.max(lex.deepestLevel, lex.counter)*/
            return new Bracket('()', false);

        }
    },
    {
        name: 'Operator',
        rex: "[•+-:=\\^]",
        fnc: (m, lex, i)=> new Operator(m[0])
    },
    {
        name: 'Variable',
        rex: "[a-z]",
        fnc: (m,lex, i)=>new Variable(m[0])
    }
]
export class LexedArray{
    public parts = [];
    public counter = 0;
    public temp = {};
    public brackets = [];
    public deepestLevel = 0;
    constructor(public str, types=Types){
        let i = 0;            
        while (str.length && i < 100){
            for (let k of types){
                let m = str.match(new RegExp('^' + k.rex + '\\s*'));
                if (m){
                    this.parts.push(k.fnc(m, this, i));
                    str = str.slice(m[0].length);
                    break;
                }
            }
            i++;

        }
        //console.log(this.parts)
    }

    itemToString(it){
        if (it instanceof RationalNumber){
            return it.fractionValue.enumerator;
        }
        else if (it instanceof Operator){
            return it.type;
        }
        else if (it instanceof Bracket){
            return it.type[it.open ? 0 : 1];
        }
        else if (it instanceof Variable){
            return it.name;
        }
        else if (Array.isArray(it)){
            return '%c{%c' + (it.length ? it.map(this.itemToString.bind(this)).join('') : '') + '%c}%c'
        }
    }
}
/*
zum brackettree: 
wenn level == 0, dann offset-left und offset-right um länge der klammer
wenn level == 1, dann ..........
*/