import { Operation } from './Operation';
import { Fraction } from '../Fraction';

export class Subtraction extends Operation{
    static rex: any =  /^-$/;

    constructor(public operands, public id){
        super(id);
    }
    /*get value(){
        return this.summands.reduce((a,b)=> a?.getValue + b?.getValue);
    }*/
    get nameOfResult(){
        return new Noun(Genera.FEMININE, 'Differenz', 'Differenzen', 'Differenz');
    }
    get nameOfProcess(){
        return new Noun(Genera.FEMININE, 'Subtraktion', 'Subtraktionen', 'Subtraktion');
    }
    get verb(){
        return new Verb('subtrahier');
    }
    get namesOfOperands(){
        return [
            new Noun(Genera.MASCULINE, 'Minuend', 'Minuenden', 'Minuenden'),
            new Noun(Genera.MASCULINE, 'Subtrahend', 'Subtrahenden', 'Subtrahenden'),
    ];
    }
    get angularString(){
        return super.angularString.replace(/CHILD_CLASS_CONTENT/, 
            this.operands.map(x=>x.angularString).join(`operator(-)[${this.id}]`))
    }
    get value(){
        let r = this.operands[0].value.subtract(this.operands[1].value);
        r.sign = Fraction.computeSign(this, r);
        return r;

        
    }
    string(){
        return super.string(this.operands.map(x=>x.string()).join('-'));
    }
}