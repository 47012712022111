import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TaskButtonsServiceService {
  public hideButtons;
  public buttonsText;
  constructor() { }
}
