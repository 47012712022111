import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface DragSource {
  element: HTMLElement;
  index: number;
}

@Injectable()
export class DragService {
  collapse$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  dragging$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  dragSource$: BehaviorSubject<DragSource> = new BehaviorSubject(null);
}