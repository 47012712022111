import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'duration'
})
export class DurationPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (!value) return;
    let seconds = String(Math.floor((value/1000) % 60)).padStart(2,'0');
    let minutes = String(Math.floor(value/60000)).padStart(2,'0')
    return minutes + ':' + seconds;
  }

}
