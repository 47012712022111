import { Component, OnChanges, Input, ViewChild, ComponentFactoryResolver, ViewChildren, ViewContainerRef, AfterViewInit } from '@angular/core';
import { Task } from 'src/app/DBTask';

@Component({
  selector: 'app-task-item',
  templateUrl: './task-item.component.html',
  styleUrls: ['./task-item.component.css']
})
export class TaskItemComponent implements AfterViewInit, OnChanges {
  @Input() public task;
  @Input() public component;
  @Input() public paper = false;
  
  @ViewChildren('container', { read: ViewContainerRef }) public entry;

  public componentRef;

  constructor(private resolver: ComponentFactoryResolver ) { }

  createComponent() {
   this.entry.last.clear();
   const factory = this.resolver.resolveComponentFactory(this.component)
   this.componentRef = this.entry.last.createComponent(factory);
   this.componentRef.instance.task = this.task;
   this.componentRef.instance.paper = this.paper;    
   this.componentRef.instance.init.bind(this.componentRef.instance)();
  }

  ngAfterViewInit() {
    setTimeout(x=>this.createComponent())
  }

  ngOnChanges(){
    setTimeout(x=>this.createComponent())
  }

}
