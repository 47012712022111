import { Task } from "../DBTask";
import { randomInt } from '../general';
import { DBPlaceValueRepresentation } from '../DBPlaceValueRepresentation';

export class TaskNegativeNumbersNumberlineFind extends Task{
    static difficulties = {
        1 : ()=>{
            let start = randomInt(-10,-4);
            let end = -start;
            let smallestStep = "1";
            let firstFiver = 0;
            while (((start - firstFiver) % 5)  != 0) firstFiver += 1;
            console.log(start, firstFiver)
            let bigSteps =  {intervals: [{value: 5, marked: false}], offset: firstFiver};
            let marks = [
                {text: '0', place: 0, position: 'above'},
                {text: '1', place: 1, position: 'above'},
            ]
            return new TaskNegativeNumbersNumberlineFind(1,
                start, 
                end, 
                smallestStep, 
                bigSteps, 
                marks,
                randomInt(start+1,0));
        },
        2 : ()=>{
            let start = randomInt(-8,-4);
            let end = -start/2;
            let smallestStep = "0.1";
            let bigSteps =  {intervals: [{value: 10, marked: false}], offset: 0};
            let marks = [
                {text: '0', place: 0, position: 'above'},
                {text: '1', place: 1, position: 'above'},
            ]
            return new TaskNegativeNumbersNumberlineFind(1,
                start, 
                end, 
                smallestStep, 
                bigSteps, 
                marks,
                ''+randomInt(start+1, 0) + ',' + randomInt(1,10));
        },
        //3 mit Brüchen
    }

    public result;
    public guess;
    constructor(public difficulty, 
        public start, 
        public end, 
        public smallestStep, 
        public bigSteps, 
        public marks,
        public number){
        super();
    }
    check(){
        console.log(this.number)
        if ( +(''+this.number).replace(/,/,'.') == +(''+this.guess).replace(/,/,'.')){
            console.log("genau")
            this.emit('correct');  
        }

        else {
          console.log("leider nicht")
          this.emit('wrong');  
        }
    }
}