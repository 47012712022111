import { Component, OnInit } from '@angular/core';
import { EquationWordproblemsService } from 'src/app/Services/equation-wordproblems.service';
import { TaskEquationsWordproblem } from 'src/app/Task-Models/model-task-equations-wordproblem';
import { TaskEquationsWordproblemComponent } from '../task-equations-wordproblem/task-equations-wordproblem.component';

@Component({
  selector: 'app-sheet-equation-wordproblems',
  templateUrl: './sheet-equation-wordproblems.component.html',
  styleUrls: ['./sheet-equation-wordproblems.component.css']
})
export class SheetEquationWordproblemsComponent implements OnInit {
  public tasks;
  public component = TaskEquationsWordproblemComponent;
  constructor(public es : EquationWordproblemsService) { 
    this.tasks = es.tasks.map(x=>new TaskEquationsWordproblem(1, x));
  }

  ngOnInit() {
  }

}
