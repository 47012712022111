import { PRIMES, randomIndex, randomItems, digitsum, without, numbers } from '../general';

export class TaskDivision {
  private dividentFactors;
  private divisorFactors;
  public name = "TaskDivision"
  constructor(){
      this.dividentFactors = randomItems(5, PRIMES.slice(0,7));
      this.divisorFactors = randomItems(5, PRIMES.slice(0,5));
      //this.divisorFactors = this.dividentFactors.slice().splice(randomIndex(this.dividentFactors), 1);
  }
  get divisor(){
      return this.divisorFactors.reduce((a,b)=>a*b);
  }
  get divident(){
      return this.dividentFactors.reduce((a,b)=>a*b);
  }
  get result(){
    return this.divisor * this.divident;  
    //return without(this.dividentFactors, this.divisorFactors).reduce((a,b)=>a*b);
  }
  get checkNumber(){
      return digitsum(this.result);
  }
  get difficulty(){
    return this.divident;
  }
  get digitsOfDivident(){
      return Array.from(String(this.divident)).map(Number);
  }
}