export const PRIMES = [2,3,5,7,11,13,17,19,23,29,31,37,41,43,47,53,59,61,67,71,73,79,83,89,91,97];
export  const POSITIONS = ['Einer', 'Zehner', 'Hunderter', 'Tausender', 'Zehntausender', 'Hunderttausender', 'Millionen', 'Zehn Millionen', 'Hundert Millionen', 'Milliarden', 'Zehn Milliarden', 'Hundert Milliarden', 'Billionen', 'Zehn Billionen', 'Hundert Billionen', 'Billiarden',
 'Zehn Billiarden', 'Hundert Billiarden', 'Trillionen', 'Zehn Trillionen', 'Hundert Trillionen', 'Trilliarden', 'Zehn Trilliarden', 'Hundert Trilliarden', 'Quadrillionen', 'Zehn Quadrillionen', 'Hundert Quadrillionen', 'Quadrilliarden', 'Zehn Quadrilliarden', 'Hundert Quadrilliarden', 'Quintillionen'];
export const POSITIONS_RIGHT = ['','Zehntel', 'Hundertstel', 'Tausendstel', 'Zehntausendtstel', 'Hunderttausendstel', 'Millionstel', 'Zehn Millionstel', 'Hundert Millionstel', 'Milliardstel', 'Zehn Milliardstel', 'Hundert Milliardstel', 'Billionstel', 'Zehn Billionstel', 'Hundert Billionstel', 'Billiardstel', 'Zehn Billiardstel', 'Hundert Billiardstel',
'Trillionstel', 'Zehn Trillionstel', 'Hundert Trillionstel', 'Trilliardstel', 'Zehn Trilliardstel', 'Hundert Trilliardstel', 'Quadrillionstel', 'Zehn Quadrillionstel', 'Hundert Quadrillionstel', 'Quadrilliardstel', 'Zehn Quadrilliardstel', 'Hundert Quadrilliardstel', 'Quintillionstel']
export const equal = (x, y, marginOfError) => {
  return x <= y + marginOfError && x >= y - marginOfError;
}

//für Equations
export const intermix = (arr1, arr2)=>{ //wird auch nur bei der equationerstellung benötigt, besser in "general"
let arr3 = [];
if (typeof arr2 == 'string') arr2 = numbers(arr1.length).map(x=>arr2);
arr1.forEach((x,i)=>{
  arr3.push(x.value);
  arr3.push('unit[' + x.unit + ']');
  if (arr2[i]) arr3.push(arr2[i]);
})
return arr3.slice(0,-1); 
}

export const numbers = (n)=>{
  let result = [];
  for (let i=0; i<n; i++) result.push(i)
  return result;
}
export const shallowCopy = (arr)=>JSON.parse(JSON.stringify(arr));
export const numberWithFactors = (fs, min?, max?, skewFn?) => {
  skewFn = skewFn || ((x,y) => x);
  min = min || 0;
  max = max || 9999999999999999;
  let result = [randomItem(fs)];
  while (result.reduce((a,b)=>a*b, 1) <= max){
    fs = skewFn(fs, result);
    let f = randomItem(fs);
    if (result.reduce((a,b)=>a*b, 1) > min && randomBool()) break;
    if (result.reduce((a,b)=>a*b, 1) * f > max) break;
    else {
      result.push(f);
    }
  }
  return result.filter(x=>x!== undefined).reduce((a,b)=>a*b,1);
}
export const niceRoundUp = (n) => {
  return Math.ceil(n / Math.pow(10, String(n).length-1)) * Math.pow(10, String(n).length-1);
}
export const digitsum = (n) => Array.from(String(n)).map(x=>Number(x)).reduce((a,b)=> +a+ +b);
export const numberSort = (a,b) => (a-b)/Math.abs(a-b);

export const randomDigit = (exceptions)=> {
  let r =   Math.floor(Math.random()*10);
  while (exceptions.includes(r)){
    r =   Math.floor(Math.random()*10);
  }
  return r;
}
export const randomInt = (min?,max?)=> min + Math.floor(Math.random() * (max-min));
export const randomBool = () => Math.random() < .5;

export const randomIndex = (arr) => Math.floor(Math.random()*arr.length)
export const randomItem = (arr) => arr[randomIndex(arr)]
export const randomItems = (n, arr) => numbers(n).map(()=>randomItem(arr));
export const randomItemsUnique = (n,arr) => {
  let result = [];
  while (n){
    let i = randomIndex(arr);
    result.push(arr[i]);
    arr = arr.slice(0,i).concat(arr.slice(i+1));
    n--;
  }
  return result;
}
export const without = (arr1,arr2) => {
  let result = arr1.slice();
  for (let a of arr2){
    const i = result.indexOf(a);
    if (i !== -1) result.splice(i,1);
  }
  return result;
}
export const shakeTo = (n, arr) => {
  while (arr.length > n) arr.splice(randomIndex(arr),1);
  return arr;
}

export class Timer{
  public startTime;
  public lastTime;
  public timer;
  public spans = [];
  public running = false;
  public duration;
  public callbacks ={
    tick: []
  };
  public onTick = function(){
    this.duration = this.spans
        .map(x=>x.end-x.start)
        .reduce((a,b)=>a+b, 0) + (Date.now() - this.lastTime);
  };
  constructor(){}

  start(){
      if (!this.timer){
        this.timer = setInterval(()=>{
          if (this.onTick) this.onTick();
          this.callbacks.tick.forEach(x=>x());
        },1000)
      }
      if (!this.startTime) this.startTime = Date.now();
      this.lastTime = Date.now();
      this.running = true;

  }

  stop(){
    if (this.running) {
      clearInterval(this.timer);
    this.timer = undefined;}
    this.running = false;
    this.spans.push({start: this.lastTime, end: Date.now()})
  }

  addEventListener(event, callback){
    this.callbacks[event].push(callback);
  }
}
export const walkTree = (root, fn, level=0)=>{
  root.forEach(x=>{
    fn(x, level);
    if (x.children){
      walkTree(x.children, fn, level + 1);
    }
  });
}
export class Point{
  static randomInteger(x1,x2,y1,y2, name){
    return new Point(randomInt(x1,x2), randomInt(y1,y2), name)
  }
  constructor(public x, public y, public name){}
}

export function offSetsForElement(elementNode){
  //http://www.kirupa.com/html5/get_element_position_using_javascript.htm
      elementNode = elementNode;
      var totalOffset, currentElement;
  
      totalOffset = [0, 0];
      currentElement = elementNode;
      do{
          totalOffset[0] += currentElement.offsetLeft	- currentElement.scrollLeft + currentElement.clientLeft;
          totalOffset[1] += currentElement.offsetTop	- currentElement.scrollTop + currentElement.clientTop;
      }
      while(currentElement = currentElement.offsetParent)
      return totalOffset;
  }

export function getDimensionsOfElement(elementNode){
  let o = offSetsForElement(elementNode);
  let s = window.getComputedStyle(elementNode);
  let result = {x: undefined, y: undefined, width: undefined, height: undefined};
  result.x = o[0];
  result.y = o[1];
  result.width = +s.width.slice(0,-2);
  result.height = +s.height.slice(0,-2);   
  return result;
}

export function pointInRectangle(p, b){
  return (p[0] >= b[0]) && (p[0] <= b[0] + b[2]) && (p[1] >= b[1]) && (p[1] <= b[1] + b[3]);
}

export const objectFromArray = (array: Object[], key: string)=>{
  let t = {};
  if (key.length){
    array.forEach(x=> t[x[key]] = x );
  } else {
    array.forEach(x=>t[x[0]] = x[1]);
  }
  return t
}

export const mapFromArray = (array: Object[],key: string)=>{
  let t = new Map();
  array.forEach(x=>t.set(x[key], x));
  return t;
}

export const padArrayLeft = (array, ln, obj)=>{
  let arr = array.slice(0);
  while (arr.length < ln){
    arr.unshift(shallowCopy(obj));
  }
  return arr;
}
export const padArrayRight = (array, ln, obj)=>{
  let arr = array.slice(0);
  while (arr.length < ln){
    arr.push(shallowCopy(obj));
  }
  return arr;
}
export function linearScale(x:number, projectedLength: number, originalLength = 1, offSet = 0 ){
  return offSet + (x * projectedLength / originalLength);
};

export function linearScale2D(x:number, y:number, projectedWidth:number,projectedHeight:number,  originalWidth = 1, originalHeight = 1, offSetX = 0, offSetY=0){
  return [
    linearScale(x, projectedWidth, originalWidth, offSetX),
    linearScale(y, projectedHeight, originalHeight, offSetY)
  ]
};

export function equidistantSteps(n:number, from:number, to: number){
    let s = 1/n;
    let r = []
    for (let i = 0; i < n; i++){
      r.push(i*s);
    }
    let f = (x)=>linearScale(x, to-from, 1, from);
    return r.map(f);
}

export function equidistantGrid(nx: number, ny: number, fromX:number, toX: number, fromY: number, toY: number){
  let xs = equidistantSteps(nx, fromX, toX);
  let ys = equidistantSteps(ny, fromY, toY);
  return xs.map((k,i)=> ys.map(l=>[k, l]));

}

export function degreesToRadians(angleInDegrees){
  return angleInDegrees * Math.PI / 180.0
};

export function radiansToDegree(angleInRadians){
  return angleInRadians * 180.0 / Math.PI;
};

export function cartesianToPolar(centerX:number, centerY:number, x:number, y:number) {
  return [
    Math.sqrt(Math.pow(centerX - x, 2) + Math.pow(centerY - y, 2)),
    Math.atan2(centerY - y, centerX - x)
  ]
};

export function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
  var angleInRadians;

  angleInRadians = degreesToRadians(angleInDegrees)
  return [
    centerX + (radius * Math.cos(angleInRadians)),
    centerY + (radius * Math.sin(angleInRadians))
  ];
};

export function relMouseCoords(event, elementNode){
  //http://www.kirupa.com/html5/get_element_position_using_javascript.htm
      elementNode = elementNode || event.target;
      var totalOffset, currentElement;
  
      totalOffset = [0, 0];
      currentElement = elementNode;
      do{
          totalOffset[0] += currentElement.offsetLeft	- currentElement.scrollLeft + currentElement.clientLeft;
          totalOffset[1] += currentElement.offsetTop	- currentElement.scrollTop + currentElement.clientTop;
      }
      while(currentElement = currentElement.offsetParent)
      return [
        (event.pageX - totalOffset[0]) * elementNode.width/+elementNode.clientWidth,
        (event.pageY - totalOffset[1]) * elementNode.height/+elementNode.clientHeight
      ]
  }
  