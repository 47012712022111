import { Injectable } from '@angular/core';
import { TaskMentalArithmetic } from '../Task-Models/model-mental-arithmetic';
import { TaskFractionCancel } from '../Task-Models/model-task-fraction-cancel';
import { TaskFractionRecognition } from '../Task-Models/model-task-fraction-recognition';
import { TaskFractionToPercent } from '../Task-Models/model-task-fraction-to-percent';
import { TaskPercentToFraction } from '../Task-Models/model-task-percent-to-fraction';
import { TaskPercentCalculations } from '../Task-Models/model-task-percent-calculations';
import { TaskProportionalIsit } from '../Task-Models/model-task-proportional-isit';
import { TaskProportionalCompletion } from '../Task-Models/model-task-proportional-completion';
import { TaskRuleOfThree } from '../Task-Models/model-task-rule-of-three';
import { Timer } from '../general';
import { TaskRound } from '../Task-Models/model-task-round';
import { TaskUnitConversion } from '../Task-Models/model-task-unit-conversion';
import { TaskPercentConcepts } from '../Task-Models/model-task-percent-concepts';
import { TaskCommaSliding } from '../Task-Models/model-task-comma-sliding';
import { TaskMarkPoint } from '../Task-Models/model-task-mark-point';
import { TaskFractionExpansion } from '../Task-Models/model-task-fraction-expansion';
import { TaskNegativeAddition } from '../Task-Models/model-task-negative-addition';
import { TaskCompareNegativeNumbers } from '../Task-Models/model-task-compare-negative-numbers';
import { TaskNegativeNumbersWithoutParentheses } from '../Task-Models/model-task-negative-numbers-without-parentheses'; 
import { TaskFractionWordProblemSharing } from '../Task-Models/model-task-fraction-word-problem-sharing';
import { TaskFractionComparison } from '../Task-Models/model-task-fraction-comparison';
import { TaskFractionOfNumber } from '../Task-Models/model-task-fraction-of-number';
import { TaskFractionExpandWith} from '../Task-Models/model-task-fraction-expand-with';
import { TaskFractionExpansionFactor } from '../Task-Models/model-task-fraction-expansion-factor';
import { TaskFractionChooseIdentical } from '../Task-Models/model-task-fraction-choose-identical';
import { TaskTermSimplification } from '../Task-Models/model-task-term-simplification';
import { objectFromArray } from '../general';
import { TaskMentalArithmeticComponent } from '../Components/task-mental-arithmetic/task-mental-arithmetic.component';
import { TaskFractionRecognizeComponent } from '../Components/task-fraction-recognize/task-fraction-recognize.component';
import { TaskFractionCancelComponent } from '../Components/task-fraction-cancel/task-fraction-cancel.component';
import { TaskFractionToPercentComponent } from '../Components/task-fraction-to-percent/task-fraction-to-percent.component';
import { TaskPercentToFractionComponent } from '../Components/task-percent-to-fractions/task-percent-to-fractions.component';
import 'lodash';
import { TaskPercentCalculationsComponent } from '../Components/task-percent-calculations/task-percent-calculations.component';
import { TaskPercentConceptsComponent } from '../Components/task-percent-concepts/task-percent-concepts.component';
import { TaskUnitConversionComponent } from '../Components/task-unit-conversion/task-unit-conversion.component';
import { TaskRoundComponent } from '../Components/task-round/task-round.component';
import { TaskCommaSlidingComponent } from '../Components/task-comma-sliding/comma-sliding.component';
import { TaskMarkPointComponent } from '../Components/task-mark-point/task-mark-point.component';
import { TaskFractionExpansionComponent } from '../Components/task-fraction-expansion/task-fraction-expansion.component';
import { TaskNegativeAdditionComponent } from '../Components/task-negative-addition/task-negative-addition.component';
import { TaskCompareNegativeNumbersComponent } from '../Components/task-compare-negative-numbers/task-compare-negative-numbers.component';
import { TaskNegativeNumbersWithoutParenthesesComponent } from '../Components/task-negative-numbers-without-parentheses/task-negative-numbers-without-parentheses.component';
import { TaskFractionWordProblemSharingComponent } from '../Components/task-fraction-word-problem-sharing/task-fraction-word-problem-sharing.component';
import { TaskFractionOfNumberComponent } from '../Components/task-fraction-of-number/task-fraction-of-number.component';
import { TaskFractionComparisonComponent } from '../Components/task-fraction-comparison/task-fraction-comparison.component';
import { TaskFractionExpandWithComponent } from '../Components/task-fraction-expand-with/task-fraction-expand-with.component';
import { TaskFractionExpansionFactorComponent } from '../Components/task-fraction-expansion-factor/task-fraction-expansion-factor.component';
import { TaskFractionChooseIdenticalComponent } from '../Components/task-fraction-choose-identical/task-fraction-choose-identical.component';
import { TaskTermSimplificationComponent } from '../Components/task-term-simplification/task-term-simplification.component';
import { PageOrigamiComponent } from '../Components/page-origami/page-origami.component';
import { TaskFlashcardTerms } from '../Task-Models/model-task-flashcard-terms';
import { TaskFlashcardTermsComponent } from '../Components/task-flashcard-terms/task-flashcard-terms.component';
import { TaskDecimalWrite } from '../Task-Models/model-task-decimals-write';
import { TaskDecimalWriteComponent } from '../Components/task-decimal-write/task-decimal-write.component';
import { TaskDecimalCompare } from '../Task-Models/model-task-decimal-comparison';
import { TaskDecimalComparisonComponent } from '../Components/task-decimal-comparison/task-decimal-comparison.component';
import { TaskDecimalComparisonCards } from '../Task-Models/model-task-decimals-comparison-cards';
import { TaskDecimalComparisonCardsComponent } from '../Components/task-decimal-comparison-cards/task-decimal-comparison-cards.component';
import { TaskDecimalsComparisonInbetween } from '../Task-Models/model-task-decimals-comparison-inbetween';
import { TaskDecimalsComparisonInbetweenComponent } from '../Components/task-decimals-comparison-inbetween/task-decimals-comparison-inbetween.component';
import { TaskDecimalsNumberlineFind } from '../Task-Models/model-task-decimals-numberline-find';
import { TaskDecimalsNumberlineTell } from '../Task-Models/model-task-decimals-numberline-tell';
import { TaskDecimalsNumberlineFindComponent } from '../Components/task-decimals-numberline-find/task-decimals-numberline-find.component';
import { TaskDecimalsNumberlineTellComponent } from '../Components/task-decimals-numberline-tell/task-decimals-numberline-tell.component';
import { TaskDecimalRoundComponent } from '../Components/task-decimal-round/task-decimal-round.component';
import { TaskDecimalsRound } from '../Task-Models/model-task-decimals-round';
import { TaskDecimalNamePlace } from '../Task-Models/model-task-decimal-name-place';
import { TaskDecimalNamePlacesComponent } from '../Components/task-decimal-name-places/task-decimal-name-places.component';
import { TaskRuleOfThreeComponent } from '../Components/task-rule-of-three/task-rule-of-three.component';
import { TaskProportionalCompletionComponent } from '../Components/task-proportional-completion/task-proportional-completion.component';
import { TaskCompareUnitsComponent } from '../Components/task-compare-units/task-compare-units.component';
import { TaskCompareUnits } from '../Task-Models/model-task-compare-units';
import { TaskFractionAddition } from '../Task-Models/model-task-fraction-addition';
import { TaskFractionAdditionComponent } from '../Components/task-fraction-addition/task-fraction-addition.component';
import { TaskNegativeNumbersNumberlineFind } from '../Task-Models/model-task-negative-numbers-numberline-find';
import { TaskRectangleShapes } from '../Task-Models/model-task-rectangle-shapes';
import { TaskRectangleShapesComponent } from '../Components/task-rectangle-shapes/task-rectangle-shapes.component';
import { TaskRectanglesComponent } from '../Components/task-rectangles/task-rectangles.component';
import { TaskRectangles } from '../Task-Models/model-task-rectangles';
import { TaskNegativeNumbersMultiplication } from '../Task-Models/model-task-negative-numbers-multiplication';
import { TaskNegativeNumbersMultiplicationComponent } from '../Components/task-negative-numbers-multiplication/task-negative-numbers-multiplication.component';
import { TaskFractionToMixedNumber } from '../Task-Models/model-task-fraction-to-mixed-number';
import { TaskFractionToMixedNumberComponent } from '../Components/task-fraction-to-mixed-number/task-fraction-to-mixed-number.component';
import { TaskEquationsWordproblemComponent } from '../Components/task-equations-wordproblem/task-equations-wordproblem.component';
import { TaskEquationsWordproblem } from '../Task-Models/model-task-equations-wordproblem';
import { TaskEquationsGraphs } from '../Task-Models/model-task-equations-graphs';
import { TaskEquationsGraphsComponent } from '../Components/task-equations-graphs/task-equations-graphs.component';
import { TaskEquivalenceTransformationComponent } from '../Components/task-equivalence-transformation/task-equivalence-transformation.component';
import { TaskEquivalencetransformation } from '../Task-Models/model-task-equivalencetransformation';
import { TaskNumberBaseChangeComponent } from '../Components/task-number-base-change/task-number-base-change.component';
import { TaskNumberBaseChange } from '../Task-Models/model-task-number-base-change';

const Tasks = [  
  {path: 'dezimalzahlen-stellen-nennen', task: TaskDecimalNamePlace, name: "Stellen Benennen", component: TaskDecimalNamePlacesComponent},
  {path: 'dezimalzahlen-runden', task: TaskDecimalsRound, name: "Dezimalzahlen runden", component: TaskDecimalRoundComponent},
  {path: 'dezimalzahlen-zahlenstrahl-finden', task: TaskDecimalsNumberlineTell, name: "Dezimalzahlen auf dem Zahlenstrahl finden", component: TaskDecimalsNumberlineFindComponent},
  {path: 'dezimalzahlen-zahlenstrahl-benennen', task: TaskDecimalsNumberlineTell, name: "Dezimalzahlen auf dem Zahlenstrahl benennen", component: TaskDecimalsNumberlineTellComponent},
  {path: 'dezimalzahlen-vergleichen-zwischenzahl', task: TaskDecimalsComparisonInbetween, name: "Dezimalzahlen vergleichen 3", component: TaskDecimalsComparisonInbetweenComponent},
  {path: 'dezimalzahlen-vergleichen-karten', task: TaskDecimalComparisonCards, name: "Dezimalzahlen vergleichen 2", component: TaskDecimalComparisonCardsComponent},
  {path: 'dezimalzahlen-vergleichen', task: TaskDecimalCompare, name: "Dezimalzahlen vergleichen 1", component: TaskDecimalComparisonComponent},
  {path: 'dezimalzahlen-schreiben', task: TaskDecimalWrite, name: "Dezimalzahlen schreiben", component: TaskDecimalWriteComponent},
  {path: 'karteikarten-begriffe', task: TaskFlashcardTerms, name: "Karteikartenbegriffe", component: TaskFlashcardTermsComponent},
  {path: 'prozente-berechnen', task: TaskPercentCalculations, name: "Mit Prozenten rechnen", component: TaskPercentCalculationsComponent},
  {path: 'bruch-prozent', task: TaskFractionToPercent, name: "Bruch zu Prozent", component: TaskFractionToPercentComponent},
  {path: 'prozent-bruch', task: TaskPercentToFraction, name: "Prozent zu Bruch", component: TaskPercentToFractionComponent},
  {path: 'prozent-begriffe', task: TaskPercentConcepts, name: "Prozent Begriffe", component: TaskPercentConceptsComponent},
  {path: 'bruch-kuerzen', task: TaskFractionCancel, name: "Brüche kürzen", component: TaskFractionCancelComponent},
  {path: 'negative-zahlen-addieren-und-subtrahieren', task: TaskNegativeAddition, name: "Addition und Subtraktion", component:TaskNegativeAdditionComponent},
  {path: 'negative-zahlen-vergleichen', task: TaskCompareNegativeNumbers, name: "Vergleichen", component:TaskCompareNegativeNumbersComponent},
  {path: 'negative-zahlen-ohne-klammern', task: TaskNegativeNumbersWithoutParentheses, name: "Klammern auflösen" , component:TaskNegativeNumbersWithoutParenthesesComponent},        
  {path: 'terme-vereinfachen', task: TaskTermSimplification, name: "Terme vereinfachen" , component: TaskTermSimplificationComponent},
  {path: 'falten', component: PageOrigamiComponent, isNoTask: true, name: "Origami falten"},
  {path: 'bruch-erweitern', task: TaskFractionExpansion, name: "Brüche erweitern 1" , component: TaskFractionExpansionComponent},
  {path: 'bruch-erweitern-mit', task: TaskFractionExpandWith, name: "Brüche erweitern 2" , component: TaskFractionExpandWithComponent},
  {path: 'bruch-erweitern-3', task: TaskFractionExpansionFactor, name: "Brüche erweitern 3" , component: TaskFractionExpansionFactorComponent},
  {path: 'bruch-erkennen', task: TaskFractionRecognition, name: "Brüche erkennen" , component: TaskFractionRecognizeComponent},
  {path: 'bruch-gleichwertig', task: TaskFractionChooseIdentical, name: "Gleich große Brüche" , component: TaskFractionChooseIdenticalComponent},
  {path: 'bruch-textaufgaben-teilen', task: TaskFractionWordProblemSharing, name: "Textaufgaben" , component: TaskFractionWordProblemSharingComponent},
  {path: 'brueche-vergleichen', task: TaskFractionComparison, name: "Brüche vergleichen" , component: TaskFractionComparisonComponent},
  {path: 'anteile', task: TaskFractionOfNumber, name: "Anteile berechnen" , component: TaskFractionOfNumberComponent},
  {path: 'kopfrechnen', task: TaskMentalArithmetic, name: "Kopfrechnen" , component:TaskMentalArithmeticComponent},
  {path: 'runden', task: TaskRound, name: "Runden" , component:TaskRoundComponent},
  {path: 'einheiten', task: TaskUnitConversion, name: "Einheiten umrechnen" , component:TaskUnitConversionComponent},
  {path: 'zehnerpotenzen-kommas-schieben', task: TaskCommaSliding, name: "Kommaverschiebung" , component:TaskCommaSlidingComponent},
  {path: 'markiere-den-punkt', task: TaskMarkPoint, name: "Punkt markieren" , component:TaskMarkPointComponent},
  {path: 'dreisatz', task: TaskRuleOfThree, name: "Dreisatz" , component:TaskRuleOfThreeComponent},
  {path: 'proportionale-zuordnungen', task: TaskProportionalCompletion, name: "Proportionale Zuordnungen" , component:TaskProportionalCompletionComponent},
  {path: 'einheiten-vergleichen', task: TaskCompareUnits, name: "Einheiten vergleichen" , component:TaskCompareUnitsComponent},
  {path: 'mit-bruechen-rechnen', task: TaskFractionAddition, name: "Mit Brüchen rechnen" , component:TaskFractionAdditionComponent},
  {path: 'negative-zahlen-zahlenstrahl-finden', task: TaskNegativeNumbersNumberlineFind, name: "Finde die Zahl" , component:TaskDecimalsNumberlineFindComponent},
  {path: 'negative-zahlen-zahlenstrahl-benennen', task: TaskNegativeNumbersNumberlineFind, name: "Welche Zahl ist es?" , component:TaskDecimalsNumberlineTellComponent},
  {path: 'umfang', task: TaskRectangles, name: "Berechne den Umfang?" , component:TaskRectanglesComponent},
  {path: 'negative-zahlen-multiplizieren', task: TaskNegativeNumbersMultiplication, name: "Negative Zahlen multiplizieren" , component:TaskNegativeNumbersMultiplicationComponent},
  {path: 'gemischte-zahlen', task: TaskFractionToMixedNumber, name: "Gemischte Zahlen" , component:TaskFractionToMixedNumberComponent},
  {path: 'gleichungen-textaufgaben', task: TaskEquationsWordproblem, name: "Gleichungen - Textaufgaben" , component:TaskEquationsWordproblemComponent},
  {path: 'gleichungen-graphisch', task: TaskEquationsGraphs, name: "Gleichungen - Graphisch lösen" , component:TaskEquationsGraphsComponent},
  {path: 'gleichungen-aequivalenzumformungen', task: TaskEquivalencetransformation, name: "Gleichungen - Äquivalenzumformungen" , component:TaskEquivalenceTransformationComponent},
  {path: 'zahlen-umwandeln', task: TaskNumberBaseChange , name: "Zahlendarstellungen umwandeln" , component:TaskNumberBaseChangeComponent},

];
export class TaskDataContainer{
  public difficulty = 1;
  public history:any = [];
  public maxDifficulty;
  public timer = new Timer();
  public class;
  public solved;


  constructor(public _type, public displayName, classes, public component?, public name?){
    this.class = classes;
    if (_type){
      this.maxDifficulty = (this._type.maxDifficulty || Math.max(...Object.keys(this.type.difficulties).map(Number)));
    }
    else {
      this.history = {length: ''};
    }
  }

  get type(){
    return Object.create(this._type);
  }
}

const tasks = objectFromArray(Tasks.map(x=> new TaskDataContainer(x.task, x.name, [], x.component, x.path)), 'name');


@Injectable({
  providedIn: 'root'
})
export class TaskRegistryService {

  constructor() { 
  }
  
  getTask(n){
    console.log(this.tasks, n, this.tasks[n])
    return this.tasks[n];
  }

  get tasks(){ 
    return tasks;
  }

  tasksFor(c){
    return Object.keys(this.tasks).filter(x=>this.tasks[x].class.indexOf(+c) !== -1);
  }
  
  get taskNames(){ return Object.keys(this.tasks);}
}
