import { Component } from '@angular/core';
import { POSITIONS } from '../../general';
import { TaskComponent } from '../task/task.component';
@Component({
  selector: 'app-task-round',
  templateUrl: './task-round.component.html',
  styleUrls: ['./task-round.component.css']
})
export class TaskRoundComponent extends TaskComponent {
  public name = "TaskRound"
  positionAt(i){
    return POSITIONS[this.task.number.length-i-1];
  }
}
