import { Task } from "../DBTask";
import { numbers, randomInt } from '../general';
import { DBPlaceValueRepresentation } from '../DBPlaceValueRepresentation';

export class TaskDecimalsRound extends Task{
    static difficulties = {
        1 : ()=>{
            let l = numbers(3).map(x=>randomInt(0,9)).join('');
            let r = numbers(3).map(x=>randomInt(0,9)).join('');
            let a = DBPlaceValueRepresentation.fromNumber(l + '.' + r).shrink();
            let p = randomInt(a.positionsRange[1] + 1, a.positionsRange[0]);
            console.log(a.germanString,a.positionsRange.reverse(), p)
            return new TaskDecimalsRound(1, a, p);
        }
    }

    public result;
    public guess;
    constructor(public difficulty, public number, public position){
        super();
    }
    check(){
        console.log(this.number.round)
        if ( this.number.round(this.position).match(this.guess)){
            console.log("genau")
            this.emit('correct');  
        }

        else {
          console.log("leider nicht")
          this.emit('wrong');  
        }
    }


}