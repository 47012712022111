import { Component, OnInit } from '@angular/core';
import { TaskComponent } from '../task/task.component';

@Component({
  selector: 'app-task-equations-wordproblem',
  templateUrl: './task-equations-wordproblem.component.html',
  styleUrls: ['./task-equations-wordproblem.component.css']
})
export class TaskEquationsWordproblemComponent extends TaskComponent {


}
