import { Component, OnInit } from '@angular/core';
import { DragService } from '../DragnDrop/drag.service';
import { numbers, randomInt } from '../general';
@Component({
  selector: 'app-drag-drop-test',
  templateUrl: './drag-drop-test.component.html',
  styleUrls: ['./drag-drop-test.component.css']
})
export class DragDropTestComponent implements OnInit {
  public baskets = []
  public sortables = []
  constructor() {   
    numbers(6).forEach(x=>{
      this.baskets.push({
        id: x,
        content: []
      });
      this.sortables.push({
        id: x,
        value: randomInt(1,100)
      })
    })
  }

  log(e){ console.log(e); }
  
  put(sortable, basket){
    //if (! (basket.content.filter(x=>x.id == sortable.id).length)) 
    basket.content.push(sortable);
    console.log(this.baskets.map(x=>x.content))
  }
  remove(sortable, basket){
    if(sortable.droppable[0]){
      basket.content = basket.content.filter(x=>x.id != sortable.droppable[0].data.id);
    }
    console.log(this.baskets.map(x=>x.content))
  }
  ngOnInit() {
  }

}
