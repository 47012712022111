import { Task } from "../DBTask";
import { randomInt, randomBool, randomItem, equal } from '../general';
export class TaskCompareNegativeNumbers extends Task{
    public name = "TaskCompareNegativeNumbers";
    static options = {
        /*addition: {
            model: true,
            type: 'bool',
            label: 'Addition',
            disabled: false,
            relations: (o, v)=>{
                if (!v) {
                    o.subtraction.model = true;            
                    o.type.model = '';
                }
            }
        },*/
    }

    static difficulties = {
        1 : ()=>{ 
            let first = randomInt(-20,0);
            let second = randomInt(-20,0);
            return new TaskCompareNegativeNumbers(1, first, second)
        },
        2 : ()=>{
            let first = randomInt(-20,0);
            let second = randomInt(-20,0);
            first = first/randomItem([1,10,100]);
            second = second/randomItem([1,10,100]);
            return new TaskCompareNegativeNumbers(2, first, second)
        },/*
        3 : ()=>{
            let operands = [randomInt(0,100), randomInt(0,100)]; 
            return new TaskNegativeAddition(3, operands)
        },
        4 : ()=>{
            let operands = [randomInt(0,100)+ (randomInt(0,9) * 0.1), randomInt(0,100)]; 
            return new TaskNegativeAddition(4, operands)
        },
        5 : ()=>{
            let operands = [randomInt(0,100) + (randomInt(0,9) * 0.1), randomInt(0,100) + (randomInt(0,9) * 0.1)]; 
            return new TaskNegativeAddition(5, operands)
        },
        6 : ()=>{
            let operands = [randomInt(0,100) + (randomInt(0,9) * 0.1), randomInt(0,100) + (randomInt(0,9) * 0.1), randomInt(0,100) + (randomInt(0,9) * 0.1)]; 
            return new TaskNegativeAddition(6, operands)
        },*/
    }


    public result;
    public guess;
    public description = `
    <p>Wähle das passende Symbol (<,> oder =)
    </p>
    `
    constructor(public difficulty:number, public first, public second){
        super();
        this.result = this.first == this.second ? '=' :
                      this.first < this.second ? '<' :
                      '>'
    }

    check(){
        console.log(this.result, this.guess)
        if (this.result === this.guess){
            console.log("genau")
            this.emit('correct');  
        }
        else{
          console.log("leider nicht")
          this.emit('wrong');  
        }
    }
}