import { Fraction } from './Fraction';

export class MixedNumber{
    static fromFraction(frac){
        let n = Math.floor(frac.enumerator/frac.denominator);
        n = frac.sign == '-' ? -n : n;
        return new MixedNumber(n, new Fraction(frac.enumerator - n*frac.denominator, frac.denominator));
    }
    constructor(public number : number, public fraction : Fraction){
        console.log(this);
    }

    toFraction(){
        return new Fraction(this.fraction.denominator * Math.abs(this.number), this.fraction.denominator, this.number < 0 ? '-' : '+')
    }
    toString(){
        return 'mixedNumber(' + [this.number, this.fraction.enumerator, this.fraction.denominator].join(',') + ')';
    }
    reduce(){
        return new MixedNumber(this.number, this.fraction.reduce());
    }
    equal(mn){
        return this.number == mn.number && this.fraction.equal(mn.fraction); 
    }
    identical(mn){
        return this.number == mn.number && this.fraction.identical(mn.fraction); 
    }
}