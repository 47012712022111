import { Component, OnInit } from '@angular/core';
import { TaskComponent } from '../task/task.component';

@Component({
  selector: 'app-task-decimals-numberline-tell',
  templateUrl: './task-decimals-numberline-tell.component.html',
  styleUrls: ['./task-decimals-numberline-tell.component.css']
})
export class TaskDecimalsNumberlineTellComponent extends TaskComponent {

toNumber(s){
  return +s;
}
}
