import { Component, OnInit, ViewChild, ViewChildren, AfterViewInit } from '@angular/core';
import { TokenHoldersContainerComponent } from '../token-holders-container/token-holders-container.component';
@Component({
  selector: 'app-place-value-token-system',
  templateUrl: './place-value-token-system.component.html',
  styleUrls: ['./place-value-token-system.component.css']
})
export class PlaceValueTokenSystemComponent implements AfterViewInit {
  @ViewChildren(TokenHoldersContainerComponent) public containers;
  constructor() { }
  distribute(type){
    let i = 0;
    let c = this.containers.toArray();
    let src = c[0];
    let targets = c.slice(1)
    while(src.remove(1,type)){
      targets[i].add(1,type);
      i = (i+1)%targets.length;
    }
  }
  moveBack(){
    let c = this.containers.toArray();
    let src = c[0];
    let targets = c.slice(1)

    targets.forEach(x=>{
      [100,10,1].forEach(type=>{
      while(x.remove(1,type)){
        src.add(1,type);
      }
    })
  })
  }
  ngAfterViewInit() {
  }

}
