import { InjectionToken } from '@angular/core';

export const APP_CONFIG: InjectionToken<string> = new InjectionToken('app.config');

export interface IAppConfig {
  isIE: boolean;
  isEdge: boolean;
  isSafari: boolean;
}

export const appConfig: IAppConfig = {
  isIE: (() => {
    return !!(<any>document).documentMode;
  })(),

  isEdge: (() => {
    return /Edge/.test(navigator.userAgent)
  })(),

  isSafari: (() => {
    const ua = navigator.userAgent.toLowerCase();

    return ua.indexOf('safari') !== -1 && ua.indexOf('chrome') === -1;
  })(),
}
