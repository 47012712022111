export const list = [
    {
      "city": "Berlin",
      "population1970": 3208719,
      "population2017": 3613495
    },
    {
      "city": "Hamburg",
      "population1970": 1793640,
      "population2017": 1830584
    },
    {
      "city": "München",
      "population1970": 1311978,
      "population2017": 1456039
    },
    {
      "city": "Köln",
      "population1970": 849451,
      "population2017": 1080394
    },
    {
      "city": "Frankfurt am Main",
      "population1970": 666179,
      "population2017": 746878
    },
    {
      "city": "Stuttgart",
      "population1970": 634202,
      "population2017": 632743
    },
    {
      "city": "Düsseldorf",
      "population1970": 660963,
      "population2017": 617280
    },
    {
      "city": "Dortmund",
      "population1970": 640642,
      "population2017": 586600
    },
    {
      "city": "Essen",
      "population1970": 696419,
      "population2017": 583393
    },
    {
      "city": "Leipzig",
      "population1970": 583885,
      "population2017": 581980
    },
    {
      "city": "Bremen",
      "population1970": 592533,
      "population2017": 568006
    },
    {
      "city": "Dresden",
      "population1970": 502432,
      "population2017": 551072
    },
    {
      "city": "Hannover",
      "population1970": 521003,
      "population2017": 535061
    },
    {
      "city": "Nürnberg",
      "population1970": 478181,
      "population2017": 515201
    },
    {
      "city": "Duisburg",
      "population1970": 452721,
      "population2017": 498110
    },
    {
      "city": "Bochum",
      "population1970": 343809,
      "population2017": 365529
    },
    {
      "city": "Wuppertal",
      "population1970": 417694,
      "population2017": 353590
    },
    {
      "city": "Bielefeld",
      "population1970": 168609,
      "population2017": 332552
    },
    {
      "city": "Bonn",
      "population1970": 275722,
      "population2017": 325490
    },
    {
      "city": "Münster",
      "population1970": 198878,
      "population2017": 313559
    },
    {
      "city": "Karlsruhe",
      "population1970": 259091,
      "population2017": 311919
    },
    {
      "city": "Mannheim",
      "population1970": 332378,
      "population2017": 307997
    },
    {
      "city": "Augsburg",
      "population1970": 213230,
      "population2017": 292851
    },
    {
      "city": "Wiesbaden",
      "population1970": 250715,
      "population2017": 278654
    },
    {
      "city": "Mönchengladbach",
      "population1970": 151085,
      "population2017": 262188
    },
    {
      "city": "Gelsenkirchen",
      "population1970": 347074,
      "population2017": 260305
    },
    {
      "city": "Braunschweig",
      "population1970": 223275,
      "population2017": 248023
    },
    {
      "city": "Kiel",
      "population1970": 271042,
      "population2017": 247943
    },
    {
      "city": "Chemnitz",
      "population1970": 299411,
      "population2017": 246855
    },
    {
      "city": "Aachen",
      "population1970": 175451,
      "population2017": 246272
    },
    {
      "city": "Halle (Saale)",
      "population1970": 257261,
      "population2017": 239173
    },
    {
      "city": "Magdeburg",
      "population1970": 272237,
      "population2017": 238478
    },
    {
      "city": "Freiburg im Breisgau",
      "population1970": 163568,
      "population2017": 229636
    },
    {
      "city": "Krefeld",
      "population1970": 222666,
      "population2017": 226699
    },
    {
      "city": "Lübeck",
      "population1970": 239955,
      "population2017": 216318
    },
    {
      "city": "Mainz",
      "population1970": 174858,
      "population2017": 215110
    },
    {
      "city": "Erfurt",
      "population1970": 195994,
      "population2017": 212988
    },
    {
      "city": "Oberhausen",
      "population1970": 246199,
      "population2017": 211422
    },
    {
      "city": "Rostock",
      "population1970": 198636,
      "population2017": 208409
    },
    {
      "city": "Kassel",
      "population1970": 214785,
      "population2017": 200736
    },
    {
      "city": "Hagen",
      "population1970": 201042,
      "population2017": 187730
    },
    {
      "city": "Saarbrücken",
      "population1970": 128251,
      "population2017": 180966
    },
    {
      "city": "Hamm",
      "population1970": 84629,
      "population2017": 179185
    },
    {
      "city": "Potsdam",
      "population1970": 111336,
      "population2017": 175710
    },
    {
      "city": "Mülheim an der Ruhr",
      "population1970": 192183,
      "population2017": 171265
    },
    {
      "city": "Ludwigshafen am Rhein",
      "population1970": 176257,
      "population2017": 168497
    },
    {
      "city": "Oldenburg",
      "population1970": 131545,
      "population2017": 167081
    },
    {
      "city": "Osnabrück",
      "population1970": 144864,
      "population2017": 164374
    },
    {
      "city": "Leverkusen",
      "population1970": 107626,
      "population2017": 163577
    },
    {
      "city": "Heidelberg",
      "population1970": 121398,
      "population2017": 160601
    },
    {
      "city": "Solingen",
      "population1970": 177124,
      "population2017": 158803
    },
    {
      "city": "Darmstadt",
      "population1970": 141847,
      "population2017": 158254
    },
    {
      "city": "Herne",
      "population1970": 103849,
      "population2017": 156490
    },
    {
      "city": "Neuss",
      "population1970": 116013,
      "population2017": 153810
    },
    {
      "city": "Regensburg",
      "population1970": 130888,
      "population2017": 150894
    },
    {
      "city": "Paderborn",
      "population1970": 68207,
      "population2017": 149075
    },
    {
      "city": "Ingolstadt",
      "population1970": 70594,
      "population2017": 135244
    },
    {
      "city": "Offenbach am Main",
      "population1970": 118841,
      "population2017": 126658
    },
    {
      "city": "Würzburg",
      "population1970": 116395,
      "population2017": 126635
    },
    {
      "city": "Fürth",
      "population1970": 94415,
      "population2017": 126526
    },
    {
      "city": "Ulm",
      "population1970": 93158,
      "population2017": 125596
    },
    {
      "city": "Heilbronn",
      "population1970": 102300,
      "population2017": 125113
    },
    {
      "city": "Pforzheim",
      "population1970": 91104,
      "population2017": 124289
    },
    {
      "city": "Wolfsburg",
      "population1970": 90115,
      "population2017": 123914
    },
    {
      "city": "Göttingen",
      "population1970": 109892,
      "population2017": 119529
    },
    {
      "city": "Bottrop",
      "population1970": 106225,
      "population2017": 117364
    },
    {
      "city": "Reutlingen",
      "population1970": 79705,
      "population2017": 115762
    },
    {
      "city": "Koblenz",
      "population1970": 120079,
      "population2017": 113844
    },
    {
      "city": "Recklinghausen",
      "population1970": 125369,
      "population2017": 113360
    },
    {
      "city": "Bremerhaven",
      "population1970": 142919,
      "population2017": 113026
    },
    {
      "city": "Bergisch Gladbach",
      "population1970": 50228,
      "population2017": 111627
    },
    {
      "city": "Jena",
      "population1970": 88130,
      "population2017": 111099
    },
    {
      "city": "Erlangen",
      "population1970": 85061,
      "population2017": 110998
    },
    {
      "city": "Remscheid",
      "population1970": 136822,
      "population2017": 110584
    },
    {
      "city": "Trier",
      "population1970": 103598,
      "population2017": 110013
    },
    {
      "city": "Salzgitter",
      "population1970": 119031,
      "population2017": 104548
    },
    {
      "city": "Moers",
      "population1970": 53410,
      "population2017": 103949
    },
    {
      "city": "Siegen",
      "population1970": 57563,
      "population2017": 102337
    },
    {
      "city": "Hildesheim",
      "population1970": 93373,
      "population2017": 101744
    },
    {
      "city": "Cottbus",
      "population1970": 83444,
      "population2017": 101036
    },
    {
      "city": "Kaiserslautern",
      "population1970": 100696,
      "population2017": 99684
    },
    {
      "city": "Gütersloh",
      "population1970": 75912,
      "population2017": 99315
    },
    {
      "city": "Witten",
      "population1970": 97687,
      "population2017": 96565
    },
    {
      "city": "Hanau",
      "population1970": 55492,
      "population2017": 96130
    },
    {
      "city": "Schwerin",
      "population1970": 97389,
      "population2017": 95797
    },
    {
      "city": "Gera",
      "population1970": 111535,
      "population2017": 94859
    },
    {
      "city": "Ludwigsburg",
      "population1970": 78111,
      "population2017": 93593
    },
    {
      "city": "Esslingen am Neckar",
      "population1970": 87371,
      "population2017": 93004
    },
    {
      "city": "Iserlohn",
      "population1970": 57321,
      "population2017": 92928
    },
    {
      "city": "Düren",
      "population1970": 53755,
      "population2017": 90502
    },
    {
      "city": "Zwickau",
      "population1970": 126988,
      "population2017": 90192
    },
    {
      "city": "Tübingen",
      "population1970": 55168,
      "population2017": 89447
    },
    {
      "city": "Flensburg",
      "population1970": 95366,
      "population2017": 88519
    },
    {
      "city": "Gießen",
      "population1970": 75532,
      "population2017": 87343
    },
    {
      "city": "Ratingen",
      "population1970": 44479,
      "population2017": 87226
    },
    {
      "city": "Lünen",
      "population1970": 71826,
      "population2017": 86465
    },
    {
      "city": "Villingen-Schwenningen",
      "population1970": 37999,
      "population2017": 84557
    },
    {
      "city": "Konstanz",
      "population1970": 61614,
      "population2017": 84440
    },
    {
      "city": "Marl",
      "population1970": 76999,
      "population2017": 83695
    },
    {
      "city": "Worms",
      "population1970": 77064,
      "population2017": 83081
    },
    {
      "city": "Dessau-Roßlau",
      "population1970": 98402,
      "population2017": 82111
    },
    {
      "city": "Velbert",
      "population1970": 55610,
      "population2017": 82061
    },
    {
      "city": "Minden",
      "population1970": 48860,
      "population2017": 81698
    },
    {
      "city": "Neumünster",
      "population1970": 86133,
      "population2017": 79335
    },
    {
      "city": "Norderstedt",
      "population1970": 55770,
      "population2017": 78679
    },
    {
      "city": "Delmenhorst",
      "population1970": 63870,
      "population2017": 77521
    },
    {
      "city": "Bamberg",
      "population1970": 70384,
      "population2017": 77179
    },
    {
      "city": "Viersen",
      "population1970": 85698,
      "population2017": 76586
    },
    {
      "city": "Wilhelmshaven",
      "population1970": 102978,
      "population2017": 76316
    },
    {
      "city": "Marburg",
      "population1970": 47230,
      "population2017": 76226
    },
    {
      "city": "Rheine",
      "population1970": 50076,
      "population2017": 76018
    },
    {
      "city": "Gladbeck",
      "population1970": 83132,
      "population2017": 75689
    },
    {
      "city": "Dorsten",
      "population1970": 39551,
      "population2017": 75252
    },
    {
      "city": "Lüneburg",
      "population1970": 59763,
      "population2017": 75192
    },
    {
      "city": "Troisdorf",
      "population1970": 51941,
      "population2017": 74870
    },
    {
      "city": "Detmold",
      "population1970": 63684,
      "population2017": 74353
    },
    {
      "city": "Bayreuth",
      "population1970": 64185,
      "population2017": 73999
    },
    {
      "city": "Castrop-Rauxel",
      "population1970": 83901,
      "population2017": 73989
    },
    {
      "city": "Arnsberg",
      "population1970": 22398,
      "population2017": 73814
    },
    {
      "city": "Lüdenscheid",
      "population1970": 79086,
      "population2017": 72894
    },
    {
      "city": "Brandenburg an der Havel",
      "population1970": 93983,
      "population2017": 71886
    },
    {
      "city": "Landshut",
      "population1970": 52425,
      "population2017": 71193
    },
    {
      "city": "Bocholt",
      "population1970": 49244,
      "population2017": 71036
    },
    {
      "city": "Aschaffenburg",
      "population1970": 55119,
      "population2017": 69928
    },
    {
      "city": "Celle",
      "population1970": 57130,
      "population2017": 69706
    },
    {
      "city": "Kempten (Allgäu)",
      "population1970": 44884,
      "population2017": 68330
    },
    {
      "city": "Fulda",
      "population1970": 45187,
      "population2017": 67973
    },
    {
      "city": "Lippstadt",
      "population1970": 41743,
      "population2017": 67936
    },
    {
      "city": "Aalen",
      "population1970": 37066,
      "population2017": 67849
    },
    {
      "city": "Dinslaken",
      "population1970": 55241,
      "population2017": 67489
    },
    {
      "city": "Herford",
      "population1970": 65422,
      "population2017": 66923
    },
    {
      "city": "Kerpen",
      "population1970": 10608,
      "population2017": 65420
    },
    {
      "city": "Plauen",
      "population1970": 82026,
      "population2017": 65148
    },
    {
      "city": "Rüsselsheim am Main",
      "population1970": 60911,
      "population2017": 64922
    },
    {
      "city": "Neuwied",
      "population1970": 62666,
      "population2017": 64661
    },
    {
      "city": "Sindelfingen",
      "population1970": 41967,
      "population2017": 64599
    },
    {
      "city": "Weimar",
      "population1970": 63634,
      "population2017": 64426
    },
    {
      "city": "Neubrandenburg",
      "population1970": 46087,
      "population2017": 64259
    },
    {
      "city": "Dormagen",
      "population1970": 30869,
      "population2017": 64177
    },
    {
      "city": "Grevenbroich",
      "population1970": 27698,
      "population2017": 63204
    },
    {
      "city": "Rosenheim",
      "population1970": 37078,
      "population2017": 63080
    },
    {
      "city": "Herten",
      "population1970": 52966,
      "population2017": 61669
    },
    {
      "city": "Bergheim",
      "population1970": 10493,
      "population2017": 61099
    },
    {
      "city": "Schwäbisch Gmünd",
      "population1970": 44385,
      "population2017": 60914
    },
    {
      "city": "Garbsen",
      "population1970": 26444,
      "population2017": 60875
    },
    {
      "city": "Wesel",
      "population1970": 45338,
      "population2017": 60496
    },
    {
      "city": "Friedrichshafen",
      "population1970": 43651,
      "population2017": 60088
    },
    {
      "city": "Hürth",
      "population1970": 52235,
      "population2017": 59762
    },
    {
      "city": "Stralsund",
      "population1970": 71489,
      "population2017": 59517
    },
    {
      "city": "Offenburg",
      "population1970": 33066,
      "population2017": 59060
    },
    {
      "city": "Greifswald",
      "population1970": 47017,
      "population2017": 58886
    },
    {
      "city": "Langenfeld (Rheinland)",
      "population1970": 44217,
      "population2017": 58698
    },
    {
      "city": "Frankfurt (Oder)",
      "population1970": 62433,
      "population2017": 58237
    },
    {
      "city": "Neu-Ulm",
      "population1970": 27925,
      "population2017": 57727
    },
    {
      "city": "Euskirchen",
      "population1970": 42278,
      "population2017": 57715
    },
    {
      "city": "Hameln",
      "population1970": 47201,
      "population2017": 57228
    },
    {
      "city": "Unna",
      "population1970": 50299,
      "population2017": 57158
    },
    {
      "city": "Göppingen",
      "population1970": 48028,
      "population2017": 57094
    },
    {
      "city": "Stolberg (Rheinland)",
      "population1970": 39704,
      "population2017": 56751
    },
    {
      "city": "Görlitz",
      "population1970": 87492,
      "population2017": 56391
    },
    {
      "city": "Eschweiler",
      "population1970": 38666,
      "population2017": 56207
    },
    {
      "city": "Sankt Augustin",
      "population1970": 31892,
      "population2017": 55873
    },
    {
      "city": "Hilden",
      "population1970": 50228,
      "population2017": 55817
    },
    {
      "city": "Meerbusch",
      "population1970": 46904,
      "population2017": 55548
    },
    {
      "city": "Waiblingen",
      "population1970": 24195,
      "population2017": 55123
    },
    {
      "city": "Baden-Baden",
      "population1970": 37185,
      "population2017": 54718
    },
    {
      "city": "Hattingen",
      "population1970": 59392,
      "population2017": 54628
    },
    {
      "city": "Lingen (Ems)",
      "population1970": 31522,
      "population2017": 54117
    },
    {
      "city": "Pulheim",
      "population1970": 11323,
      "population2017": 53900
    },
    {
      "city": "Bad Homburg vor der Höhe",
      "population1970": 42066,
      "population2017": 53884
    },
    {
      "city": "Bad Salzuflen",
      "population1970": 49571,
      "population2017": 53856
    },
    {
      "city": "Langenhagen",
      "population1970": 35474,
      "population2017": 53790
    },
    {
      "city": "Schweinfurt",
      "population1970": 58178,
      "population2017": 53437
    },
    {
      "city": "Neustadt an der Weinstraße",
      "population1970": 50952,
      "population2017": 53353
    },
    {
      "city": "Nordhorn",
      "population1970": 44470,
      "population2017": 53278
    },
    {
      "city": "Menden (Sauerland)",
      "population1970": 30109,
      "population2017": 53046
    },
    {
      "city": "Wetzlar",
      "population1970": 36922,
      "population2017": 52828
    },
    {
      "city": "Ahlen",
      "population1970": 49970,
      "population2017": 52530
    },
    {
      "city": "Wolfenbüttel",
      "population1970": 40527,
      "population2017": 52357
    },
    {
      "city": "Frechen",
      "population1970": 31337,
      "population2017": 52212
    },
    {
      "city": "Ibbenbüren",
      "population1970": 17561,
      "population2017": 52037
    },
    {
      "city": "Passau",
      "population1970": 30758,
      "population2017": 51781
    },
    {
      "city": "Kleve",
      "population1970": 43640,
      "population2017": 51320
    },
    {
      "city": "Willich",
      "population1970": 37747,
      "population2017": 51179
    },
    {
      "city": "Goslar",
      "population1970": 40051,
      "population2017": 51128
    },
    {
      "city": "Speyer",
      "population1970": 42184,
      "population2017": 50931
    },
    {
      "city": "Emden",
      "population1970": 48595,
      "population2017": 50607
    },
    {
      "city": "Gummersbach",
      "population1970": 44880,
      "population2017": 50497
    },
    {
      "city": "Bad Kreuznach",
      "population1970": 42258,
      "population2017": 50484
    },
    {
      "city": "Ravensburg",
      "population1970": 32045,
      "population2017": 50393
    },
    {
      "city": "Böblingen",
      "population1970": 36127,
      "population2017": 50035
    },
    {
      "city": "Peine",
      "population1970": 31205,
      "population2017": 49901
    },
    {
      "city": "Erftstadt",
      "population1970": 36578,
      "population2017": 49647
    },
    {
      "city": "Elmshorn",
      "population1970": 41262,
      "population2017": 49618
    },
    {
      "city": "Heidenheim an der Brenz",
      "population1970": 49853,
      "population2017": 49297
    },
    {
      "city": "Lörrach",
      "population1970": 33995,
      "population2017": 49213
    },
    {
      "city": "Rastatt",
      "population1970": 29915,
      "population2017": 49100
    },
    {
      "city": "Bergkamen",
      "population1970": 44304,
      "population2017": 48829
    },
    {
      "city": "Bad Oeynhausen",
      "population1970": 13948,
      "population2017": 48747
    },
    {
      "city": "Rheda-Wiedenbrück",
      "population1970": 36943,
      "population2017": 48685
    },
    {
      "city": "Cuxhaven",
      "population1970": 47185,
      "population2017": 48470
    },
    {
      "city": "Frankenthal (Pfalz)",
      "population1970": 41493,
      "population2017": 48417
    },
    {
      "city": "Freising",
      "population1970": 29569,
      "population2017": 48318
    },
    {
      "city": "Leonberg",
      "population1970": 25049,
      "population2017": 48222
    },
    {
      "city": "Bornheim",
      "population1970": 31420,
      "population2017": 48173
    },
    {
      "city": "Singen (Hohentwiel)",
      "population1970": 41427,
      "population2017": 47716
    },
    {
      "city": "Gronau (Westf.)",
      "population1970": 26482,
      "population2017": 47671
    },
    {
      "city": "Straubing",
      "population1970": 37826,
      "population2017": 47586
    },
    {
      "city": "Soest",
      "population1970": 37671,
      "population2017": 47376
    },
    {
      "city": "Stade",
      "population1970": 32769,
      "population2017": 47330
    },
    {
      "city": "Hennef (Sieg)",
      "population1970": 26053,
      "population2017": 47293
    },
    {
      "city": "Dachau",
      "population1970": 32929,
      "population2017": 47255
    },
    {
      "city": "Alsdorf",
      "population1970": 30778,
      "population2017": 46891
    },
    {
      "city": "Neunkirchen",
      "population1970": 43411,
      "population2017": 46767
    },
    {
      "city": "Schwerte",
      "population1970": 24402,
      "population2017": 46641
    },
    {
      "city": "Dülmen",
      "population1970": 21168,
      "population2017": 46507
    },
    {
      "city": "Melle",
      "population1970": 16154,
      "population2017": 46451
    },
    {
      "city": "Landau in der Pfalz",
      "population1970": 31437,
      "population2017": 46292
    },
    {
      "city": "Lutherstadt Wittenberg",
      "population1970": 47323,
      "population2017": 46272
    },
    {
      "city": "Lahr/Schwarzwald",
      "population1970": 25030,
      "population2017": 46156
    },
    {
      "city": "Oberursel (Taunus)",
      "population1970": 25301,
      "population2017": 46069
    },
    {
      "city": "Hof",
      "population1970": 54058,
      "population2017": 45950
    },
    {
      "city": "Filderstadt",
      "population1970": 11311,
      "population2017": 45807
    },
    {
      "city": "Fellbach",
      "population1970": 29000,
      "population2017": 45783
    },
    {
      "city": "Bünde",
      "population1970": 40403,
      "population2017": 45712
    },
    {
      "city": "Gotha",
      "population1970": 57010,
      "population2017": 45589
    },
    {
      "city": "Weinheim",
      "population1970": 29640,
      "population2017": 45114
    },
    {
      "city": "Albstadt",
      "population1970": 22446,
      "population2017": 45023
    },
    {
      "city": "Rodgau",
      "population1970": 10035,
      "population2017": 44725
    },
    {
      "city": "Bruchsal",
      "population1970": 27151,
      "population2017": 44532
    },
    {
      "city": "Erkrath",
      "population1970": 20141,
      "population2017": 44409
    },
    {
      "city": "Brühl",
      "population1970": 40975,
      "population2017": 44144
    },
    {
      "city": "Oranienburg",
      "population1970": 20381,
      "population2017": 43982
    },
    {
      "city": "Neustadt am Rübenberge",
      "population1970": 13463,
      "population2017": 43964
    },
    {
      "city": "Lehrte",
      "population1970": 21940,
      "population2017": 43932
    },
    {
      "city": "Rottenburg am Neckar",
      "population1970": 13083,
      "population2017": 43615
    },
    {
      "city": "Falkensee",
      "population1970": 26007,
      "population2017": 43552
    },
    {
      "city": "Kaufbeuren",
      "population1970": 38918,
      "population2017": 43478
    },
    {
      "city": "Memmingen",
      "population1970": 32955,
      "population2017": 43470
    },
    {
      "city": "Erkelenz",
      "population1970": 12435,
      "population2017": 43392
    },
    {
      "city": "Kamen",
      "population1970": 42058,
      "population2017": 43275
    },
    {
      "city": "Bietigheim-Bissingen",
      "population1970": 22492,
      "population2017": 43266
    },
    {
      "city": "Kaarst",
      "population1970": 15929,
      "population2017": 43216
    },
    {
      "city": "Pinneberg",
      "population1970": 34987,
      "population2017": 43155
    },
    {
      "city": "Wismar",
      "population1970": 56287,
      "population2017": 42906
    },
    {
      "city": "Eisenach",
      "population1970": 50918,
      "population2017": 42710
    },
    {
      "city": "Weiden in der Oberpfalz",
      "population1970": 42244,
      "population2017": 42543
    },
    {
      "city": "Borken",
      "population1970": 29402,
      "population2017": 42509
    },
    {
      "city": "Nettetal",
      "population1970": 36838,
      "population2017": 42265
    },
    {
      "city": "Amberg",
      "population1970": 41345,
      "population2017": 42248
    },
    {
      "city": "Gifhorn",
      "population1970": 23405,
      "population2017": 42239
    },
    {
      "city": "Nordhausen",
      "population1970": 44547,
      "population2017": 42014
    },
    {
      "city": "Homburg",
      "population1970": 32497,
      "population2017": 41934
    },
    {
      "city": "Aurich",
      "population1970": 11880,
      "population2017": 41854
    },
    {
      "city": "Heinsberg",
      "population1970": 10854,
      "population2017": 41673
    },
    {
      "city": "Ansbach",
      "population1970": 33219,
      "population2017": 41652
    },
    {
      "city": "Dreieich",
      "population1970": 22789,
      "population2017": 41548
    },
    {
      "city": "Wunstorf",
      "population1970": 17211,
      "population2017": 41532
    },
    {
      "city": "Freiberg",
      "population1970": 50362,
      "population2017": 41496
    },
    {
      "city": "Laatzen",
      "population1970": 13666,
      "population2017": 41449
    },
    {
      "city": "Siegburg",
      "population1970": 33667,
      "population2017": 41326
    },
    {
      "city": "Coburg",
      "population1970": 42671,
      "population2017": 41236
    },
    {
      "city": "Königswinter",
      "population1970": 31357,
      "population2017": 41050
    },
    {
      "city": "Nürtingen",
      "population1970": 21432,
      "population2017": 41017
    },
    {
      "city": "Weißenfels",
      "population1970": 46223,
      "population2017": 40874
    },
    {
      "city": "Halberstadt",
      "population1970": 46724,
      "population2017": 40871
    },
    {
      "city": "Lemgo",
      "population1970": 38984,
      "population2017": 40871
    },
    {
      "city": "Schwabach",
      "population1970": 26551,
      "population2017": 40781
    },
    {
      "city": "Pirmasens",
      "population1970": 55211,
      "population2017": 40632
    },
    {
      "city": "Monheim am Rhein",
      "population1970": 38094,
      "population2017": 40598
    },
    {
      "city": "Kirchheim unter Teck",
      "population1970": 28921,
      "population2017": 40446
    },
    {
      "city": "Bensheim",
      "population1970": 27675,
      "population2017": 40326
    },
    {
      "city": "Germering",
      "population1970": 16444,
      "population2017": 40285
    },
    {
      "city": "Eberswalde",
      "population1970": 46299,
      "population2017": 40223
    },
    {
      "city": "Löhne",
      "population1970": 36860,
      "population2017": 39867
    },
    {
      "city": "Leinfelden-Echterdingen",
      "population1970": 12488,
      "population2017": 39826
    },
    {
      "city": "Stendal",
      "population1970": 36786,
      "population2017": 39822
    },
    {
      "city": "Neumarkt in der Oberpfalz",
      "population1970": 18852,
      "population2017": 39822
    },
    {
      "city": "Schwäbisch Hall",
      "population1970": 23665,
      "population2017": 39818
    },
    {
      "city": "Buxtehude",
      "population1970": 22799,
      "population2017": 39782
    },
    {
      "city": "Hofheim am Taunus",
      "population1970": 18705,
      "population2017": 39692
    },
    {
      "city": "Schorndorf",
      "population1970": 20979,
      "population2017": 39601
    },
    {
      "city": "Hückelhoven",
      "population1970": 24853,
      "population2017": 39585
    },
    {
      "city": "Bautzen",
      "population1970": 43818,
      "population2017": 39429
    },
    {
      "city": "Völklingen",
      "population1970": 39423,
      "population2017": 39376
    },
    {
      "city": "Ettlingen",
      "population1970": 21548,
      "population2017": 39315
    },
    {
      "city": "Freital",
      "population1970": 42103,
      "population2017": 39300
    },
    {
      "city": "Ostfildern",
      "population1970": 11371,
      "population2017": 39205
    },
    {
      "city": "Ahaus",
      "population1970": 15986,
      "population2017": 39185
    },
    {
      "city": "Bitterfeld-Wolfen",
      "population1970": 29232,
      "population2017": 39103
    },
    {
      "city": "Buchholz in der Nordheide",
      "population1970": 13996,
      "population2017": 39042
    },
    {
      "city": "Würselen",
      "population1970": 19903,
      "population2017": 38934
    },
    {
      "city": "Maintal",
      "population1970": 16429,
      "population2017": 38930
    },
    {
      "city": "Mettmann",
      "population1970": 30623,
      "population2017": 38789
    },
    {
      "city": "Pirna",
      "population1970": 47533,
      "population2017": 38276
    },
    {
      "city": "Bernau bei Berlin",
      "population1970": 13940,
      "population2017": 38194
    },
    {
      "city": "Niederkassel",
      "population1970": 20616,
      "population2017": 38057
    },
    {
      "city": "Haltern am See",
      "population1970": 15272,
      "population2017": 37977
    },
    {
      "city": "Langen",
      "population1970": 29891,
      "population2017": 37527
    },
    {
      "city": "Neu-Isenburg",
      "population1970": 34873,
      "population2017": 37512
    },
    {
      "city": "Greven",
      "population1970": 26653,
      "population2017": 37502
    },
    {
      "city": "Kamp-Lintfort",
      "population1970": 38429,
      "population2017": 37346
    },
    {
      "city": "Warendorf",
      "population1970": 19043,
      "population2017": 37242
    },
    {
      "city": "Fürstenfeldbruck",
      "population1970": 22728,
      "population2017": 37202
    },
    {
      "city": "Papenburg",
      "population1970": 17305,
      "population2017": 37030
    },
    {
      "city": "Backnang",
      "population1970": 27704,
      "population2017": 36893
    },
    {
      "city": "Königs Wusterhausen",
      "population1970": 10435,
      "population2017": 36706
    },
    {
      "city": "Beckum",
      "population1970": 27508,
      "population2017": 36689
    },
    {
      "city": "Erding",
      "population1970": 10838,
      "population2017": 36382
    },
    {
      "city": "Coesfeld",
      "population1970": 26412,
      "population2017": 36302
    },
    {
      "city": "Voerde (Niederrhein)",
      "population1970": 28241,
      "population2017": 36268
    },
    {
      "city": "Emsdetten",
      "population1970": 29053,
      "population2017": 36151
    },
    {
      "city": "Wesseling",
      "population1970": 25948,
      "population2017": 35955
    },
    {
      "city": "Sankt Ingbert",
      "population1970": 29295,
      "population2017": 35951
    },
    {
      "city": "Kehl",
      "population1970": 16056,
      "population2017": 35695
    },
    {
      "city": "Tuttlingen",
      "population1970": 26566,
      "population2017": 35456
    },
    {
      "city": "Meppen",
      "population1970": 18697,
      "population2017": 35267
    },
    {
      "city": "Suhl",
      "population1970": 31661,
      "population2017": 35166
    },
    {
      "city": "Lage",
      "population1970": 31063,
      "population2017": 35166
    },
    {
      "city": "Limburg an der Lahn",
      "population1970": 15250,
      "population2017": 34989
    },
    {
      "city": "Kempen",
      "population1970": 39943,
      "population2017": 34711
    },
    {
      "city": "Wermelskirchen",
      "population1970": 26810,
      "population2017": 34705
    },
    {
      "city": "Winsen (Luhe)",
      "population1970": 12067,
      "population2017": 34635
    },
    {
      "city": "Cloppenburg",
      "population1970": 18452,
      "population2017": 34581
    },
    {
      "city": "Datteln",
      "population1970": 35239,
      "population2017": 34563
    },
    {
      "city": "Saarlouis",
      "population1970": 39016,
      "population2017": 34532
    },
    {
      "city": "Mörfelden-Walldorf",
      "population1970": 11923,
      "population2017": 34315
    },
    {
      "city": "Zweibrücken",
      "population1970": 32814,
      "population2017": 34270
    },
    {
      "city": "Leer",
      "population1970": 30463,
      "population2017": 34226
    },
    {
      "city": "Merseburg",
      "population1970": 55857,
      "population2017": 34197
    },
    {
      "city": "Crailsheim",
      "population1970": 16763,
      "population2017": 34130
    },
    {
      "city": "Balingen",
      "population1970": 14138,
      "population2017": 34062
    },
    {
      "city": "Dietzenbach",
      "population1970": 13396,
      "population2017": 34055
    },
    {
      "city": "Seelze",
      "population1970": 10321,
      "population2017": 34036
    },
    {
      "city": "Hemer",
      "population1970": 24473,
      "population2017": 34016
    },
    {
      "city": "Viernheim",
      "population1970": 27753,
      "population2017": 33989
    },
    {
      "city": "Barsinghausen",
      "population1970": 21042,
      "population2017": 33989
    },
    {
      "city": "Radebeul",
      "population1970": 40635,
      "population2017": 33954
    },
    {
      "city": "Steinfurt",
      "population1970": 17357,
      "population2017": 33915
    },
    {
      "city": "Geldern",
      "population1970": 22524,
      "population2017": 33819
    },
    {
      "city": "Kornwestheim",
      "population1970": 28830,
      "population2017": 33766
    },
    {
      "city": "Bad Vilbel",
      "population1970": 18010,
      "population2017": 33745
    },
    {
      "city": "Uelzen",
      "population1970": 24598,
      "population2017": 33673
    },
    {
      "city": "Goch",
      "population1970": 27617,
      "population2017": 33618
    },
    {
      "city": "Deggendorf",
      "population1970": 19356,
      "population2017": 33373
    },
    {
      "city": "Wedel",
      "population1970": 29590,
      "population2017": 33347
    },
    {
      "city": "Ahrensburg",
      "population1970": 25501,
      "population2017": 33305
    },
    {
      "city": "Mühlhausen/Thüringen",
      "population1970": 45190,
      "population2017": 33127
    },
    {
      "city": "Hoyerswerda",
      "population1970": 59144,
      "population2017": 33116
    },
    {
      "city": "Bernburg (Saale)",
      "population1970": 45367,
      "population2017": 32876
    },
    {
      "city": "Rheinfelden (Baden)",
      "population1970": 16469,
      "population2017": 32856
    },
    {
      "city": "Wernigerode",
      "population1970": 32730,
      "population2017": 32837
    },
    {
      "city": "Biberach an der Riß",
      "population1970": 25542,
      "population2017": 32801
    },
    {
      "city": "Naumburg (Saale)",
      "population1970": 37900,
      "population2017": 32755
    },
    {
      "city": "Lampertheim",
      "population1970": 24362,
      "population2017": 32609
    },
    {
      "city": "Jülich",
      "population1970": 19754,
      "population2017": 32505
    },
    {
      "city": "Altenburg",
      "population1970": 46799,
      "population2017": 32374
    },
    {
      "city": "Vechta",
      "population1970": 16216,
      "population2017": 32201
    },
    {
      "city": "Forchheim",
      "population1970": 21862,
      "population2017": 32125
    },
    {
      "city": "Bad Nauheim",
      "population1970": 14493,
      "population2017": 32102
    },
    {
      "city": "Fürstenwalde/Spree",
      "population1970": 30841,
      "population2017": 32098
    },
    {
      "city": "Itzehoe",
      "population1970": 36150,
      "population2017": 31848
    },
    {
      "city": "Georgsmarienhütte",
      "population1970": 28781,
      "population2017": 31624
    },
    {
      "city": "Nienburg/Weser",
      "population1970": 22664,
      "population2017": 31602
    },
    {
      "city": "Herrenberg",
      "population1970": 12507,
      "population2017": 31499
    },
    {
      "city": "Oer-Erkenschwick",
      "population1970": 24360,
      "population2017": 31378
    },
    {
      "city": "Rheinberg",
      "population1970": 12070,
      "population2017": 31195
    },
    {
      "city": "Ganderkesee",
      "population1970": 17626,
      "population2017": 31100
    },
    {
      "city": "Radolfzell am Bodensee",
      "population1970": 15660,
      "population2017": 31064
    },
    {
      "city": "Schönebeck",
      "population1970": 46234,
      "population2017": 31038
    },
    {
      "city": "Kreuztal",
      "population1970": 28952,
      "population2017": 31017
    },
    {
      "city": "Bramsche",
      "population1970": 10635,
      "population2017": 30992
    },
    {
      "city": "Einbeck",
      "population1970": 18609,
      "population2017": 30925
    },
    {
      "city": "Gevelsberg",
      "population1970": 33667,
      "population2017": 30910
    },
    {
      "city": "Neuruppin",
      "population1970": 22369,
      "population2017": 30889
    },
    {
      "city": "Emmerich am Rhein",
      "population1970": 24402,
      "population2017": 30845
    },
    {
      "city": "Werl",
      "population1970": 24619,
      "population2017": 30782
    },
    {
      "city": "Haan",
      "population1970": 20821,
      "population2017": 30483
    },
    {
      "city": "Burgdorf",
      "population1970": 18102,
      "population2017": 30482
    },
    {
      "city": "Lohmar",
      "population1970": 17870,
      "population2017": 30451
    },
    {
      "city": "Geesthacht",
      "population1970": 23345,
      "population2017": 30407
    },
    {
      "city": "Riesa",
      "population1970": 49960,
      "population2017": 30392
    },
    {
      "city": "Osterholz-Scharmbeck",
      "population1970": 15273,
      "population2017": 30329
    },
    {
      "city": "Weil am Rhein",
      "population1970": 20657,
      "population2017": 30197
    },
    {
      "city": "Meschede",
      "population1970": 15727,
      "population2017": 30086
    },
    {
      "city": "Schwedt/Oder",
      "population1970": 34292,
      "population2017": 30075
    },
    {
      "city": "Ennepetal",
      "population1970": 37137,
      "population2017": 29929
    },
    {
      "city": "Andernach",
      "population1970": 27139,
      "population2017": 29860
    },
    {
      "city": "Merzig",
      "population1970": 12161,
      "population2017": 29818
    },
    {
      "city": "Friedberg",
      "population1970": 12961,
      "population2017": 29782
    },
    {
      "city": "Bad Hersfeld",
      "population1970": 23247,
      "population2017": 29767
    },
    {
      "city": "Taunusstein",
      "population1970": 14121,
      "population2017": 29746
    },
    {
      "city": "Werne",
      "population1970": 21123,
      "population2017": 29721
    },
    {
      "city": "Gaggenau",
      "population1970": 21124,
      "population2017": 29615
    },
    {
      "city": "Neuburg an der Donau",
      "population1970": 18808,
      "population2017": 29608
    },
    {
      "city": "Rietberg",
      "population1970": 20572,
      "population2017": 29432
    },
    {
      "city": "Güstrow",
      "population1970": 37428,
      "population2017": 29429
    },
    {
      "city": "Bretten",
      "population1970": 11543,
      "population2017": 29336
    },
    {
      "city": "Tönisvorst",
      "population1970": 19872,
      "population2017": 29286
    },
    {
      "city": "Waltrop",
      "population1970": 25743,
      "population2017": 29252
    },
    {
      "city": "Oelde",
      "population1970": 21123,
      "population2017": 29209
    },
    {
      "city": "Höxter",
      "population1970": 32830,
      "population2017": 29112
    },
    {
      "city": "Northeim",
      "population1970": 21850,
      "population2017": 29040
    },
    {
      "city": "Bühl",
      "population1970": 10013,
      "population2017": 29000
    },
    {
      "city": "Friedberg (Hessen)",
      "population1970": 16782,
      "population2017": 28962
    },
    {
      "city": "Springe",
      "population1970": 12858,
      "population2017": 28918
    },
    {
      "city": "Landsberg am Lech",
      "population1970": 14260,
      "population2017": 28865
    },
    {
      "city": "Kelkheim (Taunus)",
      "population1970": 16545,
      "population2017": 28829
    },
    {
      "city": "Rendsburg",
      "population1970": 34820,
      "population2017": 28789
    },
    {
      "city": "Schwandorf",
      "population1970": 16190,
      "population2017": 28730
    },
    {
      "city": "Rösrath",
      "population1970": 19226,
      "population2017": 28666
    },
    {
      "city": "Bad Zwischenahn",
      "population1970": 20798,
      "population2017": 28507
    },
    {
      "city": "Schwelm",
      "population1970": 34058,
      "population2017": 28478
    },
    {
      "city": "Zeitz",
      "population1970": 46864,
      "population2017": 28381
    },
    {
      "city": "Idar-Oberstein",
      "population1970": 38755,
      "population2017": 28357
    },
    {
      "city": "Winnenden",
      "population1970": 14446,
      "population2017": 28351
    },
    {
      "city": "Mühlheim am Main",
      "population1970": 20820,
      "population2017": 28318
    },
    {
      "city": "Leichlingen (Rheinland)",
      "population1970": 20254,
      "population2017": 28166
    },
    {
      "city": "Kevelaer",
      "population1970": 20555,
      "population2017": 28162
    },
    {
      "city": "Grimma",
      "population1970": 16639,
      "population2017": 28153
    },
    {
      "city": "Meißen",
      "population1970": 45609,
      "population2017": 28061
    },
    {
      "city": "Henstedt-Ulzburg",
      "population1970": 10645,
      "population2017": 28056
    },
    {
      "city": "Bad Neuenahr-Ahrweiler",
      "population1970": 23869,
      "population2017": 28048
    },
    {
      "city": "Geislingen an der Steige",
      "population1970": 27196,
      "population2017": 28021
    },
    {
      "city": "Wegberg",
      "population1970": 15611,
      "population2017": 27921
    },
    {
      "city": "Königsbrunn",
      "population1970": 12973,
      "population2017": 27850
    },
    {
      "city": "Verden (Aller)",
      "population1970": 17014,
      "population2017": 27747
    },
    {
      "city": "Aschersleben",
      "population1970": 37163,
      "population2017": 27712
    },
    {
      "city": "Baunatal",
      "population1970": 13077,
      "population2017": 27704
    },
    {
      "city": "Emmendingen",
      "population1970": 16462,
      "population2017": 27642
    },
    {
      "city": "Wetter (Ruhr)",
      "population1970": 30756,
      "population2017": 27628
    },
    {
      "city": "Reinbek",
      "population1970": 15456,
      "population2017": 27409
    },
    {
      "city": "Blankenfelde-Mahlow",
      "population1970": 7192,
      "population2017": 27378
    },
    {
      "city": "Mechernich",
      "population1970": 13233,
      "population2017": 27350
    },
    {
      "city": "Griesheim",
      "population1970": 16791,
      "population2017": 27258
    },
    {
      "city": "Rheinbach",
      "population1970": 18824,
      "population2017": 27124
    },
    {
      "city": "Wangen im Allgäu",
      "population1970": 14462,
      "population2017": 27109
    },
    {
      "city": "Geilenkirchen",
      "population1970": 10143,
      "population2017": 27106
    },
    {
      "city": "Overath",
      "population1970": 15636,
      "population2017": 27062
    },
    {
      "city": "Garmisch-Partenkirchen",
      "population1970": 27101,
      "population2017": 27024
    },
    {
      "city": "Baesweiler",
      "population1970": 13764,
      "population2017": 26996
    },
    {
      "city": "Neukirchen-Vluyn",
      "population1970": 13973,
      "population2017": 26990
    },
    {
      "city": "Sangerhausen",
      "population1970": 32303,
      "population2017": 26798
    },
    {
      "city": "Wiesloch",
      "population1970": 16145,
      "population2017": 26779
    },
    {
      "city": "Schloß Holte-Stukenbrock",
      "population1970": 16003,
      "population2017": 26772
    },
    {
      "city": "Lauf an der Pegnitz",
      "population1970": 16116,
      "population2017": 26571
    },
    {
      "city": "Strausberg",
      "population1970": 19434,
      "population2017": 26522
    },
    {
      "city": "Lohne (Oldenburg)",
      "population1970": 15942,
      "population2017": 26509
    },
    {
      "city": "Neckarsulm",
      "population1970": 18483,
      "population2017": 26428
    },
    {
      "city": "Hennigsdorf",
      "population1970": 24542,
      "population2017": 26369
    },
    {
      "city": "Nordenham",
      "population1970": 28207,
      "population2017": 26230
    },
    {
      "city": "Köthen (Anhalt)",
      "population1970": 36624,
      "population2017": 26157
    },
    {
      "city": "Heiligenhaus",
      "population1970": 28727,
      "population2017": 26132
    },
    {
      "city": "Butzbach",
      "population1970": 15732,
      "population2017": 26016
    },
    {
      "city": "Mühlacker",
      "population1970": 14194,
      "population2017": 26008
    },
    {
      "city": "Kulmbach",
      "population1970": 23786,
      "population2017": 26002
    },
    {
      "city": "Hohen Neuendorf",
      "population1970": 9566,
      "population2017": 26001
    },
    {
      "city": "Ilmenau",
      "population1970": 19220,
      "population2017": 25975
    },
    {
      "city": "St. Wendel",
      "population1970": 10086,
      "population2017": 25959
    },
    {
      "city": "Ehingen",
      "population1970": 13512,
      "population2017": 25928
    },
    {
      "city": "Staßfurt",
      "population1970": 26153,
      "population2017": 25830
    },
    {
      "city": "Selm",
      "population1970": 15552,
      "population2017": 25811
    },
    {
      "city": "Helmstedt",
      "population1970": 27326,
      "population2017": 25803
    },
    {
      "city": "Teltow",
      "population1970": 15330,
      "population2017": 25761
    },
    {
      "city": "Heppenheim (Bergstraße)",
      "population1970": 16957,
      "population2017": 25755
    },
    {
      "city": "Bad Honnef",
      "population1970": 19943,
      "population2017": 25708
    },
    {
      "city": "Werder (Havel)",
      "population1970": 9765,
      "population2017": 25695
    },
    {
      "city": "Ludwigsfelde",
      "population1970": 16738,
      "population2017": 25665
    },
    {
      "city": "Lennestadt",
      "population1970": 25408,
      "population2017": 25638
    },
    {
      "city": "Rinteln",
      "population1970": 10865,
      "population2017": 25596
    },
    {
      "city": "Zittau",
      "population1970": 43321,
      "population2017": 25575
    },
    {
      "city": "Brilon",
      "population1970": 13828,
      "population2017": 25501
    },
    {
      "city": "Lübbecke",
      "population1970": 11238,
      "population2017": 25499
    },
    {
      "city": "Bingen am Rhein",
      "population1970": 23627,
      "population2017": 25441
    },
    {
      "city": "Plettenberg",
      "population1970": 30288,
      "population2017": 25414
    },
    {
      "city": "Verl",
      "population1970": 15712,
      "population2017": 25356
    },
    {
      "city": "Zirndorf",
      "population1970": 13388,
      "population2017": 25307
    },
    {
      "city": "Lindau (Bodensee)",
      "population1970": 25232,
      "population2017": 25253
    },
    {
      "city": "Roth",
      "population1970": 11722,
      "population2017": 25220
    },
    {
      "city": "Rottweil",
      "population1970": 20532,
      "population2017": 25204
    },
    {
      "city": "Wiehl",
      "population1970": 16821,
      "population2017": 25152
    },
    {
      "city": "Schleswig",
      "population1970": 32270,
      "population2017": 25118
    },
    {
      "city": "Eisenhüttenstadt",
      "population1970": 45410,
      "population2017": 25057
    },
    {
      "city": "Norden",
      "population1970": 16976,
      "population2017": 25056
    },
    {
      "city": "Pfungstadt",
      "population1970": 17194,
      "population2017": 25020
    },
    {
      "city": "Harsewinkel",
      "population1970": 10415,
      "population2017": 25012
    },
    {
      "city": "Weingarten",
      "population1970": 18181,
      "population2017": 25011
    },
    {
      "city": "Bad Oldesloe",
      "population1970": 18444,
      "population2017": 24964
    },
    {
      "city": "Ditzingen",
      "population1970": 11534,
      "population2017": 24942
    },
    {
      "city": "Warstein",
      "population1970": 10438,
      "population2017": 24898
    },
    {
      "city": "Geretsried",
      "population1970": 16438,
      "population2017": 24892
    },
    {
      "city": "Unterhaching",
      "population1970": 14460,
      "population2017": 24864
    },
    {
      "city": "Groß-Gerau",
      "population1970": 13277,
      "population2017": 24839
    },
    {
      "city": "Ingelheim am Rhein",
      "population1970": 18939,
      "population2017": 24833
    },
    {
      "city": "Espelkamp",
      "population1970": 12860,
      "population2017": 24809
    },
    {
      "city": "Delitzsch",
      "population1970": 24435,
      "population2017": 24794
    },
    {
      "city": "Saalfeld/Saale",
      "population1970": 33643,
      "population2017": 24789
    },
    {
      "city": "Sprockhövel",
      "population1970": 21911,
      "population2017": 24783
    },
    {
      "city": "Markkleeberg",
      "population1970": 22374,
      "population2017": 24644
    },
    {
      "city": "Senftenberg",
      "population1970": 24367,
      "population2017": 24558
    },
    {
      "city": "Lüdinghausen",
      "population1970": 12824,
      "population2017": 24550
    },
    {
      "city": "Olpe",
      "population1970": 21164,
      "population2017": 24459
    },
    {
      "city": "Arnstadt",
      "population1970": 28990,
      "population2017": 24409
    },
    {
      "city": "Ellwangen (Jagst)",
      "population1970": 13049,
      "population2017": 24339
    },
    {
      "city": "Attendorn",
      "population1970": 21735,
      "population2017": 24335
    },
    {
      "city": "Rathenow",
      "population1970": 29836,
      "population2017": 24309
    },
    {
      "city": "Quedlinburg",
      "population1970": 30758,
      "population2017": 24216
    },
    {
      "city": "Waldshut-Tiengen",
      "population1970": 10389,
      "population2017": 24149
    },
    {
      "city": "Übach-Palenberg",
      "population1970": 22667,
      "population2017": 24083
    },
    {
      "city": "Ronnenberg",
      "population1970": 16652,
      "population2017": 24080
    },
    {
      "city": "Limbach-Oberfrohna",
      "population1970": 25576,
      "population2017": 24066
    },
    {
      "city": "Öhringen",
      "population1970": 11446,
      "population2017": 24010
    },
    {
      "city": "Salzwedel",
      "population1970": 19792,
      "population2017": 24002
    },
    {
      "city": "Varel",
      "population1970": 12812,
      "population2017": 23925
    },
    {
      "city": "Hann. Münden",
      "population1970": 18550,
      "population2017": 23853
    },
    {
      "city": "Sonneberg",
      "population1970": 29794,
      "population2017": 23756
    },
    {
      "city": "Döbeln",
      "population1970": 27855,
      "population2017": 23728
    },
    {
      "city": "Lutherstadt Eisleben",
      "population1970": 30482,
      "population2017": 23651
    },
    {
      "city": "Korbach",
      "population1970": 22178,
      "population2017": 23631
    },
    {
      "city": "Bedburg",
      "population1970": 13147,
      "population2017": 23531
    },
    {
      "city": "Alfter",
      "population1970": 14651,
      "population2017": 23527
    },
    {
      "city": "Bad Mergentheim",
      "population1970": 12477,
      "population2017": 23502
    },
    {
      "city": "Calw",
      "population1970": 13020,
      "population2017": 23491
    },
    {
      "city": "Dillenburg",
      "population1970": 10130,
      "population2017": 23471
    },
    {
      "city": "Waldkraiburg",
      "population1970": 16724,
      "population2017": 23435
    },
    {
      "city": "Starnberg",
      "population1970": 10544,
      "population2017": 23339
    },
    {
      "city": "Netphen",
      "population1970": 19861,
      "population2017": 23297
    },
    {
      "city": "Mosbach",
      "population1970": 13615,
      "population2017": 23288
    },
    {
      "city": "Husum",
      "population1970": 25055,
      "population2017": 23274
    },
    {
      "city": "Freudenstadt",
      "population1970": 14380,
      "population2017": 23150
    },
    {
      "city": "Herzogenaurach",
      "population1970": 12466,
      "population2017": 23098
    },
    {
      "city": "Walsrode",
      "population1970": 14318,
      "population2017": 23089
    },
    {
      "city": "Gelnhausen",
      "population1970": 10262,
      "population2017": 22873
    },
    {
      "city": "Herdecke",
      "population1970": 20815,
      "population2017": 22836
    },
    {
      "city": "Wertheim",
      "population1970": 12009,
      "population2017": 22777
    },
    {
      "city": "Glauchau",
      "population1970": 32168,
      "population2017": 22718
    },
    {
      "city": "Überlingen",
      "population1970": 12895,
      "population2017": 22636
    },
    {
      "city": "Lengerich",
      "population1970": 21638,
      "population2017": 22626
    },
    {
      "city": "Gardelegen",
      "population1970": 13266,
      "population2017": 22614
    },
    {
      "city": "Wandlitz",
      "population1970": 3284,
      "population2017": 22585
    },
    {
      "city": "Burg",
      "population1970": 29990,
      "population2017": 22583
    },
    {
      "city": "Bad Soden am Taunus",
      "population1970": 10200,
      "population2017": 22563
    },
    {
      "city": "Vreden",
      "population1970": 17150,
      "population2017": 22561
    },
    {
      "city": "Rudolstadt",
      "population1970": 31683,
      "population2017": 22560
    },
    {
      "city": "Westerstede",
      "population1970": 16651,
      "population2017": 22540
    },
    {
      "city": "Donaueschingen",
      "population1970": 11680,
      "population2017": 22485
    },
    {
      "city": "Spremberg",
      "population1970": 22871,
      "population2017": 22456
    },
    {
      "city": "Rastede",
      "population1970": 16662,
      "population2017": 22447
    },
    {
      "city": "Gersthofen",
      "population1970": 15462,
      "population2017": 22430
    },
    {
      "city": "Radevormwald",
      "population1970": 21218,
      "population2017": 22428
    },
    {
      "city": "Weilheim in Oberbayern",
      "population1970": 14676,
      "population2017": 22355
    },
    {
      "city": "Edewecht",
      "population1970": 12328,
      "population2017": 22270
    },
    {
      "city": "Bad Kissingen",
      "population1970": 12345,
      "population2017": 22245
    },
    {
      "city": "Nagold",
      "population1970": 12488,
      "population2017": 22209
    },
    {
      "city": "Apolda",
      "population1970": 29003,
      "population2017": 22163
    },
    {
      "city": "Stadthagen",
      "population1970": 16574,
      "population2017": 22158
    },
    {
      "city": "Laupheim",
      "population1970": 14355,
      "population2017": 22136
    },
    {
      "city": "Karben",
      "population1970": 11131,
      "population2017": 22049
    },
    {
      "city": "Bad Harzburg",
      "population1970": 10586,
      "population2017": 22017
    },
    {
      "city": "Eckernförde",
      "population1970": 21299,
      "population2017": 21979
    },
    {
      "city": "Metzingen",
      "population1970": 14433,
      "population2017": 21845
    },
    {
      "city": "Osterode am Harz",
      "population1970": 16660,
      "population2017": 21839
    },
    {
      "city": "Waldkirch",
      "population1970": 11120,
      "population2017": 21799
    },
    {
      "city": "Sondershausen",
      "population1970": 22949,
      "population2017": 21768
    },
    {
      "city": "Hockenheim",
      "population1970": 15670,
      "population2017": 21739
    },
    {
      "city": "Halle (Westf.)",
      "population1970": 10183,
      "population2017": 21713
    },
    {
      "city": "Zerbst/Anhalt",
      "population1970": 19638,
      "population2017": 21702
    },
    {
      "city": "Heide",
      "population1970": 22950,
      "population2017": 21699
    },
    {
      "city": "Rotenburg (Wümme)",
      "population1970": 16308,
      "population2017": 21694
    },
    {
      "city": "Xanten",
      "population1970": 15077,
      "population2017": 21614
    },
    {
      "city": "Schwetzingen",
      "population1970": 16480,
      "population2017": 21546
    },
    {
      "city": "Sonthofen",
      "population1970": 16822,
      "population2017": 21522
    },
    {
      "city": "Lindlar",
      "population1970": 12780,
      "population2017": 21513
    },
    {
      "city": "Ottobrunn",
      "population1970": 13840,
      "population2017": 21503
    },
    {
      "city": "Eschborn",
      "population1970": 11499,
      "population2017": 21419
    },
    {
      "city": "Stadtallendorf",
      "population1970": 15832,
      "population2017": 21348
    },
    {
      "city": "Kitzingen",
      "population1970": 17838,
      "population2017": 21346
    },
    {
      "city": "Flörsheim am Main",
      "population1970": 10297,
      "population2017": 21260
    },
    {
      "city": "Soltau",
      "population1970": 15835,
      "population2017": 21257
    },
    {
      "city": "Seligenstadt",
      "population1970": 12278,
      "population2017": 21250
    },
    {
      "city": "Waren (Müritz)",
      "population1970": 21306,
      "population2017": 21210
    },
    {
      "city": "Wipperfürth",
      "population1970": 13120,
      "population2017": 21202
    },
    {
      "city": "Wülfrath",
      "population1970": 23387,
      "population2017": 21196
    },
    {
      "city": "Geseke",
      "population1970": 13242,
      "population2017": 21183
    },
    {
      "city": "Meiningen",
      "population1970": 25557,
      "population2017": 21126
    },
    {
      "city": "Schramberg",
      "population1970": 18936,
      "population2017": 21125
    },
    {
      "city": "Quickborn",
      "population1970": 14780,
      "population2017": 21056
    },
    {
      "city": "Traunreut",
      "population1970": 12145,
      "population2017": 20934
    },
    {
      "city": "Westoverledingen",
      "population1970": 14911,
      "population2017": 20912
    },
    {
      "city": "Coswig",
      "population1970": 20276,
      "population2017": 20899
    },
    {
      "city": "Fröndenberg/Ruhr",
      "population1970": 17779,
      "population2017": 20843
    },
    {
      "city": "Waghäusel",
      "population1970": 16574,
      "population2017": 20816
    },
    {
      "city": "Werdau",
      "population1970": 23133,
      "population2017": 20795
    },
    {
      "city": "Eislingen/Fils",
      "population1970": 18106,
      "population2017": 20795
    },
    {
      "city": "Herborn",
      "population1970": 10287,
      "population2017": 20688
    },
    {
      "city": "Luckenwalde",
      "population1970": 29000,
      "population2017": 20674
    },
    {
      "city": "Kleinmachnow",
      "population1970": 14304,
      "population2017": 20608
    },
    {
      "city": "Gauting",
      "population1970": 15153,
      "population2017": 20556
    },
    {
      "city": "Enger",
      "population1970": 15808,
      "population2017": 20520
    },
    {
      "city": "Greiz",
      "population1970": 39247,
      "population2017": 20517
    },
    {
      "city": "Duderstadt",
      "population1970": 10943,
      "population2017": 20517
    },
    {
      "city": "Schortens",
      "population1970": 14523,
      "population2017": 20451
    },
    {
      "city": "Haßloch",
      "population1970": 17684,
      "population2017": 20433
    },
    {
      "city": "Rheinstetten",
      "population1970": 17348,
      "population2017": 20417
    },
    {
      "city": "Mössingen",
      "population1970": 9327,
      "population2017": 20416
    },
    {
      "city": "Meinerzhagen",
      "population1970": 17749,
      "population2017": 20406
    },
    {
      "city": "Stadtlohn",
      "population1970": 15470,
      "population2017": 20367
    },
    {
      "city": "Günzburg",
      "population1970": 17820,
      "population2017": 20355
    },
    {
      "city": "Uetze",
      "population1970": 5478,
      "population2017": 20260
    },
    {
      "city": "Wachtberg",
      "population1970": 11882,
      "population2017": 20251
    },
    {
      "city": "Wilnsdorf",
      "population1970": 16272,
      "population2017": 20244
    },
    {
      "city": "Nördlingen",
      "population1970": 19120,
      "population2017": 20236
    },
    {
      "city": "Traunstein",
      "population1970": 18171,
      "population2017": 20172
    },
    {
      "city": "Lichtenfels",
      "population1970": 11383,
      "population2017": 20158
    },
    {
      "city": "Dillingen/Saar",
      "population1970": 11488,
      "population2017": 20143
    },
    {
      "city": "Schwanewede",
      "population1970": 8310,
      "population2017": 20142
    },
    {
      "city": "Neustrelitz",
      "population1970": 27806,
      "population2017": 20135
    },
    {
      "city": "Holzminden",
      "population1970": 24393,
      "population2017": 20132
    },
    {
      "city": "Mühldorf am Inn",
      "population1970": 14523,
      "population2017": 20123
    },
    {
      "city": "Neufahrn bei Freising",
      "population1970": 8735,
      "population2017": 20096
    },
    {
      "city": "Hörstel",
      "population1970": 4940,
      "population2017": 20093
    },
    {
      "city": "Torgau",
      "population1970": 21688,
      "population2017": 20088
    },
    {
      "city": "Schifferstadt",
      "population1970": 17148,
      "population2017": 20073
    },
    {
      "city": "Oberkirch",
      "population1970": 8418,
      "population2017": 20042
    },
    {
      "city": "Annaberg-Buchholz",
      "population1970": 27846,
      "population2017": 20000
    }
  ]