import { Component, OnInit } from '@angular/core';
import { Switch, NotGate, OrGate, XorGate, AndGate } from '../logic-gate';

@Component({
  selector: 'app-logic-gates-page',
  templateUrl: './logic-gates-page.component.html',
  styleUrls: ['./logic-gates-page.component.css']
})
export class LogicGatesPageComponent implements OnInit {
  public switches = []
  public logicGates = [];
  constructor() { 
  let a = new Switch('A');
  let b = new Switch('B');
  let or = new OrGate(a,b,'C');
  let xor = new XorGate(a,b,'D');
  let and = new AndGate(a,b,'E');
  this.switches = [a,b]
  this.logicGates = [and, or, xor]

  }

  ngOnInit() {
  }

}
