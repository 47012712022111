import { Component, OnInit } from '@angular/core';
import { numbers } from '../../general';
class Cell{
  public content;
  public comma : Boolean;
  public nextFocus : Cell;
  public userSelected : Boolean;
  public marked : Boolean;
  public underlined : Boolean;
  public helper : Boolean;
}

@Component({
  selector: 'app-math-grid',
  templateUrl: './math-grid.component.html',
  styleUrls: ['./math-grid.component.css']
})
export class MathGridComponent {
  /*public rows = [];

  constructor(public height, public width) { 
    this.rows = numbers(height).map(x=>numbers(width).map(y=>new Cell()));
  }

  getCell(column, row){
    return this.rows[row][column];
  }

  getColumn(i){
    numbers(this.height).map(x=>this.getCell(i, x));
  }
  getRow(i){
    return this.rows[i];
  }
  ngOnInit() {
  }
*/
}
