import { Component, OnInit } from '@angular/core';
import { TaskComponent } from '../task/task.component';
import { Equation } from '../../DBEquation';

@Component({
  selector: 'app-task-fraction-addition',
  templateUrl: './task-fraction-addition.component.html',
  styleUrls: ['./task-fraction-addition.component.css']
})
export class TaskFractionAdditionComponent extends TaskComponent {
  public equation;
  ngOnChanges() {
    this.equation = new Equation(this.task.fractions.join(this.task.operator) + '=§fraction[guess]', this.task)
  }

}
