import { Component, OnInit } from '@angular/core';
import { TaskComponent } from '../task/task.component';

@Component({
  selector: 'app-task-decimal-write',
  templateUrl: './task-decimal-write.component.html',
  styleUrls: ['./task-decimal-write.component.css']
})
export class TaskDecimalWriteComponent extends TaskComponent {


}
