import { Component } from '@angular/core';

import { TaskComponent } from '../task/task.component';

@Component({
  selector: 'app-task-percent-to-fraction',
  templateUrl: './task-percent-to-fractions.component.html',
  styleUrls: ['./task-percent-to-fractions.component.css']
})
export class TaskPercentToFractionComponent extends TaskComponent {
  public name = "TaskPercentToFraction"
 }
