import { Component } from '@angular/core';
import { TaskComponent } from '../task/task.component';

@Component({
  selector: 'app-task-percent-calculations',
  templateUrl: './task-percent-calculations.component.html',
  styleUrls: ['./task-percent-calculations.component.css']
})
export class TaskPercentCalculationsComponent extends TaskComponent {
  public name = "TaskPercentCalculations"
 }
