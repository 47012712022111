import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'digits'
})
export class DigitsPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return Array.from(String(value));
  }

}
