import { Bracket, LexedArray} from './LexedArray';

export let bracketParser = (arr) => {
        let bracket = [];
        let counter = 0;
        let array = [];
         arr = arr.parts || arr;
        for (let i=0; i<arr.length;i++){
            if (counter !== 0){
                bracket.push(arr[i]); 
            }
            if (arr[i].type && arr[i].type === '()' && arr[i].open){
                counter++;
            }
            else if (arr[i].type && arr[i].type === '()' && !arr[i].open ){
                counter--;
                if (counter === 0){
                    bracket=bracket.slice(0,-1)
                    array.push(bracket);
                    bracket = [];        
                }
            }
            else if (counter === 0){
                array.push(arr[i])
            }
        }

        for (let i=0; i<array.length;i++){
            if (Array.isArray(array[i])){
                array.splice(i, 1, bracketParser(array[i]));
            }
        }
        return array;
    }