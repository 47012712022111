import { Component, OnChanges } from '@angular/core';
import { TaskComponent } from '../task/task.component';
@Component({
  selector: 'app-task-numberline',
  templateUrl: './task-numberline.component.html',
  styleUrls: ['./task-numberline.component.css']
})
export class TaskNumberlineComponent extends TaskComponent implements OnChanges {
  public name = "TaskNumberline";
  public ticks;
  public every;
  public marks;

  ngOnChanges() {
    this.task.end - this.task.start;
  }

}
