export const setVariablesValues = (map, algebratree) =>{
    console.log(algebratree)
    algebratree.walkDown((x)=> {if (x instanceof Variable) x.value = map[x.name] },{});
}

export class Variable{
    public sign = "+";
    public value;
    constructor(public name){}
    string(){
        return this.sign === "-" ? this.sign : '' + this.name;
    }

}