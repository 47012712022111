import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EquationWordproblemsService {
  public tasks = [
    {//Nils
        text: `Peter kauft sich einen Stift 2€ und einen Füller für x Euro. Er zahlt 13 Euro. Wie viel kostet der Füller?`,
        parts: [],
        solutions: ['2€ + x = 13€'], 
        fakeSolutions: ['2€ - x = 13€', '2€ • x = 13€', '2€ : x = 13€', '2€ + 13€ = x'] 
    },
    {//Erna
        text: `Elli kauft ein Puzzle für 5€ und ein Brettspiel. An der Kasse zahlt sie 12€.`,
        parts: [],
        solutions: ['5€ + x = 12€'], 
        fakeSolutions: ['5€ - x = 12€', '5€ + x = 17€', '5€ • x = 12€'] 
    },
    {//Theo D.
        text: `Günther will sich Lego-Packs kaufen. Er hat 100 Euro, ein Pack kostet 20 Euro. `,
solutions: [`20•x = 100`]    
    },  
    {//Eileen
        text: `Ina geht zum Bäcker und sieht, dass ein Brötchen 0,85€ kostet. Dann hat sie noch Lust auf etwas Süßes und holt sich ein Schokocroissant für 1,50€. Insgesamt bezahlt sie 4,05€.`,
        parts: [],
        solutions: ['1,50€ + x • 0,85€ = 4,05€', 'x • 0,85€ + 1,50€ = 4,05€'], 
        fakeSolutions: ['(1,50€ + x) • 0,85€ = 4,05€', '1,50€ + 0,85€ + x = 4,05€', '2,35€ + x = 4,05€'] 
    },
    {//Katherina
        text: `Hans kauft x Hefte für je 1,25€ und einen Stift für 2,30€. Er bezahlt 8,55€. Wie viele Hefte hat er gekauft.`,
solutions: [`x•1,25€ + 2,30€ = 8,55€`]
    },
    {//Samuel
        text: `Anne kauft Büroklammern für x Euro und noch einen Radiergummi für 3 Euro. Sie bezahlt insgesamt 5€.`,
        parts: [],
        solutions: ['x + 3€ = 5€', '5€ = 3€ + x'], 
        fakeSolutions: ['x + 5€ = 3€', '3€ • x = 5€', '5€ • x = 3€'] 
    },
    {//Mia
        text: `Anton möchte ein Geschenk kaufen, hat aber seinen Geldbeutel vergessen. Er findet aber noch {0 10€ in seiner Hosentasche}. {1 Wie viel Geld muss er noch holen}, wenn das Geschenk {2 23€} kostet?`,
        parts: ['10€', 'x', '23€'],
        solutions: ['x + 10€ = 23€', '10€ + x = 23€', '23€ - x = 10€', '10€ = -x + 23€'], 
        fakeSolutions: ['x - 10€ = 23€', 'x + 23€ = 10€', '10€ = x + 23€', ' 10€ = 23€ + 10€'] 
    },
{//Karlotta
    text: `Petra geht in einen Zoo und sieht zehn Elefanten, zwei Löwen, fünfzehn Giraffen und x Nashörner. Leider kann sie sich nicht mehr daran erinnern wie viele Nashörner sie gesehen hat. Sie weiß nur noch, dass in dem Zoo 30 Tiere waren. Wie viele Nashörner hat sie gesehen?`,
solutions: [`10 + 2 + 15 + x = 30`]
},
    {//Julius
        text: `Fritz geht einkaufen. Er hat 10 Euro. Als erstes kauft er ein Brot für 2 Euro und Eier für 3,50€ Dann holt er sich noch eine Cola für 2,50€. Zum Schluss möchte er noch ein Schokohörnchen beim Bäcker holen. Das Schokohörnchen kostet x. Zum Schluß bezahlt er 9,50€. Wie viel kostet das Schokohörnchen?`,
        parts: [],
        solutions: ['2€ + 3,50€ + 2,50€ + x = 9,50€', '8€ + x = 9,50€', '8€ = 9,50€ - x'], 
        fakeSolutions: ['10€ + 2€ + 3,50€ + 2,50€ + x  = 9,50€', '10€ - 2€ + 3,50€ + 2,50€ + x  = 9,50€', '10€ - (2€ + 3,50€ + 2,50€ + x)  = 9,50€'] 
    },
{//Timo
    text: `Fritz kauft ein Heft für 2,90€, einen Radiergummi für x, einen Stift für 1,20€ und eine Tafel Schokolade für 1,55€. Er bezahlt sieben Euro, wie viel kostet der Radiergummi.`,
solutions: [`2,90€ + 1,20€ + 1,55€ + x = 7 €`]
}    ,
    {//Lilly
        text: `Hannes füttert sein Kaninchen Leo mit {0 5 Löwenzahnblättern}. Sein Kaninchen Murmel bekommt {1 10 Blätter mehr als Leo}. Kaninchen {2 Socke bekommt x Blätter}.  {3 Zusammen bekommen sie 30 Löwenzahnblätter}. Wie viele Blätter bekommt Socke?`,
        parts: ['5', '5+10', 'x', '30'],
        solutions: ['5 + 5 + 10 + x = 30', '5 + (5 + 10) + x = 30', '20 + x = 30'], 
        fakeSolutions: ['5 + 10 + x = 30', '15 + x = 30', '30 = 5 + 10 + x', '5•10•x = 30'] 
    },
    {//Pascal
        text: `Bob möchte einen {0 Fußball für 25 €} kaufen. {1 Für eine Stunde Rasenmähen bekommt er 5 €}. {2 Zehn Euro hat er noch in seinem Sparschwein.}{3 Wie viele Stunden} muss Bob Rasen mähen, um sich den Fußball leisten zu können?`,
        parts: ['25€', '10', '5€', 'x'],
        solutions: ['25€ = 5€•x +10€ ', ' 5€•x + 10€ = 25€', '25€ - 10€ =5€•x'],
        fakeSolutions: ['25€ - x  + 10€= 5€', '25€ + 10€= 5€ • x', '25€ = 5€ + x - 10€', '25€ = 10€ • (5€ : x)', '30€ = 10€ +5 • x'] 
    },

    {//Ida
        text: `Ich gehe einkaufen für meine Mutter und kaufe Äpfel für 70 Cents das Stück, dazu braucht sie ein Päckchen Hefe für 2€, da meine Mutter einen Apfelkuchen backen möchte. Ich bezahle an der Kasse 5,50€. Wie viele Äpfel habe ich gekauft?`,
        parts: [],
        solutions: ['x • 0,70€ + 2€ = 5,50€'], 
        fakeSolutions: ['x + 0,70€ + 2€ = 5,50€', 'x • 70€ + 2€ = 5,50€', 'x + 70€ + 2€ = 5,50€', 'x + 2,70€ = 5,50€'] 
    },    
    {//Emma
        text: `Tim kauft Schokolade, pro Stück kostet sie 2,50€. Er kauft noch eine Packung Oreos, insgesamt bezahlt er 18,50€. Die Oreos kosten 6 €.`,
        parts: [],
        solutions: ['x • 2,50€ + 6€ = 18,50€', '6€ + x • 2,50€ = 18,50€'], 
        fakeSolutions: ['x • 2,50€ + 18,50€ = 6€', 'x + 2,50€ + 6€ = 18,50€', 'x + 2,50€ + 18,50€ = 6€'] 
    },
    {//Ajla
        text: `Anna kauft {0 2 Stifte für je 1,30€} und noch mehr davon. Sie zahlt {1 3,90€}. {2 Wie viele Stifte hat sie noch dazu gekauft?}`,
        parts: ['2•1,30€', '3,90€', 'x'],
        solutions: ['2 • 1,30€ + 1,30€ • x = 3,90€', '2,60€ + 1,30€ • x = 3,90'], 
        fakeSolutions: ['2,60€ - 1,30€ • x = 3,90€', '2,60€ : 1,30€ • x = 3,90€', '2,60€ + 1,30€ + x = 3,90€', '2,60€ + x = 3,90€'] 
    },    
{//Frederick
    text: `Thorben isst 500g Fleisch am Tag, außer sonntags, an diesem Tag isst er mehr Fleisch. In der Woche isst er 4kg Fleisch. Wie viel isst Torben am Sonntag?`,
solutions: [`500g • 6 + x = 4kg`]
},
{//Manuel
    text: `Drei Gruppen mit 76 Schülern besuchen eine Burg. In der ersten Gruppe ist ein Viertel und in der zweiten Gruppe ein Drittel der in der dritten Gruppe befindlichen Schüler! Wie viele Schüler sind jeweils in den Gruppen ?`,
solutions: [`(x:4) + (x:3) + x = 76`]
},
{ //Lina
    text: `Drei Bäcker habe insgesamt 360 Brötchen gebacken. Bäcker A hat doppelt so viele gebacken wie Bäcker B, Bäcker C hat 40 Brötchen weniger gebacken als Bäcker A. Wie viele Brötchen hat jeder der drei Bäcker gebacken?`,
solutions: [`x + (x : 2) + (x - 40) = 360`]    
},
    {//Lisa
        text: `Franz kauft {0 8 Stifte}. {0 Ein Stift kostet x}. Für seine Mutter kauft er einen Blumenstrauß, er kostet {1 2€ weniger als die Stifte zusammen}. An der Kasse bekommt er einen {2 3€-Gutschein}. Er bezahlt {3 38€}. Wie viel kosten die Stifte?`,
        parts: ['8x', '8x - 2', '-3€', '38€'],
        solutions: ['8x + (8x-2) - 3€ = 38€', '-3€ + 8x + (8x - 2) = 38€', '8x + (8x - 2) = 41€'], 
        fakeSolutions: ['8x + (x-2) - 3€ = 38€', '8x - 2 - 3€ = 38€', '38€ - 3€ = 8x + 2'] 
    },
    { //Tobi
        text: `Hans besitzt bereits einige Sticker. Im Laufe des Monats bekommt er 36 neue. Nun hat er 5 mal so viele wie davor. Wie viele Sticker hatte er vorher?`,
solutions: [`36 + x = 5 • x `]    
    },
    {//Fadime
        text: `Peters Großvater ist heute sechs mal so alt wie sein Enkel. In acht Jahren ist er nur noch vier mal so alt wie Peter. Wie alt sind Peter und sein Großvater?`,
        parts: [],
        solutions: ['(x + 8) : 4 = (x : 6) + 8', ], 
        fakeSolutions: ['(x • 6) + 8 = x + 8',  'x + 8 : 4 = x : 6 + 8', '(x + 8) : 6 = (x : 4) + 8', '(x • 6) + 8 = (x : 4) + 8'] 
    },
]
  constructor() { }
}
