import { Directive, ElementRef, AfterContentInit } from '@angular/core';

@Directive({
  selector: '[appDragDisplayItem]'
})
export class DragDisplayItemDirective implements AfterContentInit{

  constructor(public el: ElementRef) { }

  ngAfterContentInit(){
    this.el.nativeElement.style.position='absolute';
    this.el.nativeElement.style.bottom='0px';
  }

}
