import { simpleConvert, orderedUnits, cfactor, units } from './Measureunits';
import { equal } from './general';

export class Quantity{
    constructor(public value, public unit, public unitsTable){

    }
    convert(unit){
        let u = this.unit === unit ? this :simpleConvert(this.value, this.unit, unit, this.unitsTable)
        return new Quantity(u.value, u.unit, this.unitsTable);
    }
    factor(unit){
        return cfactor(this.unit, unit, this.unitsTable)
    }
    isGreaterThan(q){
        return this.value > q.convert(this.unit).value;
    }
    isEqual(q){
        return equal(this.value, q.convert(this.unit).value, 0.000001);
    }
    unitIsGreaterThan(unit){
        let ou = orderedUnits(this.unitsTable);
        return ou.indexOf(this.unit) > ou.indexOf(unit);
    }
    nextGreaterUnit(){
        let ou = orderedUnits(this.unitsTable);
        let i = ou.indexOf(this.unit);
        return ou[i+1];
    }
    nextSmallerUnit(){
        let ou = orderedUnits(this.unitsTable);
        let i = ou.indexOf(this.unit);
        return ou[i-1];
    }
}