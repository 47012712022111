import { numbers } from './general';

export class Matrix {
    static zero(n){
      return new Matrix(n, ...(numbers(n*n).map(x=>0)));
    }
    static one(n){
      let m = Matrix.zero(n);
      numbers(n).forEach(i=>m.setComponent(i,i,1));
      return m;
    }
    static translate(x,y,z){
      let m = Matrix.one(4);
      m.setComponent(3,0, x);
      m.setComponent(3,1, y);
      m.setComponent(3,2, z);
      return m;
    }
    static fromCSS3dString(str){
      if (!str.length) return Matrix.one(4);
      return new Matrix(4, ...str.replace(/matrix3d\(/, '').slice(0,length-1).split(',').map(Number));
    }
    public components;
    constructor(public rowLength, ...rest){
      this.components = rest;
    }
    get columnLength(){
      return this.components.length/this.rowLength;
    }
    getComponent(l,c){
      return this.components[c + l * this.rowLength];
    }
    setComponent(l,c,v){
      this.components[c + l * this.rowLength] = v;
    }
    getColumn(n){
      return numbers(this.columnLength).map((x,i)=>this.getComponent(i,n));
    }
    getRow(n){
      return numbers(this.rowLength).map((x,i)=>this.getComponent(n, i));
    }
    multiply(m){
      let h = Matrix.zero(this.rowLength);
      numbers(this.rowLength).forEach(i=>{
        numbers(this.columnLength).forEach(j=>{
          let ck = m.getColumn(j);
          h.setComponent(i,j, 
            this.getRow(i).map((c,k)=>{
              return c * ck[k]
            }).reduce((a,b)=>a+b)
            )
        })
      })
      return h;
    }
    toCSS3dString(){
      return `matrix3d(${this.components.join(',')})`
    }
  }