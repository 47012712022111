import { Task } from "../DBTask";
import { randomItem } from '../general';
import * as _ from 'lodash';
import { EquationWordproblemsService } from '../Services/equation-wordproblems.service' 

const tasks = new EquationWordproblemsService().tasks;
let pointer = -1;
export class TaskEquationsWordproblem extends Task{
    static difficulties = {
        1 : ()=>{
            pointer = (pointer + 1) % tasks.length;
            return new TaskEquationsWordproblem(1, tasks[pointer]);
        }
    }

    public result;
    public guess;
    public html;
    public forTest = {
        student: undefined,
        correct: undefined,
        extra: undefined
    }
    public possibleSolutions;
    public description = "Bestimme <em>alle</em> passenden Gleichungen"
    constructor(public difficulty, public task){
        super();
        this.html = task.text.replace(/\{(\d+) ([^\}]+)\}/g, function(m, p1, p2){
            return `<span class="id${p1}">${p2}</span>`
        })
        this.possibleSolutions = _.shuffle(task.solutions.concat(task.fakeSolutions));
        this.guess = this.possibleSolutions.map(x=>false);
    }
    check(){
        console.log(this.possibleSolutions, this.guess);
        let f = this.possibleSolutions.filter((x,i)=>this.guess[i]);
        if ( f.every((x,i) =>this.task.solutions.includes(x)) && f.length === this.task.solutions.length  ){
            console.log("genau")
            this.emit('correct');  
        }

        else {
          console.log(this.result, this.guess, this)
          console.log("leider nicht")
          this.emit('wrong');  
        }
        this.forTest.student = f.join('; ');
        this.forTest.correct = this.task.solutions.join('; ');
        this.forTest.extra = `${f.filter((x,i) =>this.task.solutions.includes(x)).length}/${this.task.solutions.length}`;

    }


}