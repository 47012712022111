import { Operator } from './LexedArray';
import {Multiplication} from './Multiplication';
import { Division } from './Division';
import { Subtraction } from './Subtraction';
import { Addition } from './Addition';
import { Comparison } from './Comparison';
import { Power } from './Power';
const precedence= [
    Power,
    Multiplication,
    Division,
    Subtraction,
    Addition,
    Comparison
]

export let algebraTreeMaker = (arr, bracket=false, sign="+")=>{
    for (let op of precedence){
        for (let i=0; i<arr.length; i += 1){
            if (Array.isArray(arr[i])){
                arr.splice(i, 1, algebraTreeMaker(arr[i], true, arr[i].sign));
            }
            if (arr[i] instanceof Operator && op.rex.test(arr[i].type)){
                if (Array.isArray(arr[i+1])){
                    arr[i+1] = algebraTreeMaker(arr[i+1], true, arr[i+1].sign);
                }
                let o = new op([arr[i-1], arr[i+1]], Math.random())
                if (!arr[i].visible) o.display.invisible = true;

                arr.splice(i-1, 3, o);
                i -= 1;
            }
        }
    }
    if (bracket) arr[0].display.brackets = true;
    if (sign) arr[0].sign = sign;

    return arr[0];

}