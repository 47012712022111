export const units = {
    lengths: {
    mm : {
        name: 'Millimeter',
        factor: 1
    },
    cm : {
        name: 'Zentimeter',
        factor: 10
    },
    dm : {
        name: 'Dezimeter',
        factor: 100
    },
    m : {
        name: 'Meter',
        factor: 1000
    },
    km : {
        name: 'Kilometer',
        factor: 1000000
    },
    },
    weights: {
        mg : {
            name: 'Milligramm',
            factor: 1
        },
        g : {
            name: 'Gramm',
            factor: 1000
        },
        kg : {
            name: 'Kilogramm',
            factor: 1000000
        },
        t : {
            name: 'Tonne',
            factor: 1000000000
        }
    },
    area:{
        mm2 : {
            name: 'Quadratmillimeter',
            factor: 1
        },
        cm2 : {
            name: 'Quadratzentimeter',
            factor: 100
        },
        dm2 : {
            name: 'Quadratdezimeter',
            factor: 10000
        },
        m2 : {
            name: 'Quadratmeter',
            factor: 1000000
        },        
        a : {
            name: 'Ar',
            factor: 100000000
        },            
        ha : {
            name: 'Hektar',
            factor: 10000000000
        },                
        km2 : {
            name: 'Quadratkilometer',
            factor: 1000000000000
        },                
    }
}
export let factorSort = (units) =>(a,b)=>units[a].factor - units[b].factor;
export let orderedUnits = (units) => {
     return Object.keys(units)
    .sort(factorSort(units))
}
export let cfactor = (from, to, units)=>{
    console.log(from, to)
    console.log(from,to,units,units[from].factor/units[to].factor)
    return (units[from].factor/units[to].factor)
}
export let simpleConvert = (value, from, to, units) => {
    console.log(to)
    return {value: +value * cfactor(from,to, units), unit: to};
}
export function fromString (str, units){
    console.log(str, units)
    return str.split(' ').filter(x=>x && x.length)
       .map(x=> x.match(new RegExp('((\\d|,|\\.)+)' + '(' + orderedUnits(units).join('|') + ')')))
       .map(x=> {return {unit: x[3], value: Number(x[1].replace(/,/, '.'))}})
}
export let toString = (o)=>{
    return o.map(x=>x.value + x.unit).join(' ');
}
export let smallestUnit = (arr, units) =>{
    
    return arr.sort(factorSort(units))[0];
    //return str.split(' ').map(x=>x.replace(/(\d|,|\.)+/g, '')).filter(x=>x.length).map(x => x.trim()).sort(factorSort(units))[0];
}

export let complexConvert = (from, to, units)=>{ //12 km 345m 6cm --> 1234506cm
    return { value: fromString(from, units).map(x=> simpleConvert(x.value, x.unit, to, units)).map(x=>x.value).reduce((a,b)=>a+b), unit: to};
}
