import { Component, OnInit } from '@angular/core';
import { TaskComponent } from '../task/task.component';
import { TaskDecimalComparisonCards } from 'src/app/Task-Models/model-task-decimals-comparison-cards';
@Component({
  selector: 'app-task-decimal-comparison-cards',
  templateUrl: './task-decimal-comparison-cards.component.html',
  styleUrls: ['./task-decimal-comparison-cards.component.css']
})
export class TaskDecimalComparisonCardsComponent extends TaskComponent { 
  ngOnChanges(){
    this.task.numbers.forEach((x,i)=> x.id = i);
    if (this.task){
      let a = this.task.serialize();
      console.log(a);
      let b = TaskDecimalComparisonCards.deserialize(a);
      console.log(b);
    }
  }
  log(e){ console.log(e); }
  
  put(sortable, basket){
    basket.content.push(sortable);
    basket.filled = true;
    console.log(basket.filled)
  }
  remove(sortable, basket){

    if(sortable.droppable[0]){
      basket.content = basket.content.filter(x=>x.id != sortable.droppable[0].data.id);
      if (!basket.content.length) basket.filled = false;
    }
  }
}
