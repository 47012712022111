import { Task } from "../DBTask";
import {  randomInt, numbers, shakeTo } from '../general';
import { DBPlaceValueRepresentation } from '../DBPlaceValueRepresentation';


export class TaskDecimalWrite extends Task{
    static difficulties = {
        1 : ()=>{
            /*
             * 1 Stelle ungleich Null vorm Komma
             * 2 Stellen ungleich Null hinterm Komma 
             */
            let beforeComma = String(randomInt(1, 10));
            let afterComma = numbers(2).map(x=>randomInt(1,10)).join('');
            let decimal = DBPlaceValueRepresentation.fromNumber(beforeComma + '.' + afterComma);
            return new TaskDecimalWrite(1, decimal);
        },
        2 : ()=>{
            /*
             * 3 Stellen ungleich Null vorm Komma
             * 3 Stellen ungleich Null hinterm Komma 
             */
            let beforeComma = String(randomInt(1, 10));
            let afterComma = numbers(3).map(x=>randomInt(1,10)).join('');
            let decimal = DBPlaceValueRepresentation.fromNumber(beforeComma + '.' + afterComma);
            return new TaskDecimalWrite(2, decimal);
        },
        3 : ()=>{
            /*
             * 3 Stellen  vorm Komma
             * 3 Stellen ungleich Null hinterm Komma 
             */
            let beforeComma = numbers(3).map(x=>randomInt(1,10)).join('');
            let afterComma = numbers(3).map(x=>randomInt(1,10)).join('');
            let decimal = DBPlaceValueRepresentation.fromNumber(beforeComma + '.' + afterComma);
            decimal = decimal.setDigitAtPosition('0', randomInt(0,3))
            return new TaskDecimalWrite(3, decimal);
        },
        4 : ()=>{
            /*
             * 3 Stellen  vorm Komma
             * 3 Stellen ungleich Null hinterm Komma 
             */
            let beforeComma = numbers(3).map(x=>randomInt(1,10)).join('');
            let afterComma = numbers(3).map(x=>randomInt(1,10)).join('');
            let decimal = DBPlaceValueRepresentation.fromNumber(beforeComma + '.' + afterComma);
            shakeTo(2,numbers(4)).forEach(x=> decimal = decimal.setDigitAtPosition('0', x));
            return new TaskDecimalWrite(4, decimal);
        },
        5 : ()=>{
            /*
             * 3 Stellen  vorm Komma
             * 3 Stellen ungleich Null hinterm Komma 
             */
            let beforeComma = numbers(3).map(x=>randomInt(1,10)).join('');
            let afterComma = numbers(3).map(x=>randomInt(1,10)).join('');
            let decimal = DBPlaceValueRepresentation.fromNumber(beforeComma + '.' + afterComma);
            shakeTo(2,numbers(4)).forEach(x=> decimal = decimal.setDigitAtPosition('0', x));
            shakeTo(2,numbers(4)).forEach(x=> decimal = decimal.setDigitAtPosition('0', -x));
            return new TaskDecimalWrite(5, decimal);
        },
        6 : ()=>{
            /*
             * 3 Stellen  vorm Komma
             * 3 Stellen ungleich Null hinterm Komma 
             */
            let beforeComma = '0';
            let afterComma = numbers(6).map(x=>randomInt(1,10)).join('');
            let decimal = DBPlaceValueRepresentation.fromNumber(beforeComma + '.' + afterComma);
            shakeTo(3,numbers(6)).forEach(x=> decimal = decimal.setDigitAtPosition('0', -x));
            return new TaskDecimalWrite(6, decimal);
        },
        7 : ()=>{
            /*
             * 3 Stellen  vorm Komma
             * 3 Stellen ungleich Null hinterm Komma 
             */
            let beforeComma = numbers(6).map(x=>randomInt(1,10)).join('');
            let afterComma = numbers(6).map(x=>randomInt(1,10)).join('');
            let decimal = DBPlaceValueRepresentation.fromNumber(beforeComma + '.' + afterComma);
            shakeTo(2,numbers(6)).forEach(x=> decimal = decimal.setDigitAtPosition('0', x));
            shakeTo(3,numbers(6)).forEach(x=> decimal = decimal.setDigitAtPosition('0', -x));
            return new TaskDecimalWrite(7, decimal);
        },
        8 : ()=>{
            /*
             * 3 Stellen  vorm Komma
             * 3 Stellen ungleich Null hinterm Komma 
             */
            let beforeComma = numbers(10).map(x=>randomInt(1,10)).join('');
            let afterComma = numbers(10).map(x=>randomInt(1,10)).join('');
            let decimal = DBPlaceValueRepresentation.fromNumber(beforeComma + '.' + afterComma);
            shakeTo(randomInt(2,5),numbers(10)).forEach(x=> decimal = decimal.setDigitAtPosition('0', x));
            shakeTo(randomInt(2,5),numbers(10)).forEach(x=> decimal = decimal.setDigitAtPosition('0', -x));
            return new TaskDecimalWrite(8, decimal);
        },
            
    }
    public result;
    public guess;
    public text;
    constructor(public difficulty, public decimal){
        super();
        this.text = Object.keys(this.decimal.namedPositionsObject).map(x=>this.decimal.namedPositionsObject[x] + '\u00A0' + x).filter(x=> !(/^0\s/).test(x)).join(', ');
        this.result = this.decimal;
    }
    check(){
        console.log(this.result, this.guess)
        if ( this.result.match(this.guess)){
            console.log("genau")
            this.emit('correct');  
        }

        else {
          console.log("leider nicht")
          this.emit('wrong');  
        }
    }
}