import { Component, Input, Output, EventEmitter, ApplicationRef, NgZone, ChangeDetectorRef } from '@angular/core';
import { TutorServiceService } from '../../Services/tutor-service.service';
import { TaskRegistryService } from 'src/app/Services/task-registry.service';
 

@Component({
  template: '',
})
export class TaskComponent{
  @Input() public task;
  @Input() public paper = false;
  @Output() solved: EventEmitter<any> = new EventEmitter();

/**
 * 
 * Und der guess müsste dann auch mehr als nur correct und nicht correct ermöglichen
 * 
 * Und das Modell sollte anstatt gelöst und nicht gelöst einen Score speichern
 * 
 * Folgende TaskComponenten und model-tasks sind noch nicht geändert:
 * Proportional-Is-It
 * Proportional-Completion
 * Rule-Of-Three
 */

 public name;
 public options;
  constructor() {
    if (this.task) this.init();

  }
  
  init(){
    this.options = this.task.constructor.options;
    this.task.on('solved', this.solved.emit());
    if (this['ngOnChanges']){
      this['ngOnChanges'].bind(this)();
    }
  }
}
