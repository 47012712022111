import { Component, OnChanges, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-fraction',
  templateUrl: './fraction.component.html',
  styleUrls: ['./fraction.component.css']
})
export class FractionComponent implements OnChanges {
  @Input() public size = '3vw';
  @Input() public fraction;
  @Input() public editable;
  @Input() public denominatorEditable = true;
  @Input() public enumeratorEditable = true;
  @Input() public noSign = true;

  @Output() public changed = new EventEmitter();
  @Output() public submit = new EventEmitter();

   

  public displayWidth;

  constructor() { }
  

  change(e, k){
    if (e.length){
      this.fraction[k] = +e;
      this.displayWidth = String(Math.max(String(this.fraction.denominator).length, String(this.fraction.enumerator).length) * 2.4) + 'vw';
    }
    if (this.fraction.denominator && this.fraction.enumerator){
      this.changed.emit(this.fraction);
    }
  }
  ngOnChanges() {
    if (!this.fraction.denominator || !this.fraction.enumerator){
      this.fraction.denominator = this.fraction.denominator || '';
      this.fraction.enumerator = this.fraction.enumerator || '';
    }
    this.displayWidth = String(Math.max(String(this.fraction.denominator).length, String(this.fraction.enumerator).length) * 2.4) + 'vw';
  }


}
