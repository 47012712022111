import { Component, OnChanges, OnInit } from '@angular/core';
import { TaskComponent } from '../task/task.component';
import { numbers, intermix } from '../../general';
import { Equation } from '../../DBEquation';
@Component({
  selector: 'app-task-unit-conversion',
  templateUrl: './task-unit-conversion.component.html',
  styleUrls: ['./task-unit-conversion.component.css']
})
export class TaskUnitConversionComponent extends TaskComponent implements OnChanges, OnInit{ 
  public name = "TaskUnitConversion"
  public showHelpRow = true;
  public equation = {parts: []};

  ngOnInit(){//das vielleicht direkt in die task verlagern!
    if (this.task){
      this.equation = new Equation(intermix(this.task.from, '+').join('') + '=§number[guess]' + 'unit[' + this.task.to + ']', this.task);
    }

  }
  ngOnChanges(){
    if (this.task){ //das vielleicht direkt in die task verlagern!
      this.equation = new Equation(intermix(this.task.from, '+').join('') + '=§number[guess]' + 'unit[' + this.task.to + ']', this.task);
    }  }

}
