import { DBEventEmitter } from './DBEventEmitter';

export class Task extends DBEventEmitter{
    public posedAt;
    public solvedAt;
    public correct;
    static deserializers = {};
    static deserialize(s){
        s = s.map(x => this.deserializers[Object.keys(x)[0]] ? this.deserializers[Object.keys(x)[0]](x[Object.keys(x)[0]]) : x[Object.keys(x)[0]]);
        console.log(s)
        return new (this)( ...s);
    }
    constructor(...s){
        super();
        console.log(s);
    }
    check(){
        /*
        Muss von jeder Subklasse implementiert werden
        */
    }

    serialize(){
        let r = [];
        let constArgs = this.constructor.toString().replace(/(^.*?\(|\).*)/g,'').split(',').map(x=>x.trim());
        console.log(this.constructor.toString().replace(/(^.*?\(|\).*)/g,''))
        for (let arg of constArgs){
            console.log(arg);
            let z = {};
            /*
            Das muss noch viel mehr Fälle auffangen!*/
            
            if (Array.isArray(this[arg])){
                z[arg] = this[arg].map(x=>x.serialize ? x.serialize() : x)
            }
            else{
                z[arg] = this[arg].serialize ? this[arg].serialize() : this[arg]
            }
            r.push(z) ;
        }
        return r;
    }

    isEqual(o){
        return this.serialize() == o.serialize();
    }
}