import { Task } from "../DBTask";
import { randomInt, randomItem } from '../general';
import { Quantity } from '../DBQuantity';
import { units } from '../Measureunits';
export class TaskCompareUnits extends Task{
    static options = {
        lengths: {
            model: false,
            type: 'bool',
            label: 'Längen',
            disabled: false,
            relations: (o, v)=>{            }
        },
        weights: {
            model: false,
            type: 'bool',
            label: 'Gewichte',
            disabled: false,
            relations: (o, v)=>{            }
        },
        area: {
            model: true,
            type: 'bool',
            label: 'Flächen',
            disabled: false,
            relations: (o, v)=>{            }
        },
    }

    static difficulties = {
        1 : ()=>{
            let n = randomInt(1, 100);
            let u = units[randomItem(['lengths', 'weights', 'area'].filter(x => TaskCompareUnits.options[x].model))];
            let u1 = randomItem(Object.keys(u));
            let u2 = randomItem(Object.keys(u).filter(x=>x!=u1))
            let q1 = new Quantity(n , u1, u);
            let q2 = new Quantity(n,u2, u);
            let bigger, smaller;
            if (q1.unitIsGreaterThan(q2.unit)){
                bigger = q1;
                smaller = q2;
            }
            else { 
                bigger = q2;
                smaller = q1;
            }
            let factor = bigger.factor(smaller.unit);
            factor = factor * 10**randomInt(-2, 2);
            smaller.value = smaller.value * factor;
            return new TaskCompareUnits(1, q1, q2 );
        }
    }

    public result;
    public guess;
    public helpHtml = `
        <p>Wandle die kleinere Einheit in die größere um und vergleiche dann.<p>
        <details>
        <summary>Ein Beispiel</summary>
        <div>
            <p> 100m ___ 1000cm</p>
            <p>1000cm = 10m</p>
            <p>100m > 10m</p>
            <p>Also ist > die richtige Antwort.</p>
        <div>
        </details>
    `;
    constructor(public difficulty, public q1, public q2){
        super();

        this.result = this.q1.isEqual(this.q2) ? '=' :
        this.q1.isGreaterThan(this.q2) ? '>' :
        '<'
        console.log(this.q1, this.q2,this.result);
    }
    check(){
        if ( this.result === this.guess  ){
            console.log("genau")
            this.emit('correct');  
        }

        else {
          console.log("leider nicht")
          this.emit('wrong');  
        }
    }


}