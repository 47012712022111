import { Component } from '@angular/core';
import { TaskComponent } from '../task/task.component';

@Component({
  selector: 'app-task-percent-concepts',
  templateUrl: './task-percent-concepts.component.html',
  styleUrls: ['./task-percent-concepts.component.css']
})
export class TaskPercentConceptsComponent extends TaskComponent {
  public name = "TaskPercentConcepts"
 }
