import { Component } from '@angular/core';
import { RewardPicsService } from '../../Services/reward-pics.service';

@Component({
  selector: 'app-reward-selector',
  templateUrl: './reward-selector.component.html',
  styleUrls: ['./reward-selector.component.css']
})
export class RewardSelectorComponent {

  constructor(public rp : RewardPicsService) { }


}
