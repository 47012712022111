import { Component } from '@angular/core';
import { TaskComponent } from '../task/task.component';
@Component({
  selector: 'app-task-fraction-recognize',
  templateUrl: './task-fraction-recognize.component.html',
  styleUrls: ['./task-fraction-recognize.component.css']
})
export class TaskFractionRecognizeComponent  extends TaskComponent{ 
  public name = "TaskFractionRecognition";

}
