import { Operation } from './Operation';
import { RationalNumber } from './RationalNumber';
import { Fraction } from '../Fraction';

export class Division extends Operation{
    static rex: any =  /^:$/;

    constructor(public operands, public id){
        super(id);
    }
    /*get value(){
        return this.summands.reduce((a,b)=> a?.getValue + b?.getValue);
    }*/

    get nameOfResult(){
        return new Noun(Genera.MASCULINE, 'Quotient', 'Quotienten', 'Quotient');
    }
    get nameOfProcess(){
        return new Noun(Genera.FEMININE, 'Division', 'Divisionen', 'Division');
    }
    get verb(){
        return new Verb('dividier');
    }
    get namesOfOperands(){
        return [
            new Noun(Genera.MASCULINE, 'Dividend', 'Dividenden', 'Dividenden'),
            new Noun(Genera.MASCULINE, 'Divisor', 'Divisoren', 'Divisors'),
    ];
    }
    get value(){
        let r = this.operands[0].value.divideBy(this.operands[1].value);
        r.sign = Fraction.computeSign(this, r);
        return r;
    }
    get angularString(){
        return super.angularString.replace(/CHILD_CLASS_CONTENT/, 
            this.operands.map(x=>x.angularString).join(`operator(:)[${this.id}]`))
    }
    string(){
        return super.string(this.operands.map(x=>x.string()).join(':'));
    }
}