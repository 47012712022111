import { Fraction } from '../Fraction';
import { randomInt, randomItem, equal } from '../general';
import { Task } from '../DBTask';

export class TaskFractionToPercent extends Task{
    static maxDifficulty = 4;
    public name = "TaskFractionToPercent";
    static withDifficulty(difficulty){
        let d = randomItem([2,4,5,10,20,25,50,100,200,400,300, 500, 600]);
        switch (difficulty){
            case 1: {
                d = 100;
                break;
            }
            case 2: {
                d =  randomItem([50, 100, 10]);
                break;
            }
            case 3: {
                d = randomItem([50, 100, 10, 2, 4, 5, 25, 20]);
                break;
            }
            case 4: {
                d = randomItem([50, 100, 10, 2, 4, 5, 25, 20, 200,400,300, 500, 600, 1000]);
                break;
            }
        }
        let e = d >= 100 && (d%100 === 0) ? (d/100) * randomInt(1,100): randomInt(1,d);
        let result = new TaskFractionToPercent(new Fraction(e,d));
        result.difficulty = difficulty;
        return result;

    }

    public percentageSymbolForgotten;
    public guess;
    public difficulty;
    public helpHtml = `
    <p >
    "Prozent" bedeutet das Gleiche wie "ein Hundertstel".
    <br>
    Also sind zum Beispiel 3% das Gleiche wie <span style="display:inline-flex; flex-direction: column; text-align: center;"><span style="border-bottom: 1px solid black;">3</span><span>100</span></span>
    </p>`;

    constructor(public fraction){ 
        super();
    }
    
    check(){
        if (!/%$/.test(this.guess.trim())) {
          this.percentageSymbolForgotten = true;
          return;
        }
        if (equal((this.fraction.enumerator/this.fraction.denominator)*100,  +(this.guess.trim().substring(0,this.guess.length-1)), 0.01)){
          console.log("genau")
          this.emit('correct');
        }
        else{
          console.log("leider nicht")
          this.emit('wrong');  
        }
      }
}