import { Fraction } from '../Fraction';
import { shuffle } from 'lodash';
import { randomBool, randomItemsUnique, numbers, shakeTo } from '../general';
import { Task } from '../DBTask';

export class TaskFractionRecognition extends Task{
    public name = "TaskFractionRecognition"
    public difficulty;
    static maxDifficulty = 1;
    public representations;
    static withDifficulty(difficulty){
        return new TaskFractionRecognition(Fraction.true(), true);
    }

    constructor(public fraction, public scrambled){    
        super();
        let arr = shuffle([1,1,2,2,3,3])
        this.representations = numbers(6)
          .map(n => {
          let f = this.fraction.copy()
          return {
            fraction: f,
            type: arr[n],
            markedOnes: this.scrambled ? randomItemsUnique(f.enumerator, numbers(f.denominator)) : numbers(f.enumerator)
          }
        })
        shakeTo(3, numbers(6)).forEach(n=>{
          if (randomBool()) {
            this.representations[n].fraction.denominator += 1;
          }
          if (randomBool()) this.representations[n].fraction.enumerator += 1;
          this.representations[n].markedOnes = this.scrambled ? randomItemsUnique(this.representations[n].fraction.enumerator, numbers(this.representations[n].fraction.denominator)) : numbers(this.representations[n].fraction.enumerator)
        })
    }

    check(){
        let selected = this.representations.filter(x=>x.selected);
        if (selected.every(f=>f.fraction.identical(this.fraction)) && selected.length === this.representations.filter(f=>f.fraction.identical(this.fraction)).length){
            this.emit('correct')
        }
        else{
            this.emit('wrong')
            console.log("leider nicht");
        }
      }
}