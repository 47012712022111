import { Task } from '../DBTask';

let variables : any;
 variables = [
    {
        char: 'a',
        word: {
            singular: 'Apfel',
            plural: 'Äpfel'
        }    
    },
    {
        char: 'b',
        word: {
            singular: 'Banane',
            plural: 'Bananen'
        }
    },
    {
        char: 'c',
        word: {
            singular: 'Champignon',
            plural: 'Champignons'
        }   
    },
    {
        char: 'g',
        word: {
            singular: 'Gorilla',
            plural: 'Gorillas'
        }   
    },
    {
        char: 'k',
        word: {
            singular: 'Krawatte',
            plural: 'Krawatten'
        }   
    },
    {
        char: 'x',
        word: {
            singular: 'Xylophon',
            plural: 'Xylophone'
        }   
    },
    {
        char: 'y',
        word: {
            singular: 'YouTubevideo',
            plural: 'YouTubevideos'
        }   
    },
    {
        char: 'z',
        word: {
            singular: 'Zitrone',
            plural: 'Zitronen'
        }   
    },
]

let operators :any;
operators = [
    {
        symbol: '+',
        name: 'plus',
        precedence: 0,
        regExp: '\\+'
    },
    {
        symbol: '-',
        name: 'minus',
        precedence: 0,
        regExp: '\\-'
    },
    {
        symbol: '•',
        name: 'mal',
        precedence: 1,
        regExp: '\\*'
    },
    {
        symbol: ':',
        name: 'geteilt durch',
        precedence: 1,
        regExp: ':'
    },
]

operators = operators.sort((a,b)=>b.regExp.length - a.regExp.length);
let res //= operators.concat(variables).concat([{regExp:}]);
class Term{
    static from(str:string){
        let i = 0;
        while (str.length && i < 100){
            i++;
            for (let k of res){
                let m = str.match(new RegExp('^' + k[k.regExp || k.char] + '\\s*'));
                if (m){
                    let path;
                    /*
                    if ((/^\(?§/).test(m[1])){
                        path = m[4];
                        this.parts.push(new EquationPart(m[2], types[k], path));
                    }
                    else {
                        if(types[k] === 'fraction'){
                            this.parts.push(new EquationPart(new Fraction(+m[2], +m[3]), types[k], path));
                        }
                        else {
                            this.parts.push(new EquationPart(m[1], types[k], path));
                        }
                    }
                    */

                    str = str.slice(m[1].length);
                    break;
                }
            }
        }
    }

    constructor(){

    }
}

export class TaskTermSimplification extends Task{
    public name = "TaskTermSimplification";
    static difficulties = {
        1: ()=>{

            return new TaskTermSimplification(1);
        },

    }
    static options = {
        showVisuals: {
            model: false,
            type: 'bool',
            label: 'Veranschaulichung',
            disabled: false,
            noNewTask: true,
            relations: (o, v, d)=>{
/*                if (d>2) {
                    o.showVisuals.model = false;
                    o.showVisuals.disabled = true;
                }            
*/
            }
        }
    }
    public result;
    public guess;
    constructor(public difficulty:number){
        super();
    }

    check(){
        if (this.result === this.guess){
            console.log("genau");
            this.emit('correct');  
          }
        else{
          console.log("leider nicht");
          console.log(this)
          this.emit('wrong');  
        }
      }
}
