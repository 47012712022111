import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-comparison-selector',
  templateUrl: './comparison-selector.component.html',
  styleUrls: ['./comparison-selector.component.css']
})
export class ComparisonSelectorComponent {
  public comparisons = ['<', '=', '>'];

  @Input() public placeholder = '';
  @Input() public task;
  @Input() public guessPath = 'guess';

  @Output() public hasChanged = new EventEmitter();

  get guess(){
    let o = this.task;
    let path;
    if (this.guessPath){
      path = this.guessPath.split('.').filter(x=>x.length);
    }
    else {
      path = ['guess'];
      this.guessPath = 'guess';
    }
    for (let p of path){
      o = o[p];
    }
    return o;
  }

  setGuess(e){
    let o = this.task;
    let path;
    if (this.guessPath){
      path = this.guessPath.split('.').filter(x=>x.length);
    }
    else {
      path = ['guess'];
      this.guessPath = 'guess';
    }    for (let p of path.slice(0,-1)){
      o = o[p];
    }
    o[path[path.length-1]] = e.replace(/,/, '.');
  }

  constructor() { }

  ngOnInit() {
  }
  test(u){
    console.log(u)
  }
}
