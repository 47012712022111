import { Task } from "../DBTask";
import { randomInt, randomItem } from '../general';
import { Fraction } from '../Fraction';

const operators = {

    '*' : {
        stringRepresentation : '•§(0)',
        operation: (a, b)=>{
            if ( a instanceof SignedNumber ) a = a.value;
            if ( b instanceof SignedNumber ) b = b.value;
            if ( a instanceof Fraction) return a.multiply(b)
            if ( !Number.isNaN(a) && ( !Number.isNaN(b) )) return a*b;
        }
    },
    ':' : {
        stringRepresentation : ':§(0)',
        operation: (a, b)=>{
            if ( a instanceof SignedNumber ) a = a.value;
            if ( b instanceof SignedNumber ) b = b.value;
            if ( a instanceof Fraction) return a.divideBy(b)
            if ( !Number.isNaN(a) && ( !Number.isNaN(b) )) return new Fraction(Math.abs(a), Math.abs(b), Fraction.getSign(a*b));        }
    }
}
class OOPair{
    public operands;
    constructor(public operator,  ...operands){
        this.operands = operands;
    }
    toString(){
        if (!this.operator) return this.operands[0].toString()
        let s = operators[this.operator].stringRepresentation;
        this.operands.forEach((x, i)=> s = s.replace(new RegExp('§\\(' + i + '\\)' ), x.toString ? x.toString() : x))
        return s;
    }
    get value(){
        let op = this.operands[0];
        if (this.operator) return false;
        else if ( !Number.isNaN(op) ) return op
    }
    reduce(oop){
        return new OOPair(false, operators[oop.operator].operation(this.value, ...oop.operands))
    }
}
class SignedNumber{
    static randomSign(){
        return randomItem(['-', '+']);
    }
    constructor(public number, public sign){

    }
    toString(){
        return '(' + this.sign + (''+this.number).replace(/\./, ',') + ')'
    }
    get value(){
        return Math.abs(this.number) * (this.sign == '-' ? -1 : 1)
    }
    equalString(str){
        return this.value == str.replace(/,/, '.').replace(/^\+/, '')
    }
}

export class TaskNegativeNumbersMultiplication extends Task{
    static difficulties = {
        1 : ()=>{
            let o1 = randomInt(2,10);
            let o2 = randomInt(2,10);
            let s1 = SignedNumber.randomSign();
            let s2 = SignedNumber.randomSign();
            return new TaskNegativeNumbersMultiplication(1, new OOPair(false, new SignedNumber(o1, s1)), new OOPair( '*', new SignedNumber(o2, s2)));
        },
        2 : ()=>{
            let o1 = Fraction.true(2,10);
            let o2 = Fraction.true(2,10);
            let s1 = SignedNumber.randomSign();
            let s2 = SignedNumber.randomSign();
            o1.sign = s1;
            o2.sign = s2;
            return new TaskNegativeNumbersMultiplication(2, new OOPair(false, o1), new OOPair( '*', o2));
        },
        3 : ()=>{
            let o1 = Fraction.true(2,10);
            let o2 = Fraction.true(2,10);
            let s1 = SignedNumber.randomSign();
            let s2 = SignedNumber.randomSign();
            o1.sign = s1;
            o2.sign = s2;
            return new TaskNegativeNumbersMultiplication(3, new OOPair(false, o1), new OOPair( ':', o2));
        },
        4 : ()=>{
            let o1 = Fraction.true(2,10);
            let o2 = Fraction.true(2,10);
            let s1 = SignedNumber.randomSign();
            let s2 = SignedNumber.randomSign();
            o1.sign = s1;
            o2.sign = s2;
            return new TaskNegativeNumbersMultiplication(4, new OOPair(false, o1), new OOPair( ':', o2));
        }
        ,
        5 : ()=>{
            let o1 = Fraction.true(2,10);
            let o2 = Fraction.true(2,10);
            let s1 = SignedNumber.randomSign();
            let s2 = SignedNumber.randomSign();
            o1.sign = s1;
            o2.sign = s2;
            return new TaskNegativeNumbersMultiplication(5, new OOPair(false, o1), new OOPair( randomItem([':', '*']), o2));
        }
    }

    public result;
    public guess;
    public ooPairs;
    constructor(public difficulty, ...ooPairs){
        super();
        this.ooPairs = ooPairs;
        this.result = this.ooPairs.reduce((a,b)=> {
            console.log(a,b)
            return a.reduce(b)
        })
        this.guess = this.ooPairs[0].operands[0] instanceof Fraction ? {sign: '', enumerator: '', denominator: ''} : undefined;
    }
    check(){
        let g = JSON.parse(JSON.stringify(this.guess));
        if (g.sign=='') g.sign='+'
        console.log(this.difficulty)
        if ( this.result == g  || (this.result.operands[0] instanceof Fraction && this.result.operands[0].reduce()[this.difficulty > 3 ? 'identical' : 'equal'](g))){
            console.log("genau")
            this.emit('correct');  
        }

        else {
          console.log("leider nicht")
          console.log(this.result.value, this.guess)
          this.emit('wrong');  
        }
    }


}