import { Directive, ElementRef, AfterViewChecked } from '@angular/core';

@Directive({
  selector: '[FlexWidth]'
})
export class FlexWidthDirective implements AfterViewChecked {
  constructor(public el: ElementRef) { }
  ngAfterViewChecked(){
        this.el.nativeElement.style.width = (this.el.nativeElement.value.length + 1) + "ch";
  }
}
