import { Component, Input } from '@angular/core';
import { TaskButtonsServiceService } from 'src/app/task-buttons-service.service';

@Component({
  selector: 'app-answer-check-button',
  templateUrl: './answer-check-button.component.html',
  styleUrls: ['./answer-check-button.component.css']
})
export class AnswerCheckButtonComponent {
  @Input() public task;
  constructor(public tbs : TaskButtonsServiceService){}
}
