import { Component, Input } from '@angular/core';

import { SortableItem } from '../../core/models';

@Component({
  selector: 'sortable-item',
  template: `
  <div class="item-title"><div class="name">{{ item.name }}</div><div class="number">{{ item.number }}</div></div>
    <!--<img class="item-image"
        src="{{item.imageUrl}}"
        alt="Item image"
        draggable="false">-->
    <!-- This element is used as ghost image for whole draggable item. -->
    <h3 class="item-title drag-image">{{item.title}}</h3>
  `,
  styleUrls: ['./sortable-item.component.css']
})
export class SortableItemComponent {
  @Input() item: SortableItem;
}