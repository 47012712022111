import { Component, OnInit, Input } from '@angular/core';
import { numbers } from '../../general';

export class Token{

}
@Component({
  selector: 'app-token-holder',
  templateUrl: './token-holder.component.html',
  styleUrls: ['./token-holder.component.css']
})
export class TokenHolderComponent implements OnInit {
  public tokens = [];
  public symbols = {
    1: '1.png',
    10:'10.png',
    100:'100.png',
    1000:'1000.png'
  }
  @Input() public type;
  constructor() { 
  }
  
  addTokens(n){
    this.tokens = this.tokens.concat(numbers(n).map(x=>new Token));
    return this.tokens.length;
  }
  removeTokens(n){
    let r = Number(this.tokens.length);
    this.tokens = this.tokens.slice(0,-n);
    return +r;
  }
  get numberOfTokens(){
    return this.tokens.length;
  }
  ngOnInit() {
  }

}
