import { Task } from "../DBTask";
import { randomInt } from '../general';
import { DBPlaceValueRepresentation } from "../DBPlaceValueRepresentation";

export class TaskDecimalNamePlace extends Task{
    static difficulties = {
        1 : ()=>{
            let a = DBPlaceValueRepresentation.random(4,4,0,0);
            let p = randomInt(0,4);
            return new TaskDecimalNamePlace(1, a, p);
        },
        2 : ()=>{
            let a = DBPlaceValueRepresentation.random(4,4,0,0);
            let p = -randomInt(0,4);
            return new TaskDecimalNamePlace(2, a, p);
        },
        3 : ()=>{
            let a = DBPlaceValueRepresentation.random(4,4,0,0);
            let p = randomInt(-4,4);
            return new TaskDecimalNamePlace(3, a, p);
        },
        4 : ()=>{
            let a = DBPlaceValueRepresentation.random(7,7,0,0);
            let p = randomInt(-7,7);
            return new TaskDecimalNamePlace(4, a, p);
        }
    }

    public result;
    public guess;
    constructor(public difficulty, public number, public position){
        super();
    }
    check(){
        console.log(this.position, this.guess)
        if ( this.position == this.guess  ){
            console.log("genau")
            this.emit('correct');  
        }

        else {
          console.log("leider nicht")
          this.emit('wrong');  
        }
    }


}