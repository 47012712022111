export const list = [
{"item":"Artischocke","percentage":"2.9"},
{"item":"Aubergine","percentage":"2"},
{"item":"Avocado","percentage":"1"},
{"item":"Bambussprossen","percentage":"1"},
{"item":"Blattsalat","percentage":"0.7"},
{"item":"Blumenkohl","percentage":"2"},
{"item":"Bohnen, grün","percentage":"3"},
{"item":"Bohnen, weiß","percentage":"0.6"},
{"item":"Brokkoli","percentage":"1.9"},
{"item":"Brombeeren","percentage":"2.7"},
{"item":"Champignons","percentage":"0.8"},
{"item":"Cherrytomaten","percentage":"3.2"},
{"item":"Chicorée","percentage":"2"},
{"item":"Chinakohl","percentage":"0.7"},
{"item":"Cranberry","percentage":"5"},
{"item":"Erbsen","percentage":"3.2"},
{"item":"Fenchel","percentage":"0.4"},
{"item":"Frühlingszwiebel","percentage":"2"},
{"item":"Grünkohl","percentage":"2"},
{"item":"Guave","percentage":"2.4"},
{"item":"Heidelbeeren","percentage":"5"},
{"item":"Himbeeren","percentage":"4.6"},
{"item":"Ingwer","percentage":"2"},
{"item":"Johannisbeeren","percentage":"4.7"},
{"item":"Kaktusfeigen","percentage":"4"},
{"item":"Kapern","percentage":"0.5"},
{"item":"Kartoffeln","percentage":"2.1"},
{"item":"Kichererbsen","percentage":"3"},
{"item":"Kidneybohnen","percentage":"0.2"},
{"item":"Knoblauch","percentage":"1"},
{"item":"Knollensellerie","percentage":"2"},
{"item":"Kohlrabi","percentage":"2.6"},
{"item":"Kürbis","percentage":"3.0"},
{"item":"Lauch/Porree","percentage":"3.9"},
{"item":"Limette","percentage":"1.7"},
{"item":"Linsen","percentage":"1.8"},
{"item":"Mais","percentage":"4.6"},
{"item":"Mangold","percentage":"0.5"},
{"item":"Möhren/Karotten","percentage":"4.6"},
{"item":"Oliven, grün","percentage":"0"},
{"item":"Oliven, schwarz","percentage":"1.5"},
{"item":"Papaya","percentage":"2.4"},
{"item":"Paprika, gelb","percentage":"5"},
{"item":"Paprika, grün","percentage":"2.8"},
{"item":"Peperoni","percentage":"5"},
{"item":"Petersilie","percentage":"0.9"},
{"item":"Pfifferlinge","percentage":"0"},
{"item":"Pilze","percentage":"0"},
{"item":"Preiselbeeren","percentage":"5"},
{"item":"Radieschen","percentage":"2"},
{"item":"Rettich","percentage":"2.2"},
{"item":"Rhabarber","percentage":"1"},
{"item":"Rosenkohl","percentage":"2.2"},
{"item":"Rucola","percentage":"1.9"},
{"item":"Salatgurke","percentage":"2"},
{"item":"Sauerkraut","percentage":"0.8"},
{"item":"Schalotten","percentage":"4"},
{"item":"Sojabohne","percentage":"2"},
{"item":"Sojasprossen","percentage":"2.1"},
{"item":"Spargel, grün","percentage":"0.2"},
{"item":"Spargel, weiß","percentage":"1.9"},
{"item":"Spinat","percentage":"2"},
{"item":"Spitzkohl","percentage":"2.7"},
{"item":"Stangensellerie","percentage":"0.7"},
{"item":"Tomate","percentage":"2.6"},
{"item":"Vitelotte","percentage":"2.1"},
{"item":"Weißkohl","percentage":"5"},
{"item":"Wirsing","percentage":"2"},
{"item":"Zitrone","percentage":"2.5"},
{"item":"Zucchini","percentage":"2.5"},
{"item":"Zwiebeln","percentage":"4"},
{"item":"Ananas","percentage":"11.3"},
{"item":"Apfel","percentage":"10.4"},
{"item":"Aprikose","percentage":"9"},
{"item":"Banane","percentage":"17"},
{"item":"Birne","percentage":"10"},
{"item":"Bohnen, schwarz","percentage":"6.2"},
{"item":"Chilischote","percentage":"6"},
{"item":"Clementine","percentage":"9.5"},
{"item":"Datteln","percentage":"60"},
{"item":"Erdbeeren","percentage":"7"},
{"item":"Feige","percentage":"11"},
{"item":"Gewürzgurken","percentage":"5.1"},
{"item":"Granatapfel","percentage":"14"},
{"item":"Grapefruit","percentage":"6"},
{"item":"Holunder","percentage":"8"},
{"item":"Honigmelone","percentage":"11"},
{"item":"Kirschen","percentage":"13.4"},
{"item":"Kiwi","percentage":"8"},
{"item":"Kohlrübe","percentage":"5.1"},
{"item":"Mandarine","percentage":"10"},
{"item":"Mango","percentage":"12"},
{"item":"Nektarine","percentage":"12"},
{"item":"Orange","percentage":"10"},
{"item":"Paprika, rot","percentage":"6.2"},
{"item":"Passionsfrucht","percentage":"13"},
{"item":"Pfirsich","percentage":"9"},
{"item":"Pflaumen","percentage":"11"},
{"item":"Quitte","percentage":"9"},
{"item":"Rosinen","percentage":"75"},
{"item":"Rote Bete","percentage":"7.9"},
{"item":"Stachelbeeren","percentage":"8"},
{"item":"Steckrüben","percentage":"5.1"},
{"item":"Süßkartoffeln","percentage":"6"},
{"item":"Wassermelone","percentage":"6"},
{"item":"Weintrauben","percentage":"14"},
]