import { Component, OnInit, Input } from '@angular/core';
import {Fraction} from '../../Fraction';
import { TaskFractionRecognition } from '../../Task-Models/model-task-fraction-recognition';
import { randomIndex, randomInt, randomItem, PRIMES, numbers } from '../../general';
import { TaskFractionToPercent } from '../../Task-Models/model-task-fraction-to-percent';
import { TaskPercentToFraction } from '../../Task-Models/model-task-percent-to-fraction';
import { TaskFractionCancel } from '../../Task-Models/model-task-fraction-cancel';
import { shuffle } from 'lodash';
import { TaskPercentCalculations} from '../../Task-Models/model-task-percent-calculations';
import { TaskPercentConcepts} from '../../Task-Models/model-task-percent-concepts';
@Component({
  selector: 'app-exercise-paper',
  templateUrl: './exercise-paper.component.html',
  styleUrls: ['./exercise-paper.component.css']
})
export class ExercisePaperComponent implements OnInit {
  @Input() public tasks;
  @Input() public show;
  @Input() public number;
  constructor() { }

  ngOnInit() {
    let d = randomInt(8,13);
    let e = d - randomInt(2,4);
    let e1 = randomItem([2,4,5,10,25,50]);
    let d1 = randomInt(1,e1);
    let e2 = randomItem([2,4,5,10,25,50]);
    let d2 = randomInt(1,e1);
    let t1 = randomItem([2,4,25,10,5,50])
    let s1 = randomInt(1,100/t1)
    let t2 = randomItem([2,4,25,10,5,50])
    let s2 = randomInt(1,100/t2);
    

    this.tasks = {
      //'fraction-recognize':new TaskFractionRecognition(new Fraction(e, d), true),
      'fraction-to-percent':[
        new TaskFractionToPercent(new Fraction(randomInt(1,100),100)),
        //new TaskFractionToPercent(new Fraction(randomInt(1,100),100)),
        new TaskFractionToPercent(new Fraction(d1,e1)),
        new TaskFractionToPercent(new Fraction(d2,e2)),
      ],
      'percent-to-fraction':[
        new TaskPercentToFraction(randomItem(PRIMES.filter(x=>x<100))),
        //new TaskPercentToFraction(randomItem(PRIMES.filter(x=>x<100))),
        new TaskPercentToFraction(t1*s1),
        new TaskPercentToFraction(t2*s2),        
      ],/*
      'fraction-cancel': shuffle([
        new TaskFractionCancel(Fraction.reduced(3,5,12),2),
        //new TaskFractionCancel(Fraction.reduced(3,5,12),10),
        new TaskFractionCancel(Fraction.reduced(3,5,12),5),
        new TaskFractionCancel(Fraction.reduced(3,5,12),3),
      ]),*/
      'percent-calculations': (()=>{
        TaskPercentCalculations.options.percentageValueWanted.model = true;
        TaskPercentCalculations.options.percentageWanted.model = false;
        let r = [
          TaskPercentCalculations.withDifficulty(1),
          TaskPercentCalculations.withDifficulty(4),  
        ];
        TaskPercentCalculations.options.percentageValueWanted.model = false;
        TaskPercentCalculations.options.percentageWanted.model = true;
        r = r.concat([
          TaskPercentCalculations.withDifficulty(1),
          TaskPercentCalculations.withDifficulty(4)
        ])
        return r;         
      })(),
      'percent-concepts':[
        TaskPercentConcepts.withDifficulty(1, 'percentage'),
        TaskPercentConcepts.withDifficulty(1, 'percentageValue'),
        
      ]
    }
   }

}
