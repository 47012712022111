import { Injectable } from '@angular/core';
import { randomItem } from '../general';
const goodFeedback = [
  "Super!",
  "Sehr gut!",
  "😊",
  "😃",
  "👍",
  "😼",
  "🎉",
  "Genau!",
  "Weiter so!",
  "✅",
  "✔️",
  "🙃 Jawohl!"
]
const badFeedback = [
  "Beim nächsten Mal klappt es bestimmt besser!",
  "🙁 leider nicht."
]

@Injectable({
  providedIn: 'root'
})
export class FeedbacksService {

  constructor() { }

  get good(){
    return randomItem(goodFeedback);
  }

  get bad(){
    return randomItem(badFeedback);
  }
}
