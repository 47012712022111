import { Component, ViewChild, ViewChildren, ViewContainerRef, AfterViewInit } from '@angular/core';
import { TutorServiceService } from '../../Services/tutor-service.service';
import { RewardPicsService } from 'src/app/Services/reward-pics.service';
import { TaskRegistryService } from '../../Services/task-registry.service';
import { ComponentFactoryResolver } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-tutor',
  templateUrl: './tutor.component.html',
  styleUrls: ['./tutor.component.css'],
  providers: [ ]
})
export class TutorComponent implements AfterViewInit{
  @ViewChild('difficulty', {static:false}) public difficulty_sidenav;
  @ViewChild('help', {static:false}) public help_sidenav;

  public taskDataContainer;

  constructor(private resolver: ComponentFactoryResolver, public ts : TutorServiceService, public rp : RewardPicsService, public ar : ActivatedRoute, public tr : TaskRegistryService){
    window.document.addEventListener('keydown', this.ts.next.bind(this.ts));
  }
  
  toggleHelp(){
    this.help_sidenav.toggle();
    this.difficulty_sidenav.close();
  }

  toggleDifficulty(){
    this.difficulty_sidenav.toggle();
    this.help_sidenav.close();
  }
  ngAfterViewInit(){
    this.ts.on('queryParamsChanged', x => {
      let { name, klasse, difficulty, options } = x;
      this.taskDataContainer = this.tr.getTask(name);
      this.ts.taskDataContainer = this.taskDataContainer;
      this.ts.setTask(true);
      this.difficulty_sidenav.close();
      this.help_sidenav.close();
  }, 'somethingsomethingid', true)

  }
}
