import { Fraction } from '../Fraction';
import { randomInt, randomItem, numbers } from '../general';
import { Task } from '../DBTask';

export class TaskFractionCancel extends Task{
    public name = "TaskFractionCancel";
    static maxDifficulty = 4;
    static withDifficulty( difficulty){ 
        let easierFactors = [2,5,10];
        let easyFactors = [2, 4, 5, 10, 100, 50, 8];
        let harderFactors = easyFactors.concat([3, 6, 9, 30, 300, 15, 12]);
        let evenHarderFactors = harderFactors.concat(numbers(10).map(x=>x*7))
        let fraction = Fraction.reduced(3,5, 20);//2,3,4,5,7,11
        let factor;
        switch (difficulty){
            case 1: {
                factor = randomItem(easierFactors);
                break;
            }
            case 2: {
                factor = randomItem(easyFactors);
                break;
            }
            case 3: {
                factor = randomItem(harderFactors);
                break;
            }
            case 4: {
                factor = randomItem(evenHarderFactors);
                break;
            }
            case 5: {
                factor = randomInt(10,30);
                break;
            }
        }
        let result = new TaskFractionCancel(fraction, factor);
        result.difficulty = difficulty;
        return result;
    }

    public guess = {fraction: {denominator: '', enumerator: '', sign: '+'}};
    public almost;
    public difficulty;
    constructor(public fraction, public factor){
        super();
        this.fraction = this.fraction.expandBy(factor);
    }

    check(){
      if (this.fraction.cancelBy(this.factor).identical(this.guess.fraction)){
        this.emit('correct');
      }
      else if (this.guess.fraction.denominator !== '' && this.guess.fraction.enumerator !== '' && !this.fraction.identical(this.guess.fraction) && this.fraction.equal(this.guess.fraction)){
        this.almost = true;
      }
      else {
        this.almost = false;
        this.emit('wrong');  
      }
    }
}