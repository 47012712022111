import { Task } from "../DBTask";
import { numbers, randomInt } from '../general';
import { DBPlaceValueRepresentation } from '../DBPlaceValueRepresentation';

export class TaskDecimalsNumberlineTell extends Task{
    static difficulties = {
        1 : ()=>{
            return new TaskDecimalsNumberlineTell(1,
                0, 
                2, 
                "0.1", 
            {intervals: [{value: 5, marked: true}, {value: 10, marked: false}], offset: 0}, 
            DBPlaceValueRepresentation.random(''+randomInt(0,2),1,0,0));
        },
        2 : ()=>{
            let rand = randomInt(0,20)
            let rand1 = ''+randomInt(rand,rand+2);
            console.log(rand1)
            return new TaskDecimalsNumberlineTell(2,
                rand, 
                rand+2, 
                "0.1", 
            {intervals: [{value: 5, marked: true}, {value: 10, marked: false}], offset: 0}, 
            DBPlaceValueRepresentation.random(rand1,1,0,0));
        },
/*        3 : ()=>{
            let rand = randomInt(0,20)
            let rand1 = ''+randomInt(rand,rand+2);
            console.log(rand1)
            return new TaskDecimalsNumberlineTell(3,
                rand/10, 
                (rand+2)/10, 
                "0.001", 
            {intervals: [{value: 10, marked: false}, {value: 100, marked: false}], offset: 0}, 
            DBPlaceValueRepresentation.random(rand1,1,0,0));
        }*/
    }

    public result;
    public guess;
    constructor(public difficulty, public start, public end, public smallestStep, public bigSteps, public number){
        super();
    }
    check(){
        console.log(this.number)
        if ( this.number.match(this.guess)){
            console.log("genau")
            this.emit('correct');  
        }

        else {
          console.log("leider nicht")
          this.emit('wrong');  
        }
    }
}