import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'commatize'
})
export class CommatizePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (String(value).indexOf(',') !== -1) return value;
    if (String(value).indexOf('.') === -1) return value;

    if (args) if (value !== '') value = +value.toFixed(+args);
    if (args && /\.0+$/.test(value)) value = value.slice(0,-1);
    
    let result = (value !== null && value !== undefined) ? String(value).replace(/\./, ',') : '';

    return result;
  }


}
