import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { TutorServiceService } from 'src/app/Services/tutor-service.service';
import { RewardPicsService } from 'src/app/Services/reward-pics.service';
import { TaskRegistryService } from 'src/app/Services/task-registry.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';

@Component({
  selector: 'app-tutor-toolbar',
  templateUrl: './tutor-toolbar.component.html',
  styleUrls: ['./tutor-toolbar.component.css'],
  providers: [Location, {provide: LocationStrategy, useClass: PathLocationStrategy}]

})
export class TutorToolbarComponent {
  
  constructor(public location: Location, public router : Router, public ts: TutorServiceService, public rp: RewardPicsService, public tr : TaskRegistryService, public activatedRoute : ActivatedRoute ) { }

  openTask(t){
    console.log(t.path)
    const querystr = 'name=' + t.path;
    if (this.tr.tasks[t.path]){
this.router.navigateByUrl('aufgabe?name=' + t.path)     /* this.router.navigate(
        ['/aufgabe'], 
        {
          relativeTo: this.activatedRoute,
          queryParams: { name: t.path},
          queryParamsHandling: "merge"
        });*/
    }
  }
}
