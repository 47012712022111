import { Task } from "../DBTask";
import { randomInt, randomItem } from '../general';
import { OptionsTaskNumberBaseChangeComponent } from '../Components/options-task-number-base-change/options-task-number-base-change.component';

let g = (options, newValue, thisItem)=>{
    let groups = ['from', 'to']
    let group = thisItem.groups.filter(x=>groups.includes(x))[0];
    console.log(group, groups.includes(group), !newValue)
    if (!newValue && groups.includes(group)){
        groups.splice(groups.indexOf(group),1)
        let otherGroup = groups[0];
        options = Object.values(options);
        let otherGroupArr = options.filter(x=>x.groups.includes(otherGroup));
        let groupArr = options.filter(x=>x.groups.includes(group));
        let i = groupArr.indexOf(thisItem);
        groupArr.splice(i,1);
        let gOtherGroupArr = otherGroupArr.filter(x=>x.model);
        let gGroupArr = groupArr.filter(x=>x.model);
        
        if (gOtherGroupArr.length === 1 && gGroupArr.length === 1 && gOtherGroupArr[0].label ===gGroupArr[0].label) otherGroupArr.forEach(x=>x.model = true);
        i = otherGroupArr.findIndex(x=>x.label === thisItem.label);
        otherGroupArr.splice(i,1);
        if (groupArr.every(x=>!x.model)) groupArr.forEach(x=>x.model = true);
    }
    thisItem.model = newValue;
}

let d = (s,o)=>{
    return ()=>{
        let from = +(randomItem(Object.values(TaskNumberBaseChange.options).filter(x=>x.groups.includes('from') && x.model)).label)
        let to = +(randomItem(Object.values(TaskNumberBaseChange.options).filter(x=>x.groups.includes('to') && x.model && x.label != ''+from)).label)
        let a = Math.min(from,to)
        let number = randomInt(a**s, a**o);
        return new TaskNumberBaseChange(1, number, from, to);
    }
}

export class TaskNumberBaseChange extends Task{
    static difficulties = {
        1 : d(1,3),
        2 : d(3,5),
        3 : d(5,7),
        4 : d(7,9),
        5 : d(9,11),
        6 : d(11,13)
    }
    static options = {
        from10: {
            model: true,
            type: 'bool',
            label: '10',
            groups: ['from'],
            disabled: false,
            relations: g
        },
        from2 : {
            model: false,
            type: 'bool',
            label: '2',
            groups: ['from'],
            disabled: false,
            relations: g
        },
        from16 : {
            model: false,
            type: 'bool',
            label: '16',
            groups: ['from'],
            disabled: false,
            relations: g
        },
        to10 : {
            model: false,
            type: 'bool',
            label: '10',
            groups: ['to'],
            disabled: false,
            relations: g
        },
        to2 : {
            model: true,
            type: 'bool',
            label: '2',
            groups: ['to'],
            disabled: false,
            relations: g
        },
        to16 : {
            model: false,
            type: 'bool',
            label: '16',
            groups: ['to'],
            disabled: false,
            relations: g
    }
}
    //static optionsComponent = OptionsTaskNumberBaseChangeComponent;
    public result;
    public guess;
    constructor(public difficulty, public number, public from, public to){
        super();
        this.result = this.number.toString(this.to);
    }
    check(){
        if ( ''+this.result === ''+this.guess  ){
            console.log("genau")
            this.emit('correct');  
        }

        else {
          console.log(this.result, this.guess, this)
          console.log("leider nicht")
          this.emit('wrong');  
        }
    }


}