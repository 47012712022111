import { randomInt, randomItem, randomBool, randomItemsUnique, numbers, without, numberWithFactors, PRIMES } from '../general';
import { Task } from '../DBTask';
import { Fraction } from '../Fraction';

export class TaskFractionComparison extends Task{
    public name = "TaskFractionComparison";
    static difficulties = {
        1: ()=>{
            let a = randomInt(2,10);
            let b,c,first, second;
            if (randomBool()){
                b = randomInt(1,a);
                c = randomInt(1,a);
                first = new Fraction(b,a);
                second = new Fraction(c,a);    
            } else{
                b = randomInt(a,10);
                c = randomInt(a,10);
                first = new Fraction(a,b);
                second = new Fraction(a,c);
            }
            return new TaskFractionComparison(1, first, second);
        },
        2: ()=>{
            let a = randomInt(10,30);
            let b,c,first, second;
            if (randomBool()){
                b = randomInt(1,a);
                c = randomInt(1,a);
                first = new Fraction(b,a);
                second = new Fraction(c,a);    
            } else{
                b = randomInt(a,30);
                c = randomInt(a,30);
                first = new Fraction(a,b);
                second = new Fraction(a,c);
            }
            return new TaskFractionComparison(2, first, second);
        },
        3: ()=>{
            let first = Fraction.true(2,20);
            let second = Fraction.true(2,20);
            return new TaskFractionComparison(3, first, second);
        }
    }
    static options = {
        showVisuals: {
            model: false,
            type: 'bool',
            label: 'Veranschaulichung',
            disabled: false,
            noNewTask: true,
            relations: (o, v, d)=>{
/*                if (d>2) {
                    o.showVisuals.model = false;
                    o.showVisuals.disabled = true;
                }            
*/
            }
        }
    }
    public result;
    public guess;
    constructor(public difficulty:number, public first, public second){
        super();
        this.result = this.first.equal(this.second) ? '=' :
                      this.first.lessThen(this.second) ? '<' :
                      '>'
    }

    check(){
        if (this.result ===(this.guess)){
            console.log("genau");
            this.emit('correct');  
          }
        else{
          console.log("leider nicht");
          console.log(this)
          this.emit('wrong');  
        }
      }
}
