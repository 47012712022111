import { Task } from "../DBTask";
import { randomInt } from '../general';
import { Fraction } from '../Fraction';

export class TaskFractionToMixedNumber extends Task{
    static difficulties = {
        1 : ()=>{
            let d = randomInt(3,20);
            let enumerator = Fraction.coprimeNumber(d,d);
            
            return new TaskFractionToMixedNumber(1, new Fraction(enumerator, d));
        }
    }

    public result;
    public guess = {number: '', fraction:{enumerator: '', denominator: '', sign: '+'}};
    constructor(public difficulty, public fraction : Fraction){
        super();
    }
    check(){
        console.log(this.fraction, Number(this.guess.number), Number(this.guess.fraction.denominator),Number(this.guess.fraction.enumerator), Number(this.guess.fraction.denominator), Number(this.guess.number) < 0 ? '-' : '+');
        console.log(this.fraction.equal(new Fraction(3,4)))
        if ( this.fraction.equal(new Fraction(Math.abs(Number(this.guess.number)) * Number(this.guess.fraction.denominator) + Number(this.guess.fraction.enumerator), Number(this.guess.fraction.denominator), Number(this.guess.number) < 0 ? '-' : '+'))){
            console.log("genau")
            this.emit('correct');  
        }
 
        else {
          console.log("leider nicht")
          this.emit('wrong');  
        }
    }


}