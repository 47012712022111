import { POSITIONS, POSITIONS_RIGHT, objectFromArray, numbers, shakeTo, randomInt, padArrayLeft, padArrayRight, randomDigit } from './general';

let isNonZero = (s)=>s!=='0';

export class DBPlaceValueRepresentation{
    static getPositionName(i){
        if (i>=0){
            return POSITIONS[i];
        }
        else {
            return POSITIONS_RIGHT[-i];
        }
    }
    static fromNumber(n){
        //34.213 ==> ['4', '3'], ['2', '1', '3'], false, 10
        let ds = String(n).split('.')
        let dl = Array.from(ds[0]).reverse().map(String);
        let dr = ds[1] ? Array.from(ds[1]).map(String) : [];
        let neg = n < 0;
        return new DBPlaceValueRepresentation(dl, dr, neg);
    }

    static random(nOfDL, nOfDR, nOfZeroesL, nOfZeroesR){
        let beforeComma = typeof nOfDL == 'string' ? nOfDL : numbers(nOfDL).map(x=>randomInt(1,10)).join('');
        let afterComma = typeof nOfDR == 'string' ? nOfDR :numbers(nOfDR).map(x=>randomInt(1,10)).join('');
        let decimal = DBPlaceValueRepresentation.fromNumber(beforeComma + '.' + afterComma);
        if (typeof nOfDL != 'string') shakeTo(nOfZeroesL,numbers(nOfDL)).forEach(x=> decimal = decimal.setDigitAtPosition('0', x));
        if (typeof nOfDR != 'string') shakeTo(nOfZeroesR,numbers(nOfDR)).forEach(x=> decimal = decimal.setDigitAtPosition('0', -x-1));
        return decimal;
    }

    constructor(public digitsLeft : string[], public digitsRight : string[], public negative = false, public base = 10){ }

    copy(){
        return new DBPlaceValueRepresentation(Array.from(this.digitsLeft), Array.from(this.digitsRight), this.negative, this.base);
    }
    get digits(){
        return this.digitsLeft.concat(this.digitsRight);
    }
    get string(){
        return Array.from(this.digitsLeft).reverse().join('') + (this.digitsRight.length ? '.' : '') + this.digitsRight.join('');
    }
    get germanString(){
        return Array.from(this.digitsLeft).reverse().join('') + (this.digitsRight.length ? ',' : '') + this.digitsRight.join('');
    }
    get value(){
        if (this.base == 10){
            return Number(this.digitsLeft.reverse().join('') + '.' + this.digitsRight.join(''));
        }
    }
/*
    get highestNonzero(){
        let i = this.digitsLeft.findIndex(isNonZero);
        if (i === -1){
            i = -this.digitsRight.findIndex(isNonZero);
        }
        return {value: undefined, position: i};
    }

    get lowestNonzero(){
        return {value: undefined, position: undefined};
    }
*/
    lessThan(n){
        if (!n || !n.digitsLeft) return false;
        if (n.digitsLeft.length < this.digitsLeft.length) return false;
        if (n.digitsLeft.length > this.digitsLeft.length) return true;
        for (let i = 0; i<n.digitsLeft.length; i++){
            if (+n.digitsLeft[n.digitsLeft.length-1-i] < +this.digitsLeft[this.digitsLeft.length-1-i]) return false;
            if (+n.digitsLeft[n.digitsLeft.length-1-i] > +this.digitsLeft[this.digitsLeft.length-1-i]) return true;
        }
        let m = n.digitsRight.length > this.digitsRight.length ? n.digitsRight.length : this.digitsRight.length;
        for (let i = 0; i<m; i++){
            if ((+n.digitsRight[i] || 0) < (+this.digitsRight[i] || 0)) return false;
            if ((+n.digitsRight[i] || 0) > (+this.digitsRight[i] || 0)) return true;
        }
        return false;
    }

    setDigitAtPosition(digit, position){
        let ds = position < 0 ? Array.from(this.digitsRight) : Array.from(this.digitsLeft);
        let pos = position < 0 ? Math.abs(position+1) : position;
        ds[pos] = String(digit);
        let r = new DBPlaceValueRepresentation(position < 0 ? Array.from(this.digitsLeft) : ds,  position >= 0 ? Array.from(this.digitsRight) : ds, this.negative, this.base);
        console.log(JSON.stringify(r))
        return r;
    }
    getDigitAtPosition(position){
        let ds = position < 0 ? this.digitsRight : this.digitsLeft;
        let pos = position < 0 ? Math.abs(position+1) : position;
        return ds[pos];
    }
    getNamedPosition(position) {
        return position < 0 ? POSITIONS_RIGHT[Math.abs(position)]  : POSITIONS[position];
    };
    get namedPositionsObject(){
        let dl = this.digitsLeft.map((x,i)=>[this.getNamedPosition(i), x]).reverse();
        let rl = this.digitsRight.map((x,i)=>[this.getNamedPosition(-i-1), x]);
        return objectFromArray(dl.concat(rl), '');
    }
    addDigitAtPosition(d, p){
        let i = this.getDigitAtPosition(p);
        let a = +d + +i;
        if (a < this.base){
            return this.setDigitAtPosition(''+a, p);
        }
        else {
            this.setDigitAtPosition(''+a, p).addDigitAtPosition(1,p+1);
        }
    }
    shrink(){
        let i = this.digitsRight.length-1;
        let result = this.copy();
        while (i >= 0 && result.digitsRight[i] == '0') {
            result.digitsRight.pop();
            i -= 1;
        }
        i = this.digitsLeft.length-1;
        while (i >= 0 && result.digitsLeft[i] == '0') {
            result.digitsLeft.pop();
            i -= 1;
        }
        return result;
    }
    cutAfter(position){
        let ra = this.positionsRange;
        let re : any = this;
        for (let i = position-1; i>ra[1]; i--){
            re = re.setDigitAtPosition('0', i)
        }
        return re;
    }
    round(position){
        if (+this.getDigitAtPosition(position-1) > 4){
            return this.addDigitAtPosition('1', position).cutAfter(position);
        }
        return this.cutAfter(position);
    }
    match(str : string){
        let rex = new RegExp('^0*?' + Array.from(this.digitsLeft).reverse().join('').replace(/^0*/, '') + '(.|,)?' + this.digitsRight.join('').replace(/0*$/, '') + '0*$');
        console.log(this, rex)

        return rex.test(str);
    }
    transformBase(b){
//        return new DBPlaceValueRepresentation( , b)
    }
    glueDigitsBehind(d){
        let r = this.copy();
        r.digitsRight = r.digitsRight.concat(Array.from(d));
        return r;
    }
    equals(d){
        if (!d || !d.string) return false;
        return this.match(d.string);
    }
    lessThanOrEqual(d){
        return this.equals(d) || this.lessThan(d);
    }
    get positionsRange(){
        return [this.digitsLeft.length-1, this.digitsRight.length ? -this.digitsRight.length-1 : 0];
    }
    getCommonPositionsRange(d){
        let drang = d.positionsRange;
        let trang = this.positionsRange;
        return [Math.min(drang[0],trang[0]), Math.max(drang[1],trang[1])]
    }
    differentFrom(d){
        if (this.equals(d)){
            let i = randomInt(...(this.getCommonPositionsRange(d).reverse()))
            let dd = d.getDigitAtPosition(i);
            return this.setDigitAtPosition(randomDigit([dd]), i);
        }
        return this;
    }
    differentFromAtPosition(d,i){
        if (this.getDigitAtPosition(i) == d.getDigitAtPosition(i)){
            let dd = d.getDigitAtPosition(i);
            let r = randomDigit([dd]);
            return this.setDigitAtPosition(r, i);
        }
        return this;
    }
    serialize(){
        return this.string;
    }
}