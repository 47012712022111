import { Component, OnInit } from '@angular/core';
import { TaskComponent } from '../task/task.component';
import { Equation } from 'src/app/DBEquation';

@Component({
  selector: 'app-task-decimals-comparison-inbetween',
  templateUrl: './task-decimals-comparison-inbetween.component.html',
  styleUrls: ['./task-decimals-comparison-inbetween.component.css']
})
export class TaskDecimalsComparisonInbetweenComponent extends TaskComponent {
  public equation;

  ngOnChanges() {
    this.equation = new Equation(this.task.first.germanString + ' < §string[guess] < ' + this.task.second.germanString, this.task);
  }
}
