import { Component, Input,ViewEncapsulation, ViewChild, ElementRef, OnChanges } from '@angular/core';

@Component({
  selector: 'app-number-input',
  templateUrl: './number-input.component.html',
  styleUrls: ['./number-input.component.css'],
  encapsulation: ViewEncapsulation.None

})
export class NumberInputComponent implements OnChanges{
  @Input() public type = 'text';
  @Input() public placeholder = '';
  @Input() public task;
  @Input() public guessPath = 'guess';
  @Input() public prefix;
  @Input() public suffix;
  @Input() public width;
  @Input() public autofocus = true;

  @ViewChild('inputfield', {static:false}) public input:ElementRef;

  ngOnChanges(){
    if (this.input && this.autofocus) this.input.nativeElement.focus();
  }
  get guess(){
    let o = this.task;
    let path;
    if (this.guessPath){
      path = this.guessPath.split('.').filter(x=>x.length);
    }
    else {
      path = ['guess'];
      this.guessPath = 'guess';
    }
    for (let p of path){
      o = o[p];
    }
    return o;
  }

  setGuess(e){
    let o = this.task;
    let path;
    if (this.guessPath){
      path = this.guessPath.split('.').filter(x=>x.length);
    }
    else {
      path = ['guess'];
      this.guessPath = 'guess';
    }    for (let p of path.slice(0,-1)){
      o = o[p];
    }
    o[path[path.length-1]] = e.replace(/,/, '.');
  }

}
