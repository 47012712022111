import { randomInt, randomItem, randomItemsUnique, numbers, without, numberWithFactors, PRIMES } from '../general';
import { Task } from '../DBTask';
import { list as prices } from '../Data/Fruitprices';
import { list as cities } from '../Data/GermanCities19702017'
import { Fraction } from '../Fraction';
const firstNames = ['Wanja', 'Toni', 'Kim', 'Ali', 'Alex', 'Conni', 'Chris'];
const lastNames = ['Meier', 'Geier', 'Jablonski', 'Peter', 'Speeter', 'Yıldırım'];
const names = firstNames.concat(lastNames.map(x=>randomItem(['Frau', 'Herr']) + ' ' + x))
const namesAcc = firstNames.concat(lastNames.map(x=>randomItem(['Frau', 'Herrn']) + ' ' + x))
const agoes = ['gestern', 'vor ' + randomInt(2,5) + ' Tagen', 'letzte Woche'];
const shareables = ['Kuchen', 'Pizzen', 'Muffins', 'Äpfel', 'Birnen', 'Orangen']

export class TaskFractionWordProblemSharing extends Task{
    public name = "TaskFractionWordProblemSharing";
    static difficulties = {
        1: ()=>{
            let result = Fraction.true(2);
            const templates = [
                `${randomItemsUnique(result.denominator, names).join(', ').split('').reverse().join('').replace(/,/, 'dnu ').split('').reverse().join('')} teilen sich ${result.enumerator} ${randomItem(shareables)}. <br>
                Wieviel bekommt jeder?`,
                `${result.enumerator} ${randomItem(shareables)} sollen gleichmäßig aufgeteilt werden unter ${randomItemsUnique(result.denominator, namesAcc).join(', ').split('').reverse().join('').replace(/,/, 'dnu ').split('').reverse().join('')}.. <br>
                Wieviel bekommt jeder?`
            ]
            return new TaskFractionWordProblemSharing(1, randomItem(templates), result);
        }
    }
    public guess = {enumerator: '', denominator: '', sign: '+'};
    constructor(public difficulty, public text, public result){
        super();
    }

    check(){
        if (this.result.equal(this.guess)){
            console.log("genau");
            this.emit('correct');  
          }
        else{
          console.log("leider nicht");
          console.log(this)
          this.emit('wrong');  
        }
      }
}
