import { Task } from "../DBTask";
import { randomItem, equal, randomIndex, randomInt, numbers, shakeTo } from '../general';
import { shuffle } from 'lodash';
import { Fraction } from "../Fraction";

export class TaskFractionChooseIdentical extends Task{
    public name = "TaskFractionChooseIdentical";
    static difficulties = {
        1 : ()=>{
            let urFraction = Fraction.true(1,5);
            let factors = numbers(8).slice(1);
            let fractions = shakeTo(6, factors)
                .map(x => urFraction.expandBy(x));
            shakeTo(randomInt(1,4), numbers(fractions.length)).forEach(x=>{
                fractions[x] = fractions[x].increaseEnumeratorBy(x);
            });
            fractions = shuffle(fractions);
            return new TaskFractionChooseIdentical(1, urFraction, fractions);
        },
        2 : ()=>{
            let urFraction = Fraction.true(3,10);
            let factors = numbers(20).slice(1);
            let fractions = shakeTo(6, factors)
                .map(x => urFraction.expandBy(x));
            shakeTo(randomInt(1,4), numbers(fractions.length)).forEach(x=>{
                fractions[x] = fractions[x].increaseEnumeratorBy(x);
            });
            fractions = shuffle(fractions);
            return new TaskFractionChooseIdentical(2, urFraction, fractions);
        }
    }


    public result;
    public guess;
    constructor(public difficulty, public urFraction, public fractions){
        super();
        this.guess = [];
        this.result = this.fractions.filter(x=> x.equal(this.urFraction));
    }
    check(){
        let selected = this.fractions.filter(x=>x.selected);

        console.log(this.result, selected);
        if (selected.length == this.result.length && this.result.length == selected.filter(x=> x.equal(this.urFraction)).length){
            console.log("genau")
            this.emit('correct');  
        }

        else{
          console.log("leider nicht")
          this.emit('wrong');  
        }
    }
}