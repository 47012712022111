import { Fraction } from '../Fraction';
import { randomInt, randomItem, numbers, without, numberWithFactors, PRIMES } from '../general';
import { Task } from '../DBTask';

export class TaskPercentToFraction extends Task{
    static maxDifficulty = 2;
    
    static withDifficulty(difficulty){
        let percentage;
        let fn = (fs, lastfs) => {
            //console.log(7777, fs.filter(x=> x < (100 / lastfs.reduce((a,b)=>a*b,1)));)
            return fs.filter(x=> x < (100 / lastfs.reduce((a,b)=>a*b,1)));
        }
        switch (difficulty){
            case 1: {
                percentage = numberWithFactors(PRIMES.filter(x=>x<100 && (100 % x !== 0)), 0, 100, fn)
                break;
            }
            case 2: {
                percentage = numberWithFactors(PRIMES.filter(x=>x<100), 0, 100, fn)
                break;
            }
        }
        let result = new TaskPercentToFraction(percentage);
        result.difficulty = difficulty;
        return result;

    }
    public name = "TaskPercentToFraction";
    public difficulty;
    public guess= {fraction: {enumerator: '', denominator: ''}};
    public almost;
    constructor(public percentage){
        super();
    }

    check(){
        if ((new Fraction(this.percentage, 100)).reduce().identical(this.guess.fraction)){
          this.emit('correct');
        }    
        if (this.guess.fraction.denominator !== '' && this.guess.fraction.enumerator !== '' && (new Fraction(this.percentage, 100)).equal(this.guess.fraction)){
          this.almost = true;
        }
        else {
          this.almost = false;
          this.emit('wrong');  
        }
      }
}