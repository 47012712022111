import { Component, OnInit, Input, ViewChild, ElementRef, OnChanges} from '@angular/core';

@Component({
  selector: 'app-string-input',
  templateUrl: './string-input.component.html',
  styleUrls: ['./string-input.component.css']
})
export class StringInputComponent implements OnChanges{
  @Input() public type = 'text';
  @Input() public placeholder = '';
  @Input() public task;
  @Input() public guessPath = 'guess';
  @Input() public prefix;
  @Input() public suffix;
  @Input() public width;
  @Input() public autofocus = true;
  @ViewChild('inputfield', {static:false}) public input:ElementRef;

  ngOnChanges(){
    if (this.autofocus && this.input) this.input.nativeElement.focus();
  }
  get guess(){
    let o = this.task;
    let path;
    if (this.guessPath){
      path = this.guessPath.split('.').filter(x=>x.length);
    }
    else {
      path = ['guess'];
      this.guessPath = 'guess';
    }
    for (let p of path){
      o = o[p];
    }
    return o;
  }

  setGuess(e){
    let o = this.task;
    let path;
    if (this.guessPath){
      path = this.guessPath.split('.').filter(x=>x.length);
    }
    else {
      path = ['guess'];
      this.guessPath = 'guess';
    }    for (let p of path.slice(0,-1)){
      o = o[p];
    }
    o[path[path.length-1]] = e;
    console.log('guess', e)
  }
}
