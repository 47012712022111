import { Component, OnChanges } from '@angular/core';
import { TaskComponent } from '../task/task.component';

@Component({
  selector: 'app-task-fraction-expand-with',
  templateUrl: './task-fraction-expand-with.component.html',
  styleUrls: ['./task-fraction-expand-with.component.css']
})
export class TaskFractionExpandWithComponent extends TaskComponent implements OnChanges {
  public name = "TaskFractionExpandWith"

  ngOnChanges() {
  }

}
