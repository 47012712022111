import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-task-proportional-isit',
  templateUrl: './task-proportional-isit.component.html',
  styleUrls: ['./task-proportional-isit.component.css']
})
export class TaskProportionalIsitComponent implements OnInit {
  public name = "TaskProportionalIsit"
  @Input() task;
  @Output() solved: EventEmitter<any> = new EventEmitter();

  constructor() { 

  }
  
  reveal(guess){
    this.task.guessed = true;
    if (guess === this.task.itIs){
      this.solved.emit(true);
    }
  }

  next(){
    if (this.task.guessed) this.solved.emit(false);
  }

  ngOnInit() {
  }

}
