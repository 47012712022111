import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-binary-switch',
  templateUrl: './binary-switch.component.html',
  styleUrls: ['./binary-switch.component.css']
})
export class BinarySwitchComponent implements OnInit {
@Input() public switch;
  constructor() { }

  ngOnInit() {
  }

}
