import { Component, OnChanges } from '@angular/core';
import { TaskComponent } from '../task/task.component';
import { Equation } from '../../DBEquation';

@Component({
  selector: 'app-task-mental-arithmetic',
  templateUrl: './task-mental-arithmetic.component.html',
  styleUrls: ['./task-mental-arithmetic.component.css']
})
export class TaskMentalArithmeticComponent extends TaskComponent implements OnChanges{ 
  public equation;
  public name = "TaskMentalArithmetic";
  ngOnChanges(){
    console.log(this.task)
    if (this.task.divident){ //auch hier die Equation in die Task verlagern!
      if (!this.task.flipped){
        this.equation = new Equation(this.task.divident + ':' + this.task.divisor + '=§number[guess]', this.task)
      } else {
        this.equation = new Equation(this.task.divisor + '·§number[guess]=' + this.task.divident, this.task)
      }
    }
    else {
      if (this.task){
      this.equation = new Equation(this.task.factors.join('·') + '=§number[guess]', this.task)
      }
    }
  }
}
