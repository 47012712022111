import { Component, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { equal } from '../../general';
import { TaskComponent } from '../task/task.component';

@Component({
  selector: 'app-task-rule-of-three',
  templateUrl: './task-rule-of-three.component.html',
  styleUrls: ['./task-rule-of-three.component.css']
})
export class TaskRuleOfThreeComponent extends TaskComponent{
  public name = "TaskRuleOfThree"
  @Input() public task;
  @Output() solved: EventEmitter<any> = new EventEmitter();

  public stepNumber = -1;
  public guess;
  public showStepControls;
  public guessCorrect;


   ngOnChanges() {
    this.resetGuess();
  }
  resetGuess(){
    this.guess = {
      known1: '',
      known2: '',
      known3: '',
      wanted: '',
      intermediate: '',
      one: '',
      divLeft: '',
      divRight: '',
      timesLeft: '',
      timesRight: ''
    }
    this.guessCorrect = {
      known1: '',
      known2: '',
      known3: '',
      wanted: '',
      intermediate: '',
      one: '',
      divLeft: '',
      divRight: '',
      timesLeft: '',
      timesRight: ''
    }    
    this.showStepControls = false;
    this.stepNumber = -1;
  }
  check(){
    for (let k in this.guess){
      if (this.guess[k] === '') continue;
      if (k === 'divLeft' || k === 'divRight'){
        this.guessCorrect[k] = (+(this.guess[k].replace(/,/,'.')) === +this.task.known1);
      }
      else if (k === 'timesLeft' || k === 'timesRight'){
        this.guessCorrect[k] = (+(this.guess[k].replace(/,/,'.')) === +this.task.known3);
      }
      else if (k === 'intermediate'){
        this.guessCorrect[k] = equal(+(this.guess.intermediate.replace(/,/,'.')), +this.task.intermediate, 0.01);
      }
      else if (k === 'wanted'){
        if (equal(+(this.guess.intermediate.replace(/,/,'.')), +this.task.intermediate, 0.01)){
          this.guessCorrect[k] = equal(+(this.guess.wanted.replace(/,/,'.')), +(this.guess.intermediate.replace(/,/,'.')) * this.task.known3, 0.01);
        }
        else {
          this.guessCorrect[k] = false;
        }
      }
      else {
        this.guessCorrect[k] = (+(this.guess[k].replace(/,/,'.')) === +this.task[k]);
      }
    }
    if (Object.keys(this.guessCorrect).every(x=>this.guessCorrect[x] === true)) this.solved.emit(true);
  }
  
  show(){
    switch (this.stepNumber){ /**there are meant to be no "break"s */
      case 6: {}
      case 5: {
        this.guess.intermediate = this.task.intermediate;
        this.guess.wanted = this.task.wanted;
      }
      case 4 : {
        this.guess.divRight = this.task.known1;
        this.guess.divLeft = this.task.known1;
        this.guess.timesRight = this.task.known3;
        this.guess.timesLeft = this.task.known3;
      }
      case 3: {
        this.guess.one = this.task.one;
      }
      case 2: {
        this.guess.known3 = String(this.task.known3);
      }
      case 1 : {
        this.guess.known1 = String(this.task.known1);
        this.guess.known2 = String(this.task.known2);
      }
    }
  }

  hu(e,o,k){
    o[k] = String(e);
  }

  stepForward(){
    if (this.stepNumber < 6) this.stepNumber++;
  }
  stepBack(){
    if (this.stepNumber > 0) this.stepNumber--
  }
}
