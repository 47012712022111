import { Component, OnInit } from '@angular/core';
import { TaskComponent } from '../task/task.component';
import { Equation } from 'src/app/DBEquation';
@Component({
  selector: 'app-task-fraction-word-problem-sharing',
  templateUrl: './task-fraction-word-problem-sharing.component.html',
  styleUrls: ['./task-fraction-word-problem-sharing.component.css']
})
export class TaskFractionWordProblemSharingComponent extends TaskComponent{
  public name = "TaskFractionWordProblemSharing";
  public equation;

  ngOnChanges() {
    this.equation = new Equation('§fraction[guess]', this.task);
    console.log(this.equation)
  }

}
