import { Component} from '@angular/core';
import { TaskComponent } from '../task/task.component';

@Component({
  selector: 'app-task-fraction-to-percent',
  templateUrl: './task-fraction-to-percent.component.html',
  styleUrls: ['./task-fraction-to-percent.component.css']
})
export class TaskFractionToPercentComponent extends TaskComponent { 
  public name = "TaskFractionToPercent";

}
