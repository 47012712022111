import { Component, OnInit } from '@angular/core';
import { TaskComponent } from '../task/task.component';
import { Equation } from 'src/app/DBEquation';
import { Fraction } from 'src/app/Fraction';

@Component({
  selector: 'app-task-negative-numbers-multiplication',
  templateUrl: './task-negative-numbers-multiplication.component.html',
  styleUrls: ['./task-negative-numbers-multiplication.component.css']
})
export class TaskNegativeNumbersMultiplicationComponent extends TaskComponent {
  public equation;
  ngOnChanges() {
    console.log(this.task.ooPairs.join('').replace(/fraction/g, 'sfraction'));
    this.equation = new Equation(this.task.ooPairs.join('').replace(/fraction/g, 'sfraction') + '=' + (this.task.ooPairs[0].operands[0] instanceof Fraction || this.task.ooPairs[1].operator == ':' ? '§sfraction[guess]' : '§number[guess]'), this.task)
  console.log(this.equation)
  }

}
