import { Component, OnInit, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-percentage-bar',
  templateUrl: './percentage-bar.component.html',
  styleUrls: ['./percentage-bar.component.css']
})
export class PercentageBarComponent implements OnChanges {
  @Input() public baseValue;
  @Input() public percentages; 
  public rects;

  constructor() {
   }

  ngOnChanges() {
    this.rects = this.percentages.map((x,i)=>{
      return {x: (i> 0 ? this.percentages[i-1] : 0), width: x-(i> 0 ? this.percentages[i-1] : 0)}
    });

  }

}
