import { Component, OnInit } from '@angular/core';
import { TaskComponent } from '../task/task.component';
import { Equation } from '../../DBEquation';

@Component({
  selector: 'app-task-fraction-of-number',
  templateUrl: './task-fraction-of-number.component.html',
  styleUrls: ['./task-fraction-of-number.component.css']
})
export class TaskFractionOfNumberComponent extends TaskComponent {
  public name = "TaskFractionOfNumber";
  public equation;
  ngOnChanges() {
    this.equation = new Equation(this.task.fraction + 'von' + this.task.number + 'sind§number[guess]' , this.task);
  }
}
