import { Component, OnChanges, Input } from '@angular/core';
import { numbers } from '../../general';

@Component({
  selector: 'app-fraction-circle',
  templateUrl: './fraction-circle.component.html',
  styleUrls: ['./fraction-circle.component.css']
})
export class FractionCircleComponent implements OnChanges {
  @Input() public fraction;

  public markedOnes;

  constructor() { }

  ngOnChanges() {
    this.markedOnes = numbers(this.fraction.enumerator+1)
  }

}
