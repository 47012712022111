import { Component, OnInit } from '@angular/core';
import { numbers } from '../../general';
@Component({
  selector: 'app-point-sets',
  templateUrl: './point-sets.component.html',
  styleUrls: ['./point-sets.component.css']
})
export class PointSetsComponent implements OnInit {
  public points;
  constructor() { 
    this.points = numbers(5)
      .map(x=> numbers(5).map(y=>{
        return {
          x: x,
          y: y
        }
      })
      ).reduce((a,b)=>a.concat(b), []);
  }

  ngOnInit() {
  }
}
