import { Component, OnInit } from '@angular/core';
import { TaskComponent } from '../task/task.component';

@Component({
  selector: 'app-task-rectangle-shapes',
  templateUrl: './task-rectangle-shapes.component.html',
  styleUrls: ['./task-rectangle-shapes.component.css']
})
export class TaskRectangleShapesComponent extends TaskComponent {



}
