import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-help-task-fraction-addition',
  templateUrl: './help-task-fraction-addition.component.html',
  styleUrls: ['./help-task-fraction-addition.component.css']
})
export class HelpTaskFractionAdditionComponent implements OnInit {
  public task;
  constructor() { }

  ngOnInit() {
  }

}
