import { Component, OnInit } from '@angular/core';
import { TaskComponent } from '../task/task.component';

@Component({
  selector: 'app-task-equivalence-transformation',
  templateUrl: './task-equivalence-transformation.component.html',
  styleUrls: ['./task-equivalence-transformation.component.css']
})
export class TaskEquivalenceTransformationComponent extends TaskComponent {


}
  
