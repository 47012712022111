import { Component, OnChanges } from '@angular/core';
import { TaskComponent } from '../task/task.component';

@Component({
  selector: 'app-task-mark-point',
  templateUrl: './task-mark-point.component.html',
  styleUrls: ['./task-mark-point.component.css']
})
export class TaskMarkPointComponent extends TaskComponent implements OnChanges {
  public name = "TaskMarkPoint";

  handleSelection(e){
    this.task.guess = e.selection;
  }
  ngOnChanges() {
  }

}
