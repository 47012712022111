import { Task } from "../DBTask";
import { randomInt, randomItem } from '../general';

const terms = [
    {
        operation: "Multiplikation",
        operationverb: "multipliziere",
        leftOperand: "erste Faktor",
        rightOperand: "zweite Faktor",
        result: "das Produkt",
        task: ()=>{
            let left= randomInt(0,10);
            let right= randomInt(0,10);
        return {
                left: left,
                right: right,
                result: left*right
            }
        }
    },
    {
        operation: "Subtraktion",
        operationverb: "subtrahiere",
        leftOperand: "Minuend",
        rightOperand: "Subtrahend",
        result: "die Differenz",
        task: ()=>{
            let a = randomInt(0,20);
            let b = randomInt(0,a+1);
            return {
                left: a,
                right: b,
                result: a-b
            }
        }
    },
    {
        operation: "Addition",
        operationverb: "addiere",
        leftOperand: "erste Summand",
        rightOperand: "zweite Summand",
        result: "die Summe",
        task: ()=>{
            let left = randomInt(0,10);
            let right = randomInt(0,10);

            return {
                left: left,
                right: right,
                result:left+right
            }
        }
    },
    {
        operation: "Division",
        operationverb: "dividiere",
        leftOperand: "Dividend",
        rightOperand: "Divisor",
        result: "der Quotient",
        task: ()=>{
            let a = randomInt(1,10);
            let b = randomInt(0,10);
            let c = a * b;
            return {
                left: c,
                right: a,
                result: b
            }
        }    }
]


const interpolate = (templ, description, task)=>{
    return templ.replace(/§([^.\s]*)?/g, function(m,p1){
        if (p1 === "left" || p1 === "right"){
            return task[p1];
        } else {
            return description[p1];
        }
    })
}

export class TaskFlashcardTerms extends Task{
    static difficulties = {
        1 : ()=>{
            const templates = [
                //`§operationverb §left und §right`,
                `Was ist §result von §left und §right`,
                `Der §leftOperand ist §left und der §rightOperand ist §right. Was ist das Ergebnis?`,
                `Der §rightOperand ist §right und der §leftOperand ist §left. Was ist das Ergebnis?`,
                //`Das Ergebnis einer §operation von §right und §left ist ...`
            ]
            let description = randomItem(terms);
            let task =  description.task();

            let text = interpolate(randomItem(templates), description, task);
            return new TaskFlashcardTerms(1, text, task.result);
        }
    }
    public guess;
    constructor(public difficulty, public text, public result){
        super();
    }
    check(){
        if ( this.result == this.guess  ){
            console.log("genau")
            this.emit('correct');  
        }

        else {
          console.log("leider nicht", this.result, this.guess)
          this.emit('wrong');  
        }
    }
}