import { Directive, Input, HostListener, ElementRef, EventEmitter, Output, ContentChild } from '@angular/core';
import { DragService } from './drag.service';
import { DragHandleDirective } from './drag-handle.directive';

@Directive({
  selector: '[appDraggable]'
})
export class DraggableDirective {
  @Output('selected') public gotselected = new EventEmitter();
  @Output('deselected') public gotdeselected = new EventEmitter();
  @Output() public moving = new EventEmitter();
  
  @Input('movable-data') public data;
  @Input('put-back') public putBack = true;

  @ContentChild(DragHandleDirective, {read: ElementRef, static:false}) public handle;

  public selected = false;
  public dirty;

  @HostListener('touchstart', ["$event"])
  @HostListener('mousedown', ["$event"])
  toggleSelect(e){
    e.preventDefault();
    //wäre toll, wenn der Eventlistener nur applied würde, wenn
    //(!this.handle && !this.ms.multiple) :/
    if (!this.handle && !this.ms.multiple && !this.dirty){
      this.selected = !this.selected;
      //window.alert('boo' + JSON.stringify(this.ms.selection));
      this.ms.setAnchorTransformation(this);
      this.dirty = true;
      this.ms.anchorCursorPosition = { x: e.pageX || e.touches[0].pageX, y: e.pageY || e.touches[0].pageY};
    }
  }

  @HostListener('click', ["$event"])
  select(e){
    if (!this.handle && this.ms.multiple && !this.selected){
        this.selected = true;
        this.el.nativeElement.classList.add('selected');
        this.gotselected.emit(this);
        this.ms.setAnchorTransformation(this);
        this.ms.anchorCursorPosition = { x: e.pageX || e.touches[0].pageX, y: e.pageY || e.touches[0].pageY};
    }
  }
  constructor(public ms : DragService, public el : ElementRef) {   }

}
