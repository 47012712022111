import { Task } from "../DBTask";
import { randomInt, randomBool, randomItem, equal } from '../general';
import { DBPlaceValueRepresentation } from "../DBPlaceValueRepresentation";

export class TaskDecimalCompare extends Task{
    static difficulties = {
        1 : ()=>{ 
            let first = DBPlaceValueRepresentation.random(1,1,0,0);
            let second = DBPlaceValueRepresentation.random(1,1,0,0);
            return new TaskDecimalCompare(1, first, second);
        },
        2 : ()=>{ 
            let b = ''+(randomInt(1,10));
            let first = DBPlaceValueRepresentation.random(b,2,0,1);
            let second = DBPlaceValueRepresentation.random(b,2,0,1);
            return new TaskDecimalCompare(2, first, second);
        },
        3 : ()=>{ 
            let b = ''+(randomInt(1,20));
            let first = DBPlaceValueRepresentation.random(b,randomInt(3,5),0,1);
            let second = DBPlaceValueRepresentation.random(b,randomInt(3,5),0,1);
            return new TaskDecimalCompare(3, first, second);
        },
        4 : ()=>{ 
            let a = randomInt(3,5);
            let first = DBPlaceValueRepresentation.random(2,a,0,1);
            let c = randomInt(-a, 1);
            console.log(c)
            let second = first.setDigitAtPosition(randomInt(0,10), c);
            return new TaskDecimalCompare(4, first, second);
        },
        5 : ()=>{ 
            let a = randomInt(3,5);
            let first = DBPlaceValueRepresentation.random(2,a,0,2);
            let c = randomInt(-a, 1);
            console.log(c)
            let second = first.setDigitAtPosition(randomInt(0,10), c);
            first = first.shrink();
            second = second.shrink();
            
            return new TaskDecimalCompare(5, first, second);
        }
    }


    public result;
    public guess;

    constructor(public difficulty:number, public first, public second){
        super();
        this.result = this.first.match(this.second.string) ? '=' :
                      this.first.lessThan(this.second) ? '<' :
                      '>'
    }

    check(){
        console.log(this.result, this.guess)
        if (this.result === this.guess){
            console.log("genau")
            this.emit('correct');  
        }
        else{
          console.log("leider nicht")
          this.emit('wrong');  
        }
    }
}