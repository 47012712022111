import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fraction-rectangle-triangulated',
  templateUrl: './fraction-rectangle-triangulated.component.html',
  styleUrls: ['./fraction-rectangle-triangulated.component.css']
})
export class FractionRectangleTriangulatedComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
