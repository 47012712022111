import { Component, OnInit } from '@angular/core';
import { TaskComponent } from '../task/task.component';

@Component({
  selector: 'app-task-number-base-change',
  templateUrl: './task-number-base-change.component.html',
  styleUrls: ['./task-number-base-change.component.css']
})
export class TaskNumberBaseChangeComponent  extends TaskComponent {


}
