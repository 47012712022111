import { Directive, HostListener, ElementRef, AfterContentInit, Input, ContentChildren, ContentChild } from '@angular/core';
import { DragService } from './drag.service';
import { DraggableDirective } from './draggable.directive';
import { DropAreaDirective } from './drop-area.directive';

@Directive({
  selector: '[appDragArea]',
  providers: [ DragService ]
})
export class DragAreaDirective implements AfterContentInit{
  @ContentChildren(DraggableDirective, {descendants:true}) public movables;
  @ContentChildren(DropAreaDirective, {descendants:true}) public dropAreas;

  @Input('multiple-selection') public multiple = false;

  public mousedown =false;
  constructor(public el: ElementRef, public ms : DragService) { }

  @HostListener('window:touchend', ['$event'])
  @HostListener('window:mouseup', ['$event'])
  putBack(e){
    this.mousedown = false;
    this.dropAreas
      .filter(x=> x.cursorPosition.x && (this.ms.cursorPosition.x === x.cursorPosition.x || this.ms.cursorPosition.y === x.cursorPosition.y))
      .forEach(x=> x.drops())

    if (!this.multiple){
      this.movables
      .filter(x=> x.selected || x.dirty)
      .forEach(x=>{
        this.ms.putBack(x.el.nativeElement)
        x.el.nativeElement.classList.remove('moving');
        x.selected = false;
        x.dirty = false;
        x.gotdeselected.emit(x);
      })
    }
    else if (!e.target.hasAttribute('appDraggable')) {
      this.movables
      .filter(x=> x.selected || x.dirty)
      .forEach(x=>{
        this.ms.putBack(x.el.nativeElement);
        x.el.nativeElement.classList.remove('moving');
      })
    }

    this.dropAreas.forEach(x=> {x.cursorPosition = {x:undefined, y:undefined}})
  }

  @HostListener('touchmove', ['$event'])
  @HostListener('mousemove', ['$event'])
  updateCursor(e){
    //if (this.mousedown){
      e.preventDefault();
      this.ms.cursorPosition = { x: e.pageX || e.touches[0].pageX, y: e.pageY || e.touches[0].pageY};
      this.dropAreas
        .filter(x=> x.el.nativeElement === document.elementFromPoint(this.ms.cursorPosition.x, this.ms.cursorPosition.y))
        .forEach(x=> x.hovers(this.ms.cursorPosition))
      this.dropAreas
        .filter(x=> x.cursorPosition.x && (this.ms.cursorPosition.x !== x.cursorPosition.x || this.ms.cursorPosition.y !== x.cursorPosition.y))
        .forEach(x=> {
          let t = x.areDroppableFn(x.ms.selection);
          x.itemsleft.emit(t);
        })
      this.movables
        .filter(x=>x.selected)
        .forEach(x=>this.ms.moveElement(x));
/*  hier stimmt was ganz fundamentales nicht ..... auf touch müsste es gehen, aber mit mouse keine chance
  was geputtet wird, wird auch immer wieder remove, sobald die maus sich bewegt
  und macht man das abhängig davon dass die maus gedrückt ist, geht auch was schief
*/
   // }
    
  }

  @HostListener('touchstart', ['$event'])
  @HostListener('mousedown', ['$event'])
  cancelSelection(e){
    this.mousedown = true;
    if (!e.target.hasAttribute('appDraggable')) {
      this.movables
      .filter(x=> x.selected || x.dirty)
      .forEach(x=>{
        this.ms.putBack(x.el.nativeElement)
        x.el.nativeElement.classList.remove('moving');
        x.el.nativeElement.classList.remove('selected');
        x.selected = false;
        x.dirty = false;
      })

    }
  }
  
  ngAfterContentInit(){
    this.ms.multiple = this.multiple;
    this.ms.movablesQueryList = this.movables;
  }
}
    /**
     * Scheisse meine ganzen tollen Überlegungen funktionieren auf Touch nicht,
     * weil da das Target immer das ursprüngliche ELement ist, auf dem der Touch gestartet
     * ist :( ich könnte heulen!
     * 
     * jetzt dann also anders
     * HA! Es war fast kein Thema :D ging super einfach zu ändern! \o/ Juhu,
     * fast der ganze alte Code kann stehen bleiben
     */