import { Component, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { numbers,numberSort, shallowCopy } from '../../general';
@Component({
  selector: 'app-task-lists',
  templateUrl: './task-lists.component.html',
  styleUrls: ['./task-lists.component.css']
})
export class TaskListsComponent implements OnChanges {

  @Input() task;
  @Output() solved: EventEmitter<any> = new EventEmitter();
  public name = "TaskLists";
  public hlines;
  public vlines;
  public hmarkings;
  public ticks;
  public guessStatistics;
  public correctStatistics = {max:undefined, min: undefined, median:undefined, range:undefined};
  public orderCorrect;
  public showDia;
  public guessedTickSize;

  constructor() { }
    hu(e){
    this.guessStatistics.median = e.replace(/,/, '.');
    }

   onDropSortable($event){
     let i = this.task.list.findIndex((v)=>v.name === $event.data.name)
     this.task.list.splice(i,1);
     this.task.list.splice($event.newIndex,0, $event.data);
   }

   checkStatistics(){
      for (let k in this.task.statistics){
        this.correctStatistics[k] = +this.task.statistics[k] === +this.guessStatistics[k];
      }
   }
  
   checkOrder(){
     let ns1 = this.task.list.map(x=>x.number);
     let ns2 = shallowCopy(ns1).sort(numberSort);
     this.orderCorrect = JSON.stringify(ns2) === JSON.stringify(ns1)
       || JSON.stringify(ns2.reverse()) === JSON.stringify(ns1)
   }

   showDiagramm(){
     this.showDia = true;
   }

  ngOnChanges() {
    this.orderCorrect = undefined;
    this.guessStatistics = {max:undefined, min: undefined, median:undefined, range:undefined};
    this.correctStatistics = {max:undefined, min: undefined, median:undefined, range:undefined};
    this.showDia = false;
    this.guessedTickSize = undefined;
    this.hlines = numbers(this.task.nOfBoxes+2)
    .map(x=>{
      return {
        x1: 0,
        x2: 300,
        y1: x *(300/(this.task.nOfBoxes+1)),
        y2: x *(300/(this.task.nOfBoxes+1))
      }
    })

    this.hmarkings = numbers(this.task.nOfBoxes+2)
    .filter((x,i)=>{
      if ((this.task.nOfBoxes+1) % 2 !== 0) return i%2!==0
      if ((this.task.nOfBoxes+1) % 2 === 0) return i%2===0
    })
    .map(x=>{
      return {
        x1: 90,
        x2: 100,
        y1: x *(300/(this.task.nOfBoxes+1)),
        y2: x *(300/(this.task.nOfBoxes+1))
      }
    })

    this.ticks = this.hmarkings
    .slice(this.task.nOfBoxes % 2 === 0 ? 0 : 1)
    .map((x,i) => {
      return {
        text : i * 2 * this.task.tickSize,
        y: this.hmarkings[this.hmarkings.length - (i+1)].y1
      }
    })

    this.hmarkings = this.hmarkings.slice(this.task.nOfBoxes % 2 === 0 ? 0 : 1,-1)

    this.vlines = numbers(this.task.nOfBoxes+2)
    .map(x=>{
    return {
      x1: x *(300/(this.task.nOfBoxes+1)),
      x2: x *(300/(this.task.nOfBoxes+1)),
      y1: 0,
      y2: 300
      }
    })
  }


}
