import { Component, OnInit } from '@angular/core';
import { TaskComponent } from '../task/task.component';
import { Equation } from '../../DBEquation';

@Component({
  selector: 'app-task-compare-negative-numbers',
  templateUrl: './task-compare-negative-numbers.component.html',
  styleUrls: ['./task-compare-negative-numbers.component.css']
})
export class TaskCompareNegativeNumbersComponent extends TaskComponent {
  public name = "TaskCompareNegativeNumbers";
  public equation;
  ngOnChanges() {
    this.equation = new Equation(this.task.first + '§comparison[guess]' + this.task.second, this.task);
  }

}
