import { Operator } from './LexedArray';

export let signParser = (arr) => {
    for (let i=0; i<arr.length;i++){
        if (Array.isArray(arr[i])){
            arr.splice(i,1,signParser(arr[i]));
        }
        else if(arr[i].type && (arr[i].type === "-" || arr[i].type === "+")){
            if (i === 0 || arr[i-1] instanceof Operator){
                if (arr[i+1].fractionValue){
                    arr[i+1].fractionValue.sign = arr[i].type;
                }
                else{
                    arr[i+1].sign = arr[i].type;
                }
                arr.splice(i,1);
            }
        }
    }
    return arr;
}