import { Input, Directive, HostListener, ElementRef, Output, EventEmitter } from '@angular/core';
import { DragService } from './drag.service';

@Directive({
  selector: '[appDropArea]'
})
export class DropAreaDirective{
  public cursorPosition = {x: undefined, y: undefined}
  public itemshovering = false;
  @Output('items-dropped') public itemsdropped = new EventEmitter();
  @Output('items-drop-failed') public itemsdropfailed = new EventEmitter();
  @Output('items-drop-partially') public itemsdroppartially = new EventEmitter();
  @Output('items-hover') public itemshover = new EventEmitter();
  @Output('items-left') public itemsleft = new EventEmitter();
  @Output('items-entered') public itemsentered = new EventEmitter();
  @Output('items-hover-partially-droppable') public itemshoverpartiallydroppable = new EventEmitter();
  @Output('items-hover-none-droppable') public itemshovernonedroppable = new EventEmitter();
  @Output('items-hover-all-droppable') public itemshoveralldroppable = new EventEmitter();
  @Output('items-dropping') public itemsdropping = new EventEmitter();

  @Input('dropArea') public dummy = (x?)=>{};
  @Input() public areDroppableFn = (x)=> {return {droppable: x, notdroppable: []}};
  @Input() public dropFn = (items, thisDropArea)=> items;

  constructor(public ms : DragService, public el : ElementRef) { 
    this.itemsleft.subscribe(x=>{
      this.el.nativeElement.classList.remove('items-hover-partially-droppable');
      this.el.nativeElement.classList.remove('items-hover-none-droppable');
      this.el.nativeElement.classList.remove('items-hover-all-droppable');
      this.el.nativeElement.classList.remove('items-hover');
      this.cursorPosition = {x: undefined, y: undefined};
      this.itemshovering = false;
    });
  }

  hovers(cursorPos){
    if (this.ms.selection.length){
      if (!this.itemshovering){
        this.itemshovering = true;
        this.itemsentered.emit({items: this.ms.selection,});
        let t = this.areDroppableFn(this.ms.selection);
        if (t.notdroppable.length){
          if (t.droppable.length){
            this.itemshoverpartiallydroppable.emit(t);
            this.itemshover.emit(t);
            this.el.nativeElement.classList.add('items-hover');
            this.el.nativeElement.classList.add('items-hover-partially-droppable');
          }
          else {
            this.itemshovernonedroppable.emit(t);
            this.itemshover.emit(t);
            this.el.nativeElement.classList.add('items-hover');
            this.el.nativeElement.classList.remove('items-hover-none-droppable');
          }
        } else {
          this.itemshoveralldroppable.emit(t);
          this.itemshover.emit(t);
          this.el.nativeElement.classList.add('items-hover');
          this.el.nativeElement.classList.remove('items-hover-all-droppable');
        }
      }
      this.cursorPosition = cursorPos;
    }
  }

  @HostListener('mouseup', ['$event'])
  @HostListener('touchend', ['$event'])
  @HostListener('touchcancel', ['$event'])
  drops(){
    //if (this.itemshovering){

      this.itemsdropping.emit({items: this.ms.selection,});
      let t = this.dropFn(this.ms.selection, this);
      if (t.length === this.ms.selection.length){
        this.itemsdropped.emit(t);
      } 
      else if (t.length){
        this.itemsdroppartially.emit({success: t, failed: this.ms.selection.filter(x=>!t.includes(x))})
      }
      else {
        this.itemsdropfailed.emit(this.ms.selection);
      }
    //}
  }
}
