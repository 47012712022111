import { PRIMES, POSITIONS, randomIndex, randomItems, digitsum, without, numbers, randomInt } from '../general';
import { Task } from '../DBTask';
import { DBPlaceValueRepresentation } from '../DBPlaceValueRepresentation';

export class TaskRound extends Task{
    public name = "TaskRound";
    static maxDifficulty = 4;

    static withDifficulty(difficulty){
        let number = randomInt(1000, 10000);
        let position = randomInt(1, String(number).length);
        let o = DBPlaceValueRepresentation.fromNumber(number);
        switch (difficulty){
            case 1: {
                if (+o.getDigitAtPosition(position)  === 9) o = o.setDigitAtPosition(position, '8'); 
                if (+o.getDigitAtPosition(position-1) === 5) o = o.setDigitAtPosition(position-1, '4');
                break;
            }
            case 2: {
                if (+o.getDigitAtPosition(position)  === 9) o = o.setDigitAtPosition(position, '8'); 
                break;
            }
            case 3: {
                position = randomInt(1, String(number).length+2);
                break;
            }
            case 4: {
                o = DBPlaceValueRepresentation.fromNumber(randomInt(1000000, 10000000));
                position = randomInt(1, String(o.value).length+2);
                break;
            }
        }
        let result = new TaskRound(String(o.value), position);
        result.difficulty = difficulty;
        return result;

    }
    public difficulty;
    public guess;
    public result;
    public namedPosition;

    constructor(public number : String, public position){
        super();
        let d = Math.pow(10, this.position);
        this.namedPosition = POSITIONS[this.position];
        this.result = String(Math.round(Number(this.number)/d) * d);
    }
    
    check(){
        if (this.result == this.guess){
          console.log("genau")
          this.emit('correct');
        }
        else{
          console.log("leider nicht")
          this.emit('wrong');  
        }
    }
}