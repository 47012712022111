import { Component, OnInit, ViewChildren,AfterViewInit, Input } from '@angular/core';
import { numbers } from '../../general';
@Component({
  selector: 'app-token-holders-container',
  templateUrl: './token-holders-container.component.html',
  styleUrls: ['./token-holders-container.component.css']
})
export class TokenHoldersContainerComponent implements AfterViewInit {
  @Input() public number = 3;
  @Input() public movers = false;
  @Input() public values = [];
  public powersOfTen =numbers(8).map(x=>Math.pow(10, x));
  @ViewChildren('tokenholder') public tokenholders;
  constructor() { 
    
  }
  get numberOfHolders(){
    return this.tokenholders.length;
  }
  numbers(n){
    return numbers(n);
  }
  moveTokensUp(number, from){
    console.log(number, from.type);
    let i = Array.from(this.tokenholders).indexOf(from);
    if (i !== this.tokenholders.length - 1){
      from.removeTokens(number);
      Array.from(this.tokenholders)[i+1]['addTokens'](number/10);
      return true;
    }
  }
  moveTokensDown(number,from){
    let i = Array.from(this.tokenholders).indexOf(from);
    if (i !== 0){
      from.removeTokens(number);
      Array.from(this.tokenholders)[i-1]['addTokens'](number*10);
      return true;
    }
  }
  add(n, type){
    let i = (''+type).split('').filter(x=>x=='0').length
    return Array.from(this.tokenholders)[i]['addTokens'](n);
  }
  remove(n, type){
    let i = (''+type).split('').filter(x=>x=='0').length;
    console.log(i);
    return Array.from(this.tokenholders)[i]['removeTokens'](n);
  }
  ngAfterViewInit() {
    setTimeout(()=>{
      let l = this.tokenholders.length;
      Array.from(this.tokenholders)[l-1]['addTokens'](this.values[0]);
      Array.from(this.tokenholders)[l-2]['addTokens'](this.values[1]);
      Array.from(this.tokenholders)[l-3]['addTokens'](this.values[2])
    },0);
  }

}
