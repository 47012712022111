import { registerLocaleData } from '@angular/common';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { TaskComponent } from '../task/task.component';
registerLocaleData(localeDe, 'De', localeDeExtra)

@Component({
  selector: 'app-task-proportional-completion',
  templateUrl: './task-proportional-completion.component.html',
  styleUrls: ['./task-proportional-completion.component.css']
})
export class TaskProportionalCompletionComponent extends TaskComponent {
  public name = "TaskProportionalCompletion"
  @Input() task;
  @Output() solved: EventEmitter<any> = new EventEmitter();


  ngOnInit() {
  }
   
  reveal(){
    this.task.guessed = true;
    this.task.check();
/*    if (this.task.check()){
      this.solved.emit(true);
    }
  */
   }
  
  hu(e, vp){
    vp.guess = e.replace(/,/, '.');
  }

  next(){
    if (this.task.guessed) this.solved.emit(false);
  }


}
