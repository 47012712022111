import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-equation',
  templateUrl: './equation.component.html',
  styleUrls: ['./equation.component.css']
})
export class EquationComponent implements OnChanges{
  @Input() public data = {parts: [], task: undefined};
  makeClass(f){
    let result = {};
    result[f.type] = true;
    return result;
  }
  constructor() {   }

  ngOnChanges(){
    console.log(this)
  }
}
