import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-difficulty-and-options',
  templateUrl: './difficulty-and-options.component.html',
  styleUrls: ['./difficulty-and-options.component.css']
})
export class DifficultyAndOptionsComponent implements OnInit {
  @Output() public hasChanged = new EventEmitter();
  @Input() public taskType;
  @Input() public task;
  constructor() { }

  ngOnInit() {  }

}
