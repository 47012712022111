export class DBEventEmitter{
    public events = {};
    public memoed = {};

    memo(event){
        this.memoed[event] = [];
    }
    on(event, fn, id?, memo?){
        this.events[event] = this.events[event] ? this.events[event].concat([{fn: fn, id: id}]) : [{fn: fn, id: id}]; 
        while (memo && Array.isArray(this.memoed[event]) && this.memoed[event].length != 0){
            fn(this.memoed[event].shift());
        }
    }
      
    emit(event, x?){
        if (this.events[event]){this.events[event].forEach(f=>f.fn(x))}
        else {
            console.log("No one is listening to " + event)
            if (Array.isArray(this.memoed[event])){
                this.memoed[event].push(x || true);
            }
        }
    }
    off(event, id){
        if (this.events[event]){
            this.events[event] = this.events[event].filter(x=>x.id != id);
        }
    }
}