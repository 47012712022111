import { Task } from "../DBTask";
import { randomInt, randomBool, randomItem, equal } from '../general';
export class TaskNegativeNumbersWithoutParentheses extends Task{
    public name = "TaskNegativeNumbersWithoutParentheses";
    static options = {
        addition: {
            model: true,
            type: 'bool',
            label: 'Addition',
            disabled: false,
            relations: (o, v)=>{
                if (!v) {
                    o.subtraction.model = true;            
                    o.type.model = '';
                }
            }
        },
        subtraction : {
            model: true,
            type: 'bool',
            label: 'Subtraktion',
            disabled: false,
            relations: (o, v)=>{
                if (!v){
                     o.addition.model = true;
                     o.type.model = '';
                }            
            }
        },
        type : {
            hidden: false,
            model: '',
            type: 'string',
            label: 'Typ',
            disabled: true,
            relations: (o, v)=>{ 
                if (v.length){
                    if (/^\-+$/.test(v)) o.subtraction.model = true;
                    if (/^\++$/.test(v)) o.addition.model = true;
                    else {
                        o.subtraction.model = true;
                        o.addition.model = true;
                    }
                }
            }
        }
    }

    static difficulties = {
        1 : ()=>{ 
            let operands = [randomInt(0,100), randomInt(0,100)];
            TaskNegativeNumbersWithoutParentheses.options.type.model = randomItem(['-', '+']) + randomItem(['-', '+']);
            return new TaskNegativeNumbersWithoutParentheses(1, operands)
        },

    }
    
    public result;
    public guess;
    public description = `
    <p style="padding: 5px; background-color:lightpink; border-radius:5px;">
    Hier geht es <em>nicht</em> darum, die Aufgabe zu rechnen!</p>
    <p>Bitte schreibe die Aufgabe ohne Klammern und unnötige Vorzeichen. (siehe Hilfe/?)
    </p>
    `
    public helpHtml = `
    
    <ul>
        <li>+ + → +</li>
        <li>- - → +</li>
        <li>+ - → -</li>
        <li>- + → -</li>
    </ul>
    <p>
        Mit anderen Worten: 
    </p>
    <ul>
        <li>Zwei <em>gleiche</em> Vorzeichen hintereinander  → +</li>
        <li>Zwei <em>verschiedene</em> Vorzeichen hintereinander  → -</li>
    </ul>
    <details>
        <summary>Beispiel</summary>
        <div>
        (+3) - (-1) = 3 + 1
        </div>
    </details>

    `;
    public string;
    constructor(public difficulty:number, public operands, public operation?){
        super();
        if (TaskNegativeNumbersWithoutParentheses.options.subtraction.model){
            if (TaskNegativeNumbersWithoutParentheses.options.addition.model){
                this.operation = randomItem(['+', '-']);
            }
            else {
                this.operation = '-';
            }
        }
        else {
            this.operation = '+';
        }
        this.operands = this.operands.map((x,i)=>{
            if (!TaskNegativeNumbersWithoutParentheses.options.type.model[i]){
                return -1 * x;
            }
            else {
                return x * Number(TaskNegativeNumbersWithoutParentheses.options.type.model[i] + '1');
            }
        })
        this.string = this.operands.map(x=>(x!=0 ? '(' : '') +(x>0 ? '+' + x : x)+(x!=0 ? ')' : '') ).join(this.operation);
        this.result = this.string
            .replace(/\s/g, '')
            .replace(/\(/g, '')
            .replace(/\)/g, '')
            .replace(/\-\-/g, '\\+')
            .replace(/\-\+/g, '\\-')
            .replace(/\+\-/g, '\\-')
            .replace(/\+\+/g, '\\+')
            .replace(/^\+/, '\\+?')
    }
    get type(){
        return this.operands.map(x=>x<0 ? '-' : '+')
    }
    check(){
        console.log(this.result, this.guess)
        if (new RegExp(this.result).test(this.guess.replace(/\s/g, ''))){
            console.log("genau")
            this.emit('correct');  
        }
        else{
          console.log("leider nicht")
          this.emit('wrong');  
        }
    }
}