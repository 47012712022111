import { Task } from "../DBTask";
import { randomInt } from '../general';
import { DBPlaceValueRepresentation } from "../DBPlaceValueRepresentation";

export class TaskDecimalsComparisonInbetween extends Task{
    static difficulties = {
        1 : ()=>{
            let first = DBPlaceValueRepresentation.random(1,1,0,0);
            let second = DBPlaceValueRepresentation.random(1,1,0,0).differentFromAtPosition(first,0);
            return new TaskDecimalsComparisonInbetween(1, first.lessThan(second) ? first : second, first.lessThan(second) ? second : first);
        },
        2 : ()=>{
            let z = randomInt(1,10)
            let first = DBPlaceValueRepresentation.random(''+z,1,0,0);
            let second = DBPlaceValueRepresentation.random(''+z,1,0,0).differentFrom(first);
            return new TaskDecimalsComparisonInbetween(2, first.lessThan(second) ? first : second, first.lessThan(second) ? second : first);
        },
        3 : ()=>{
            let z = randomInt(1,10)
            let first = DBPlaceValueRepresentation.random(''+z,1,0,0);
            let second = first.glueDigitsBehind(''+randomInt(1,10));
            return new TaskDecimalsComparisonInbetween(3, first.lessThan(second) ? first : second, first.lessThan(second) ? second : first);
        },
        4 : ()=>{
            let z = randomInt(1,10)
            let first = DBPlaceValueRepresentation.random(''+z,1,0,0);
            let second = first.glueDigitsBehind(randomInt(1,10)+''+randomInt(1,10));
            return new TaskDecimalsComparisonInbetween(4, first.lessThan(second) ? first : second, first.lessThan(second) ? second : first);
        },
        5 : ()=>{
            let z = randomInt(1,10)
            let first = DBPlaceValueRepresentation.random(''+z,1,0,0);
            let second = first.glueDigitsBehind(randomInt(0,10)+''+randomInt(0,10)+''+randomInt(1,10));
            return new TaskDecimalsComparisonInbetween(5, first.lessThan(second) ? first : second, first.lessThan(second) ? second : first);
        },
        6 : ()=>{
            let z = randomInt(100,1000)
            let first = DBPlaceValueRepresentation.random(''+z,randomInt(2,4),0,0);
            let second = first.glueDigitsBehind(randomInt(0,10)+''+randomInt(0,10)+''+randomInt(1,10));
            return new TaskDecimalsComparisonInbetween(6, first.lessThan(second) ? first : second, first.lessThan(second) ? second : first);
        },
    }
    public result;
    public guess;
    constructor(public difficulty, public first, public second){
        super();
    }
    check(){
        let g = DBPlaceValueRepresentation.fromNumber(this.guess.replace(/\,/, '.'));
        if ( g.lessThan(this.second) && this.first.lessThan(g)){
            console.log("genau")
            this.emit('correct');  
        }

        else {
          console.log("leider nicht")
          this.emit('wrong');  
        }
    }
}