import { Directive } from '@angular/core';

@Directive({
  selector: '[appDragHandle]'
})
export class DragHandleDirective {

  constructor() { }

}
