import { Component, OnChanges, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { numbers, numberSort } from '../../general';

@Component({
  selector: 'app-coordinate-system',
  templateUrl: './coordinate-system.component.html',
  styleUrls: ['./coordinate-system.component.css']
})
export class CoordinateSystemComponent implements OnInit, OnChanges {
  @Input() public vsize = 36;
  @Input() public hsize = 36;
  @Input() public showLines = false;
  @Input() public points = [{x: 4, y: 10, name: 'Palme'}];//[{x:0,y:0, name: 'A'}, {x:3, y: 2, name: 'B'}];
  @Input() public lines = [{points: [{x: 3, y: 1}, {x: 3, y: 2}], name: 'test', color: 'blue'} ];//[{x:0,y:0, name: 'A'}, {x:3, y: 2, name: 'B'}];
  
  @Input() public showLatticePoints = true;

  @Output() public selected = new EventEmitter();
  public vtickSize = 1;
  public htickSize = 1;
  public hlines;
  public vlines;
  public hmarkings;
  public vmarkings;
  public hticks;
  public vticks;
  public latticePoints;
  public selection = [];
  public displayPoints;
  public displayLines;
  public selectionRadius;

  constructor() { }
  
  toDisplay(p){
     return {
      x: 100 + p.x * (300/(this.hsize)),
      y: 300 - p.y * (300/(this.vsize)),
      name: p.name
    }
  }

  fromDisplay(p){
    return {
      x: (p.x - 100)/(300/this.hsize),
      y: -1*((p.y + 300)/(300/this.vsize)-this.vsize*2),
      name: p.name
    }
  }
  select(point, logicalPoint?){
    let pt = logicalPoint ? point : this.fromDisplay(point);
    let t = this.selection.map(p=>p.x==pt.x && p.y == pt.y);
    let i = t.indexOf(true);
    if (i!=-1){
      //wenn schon drin, dann wieder raus
      this.selection.splice(i,1);
      this.selected.emit({selection: this.selection, point: pt, index: i});
      console.log('gelöscht');
    } else {
      this.selection.push(pt);
      this.selected.emit({selection: this.selection, point: pt });
      console.log('hinzu');
    }
  }
  ngOnChanges(){
    this.init();
  }
  ngOnInit(){
    this.init();
  }
  init() {
      this.selectionRadius = (300/Math.max(this.vsize, this.hsize))/2;
      this.latticePoints = numbers((this.vsize)).map(p =>{
        return {
          y: p,
          x: 1
        }
      })
      .map(p =>{
        return numbers((this.hsize)).map(h=>{
          return {
            y: p.y,
            x: h
          }
        })
      })
      .reduce((a,b)=>a.concat(b), [])
      .map(p=>{
        return {
          x: 100 + p.x * (300/(this.hsize)),
          y: 300 - p.y * (300/(this.vsize))
        }
      })
  ;


    this.hmarkings = numbers(this.hsize)
    //.filter(x=> x%2 !== 1)
    .map(x=>{
      return {
        x1: 100 + x * (300/(this.hsize)),
        x2: 100 + x * (300/(this.hsize)),
        y1: 310,
        y2: 300
      }
    })
    .slice(0, (this.hsize % 2) !== 0 ? -1 : this.hsize+1)
    .slice(0,-1)


    this.hticks = this.hmarkings
    .map((x,i) => {
      return {
        text : (i * this.htickSize),
        x: x.x1
      }
    })

    this.vmarkings = numbers(this.vsize+1)
    /*.filter((x,i)=>{
      if ((this.hsize) % 2 !== 0) return i%2!==0
      if ((this.hsize) % 2 === 0) return i%2===0
    })*/
    .map(x=>{
      return {
        x1: 90,
        x2: 100,
        y1: x *(300/(this.vsize)),
        y2: x *(300/(this.vsize))
      }
    })

    this.vticks = this.vmarkings
    .slice((this.hsize-1) % 2 === 0 ? 0 : 1)
    .map((x,i) => {
      return {
        text : i  * this.vtickSize,
        y: this.vmarkings[this.vmarkings.length - (i+1)].y1
      }
    })
    .slice(0,-1)

    this.vmarkings = this.vmarkings.slice((this.hsize-1) % 2 === 0 ? 0 : 1)
    .slice(1)

    if (this.showLines){
      this.hlines = numbers(this.hsize+1)
      .map(x=>{
        return {
          x1: 0,
          x2: 300,
          y1: x *(300/(this.hsize)),
          y2: x *(300/(this.hsize))
        }
      })
  
      this.vlines = numbers(this.vsize+1)
      .map(x=>{
      return {
        x1: x * (300/(this.hsize)),
        x2: x *(300/(this.hsize)),
        y1: 0,
        y2: 300
        }
      })
    }
    
    let ptp = p=>{
      return {
        x: 100 + p.x * (300/(this.hsize)),
        y: 300 - p.y * (300/(this.vsize)),
        name: p.name
      }
    }

    this.displayLines = this.lines
    .map(l=>{
      return {
        points : l.points.map(ptp),
        name: l.name,
        color: l.color
      }
    })


    this.displayPoints = this.points
    .map(ptp)
  }

}
