import { Component, OnChanges } from '@angular/core';
import { TaskComponent } from '../task/task.component';
import { Equation } from '../../DBEquation';

@Component({
  selector: 'app-task-negative-addition',
  templateUrl: './task-negative-addition.component.html',
  styleUrls: ['./task-negative-addition.component.css']
})
export class TaskNegativeAdditionComponent extends TaskComponent implements OnChanges{
  public name = "TaskNegativeAddition";
  public equation;
  ngOnChanges() {
    this.equation = new Equation(this.task.operands.map(x=> x<0 ? '('+x+')' : String(x)).join(this.task.operation) + '=§number[guess]', this.task)
  }

}
