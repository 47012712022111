import { Component, OnInit, Input } from '@angular/core';
import { numbers } from '../../general';
import { TaskDivision } from '../../Task-Models/model-task-division';

@Component({
  selector: 'app-exercise-papers',
  templateUrl: './exercise-papers.component.html',
  styleUrls: ['./exercise-papers.component.css']
})
export class ExercisePapersComponent implements OnInit {
  @Input() public number;
  public tasks = [];

  constructor() { }

  ngOnInit() {
    for (let i =0; i<this.number; i++){
      this.tasks.push({
        number:i, 
        content: numbers(30)
          .map(x=>new TaskDivision()).sort((a,b)=>{
        if (a.difficulty > b.difficulty) return 1;
        if (a.difficulty < b.difficulty) return -1;
        return 0;      
      })})
    }
  }

}
