import { Component, OnInit } from '@angular/core';
import { TaskComponent } from '../task/task.component';
import { Equation } from 'src/app/DBEquation';

@Component({
  selector: 'app-task-fraction-to-mixed-number',
  templateUrl: './task-fraction-to-mixed-number.component.html',
  styleUrls: ['./task-fraction-to-mixed-number.component.css']
})
export class TaskFractionToMixedNumberComponent extends TaskComponent {
  public equation;
  ngOnChanges() {
    this.equation = new Equation(this.task.fraction + '=§mixedNumber[guess]', this.task)
  }

  ngOnInit() {
  }

}
