import { Component, OnInit } from '@angular/core';
import { TaskComponent } from '../task/task.component';

@Component({
  selector: 'app-task-decimals-numberline-find',
  templateUrl: './task-decimals-numberline-find.component.html',
  styleUrls: ['./task-decimals-numberline-find.component.css']
})
export class TaskDecimalsNumberlineFindComponent extends TaskComponent {


}
