import { Component, OnInit } from '@angular/core';
import { TaskComponent } from '../task/task.component';

@Component({
  selector: 'app-task-equations-graphs',
  templateUrl: './task-equations-graphs.component.html',
  styleUrls: ['./task-equations-graphs.component.css']
})
export class TaskEquationsGraphsComponent extends TaskComponent {
  public point1;
  public point2;

  modelChanged(e){
    if (/\s*((\(\s*(.+?)\s*\|\s*(.+?)\s*\))(\s*,\s*))*?/.test(e)){
      /\s*((\(\s*(.+?)\s*\|\s*(.+?)\s*\))(\s*,\s*))*?/.test(e)
    }
    this.task.lines
  }
}
