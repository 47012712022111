import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-logic-gate',
  templateUrl: './logic-gate.component.html',
  styleUrls: ['./logic-gate.component.css']
})
export class LogicGateComponent implements OnInit {
  @Input()  public gate;

  constructor() { }

  ngOnInit() {
  }

}
