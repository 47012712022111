import { Component, OnInit } from '@angular/core';
import { TaskComponent } from '../task/task.component';
import { Equation } from 'src/app/DBEquation';

@Component({
  selector: 'app-task-decimal-comparison',
  templateUrl: './task-decimal-comparison.component.html',
  styleUrls: ['./task-decimal-comparison.component.css']
})
export class TaskDecimalComparisonComponent extends TaskComponent {
  public equation;
  
  ngOnChanges() {
    this.equation = new Equation(this.task.first.string.replace(/\./, ',') + '§comparison[guess]' + this.task.second.string.replace(/\./, ','), this.task);
  }
}
