import { Task } from "../DBTask";
import { randomItem, equal, randomIndex, randomInt, numbers, shakeTo } from '../general';
import { shuffle } from 'lodash';
import { Fraction } from "../Fraction";

export class TaskFractionExpansionFactor extends Task{
    public name = "TaskFractionExpansionFactor";
    static difficulties = {
        1 : ()=>{
            return new TaskFractionExpansionFactor(1, Fraction.true(), randomInt(2,6));
        },
        2 : ()=>{
            return new TaskFractionExpansionFactor(2, Fraction.true(3,20), randomInt(6,20));
        },    
        3 : ()=>{
            return new TaskFractionExpansionFactor(3, Fraction.true(3,20), randomInt(20,100));
        },   
    }
    
    public result;
    public guess;
    public expanded;
    constructor(public difficulty, public fraction, public factor){
        super();
        this.expanded = this.fraction.expandBy(factor);
        this.result = this.factor;
    }
    check(){
        console.log(this.result, this.guess)
        if (this.result == this.guess){
            console.log("genau")
            this.emit('correct');  
        }

        else{
          console.log("leider nicht")
          this.emit('wrong');  
        }
    }
}