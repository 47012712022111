import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-task-term-simplification',
  templateUrl: './task-term-simplification.component.html',
  styleUrls: ['./task-term-simplification.component.css']
})
export class TaskTermSimplificationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
