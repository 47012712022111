import { shakeTo, randomBool, numberSort, PRIMES, randomIndex, randomItems, randomItem, digitsum, without, numbers, randomInt } from '../general';
import { Task } from '../DBTask';
import { Output, EventEmitter } from '@angular/core';

export class TaskProportionalCompletion extends Task  {
    static maxDifficulty = 4;
    public solved: EventEmitter<any> = new EventEmitter();

    public name ="TaskProportionalCompletion";
    public valuepairs = [];
    public propFactor;
    public guessed;
    public missing = [];
    static withDifficulty(d){
        return new TaskProportionalCompletion(d);
    }
    constructor(public difficulty){
        super();
        let input = [];
        let multiplier = 1;
        let missN = 1;
        let xMissingLikelihood = -1;
        if (this.difficulty === 1){
            input = [0,1,2,4,8];
            this.propFactor = Math.pow(10, randomInt(1,5));
        }
        else if (this.difficulty === 2){
            input = [1,2,4,8,16];
            this.propFactor = randomInt(1,10);
        }
        else if (this.difficulty === 3){
            input = shakeTo(5,numbers(10));
            this.propFactor = randomInt(1,10);
        }
        else if (this.difficulty === 4){
            input = shakeTo(5,numbers(10));
            this.propFactor = randomInt(1,10);
            multiplier = randomInt(2,5);
            missN = 2;
        }
        else if (this.difficulty ===5){
            input = shakeTo(5,numbers(10));
            this.propFactor = randomInt(10,15);
            multiplier = randomInt(2,5);
            missN = 3;
            xMissingLikelihood = 0.5;
        }
        else if (this.difficulty >=6){
            input = shakeTo(5,numbers(10));
            this.propFactor = randomInt(10,15)+(Math.random()<.5 ? 0.5 : 0);
            multiplier = randomInt(2,5)+(Math.random()<.5 ? 0.5 : 0);
            missN = 3;
            xMissingLikelihood = 0.5;
        }
        this.valuepairs = shakeTo(5, input)
            .map(x => x * multiplier)
            .map(x => { return {x: x, y: x * this.propFactor} })


        while (missN > 0){
            let j = randomItem(this.valuepairs.filter(x=>!x.missingX && !x.missingY));
            if (Math.random() < xMissingLikelihood){
                j.missingX = true;
            }
            else {
                j.missingY = true;
            }
            missN--;
            this.missing.push(j);
        } 
    }
    
    guess(x, y){
        this.missing.filter(v => v.x === x)[0].guess = y;
    }

    check(){
        console.log(this.missing)
        this.missing
            .filter(v=>v.guess)
            .filter(v=> {
                if(v.missingX) return +v.guess === v.x;
                if(v.missingY) return +v.guess === v.y;                                    
            })
            .forEach(v=> {
                v.correct = true;
            });

        this.missing
            .filter(v=>v.guess)
            .filter(v=> {
                if(v.missingX) return +v.guess !== v.x;
                if(v.missingY) return +v.guess !== v.y;                                    
            })
            .forEach(v=> v.correct = false);

        if (!this.missing.filter(v=>!v.correct).length){
            console.log('RICHTIG')
            this.emit('correct');
            return true;
        }
        else{
            this.emit('wrong');

        }
    
    }
}