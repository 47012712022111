import { Task } from "../DBTask";
import { Point } from "../general"
import { randomInt } from '../general'
export class TaskMarkPoint extends Task{
    public name = "TaskMarkPoint";
    
    static difficulties = {
        1: ()=>{
            let a1 = randomInt(0,11);
            let a2 = randomInt(0,11);
            let b1 = randomInt(0,11);
            let b2 = randomInt(0,11);
            b1 = b1 == a1 ? (a1 + 1)%12 : b1;
            return new TaskMarkPoint(1, [new Point(a1,a2, 'A'), new Point(b1,b2, 'B')], true);
        }
    }

    public guess = {};

    constructor(public difficulty, public points, public mark){
        super();
        if (mark){
            points.forEach((x)=>{
            this.guess[x.name] = {x: undefined, y: undefined}
        })
        } else {
            this.guess = [];
        }
    }

    check(){
        if (this.mark &&  this.guess['length'] == this.points.length && this.points.every(p=>this.guess['filter'](x=>p.x == x.x && p.y == x.y).length)){
            console.log("genau")
            this.emit('correct');  
        }
        else{
            console.log("leider nicht");
            this.emit('wrong');  
        }
    }
}