import { PRIMES, randomIndex, randomInt, randomItem, digitsum, without, numbers, randomBool } from '../general';
import { Task } from '../DBTask';
import { shallowCopy } from '../general';

export class TaskCommaSliding extends Task{
    public name ="TaskCommaSliding";
    static difficulties = {
        1 : ()=>{
            return new TaskCommaSliding(1 ,'*' ,randomInt(1,10),1);
        },
        2 : ()=>{
            return new TaskCommaSliding(2 ,randomItem(['*', ':']) ,randomInt(1,10),1);
        },
        3 : ()=>{
            return new TaskCommaSliding(3 ,randomItem(['*', ':']) ,randomInt(1,10) * randomItem([10, 100, 1000]),randomInt(2,4));
        },
        4 : ()=>{
            return new TaskCommaSliding(4 ,randomItem(['*', ':']) ,randomInt(1,10) * randomInt(100,1000),randomInt(2,10));
        }
    }
    
    public guess;
    public digitsArray;
    constructor(public difficulty, public operation, public number, public powerOfTen){
        super();
        this.digitsArray = String(this.number).split('');
        this.guess = this.digitsArray.indexOf('.') != -1
            ? this.digitsArray.indexOf('.')+1 
            : this.digitsArray.length-1;
        this.digitsArray = this.digitsArray.filter(x=>x!='.');
    }

    check(){
        let t = shallowCopy(this.digitsArray);
        t.splice(Number(this.guess)+1,0,'.');
        t = Number(t.join('').replace(/^0*/, ''));
        let p = Math.pow(10,this.powerOfTen);
        let result =(this.operation === '*' ? this.number * p : this.number/p);
        if ((this.operation === '*' ? this.number * p : this.number/p) == t){
            console.log("genau")
            this.emit('correct');  
        }
        else{
            console.log("leider nicht");
            this.emit('wrong');  
        }
    }
}