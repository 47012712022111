import { PRIMES, randomIndex, randomInt, randomItems, digitsum, without, numbers, randomBool } from '../general';
import { Task } from '../DBTask';

export class TaskMentalArithmetic extends Task{
    static maxDifficulty = 4;
    
    public name ="TaskMentalArithmetic";
    public difficulty;
    public flipped;
    public factors;
    public result;
    public guess;
    public divident;
    public divisor;

    static withDifficulty( difficulty){
        if (randomBool()){
            let divident;
            let divisor;
            switch (difficulty){
                case 1: {
                    divisor = randomInt(2,11);
                    divident = randomInt(2,11) * divisor;
                    break;
                }
                case 2: {
                    divisor = randomInt(2,11);
                    divident = randomInt(10,20) * divisor;
                    break;
                }
                case 3: {
                    divisor = randomInt(2,11);
                    divident = randomInt(10,30) * divisor;
                    break;
                }
                case 4: {
                    divisor = randomInt(2,11);
                    divident = randomInt(10,40) * divisor;
                    break;
                }
            }
            let result = new TaskMentalArithmetic(divident, divisor, randomBool());
            result.difficulty = difficulty;
            return result;
        }
        else {
            let factors;
            switch (difficulty){
                case 1: {
                    factors = randomItems(2, numbers(10).slice(2));
                    break;
                }
                case 2: {
                    factors = [randomItems(1, numbers(10)), randomItems(1, numbers(20).slice(10))];
                    break;
                }
                case 3: {
                    factors = randomItems(3, numbers(10).slice(2));
                    break;
                }
                case 4: {
                    factors = randomItems(3, numbers(10).slice(2))
                        .concat([randomItems(1, [2,3,4])]);
                    break;
                }
            }
            let result = new TaskMentalArithmetic(factors)
            result.difficulty = difficulty;
            return result;
        }
    }

constructor(divid, divis?, flipped?){
    super();
    this.flipped = flipped;
    if (typeof divid === 'number'){
        this.divident = divid;
        this.divisor = divis;
        this.result = this.divident/this.divisor;
    } else {
        this.factors = divid;
        this.result = this.factors.reduce((a,b)=>a*b);
    }

}
    check(){
        if (+this.guess === this.result){
            this.emit('correct');
        } else {
            this.emit('wrong');
        }
    }
}