import { Component, OnInit } from '@angular/core';
import { TaskComponent } from '../task/task.component';
import { DBPlaceValueRepresentation } from 'src/app/DBPlaceValueRepresentation';

@Component({
  selector: 'app-task-decimal-name-places',
  templateUrl: './task-decimal-name-places.component.html',
  styleUrls: ['./task-decimal-name-places.component.css']
})
export class TaskDecimalNamePlacesComponent extends TaskComponent {
  public position;
  public selected;
  ngOnChanges(){
    this.position = DBPlaceValueRepresentation.getPositionName(this.task.position)
  }
  select(i){
    this.task.guess = i>=0 ? this.task.number.digitsLeft.length-1-i : i;
    this.selected = i;
  }
}
