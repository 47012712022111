import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-number-place-value-board',
  templateUrl: './number-place-value-board.component.html',
  styleUrls: ['./number-place-value-board.component.css']
})
export class NumberPlaceValueBoardComponent implements OnInit {
  public digits = [];
  constructor() { }
  
  addLeft(){

  }
  addRight(){

  }
  ngOnInit() {
  }

}
