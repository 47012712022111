import { Component, OnInit } from '@angular/core';

import { SetFunctionsService } from '../../../Services/set-functions.service';
declare let _;
@Component({
  selector: 'dhb-function-set-game',
  templateUrl: './function-set-game.component.html',
  styleUrls: ['./function-set-game.component.css'],
  providers: [ SetFunctionsService ]
})
export class FunctionSetGameComponent implements OnInit {
  public functions;
  public selectedCards = [];
  public capturedCards = [];
  public numberOfDifferentKindsOfRepresentations;
  public kindsOfRepresentation = [
    {
      name: "Wertetabelle",
      number: 1,
      selected: false
    },
    {
      name: "Funktionsvorschrift",
      number: 0,
      selected: false
    },
    {
      name: "Graph (blau)",
      number: 3,
      selected: true
    },
    {
      name: "Funktionsbeschreibung",
      number: 2,
      selected: false,
    },
    {
      name: "Ableitungsgraph (rot)",
      number: 4,
      selected: true
    },
  ]

  constructor(private fncs : SetFunctionsService) {
    this.updateTable();  
  }

  updateTable(){
    let sel = this.kindsOfRepresentation.filter(x => x.selected).map(x => x.number);
    this.numberOfDifferentKindsOfRepresentations = sel.length;
    this.functions =  _.shuffle(_.range(this.fncs.functions.length * this.kindsOfRepresentation.length)).map((x)=>{return {f: this.fncs.functions[(x/this.kindsOfRepresentation.length) | 0], n: x % this.kindsOfRepresentation.length}});
    this.functions = this.functions.filter(x => sel.indexOf(x.n) !== -1 );
  }

  equivalentFunction(x){
    let p = x.filter(t => t.n !== 4 && t.n !== 1); //funktionsbeschreibung, funktionsvorschrift und funktionsgraph
    let cp = x.filter(t => t.n === 4 || t.n === 1); //ableitungsgraph und Wertetabelle
    let p_t = true;
    let cp_t = true;
    let pivot = undefined;
    if (p.length){
      if (!p.every((t => t.f === p[0].f))){
        p_t = false;
      } else {
        pivot = p[0].f;
      }
    }
    if (cp.length){
      if (pivot){
        cp_t = cp.every(t => {
          if (t.n == 4){ 
            return t.f.derivative.toString() === pivot.derivative.toString() 
          } else {
            return [-3, -1, 0, 1, 3].map(pivot.fnc).toString() == [-3, -1, 0, 1, 3].map(t.f.fnc).toString()
          }
        })
      } else {
        cp_t = [-3, -1, 0, 1, 3].map(cp[0].f.fnc).toString() == [-3, -1, 0, 1, 3].map(cp[1].f.fnc).toString() || cp[0].f.derivative.toString() == cp[1].f.derivative.toString() 
      }
    }
    return p_t && cp_t;  
}

  checkCards(x) {
        if (this.equivalentFunction(x)){
            this.correctSet(x);
        } else {
            this.incorrectSet(x);
        }
  }

  correctSet (data) {
    data.forEach(function(x){
            x.captured = true;
            x.selected = false;
    });  
    this.selectedCards = [];
    this.capturedCards = this.capturedCards.concat(data);
  }
  
  incorrectSet(data) {
    this.selectedCards = [];
    data.forEach(function(x){
      x.selected = false;
    });
  }

  selectCard(x) {
    if (!x.selected && !x.captured
        && this.selectedCards.length < this.numberOfDifferentKindsOfRepresentations
        && this.selectedCards.every(function(y){return y.n !== x.n})){
        x.selected = true;
        this.selectedCards.push(x);
    } else if(this.selectedCards.indexOf(x) !== -1){
        x.selected = false;
        this.selectedCards.splice(this.selectedCards.indexOf(x),1)
    }
    if (this.selectedCards.length === this.numberOfDifferentKindsOfRepresentations){
      this.checkCards(this.selectedCards);
    }
  }

  ngOnInit() { }

}