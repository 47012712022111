import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TaskRegistryService } from './task-registry.service';
import { FeedbacksService } from './feedbacks.service';
import { RewardPicsService } from './reward-pics.service';
import { DBEventEmitter } from '../DBEventEmitter';
import { MatSnackBar } from '@angular/material';
import { numbers } from '../general';

@Injectable({
  providedIn: 'root'
})
export class TutorServiceService extends DBEventEmitter{
  public showTask = true;
  public feedback = "";
  public rewardpic;
  public task : any = {helpHtml: undefined};
  public taskDataContainer = {_type: {}, type: {options: undefined, difficulties: {}, withDifficulty: (x)=>x,}, difficulty: undefined, history: [], maxDifficulty: undefined };
  public showStats = false;
  public stats;

  constructor(public sb : MatSnackBar, public rp : RewardPicsService, public tr : TaskRegistryService, public fb : FeedbacksService, public ar : ActivatedRoute) { 
    super();
    this.stats = JSON.parse(window.localStorage.getItem('stats')) || {all: 0, detail: []};
    this.memo('queryParamsChanged');
    this.ar.queryParams.subscribe( x => this.emit('queryParamsChanged', x))
  }

  clearStats(){
    window.localStorage.clear();
    this.stats = {all: 0, detail: []};
  }
  setTask(askedByComponent?){
    if (this.taskDataContainer._type){
      this.task = (this.taskDataContainer.type.withDifficulty || this.taskDataContainer.type.difficulties[this.taskDataContainer.difficulty])(this.taskDataContainer.difficulty)
      this.task.posedAt = new Date();
      this.task.on('correct', ()=>this.guessed(true));
      this.task.on('wrong', ()=>this.guessed(false));
      this.showTask = true;
      if (!askedByComponent) this.emit('newTask', this.task);
      return this.task;
    } else {
      this.task = {helpHtml: undefined};
      this.showTask = true;
    }
  }

  next(){
    if (!this.showTask){
      this.setTask();
    }
  }

  guessed(correct){
    if (correct){

      this.sb.dismiss();
      this.task.correct = true;
      this.rewardpic = this.rp.random();
      this.feedback = this.fb.good;
      this.showTask = false;
      this.task.solvedAt = new Date();
      this.taskDataContainer.history.push(this.task); 
      this.stats = JSON.parse(window.localStorage.getItem('stats')) || {all: 0, detail: []};
      let t = this.stats.detail.filter(x=>x.task == this.taskDataContainer['displayName']);
      if (t.length){
        let tt = t[0].solved.filter(y=>y.difficulty == this.task.difficulty)
        if(tt.length){
          let ttt = tt[0];
          ttt.number += 1;
        }
        else{
          if (t[0].solved.length && (t[0].solved[0].difficulty > this.task.difficulty)){
            t[0].solved.unshift({difficulty: this.task.difficulty, number: 1});;
          } 
          else{
            t[0].solved.push({difficulty: this.task.difficulty, number: 1});
          }
        }
      }
      else {
        this.stats.detail.push({task: this.taskDataContainer['displayName'], solved: [{difficulty: this.task.difficulty, number: 1}]})
      }
      this.stats.all += 1;
      window.localStorage.setItem('stats', JSON.stringify(this.stats))
    }
    
    else{
      this.sb.dismiss();
      this.task.correct = false;
      this.feedback = this.fb.bad;
      this.rewardpic = "";
      this.sb.open(this.feedback);
      setTimeout(()=>this.sb.dismiss(), 2000);
    }
  }
}