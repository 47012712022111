import { Operation } from './Operation';
import { Fraction } from '../Fraction';

export class Power extends Operation{
    static rex: any =  /^\^$/;

    constructor(public operands, public id){
        super(id);
    }
    /*get value(){
        return this.summands.reduce((a,b)=> a?.getValue + b?.getValue);
    }*/

    get nameOfResult(){
        return new Noun(Genera.MASCULINE, 'Potenzwert', 'Potenzwerte', 'Potenzwerts');
    }
    get nameOfProcess(){
        return new Noun(Genera.FEMININE, 'Potenz', 'Potenzen', 'Potenz');
    }
    get verb(){
        return new Verb('');
    }
    get namesOfOperands(){
        return [
            new Noun(Genera.FEMININE, 'Basis', 'Basen', 'Basis'),
            new Noun(Genera.MASCULINE, 'Exponent', 'Exponenten', 'Exponenten'),
        ];
    }
    get angularString(){
        return super.angularString.replace(/CHILD_CLASS_CONTENT/, 
            this.operands.map(x=>x.angularString).join(`operator(^)[${this.id}]`))
    }
    get value(){
        let r = this.operands[0].value.power(this.operands[1].value);
        //console.log(r);
        r.sign = Fraction.computeSign(this, r);
        return r;

    }
    string(){
        return super.string(this.operands.map(x=>x.string()).join('^'));
    }
}