import { Task } from "../DBTask";
import { without, equal, randomItem, randomInt } from '../general';
import { list as prices} from '../Data/Fruitprices';
import { list as sugars} from '../Data/SugarPercentage';

export class TaskPercentCalculations extends Task{
    static maxDifficulty = 6;
    public name ="TaskPercentCalculations";



    static options = {
        percentageValueWanted: {
            model: true,
            type: 'bool',
            label: 'Prozentwert berechnen',
            disabled: false,
            relations: (o, v)=>{
                if (!v) o.percentageWanted.model = true;            
            }
        },
        percentageWanted : {
            model: false,
            type: 'bool',
            label: 'Prozentsatz berechnen',
            disabled: false,
            relations: (o, v)=>{
                if (!v) o.percentageValueWanted.model = true;            
            }
        }
    }
    static withDifficulty(difficulty){
        let p;
        let b;
        let unit;
        let text;
        let items = []
        if (this.options.percentageValueWanted.model) items.push('percentageValue');
        if (this.options.percentageWanted.model) items.push('percentage');
        let wanted = randomItem(items);
        let t = (wanted === 'percentage') 
                ? randomItem(sugars)
                : undefined;
        unit = ' g';
        switch (difficulty){
            case 1: {
                t = t || randomItem(sugars.filter(x=>(Math.ceil(+x.percentage)) === 1));
                p = Math.ceil(+t.percentage) ; //Prozentsatz
                b = randomInt(1,20)*(100); //Grundwert
                break;
            }
            case 2:{
                b = randomInt(3,20)*(100);
                t = t || randomItem(sugars.filter(x=>(Math.ceil(+x.percentage)) === 2));
                p = Math.ceil(+t.percentage) ;
                break;
            }
            case 3:{
                t = t || randomItem(sugars.filter(x=>(100 % Math.ceil(+x.percentage)) === 0));
                p = Math.ceil(+t.percentage) ;
                b = randomInt(3,20)*(100);
                unit = ' g';
                break;
            }
            case 4:{
                t = t || randomItem(sugars.filter(x=> Math.ceil(+x.percentage) != 1 && Math.ceil(+x.percentage) != 2));
                p = Math.ceil(+t.percentage) ;
                b = randomInt(3,20)*(100);
                break;
            }
            case 5:{
                t = t || randomItem(sugars.filter(x=> Math.ceil(+x.percentage) != 1 && Math.ceil(+x.percentage) != 2));
                p = +t.percentage;
                b = randomInt(3,20)*(100);
                break;
            }
            case 6:{
                t = t || randomItem(sugars.filter(x=> Math.ceil(+x.percentage) != 1 && Math.ceil(+x.percentage) != 2));
                p = +t.percentage;
                b = randomInt(3,20)*(10);
                break;
            }
        }

        text = (wanted === 'percentageValue') 
                ? t.item + ': ' + p + '% Zuckergehalt.<br>Wie viel Gramm Zucker enthalten ' + b + ' g ' + t.item + '?'
                :(wanted === 'percentage')
                ? b + 'g ' + t.item + ' enthalten ' + (b * (+t.percentage/100)).toFixed(0) + 'g Zucker.<br>Wie viel Prozent Zucker enthält ' + t.item + '?'
                : '';
        let pair = (wanted === 'percentageValue') 
                ? {percentage: p, baseValue: b}
                :(wanted === 'percentage') 
                ? {baseValue: b, percentageValue: +(b * (+t.percentage/100)).toFixed(0), }
                : {};
                console.log(pair)
                console.log(b);
        let result = new TaskPercentCalculations( pair, unit, text );
        result.difficulty = difficulty;
        return result;

    }
        
    public helpHtml = `
    <table>
        <tr style="margin: 20px">
        <td>Prozentwert</td> <td>=</td> <td>(</td> <td>Grundwert</td> <td>: 100 ) · </td><td>Prozentsatz</td></tr>
        <tr style="margin: 20px; margin-bottom: 40px;">
        <td style="text-align: center;">W</td> <td>=</td> <td>(</td> <td style="text-align: center;">G</td> <td>: 100 ) · </td><td>p</td>
        </tr>
        <tr style="height: 40px;"></tr>
        <tr style="margin: 20px;">
        <td>Prozentsatz</td> <td>=</td> <td>100·</td><td style="text-align: center;">Prozentwert</td><td style="text-align: center;">:</td><td style="text-align: left;">Grundwert</td></tr>
        <tr style="margin: 20px">
        <td style="text-align: center;">p</td> <td>=</td> <td>100·</td><td style="text-align: center;">W</td> <td style="text-align: center;">:</td><td>G</td>
        </tr>
    </table>
    `;
    
    public difficulty;
    public percentage;
    public percentageValue;
    public baseValue;
    public given;
    public wanted;
    public result;
    public unit;
    public text;
    public guess;

    constructor(pairObject, unit?, text?){
        super();
        this.text = text;
        this.unit = unit;
        this.given = Object.keys(pairObject);
        this.wanted = without(['percentage', 'baseValue', 'percentageValue'], this.given)[0];
        this.percentage = pairObject.percentage ? pairObject.percentage : 100*pairObject.percentageValue/pairObject.baseValue;
        this.baseValue = pairObject.baseValue ? pairObject.baseValue : (pairObject.percentageValue/pairObject.percentage)*100;
        this.percentageValue = pairObject.percentageValue ? pairObject.percentageValue : pairObject.baseValue*(pairObject.percentage/100);
        this.result = this[this.wanted];

    }

    check(){
        console.log(this.guess, this.result)
        if (equal(+this.guess.replace(/,/, '.'), this.result, 0.1)){
          console.log("genau")
          this.emit('correct');
        }
        else{
          console.log("leider nicht")
          this.emit('wrong');  
        }
      }
}