import { Component, OnInit } from '@angular/core';
import { TaskComponent } from '../task/task.component';
import { DBPlaceValueRepresentation } from 'src/app/DBPlaceValueRepresentation';

@Component({
  selector: 'app-task-decimal-round',
  templateUrl: './task-decimal-round.component.html',
  styleUrls: ['./task-decimal-round.component.css']
})
export class TaskDecimalRoundComponent extends TaskComponent {
  public positionName = DBPlaceValueRepresentation.getPositionName;

}
