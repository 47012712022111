import { Component, OnChanges } from '@angular/core';
import { TaskComponent } from '../task/task.component';
import { Equation } from '../../DBEquation';
@Component({
  selector: 'app-task-fraction-expansion-factor',
  templateUrl: './task-fraction-expansion-factor.component.html',
  styleUrls: ['./task-fraction-expansion-factor.component.css']
})
export class TaskFractionExpansionFactorComponent extends TaskComponent implements OnChanges {
  public name = "TaskFractionExpansionFactor"
  public equation1;
  public equation2;


  ngOnChanges(){
    this.equation1 = new Equation(this.task.fraction + '=' + this.task.expanded, this.task)
    this.equation2 = new Equation('§number[guess]', this.task);
  }
}
