import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { TaskRegistryService } from 'src/app/Services/task-registry.service';
import { cloneDeep } from 'lodash';
@Component({
  selector: 'app-worksheet-generator-configuration-item',
  templateUrl: './worksheet-generator-configuration-item.component.html',
  styleUrls: ['./worksheet-generator-configuration-item.component.css']
})
export class WorksheetGeneratorConfigurationItemComponent implements OnInit {
  @Input() public configItem;
  @Output() public hasChanged = new EventEmitter();
  public class;
  public taskType;
  

  constructor( public tr: TaskRegistryService) { 
  }

  propagate(t){
    if (t){
      this.configItem.taskType = cloneDeep(this.tr.getTask(t.path));
      this.configItem.taskType._type = cloneDeep(this.tr.getTask(t.path)._type);  
    }
    this.hasChanged.emit(this.configItem)
    
    console.log(88888, this.configItem)

  }
  ngOnInit() {
  }

}
