import { Injectable } from '@angular/core';
import { Matrix } from '../DBMatrix';
import { DraggableDirective } from './draggable.directive';

@Injectable()
export class DragService {
  public movablesQueryList;
  public movables = new Map();
  public anchorCursorPosition = { x: undefined, y:undefined };
  public cursorPosition = { x: undefined, y:undefined };
  public multiple = false;

  get selection(){
    return this.movablesQueryList.filter(x=>x.selected);
  }
  moveElement(comp : DraggableDirective){
   let el = comp.el.nativeElement;
   comp.moving.emit(comp);
   let x = this.cursorPosition.x - this.anchorCursorPosition.x;
   let y = this.cursorPosition.y - this.anchorCursorPosition.y;
   el.classList.add('moving');
   el.style.transform =  this.movables
      .get(el).anchor
      .multiply(Matrix.translate(x,y,0))
      .toCSS3dString();
  }
  setAnchorTransformation(comp : DraggableDirective){
    //DAS   können wir auch einfach in der direktive speichern
    let el = comp.el.nativeElement;
    this.movables.set(el, { 
      anchor : Matrix.fromCSS3dString((window.getComputedStyle(el).transform)), 
      putback: comp.putBack 
    });
  }
  putBack(el : HTMLElement){
    //das kann auch in die movable-directive
    if (this.movables.get(el).putback){
      el.style.transform = this.movables.get(el).anchor.toCSS3dString();
      el.classList.add('onwayback');
      setTimeout(()=>el.classList.remove('onwayback'), 200)
    }
  }
}