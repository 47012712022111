import { Component, OnInit } from '@angular/core';
import { TaskComponent } from '../task/task.component';

@Component({
  selector: 'app-task-fraction-choose-identical',
  templateUrl: './task-fraction-choose-identical.component.html',
  styleUrls: ['./task-fraction-choose-identical.component.css']
})
export class TaskFractionChooseIdenticalComponent  extends TaskComponent{ 
  public name = "TaskFractionChooseIdentical";

}