import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { paths } from '../../app.module';
import { TaskRegistryService } from 'src/app/Services/task-registry.service';
import { walkTree } from '../../general';
@Component({
  selector: 'app-tasks-menu',
  templateUrl: './tasks-menu.component.html',
  styleUrls: ['./tasks-menu.component.css']
})
export class TasksMenuComponent implements OnInit {
  public routes;
  @Input() public text = "Aufgaben"
  @Output() public itemclicked = new EventEmitter();

  constructor(public tr : TaskRegistryService) { 
    this.routes = JSON.parse(JSON.stringify(paths));
    walkTree(this.routes, (x)=>{
      if (x.path && !x.display){
        x.display = tr.tasks[x.path].displayName;
      }
    });
  }
  
  propagate(t){
    this.itemclicked.emit(t);
  }
  ngOnInit() {
  }

}
