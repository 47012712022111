import { Operation } from './Operation';
import { RationalNumber } from './RationalNumber';
import { Fraction } from '../Fraction';

export class Multiplication extends Operation{
    static rex: any =  /^[•\*]$/;

    constructor(public operands, public id){
        super(id);
    }
    /*get value(){
        return this.summands.reduce((a,b)=> a?.getValue + b?.getValue);
    }*/

    get nameOfResult(){
        return new Noun(Genera.NEUTER, 'Produkt', 'Produkte', 'Produkts');
    }
    get nameOfProcess(){
        return new Noun(Genera.FEMININE, 'Multiplikation', 'Multiplikationen', 'Multiplikation');
    }
    get verb(){
        return new Verb('multiplizier');
    }
    get namesOfOperands(){
        return this.operands.map(x=>new Noun(Genera.MASCULINE, 'Faktor', 'Faktoren', 'Faktors'));
    }
    get angularString(){
        return super.angularString.replace(/CHILD_CLASS_CONTENT/, 
            this.operands.map(x=>x.angularString).join(`operator(•)[${this.id}]`))
    }
    get value(){
        let r = this.operands.reduce((a,b)=> {
            //console.log(a,b);
            return a.value.multiply(b.value)
        }
            , new RationalNumber(1))
        ;
        r.sign = Fraction.computeSign(this, r);
        return r;

    }
    string(){
        return super.string(this.operands.map(x=>x.string()).join(this.display.invisible ? '' : '•'));
    }
}