import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-eigth-grade',
  templateUrl: './eigth-grade.component.html',
  styleUrls: ['./eigth-grade.component.css']
})
export class EigthGradeComponent implements OnInit {
  public links = [
    {name: 'Lied', href: 'https://www.youtube.com/watch?v=blY2qdFV4ag', text: `
    Der Liedtext befindet sich in der Videobeschreibung.
    `},
  //  {name: 'Tabelle', href: 'https://www.geogebra.org/m/vfjW1dZt'},
    {name: 'Zwei Punkte', href: 'https://www.geogebra.org/m/cGtaQn2d'},
    {name: 'Nullstelle', href: 'https://www.geogebra.org/m/nDuAbg9m'},
    {name: 'Kerzen', href: 'https://www.geogebra.org/m/nyJKK7f3'},
  ];
  public show1 = false;
  public show2 = false;
  public show3 = false;
  public show4 = false;

  public loesungen = [
    `{{9-5}\\over{6-3}}\\cdot x + \\left( 9 - {{9-5}\\over{6-3}}\\cdot 6 \\right)
            = {{4}\\over{3}}\\cdot x + \\left( 9 - {{4 \\cdot 6 }\\over{3}} \\right)
            = {{4}\\over{3}}\\cdot x + \\left( 9 - {{4 \\cdot 2 }\\over{1}} \\right)
            = {{4}\\over{3}}\\cdot x + 8`,
    `{{3+6}\\over{-10-0}}\\cdot x + \\left( -6 - {{3+6}\\over{-10-0}}\\cdot 0 \\right)
    = -{{9}\\over{10}}\\cdot x + \\left( -6 \\right)
    = -{{9}\\over{10}}\\cdot x  - 6`,
    `f(x)= 3x-7`,
    `g(x)={{1}\\over{2}}x +8`,
    `3x-7={{1}\\over{2}}x +8 |+7; -{{1}\\over{2}}x`,
     `2,5x=15 | : 2,5`,
     `x = 6`,
     `f(6)=18-7=11`,
     `f(x)= -9x+5`,
    `g(x)=5x - 9`,
    `-9x+5=5x - 9 |-5; -5x`,
     `-14x=-14 | : -14`,
     `x = 1`,
     `g(1)=5-9=-4`
  ]
  constructor() { }

  ngOnInit() {
  }

}
