import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { ConfigurationItem } from '../worksheet-generator/worksheet-generator.component';


@Component({
  selector: 'app-worksheet-generator-configuration-panel',
  templateUrl: './worksheet-generator-configuration-panel.component.html',
  styleUrls: ['./worksheet-generator-configuration-panel.component.css']
})
export class WorksheetGeneratorConfigurationPanelComponent implements OnInit {
  @Input() public configItems;
  @Output() public hasChanged = new EventEmitter();
  constructor() { }

  addConfigurationItem(){
    this.configItems.push( new ConfigurationItem() )
  }
  
  propagate(t){
    this.hasChanged.emit(t);
  }
  ngOnInit() {
  }

}
