import { Component } from '@angular/core';
import { RewardPicsService } from './Services/reward-pics.service';
import { randomInt, numbers } from './general';
import { shuffle } from 'lodash';
import { LexedArray } from './Algebra/LexedArray';
import {bracketParser} from './Algebra/BracketTree';
import { signParser } from './Algebra/Signparser';
import { invisibleTimesAdder } from './Algebra/InvisibleTimes';
import { algebraTreeMaker } from './Algebra/AlgebraTree';
import { Equation } from './DBEquation';
import { setVariablesValues } from './Algebra/Variable';
import { RationalNumber } from './Algebra/RationalNumber';
import { AngularFireDatabase } from '@angular/fire/database';
import { Observable } from 'rxjs';
import {OrGate, XorGate, AndGate, Switch, Lamp} from './logic-gate'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {  
  public tigers;


  public numbers;
  public tests;
  public dbe;
  public players: Observable<any[]>;

    constructor(public rp : RewardPicsService, public db : AngularFireDatabase){
      this.players = db.list('players').valueChanges();
      this.db.list('players').push('ert')

      let l1 = new LexedArray('1(2)3');//'(2)(((3,45)))+(21,324-263772) -(12,23(12)23+31(223))');
    let s2 = '12((((3))))4'
      let l2 = new LexedArray(s2)
      //let l21 = new LexedArray('1(2)3(4)');//'(2)(((3,45)))+(21,324-263772) -(12,23(12)23+31(223))');
      //let l3 = new LexedArray('1((2))3(4)');//'(2)(((3,45)))+(21,324-263772) -(12,23(12)23+31(223))');
     // let l4 = new LexedArray('1((2)5(6))3(4)');//'(2)(((3,45)))+(21,324-263772) -(12,23(12)23+31(223))');
      //let l5 = new LexedArray('1((2)5(6))3((4))');//'(2)(((3,45)))+(21,324-263772) -(12,23(12)23+31(223))');
      let l6 = new LexedArray('(2)(((3,45)))+(21,324-263772) -(12,23(12)23+31(223))');///'(2)(((3,45)))+(21,324-263772) -(12,23(12)23+31(223))');
//      console.log(l1.brackets, l1.deepestLevel,0)
/*      console.log(l2.brackets, l2.deepestLevel,0)
      console.log(l3.brackets, l3.deepestLevel,1)
      console.log(l4.brackets, l4.deepestLevel,1)
      console.log(l5.brackets, l5.deepestLevel,1)
      console.log(l6.brackets, l6.deepestLevel,2)*/
//      console.log(l.brackets);
//bracketParser(l1, true);
//console.log(bracketParser(l2, true));
//console.log(bracketParser(l6));
let s7 = '-(32)abc(-90•4a)-7(4--9)'
let start = Date.now();
let l7 = new LexedArray(s7);
let b7 = bracketParser(l7)
let p7 = signParser(b7);
let i7;
/*console.log(invisibleTimesAdder(p7).length);
console.log(Date.now()-start)
s7 = '-(32-67+8a9)abc(-90•4a)-7(4--9)'
start = Date.now();
l7 = new LexedArray(s7);
b7 = bracketParser(l7)
p7 = signParser(b7);
console.log(invisibleTimesAdder(p7).length);
console.log(Date.now()-start)
s7 = '-89(34+123•12-4abz)(ers-12-34+-23)'*/
/*start = Date.now();
l7 = new LexedArray(s7);
b7 = bracketParser(l7)
p7 = signParser(b7);
console.log(invisibleTimesAdder(p7).length);
console.log(Date.now()-start) */
s7 = 'x+3'
console.log(s7)
start = Date.now();
l7 = new LexedArray(s7);

b7 = bracketParser(l7);
p7 = signParser(b7);
i7 = invisibleTimesAdder(p7);
let t7 = algebraTreeMaker(i7);
//console.log(t7);
console.log(t7.string());
//console.log(t7.value.string())
setVariablesValues({x:new RationalNumber(9)}, t7)
console.log(t7.value.string())
this.dbe = new Equation(t7.string(), {});
console.log(Date.now()-start)  //console.log(s2);

/*      console.log('========================')
      console.log('========================')
      console.log(new BracketTree(l2).array);
      console.log('========================')
      console.log('========================')
      console.log(new BracketTree(l3).array);
      console.log('========================')
      console.log('========================')
      console.log(new BracketTree(l4).array);
      console.log('========================')
      console.log('========================')
      console.log(new BracketTree(l5).array);
      console.log('========================')
      console.log('========================')
      console.log(new BracketTree(l6).array);
      console.log(Date.now()-start)*/
      /*Teilbarkeitstest*/
      /*const randomNumber = (places)=>{
        let r = numbers(places).map(y=>randomInt(0,10));
        r[0] = r[0] || randomInt(1,10);
        return Number(r.join(''));
      }
      this.numbers = [3,4,5].map(x=> numbers(5).map(y=> randomNumber(x)));
      this.tests = numbers(58).map(x=>this.numbers.map(y=>shuffle(y)))
      console.log(this.numbers.map(x=> [2,3,5,10].map( y => x.filter(z=>z % y === 0).length)))*/
    /**/ 




    window.addEventListener('resize', ()=>{
      if (!window['flex-heights']) return;
      for (let el of window['flex-heights']){
        let parent = el.parentNode;
        let parentHeight = window.getComputedStyle(parent).getPropertyValue('height');
        let height = +parentHeight.slice(0,-2) - 2;
      }
    })
  }

  
  log(e,i){
    this.tigers.splice(i,1);
    console.log(JSON.stringify(e,null, 2));
  }

}
