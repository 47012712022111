import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { CoreModule } from './core/core.module';
import { COMPONENTS } from './components';
import { DIRECTIVES } from './directives';

@NgModule({
  imports:      [BrowserModule, CoreModule],
  declarations : [COMPONENTS, DIRECTIVES],
  exports: [COMPONENTS, DIRECTIVES]
})
export class DragModule { }
