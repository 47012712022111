import { Component, OnChanges } from '@angular/core';

@Component({
  selector: 'app-options-task-number-base-change',
  templateUrl: './options-task-number-base-change.component.html',
  styleUrls: ['./options-task-number-base-change.component.css']
})
export class OptionsTaskNumberBaseChangeComponent implements OnChanges {
  public task;
  public taskType;
  public from = [];
  public to = [];

  constructor() { 
  }

  ngOnChanges() {
    if (this.taskType){
      this.from = Object.values(this.taskType.options).filter(x=>x['groups'].includes('from'));
      this.to = Object.values(this.taskType.options).filter(x=>x['groups'].includes('to'));
    }
  }

}
