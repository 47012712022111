import { Component, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { numbers} from '../../general';

@Component({
  selector: 'app-fraction-wall',
  templateUrl: './fraction-wall.component.html',
  styleUrls: ['./fraction-wall.component.css']
})
export class FractionWallComponent implements OnChanges {

  @Input() public nOfParts;
  @Input() public markedOnes;
  @Input() public flipped;
  @Input() public fraction;
  
  @Output() public clickedPart = new EventEmitter();

  public parts = numbers(5);
  
  constructor() { }
  
  partClicked(part, index){
    this.clickedPart.emit({part: part, index: index});
  }

  ngOnChanges(){
    if (this.fraction){
      this.nOfParts = this.fraction.denominator;
      this.markedOnes = numbers(this.fraction.enumerator)
    }
    this.parts = numbers(this.nOfParts).map(n=>{
      let result = {marked: false, index: n, x: undefined, y: undefined, width: undefined, height: undefined};
      if (this.markedOnes.indexOf(n) !== -1){
        result.marked = true;
      }
      let x = 2;
      let y = 2 + n * 195/this.nOfParts;
      result.x = !this.flipped ? x : y;
      result.y = this.flipped ? x : y;
      result.height= this.flipped ? 195 : 195/this.nOfParts;
      result.width= !this.flipped ? 195 : 195/this.nOfParts;
      

      return result;
    })
  
  }

}
