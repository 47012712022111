import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-mixed-number',
  templateUrl: './mixed-number.component.html',
  styleUrls: ['./mixed-number.component.css']
})
export class MixedNumberComponent {
  @Input() public data;
  @Input() public size = '3vw';
  @Input() public editable;
  @Input() public denominatorEditable = true;
  @Input() public enumeratorEditable = true;
  @Input() public numberEditable = true;
  
  @Output() public changed = new EventEmitter();
  @Output() public submit = new EventEmitter();

   

  public displayWidth;
  public numberDisplayWitdth
  constructor() { }
  
// DAS HIER MUSS ALLES UMGESCHRIEBEN WERDEN:
  change(e, k){
    if (e.length){
      if (k=='number')this.data[k] = +e;
      else this.data.fraction[k] = +e;
      this.displayWidth = String(Math.max(String(this.data.fraction.denominator).length, String(this.data.fraction.enumerator).length) * 2.4) + 'vw';
      this.numberDisplayWitdth = String( String(this.data.number).length * 2.4) + 'vw';
    }
    if (this.data.fraction.denominator && this.data.fraction.enumerator){
      this.changed.emit(this.data.fraction);
    }
  }
  ngOnChanges() {
    console.log(this.data)
    if (!this.data.fraction.denominator || !this.data.fraction.enumerator || !this.data.number){
      this.data.fraction.denominator = this.data.fraction.denominator || '';
      this.data.fraction.enumerator = this.data.fraction.enumerator || '';
      this.data.number = '';
    }
    this.displayWidth = String(Math.max(String(this.data.fraction.denominator).length, String(this.data.fraction.enumerator).length) * 2.4) + 'vw';
    this.numberDisplayWitdth = String( String(this.data.number).length * 2.4) + 'vw';
    
  }

}
