import { Task } from "../DBTask";
import { randomInt, randomItem, PRIMES, numbers } from '../general';
import { Fraction } from '../Fraction';
import { HelpTaskFractionAdditionComponent } from '../Components/help-task-fraction-addition/help-task-fraction-addition.component';

export class TaskFractionAddition extends Task{
    static options = {
        /*trueFraction: {
            model: false,
            type: 'bool',
            label: 'Ergebnis echter Bruch',
            disabled: false,
            relations: (o, v)=>{
                        }
        },*/
        'streng':{
            model: false,
            type: 'bool',
            label: 'Ergebnis muss gekürzt sein',
            disabled: false,
            relations: (o, v)=>{
            }
        },
        '+' : {
            model: true,
            type: 'bool',
            label: 'Addition (+)',
            disabled: false,
            relations: (o, v)=>{
                if (!v && o['-'].model == false && o[':'].model == false && o['•'].model == false) {
                    o['•'].model = true;
                }
            }
        },
        '•' : {
            model: false,
            type: 'bool',
            label: 'Multiplikation (•)',
            disabled: false,
            relations: (o, v)=>{
                if (!v && o['-'].model == false && o[':'].model == false && o['+'].model == false) {
                    o['+'].model = true;
                }
            }
        },
        '-' : {
            model: false,
            type: 'bool',
            label: 'Subtraktion (-)',
            disabled: false,
            relations: (o, v)=>{
                if (!v && o['•'].model == false && o[':'].model == false && o['+'].model == false) {
                    o['+'].model = true;
                }
            }
        },
        ':' : {
            model: false,
            type: 'bool',
            label: 'Division (:)',
            disabled: false,
            relations: (o, v)=>{
                if (!v && o['•'].model == false && o['-'].model == false && o['+'].model == false) {
                    o['+'].model = true;
                }
            }
        },
        
    }
    static difficulties = {
        1 : ()=>{
            //2 mit gleichem nenner, Ergebnis echter Bruch
            //Ergebnis echter Bruch (noch cooler wäre das als OPTION)
            let f1 = Fraction.true(3,20);
            let f2 = f1.copy();
            let e1 = randomInt(2, f1.denominator-2);
            let e2 = randomInt(3, f1.denominator-e1);
            console.log(e1,e2, f1.denominator)
            f1.enumerator = e1;
            f2.enumerator = e2;
            let bigger = f1.greaterThen(f2) ? f1 : f2;
            let smaller = f1.greaterThen(f2) ? f2 : f1;
            console.log(f1,f2)
            return new TaskFractionAddition(1, [bigger,smaller],randomItem(['+', '•', '-', ':'].filter(x=>TaskFractionAddition.options[x].model == true)));
        },

        2 : ()=>{
            let f1 = Fraction.true(3,15);
            let f2 = f1.copy();
            let e1 = randomInt(2, f1.denominator-2);
            let e2 = randomInt(3, f1.denominator-e1);
            let q = randomItem([f1,f2]);
            let bigger = f1.greaterThen(f2) ? f1 : f2;
            let smaller = f1.greaterThen(f2) ? f2 : f1;

            q.denominator *= randomInt(2,10);
            console.log(e1,e2, f1.denominator)
            f1.enumerator = e1;
            f2.enumerator = e2;
            
            return new TaskFractionAddition(1, [bigger,smaller],randomItem(['+', '•', '-', ':'].filter(x=>TaskFractionAddition.options[x].model == true)));

            //2 mit 1 nenner geht in den anderen, Ergebnis echter Bruch

            //return new TaskFractionAddition(1, );
        },
        3 : ()=>{
            let f1 = Fraction.true(3,15);
            let f2 = f1.copy();
            let primeselection = PRIMES.slice(0,4);
            let commonPrimes = numbers(randomInt(1,3)).map(x=>randomItem(primeselection));
            let p1 = numbers(randomInt(1,2)).map(x=>randomItem(primeselection))
            let p2 = numbers(randomInt(1,2)).map(x=>randomItem(primeselection))
            f1.denominator = commonPrimes.concat(p1).reduce((a,b)=>a*b,1);
            f2.denominator = commonPrimes.concat(p2).reduce((a,b)=>a*b,1);
            let e1 = randomInt(2, f1.denominator-2);
            let e2 = randomInt(3, f1.denominator-e1);
            console.log(e1,e2, f1.denominator)
            f1.enumerator = e1;
            f2.enumerator = e2;
            
            return new TaskFractionAddition(1, [f1,f2],randomItem(['+', '•', '-', ':'].filter(x=>TaskFractionAddition.options[x].model == true)));

            //2 mit 1 nenner geht in den anderen, Ergebnis echter Bruch

            //return new TaskFractionAddition(1, );
        },    }
    public helpComponent = HelpTaskFractionAdditionComponent;
    public result;
    public guess = {enumerator: '', denominator: '', sign: '+'};
    constructor(public difficulty, public fractions : Fraction[], public operator){
        super();
        this.result = 
            operator == '+' ? this.fractions[0].add(...this.fractions.slice(1)) :
            operator == '•' ? this.fractions[0].multiply(...this.fractions.slice(1)) :
            operator == '-' ? this.fractions[0].subtract(this.fractions[1]) :
            //operator == ':' 
             this.fractions[0].multiply(...this.fractions.slice(1).map(x=>x.inverse));
             console.log(this.fractions, this.operator, this.result)

    }
    check(){
        if ((!TaskFractionAddition.options.streng.model && this.result.equal(this.guess)) || 
        (TaskFractionAddition.options.streng.model && this.result.reduce().identical(this.guess))){
            console.log("genau")
            this.emit('correct');  
        }

        else {
            console.log(this.result, this.guess)
          console.log("leider nicht")
          this.emit('wrong');  
        }
    }


}