import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { paths } from '../../app.module';
import { TaskRegistryService } from 'src/app/Services/task-registry.service';

@Component({
  selector: 'app-toolbar-menus',
  templateUrl: './toolbar-menus.component.html',
  styleUrls: ['./toolbar-menus.component.css'],

})
export class ToolbarMenusComponent{
  @Input() public routes = paths;
  @Input() public text;
  @Input() public root = true;
  @Input() public display: String;
  
  @Output() itemclicked = new EventEmitter();
  constructor() {     }

  dostuff(t){
    this.itemclicked.emit(t);
  }
}
