import { Component, OnChanges } from '@angular/core';
import { numbers, POSITIONS } from '../../general';
import { TaskComponent } from '../task/task.component';

@Component({
  selector: 'app-task-comma-sliding',
  templateUrl: './comma-sliding.component.html',
  styleUrls: ['./comma-sliding.component.css']
})
export class TaskCommaSlidingComponent extends TaskComponent implements OnChanges{
  public name ="TaskCommaSliding";

  label = (v)=>{
    v;
    let t = +this.initialGuess - +v;
    let l = t > 0 ? '←' : '';
    let r = t < 0 ? '→' : '';
    return l + Math.abs(t) + r;
  }
  numbers(n){
    return numbers(n);
  }
  ngOnChanges(){
    if (this.task){
      this.initialGuess = this.task.guess;
    }
  }
  //Das sollte alles in die Task, bzw. dort dann in eine
  //NumberPlaceValueRepresentation-property
  //ngOnChanges ist dann nicht mehr notwendig
  public positions = POSITIONS;

  initialGuess;
  addZeroLeft(){
    if (this.task.digitsArray.length < 30){
      this.task.digitsArray.unshift('0');
      this.task.guess = Number(this.task.guess)+1;
      this.initialGuess = Number(this.initialGuess)+1;
    }
  }
  addZeroRight(){
    if (this.task.digitsArray.length < 30) this.task.digitsArray.push('0');
  }

  takeZeroLeft(){
    if ( this.task.digitsArray[0] == 0  && this.task.guess != 0){
      this.task.digitsArray.shift();
      this.task.guess = Number(this.task.guess)-1;
      this.initialGuess = Number(this.initialGuess)-1;

    }
  }
  takeZeroRight(){
    if ( this.task.digitsArray[this.task.digitsArray.length-1] == 0  && this.task.guess != this.task.digitsArray.length-1){
      this.task.digitsArray.pop();
    }
  }

  allAfterZero(i){
    if (+i <= +this.task.guess) return false;
    if (this.task.digitsArray[i] != 0) return false;
    return this.task.digitsArray.slice(i).every(x=>x==0);
  }
  allBeforeZero(i){
    if (+i >= +this.task.guess) return false;
    if (this.task.digitsArray[i] != 0) return false;
    return this.task.digitsArray.slice(0,i).every(x=>x==0);
  }
  
}
