import { Directive, EventEmitter, Inject, Input, HostBinding,
    HostListener, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { take, takeUntil, takeWhile } from 'rxjs/operators';

import { DragService, DragSource } from '../core/services';
import { APP_CONFIG, IAppConfig } from '../core/config';

export interface DropSortableEvent {
  data: any;
  newIndex: number;
}

@Directive({
  selector: '[dragSortable]'
})
export class DragSortableDirective implements OnInit, OnDestroy {
  dragImg: HTMLElement;
  dragImgSize: { width: number, height: number };

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  @Input() item;
  @Input() itemIndex: number;
  @Input() sortableElSelector: string;

  @Output() dropSortable: EventEmitter<DropSortableEvent> = new EventEmitter();

  @HostBinding('draggable') get draggable() {
    return true;
  }
  @HostBinding('class.collapsed') collapsed = false;
  @HostBinding('class.dragging') dragging = false;
  @HostBinding('class.safari-specific') isSafari = this.config.isSafari;

  @HostListener('dragstart', ['$event']) onDragStart(event) {
    this.dragImg = event.target.querySelector('.drag-image');
    if (this.dragImg) {
      this.dragImgSize = {
        width: this.dragImg.offsetWidth,
        height: this.dragImg.offsetHeight
      };
    }

    this.dragging = true;
    this.dragService.dragSource$.next({ element: event.target, index: this.itemIndex });
    if (this.config.isSafari) {
      // Safari doesn't allow this logic to be executed in dragstart event handler.
      setTimeout(() => {
        this.dragService.collapse$.next(true);
      }, 0);
    } else {
      this.dragService.collapse$.next(true);
    }

    if (this.config.isIE || this.config.isEdge) {
      removeGhostImage();
    } else if (this.dragImg) {
      setCustomGhostImage.call(this);
    }

    if (this.config.isEdge && this.dragImg) {
      stickDragImageToMouse.call(this);
    }

    // IE allowes only text type to be set.
    try {
      event.dataTransfer.setData('application/json', JSON.stringify(this.item));
    } catch (e) {
      event.dataTransfer.setData('text', JSON.stringify(this.item));
    }

    /**
     * Removes ghost image. IE10, 11 and Edge don't support setting custom drag images.
     */
    function removeGhostImage() {
      const draggable = event.target;
      const clonedDraggable = draggable.cloneNode(true);

      draggable.parentNode.insertBefore(clonedDraggable, draggable);
      draggable.style.display = 'none';

      setTimeout(() => {
        draggable.parentNode.removeChild(clonedDraggable);
        draggable.style.display = 'block';
      });
    }

    function setCustomGhostImage() {
      event.dataTransfer.setDragImage(this.dragImg, this.dragImgSize.width / 2,
          this.dragImgSize.height / 2);
    }

    function stickDragImageToMouse() {
      const { top, left } = this.getDragImgPosition(event);

      this.dragImg.style.position = 'fixed';
      this.dragImg.style.zIndex = '100';
      this.dragImg.style.opacity = '0.7';
      this.dragImg.style.top = `${top}px`;
      this.dragImg.style.left = `${left}px`;
    }
  }

  @HostListener('drag', ['$event']) onDrag(event) {
    if (this.config.isEdge && this.dragImg) {
      const { top, left } = this.getDragImgPosition(event);

      this.dragImg.style.top = `${top}px`;
      this.dragImg.style.left = `${left}px`;
    }
  }

  @HostListener('dragenter', ['$event']) onDragEnter(event) {
    this.dragService.dragSource$
        .pipe(
          takeWhile(dragSource => Boolean(dragSource)),
          take(1)
        )
        .subscribe((dragSource: DragSource) => {
          const dragEl = dragSource.element;
          const dragElIndex = dragSource.index;
          const textNodeType = 3;
          const target = event.target.nodeType === textNodeType ?
              event.target.parentNode :
              event.target;
          const sortableEl = target.closest(this.sortableElSelector)

          if (dragEl !== sortableEl) {
            if (isBefore(dragEl, sortableEl)) {
              sortableEl.parentNode.insertBefore(dragEl, sortableEl);
              this.dragService.dragSource$.next(Object.assign({}, dragSource, {
                index: dragElIndex - 1
              }));
            } else {
              sortableEl.parentNode.insertBefore(dragEl, sortableEl.nextSibling);
              this.dragService.dragSource$.next(Object.assign({}, dragSource, {
                index: dragElIndex + 1
              }));
            }
          }
        });

    event.preventDefault();

    /**
     * Returns boolean indicating if socond node in the list is before the first one.
     * @param {any} firstNode
     * @param {any} secondNode
     * @returns {boolean}
     */
    function isBefore(firstNode, secondNode): boolean {
      if (firstNode.parentNode === secondNode.parentNode) {
        for (let cur = firstNode; cur; cur = cur.previousSibling) {
          if (cur === secondNode) {
            return true;
          }
        }
      }
      return false;
    }
  }

  @HostListener('dragover', ['$event']) onDragOver(event) {
    // Prevent default to allow drop.
    event.preventDefault();
  }

  @HostListener('dragend', ['$event']) onDragEnd(event) {
    this.dragging = false;
    this.dragService.dragSource$.next(null);
    this.dragService.collapse$.next(false);

    if (this.config.isEdge && this.dragImg) {
      this.dragImg.style.position = 'absolute';
      this.dragImg.style.opacity = '0';
      this.dragImg.style.zIndex = '-1';
    }
  }

  @HostListener('drop', ['$event']) onDrop(event) {
    this.dragService.dragSource$
        .pipe(
          takeWhile(dragSource => Boolean(dragSource)),
          take(1)
        )
        .subscribe((dragSource: DragSource) => {
          let data;

          // IE allowes only text type to be set.
          try {
            data = JSON.parse(event.dataTransfer.getData('application/json'));
          } catch (e) {
            data = JSON.parse(event.dataTransfer.getData('text'));
          }
          this.dropSortable.emit({ data, newIndex: dragSource.index });
        });
  }

  constructor(
    @Inject(APP_CONFIG) private config: IAppConfig,
    private dragService: DragService) {}

  ngOnInit() {
    this.dragService.collapse$
        .pipe(
          takeUntil(this.ngUnsubscribe),
        )
        .subscribe((collapsedState: boolean) => {
          this.collapsed = collapsedState;
        })
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private getDragImgPosition(event): { top: number, left: number} {
    return {
      top: event.clientY - this.dragImgSize.height / 2,
      left: event.clientX - this.dragImgSize.width / 2
    };
  }
}
