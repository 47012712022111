import { Component, OnInit, ViewChildren, ViewContainerRef, ComponentFactoryResolver } from '@angular/core';
import { TaskRegistryService } from 'src/app/Services/task-registry.service';


export class ConfigurationItem{
  public tasks = [];
  constructor(public taskType = null, public numberOfInstances=3){

  }

  generateTasks(){
    if (this.tasks.length === this.numberOfInstances){
      this.tasks = [];
    }
    this.tasks = [];
    for (let i =0; i<this.numberOfInstances; i++ ) {
      this.tasks.push((this.taskType._type.withDifficulty || this.taskType._type.difficulties[this.taskType.difficulty])());
    }
  }
}

@Component({
  selector: 'app-worksheet-generator',
  templateUrl: './worksheet-generator.component.html',
  styleUrls: ['./worksheet-generator.component.css']
})
export class WorksheetGeneratorComponent implements OnInit {
  @ViewChildren('container', { read: ViewContainerRef }) public entry;
  
  private componentRef;
  public taskComponents; 
  public configItems = [new ConfigurationItem()];

  constructor(private resolver: ComponentFactoryResolver, public tr: TaskRegistryService) {
   }

  itemChanged(ci){
      ci.generateTasks();
   }

  downloadAsPdf(){

  }
  
  print(){

  }

  ngOnInit() {
  }

}
