import { randomInt, randomItem, randomItemsUnique, numbers, without, numberWithFactors, PRIMES } from '../general';
import { Task } from '../DBTask';
import { Fraction } from '../Fraction';

export class TaskFractionOfNumber extends Task{
    public name = "TaskFractionOfNumber";
    static difficulties = {
        1: ()=>{
              let number = randomInt(4,10);
              let n = randomInt(2,10);
              let m = randomInt(1,number);
              let fraction = new Fraction(m, number);
              number = number * n;
              return new TaskFractionOfNumber(1, number, fraction);
        },
        2: ()=>{
            let number = randomInt(10,20);
            let n = randomInt(2,10);
            let m = randomInt(1,number);
            let fraction = new Fraction(m, number);
            number = number * n;
            return new TaskFractionOfNumber(2, number, fraction);
      }
    }
    public result;
    public guess;
    constructor(public difficulty:number, public number, public fraction){
        super();
        this.result = (number/fraction.denominator)*fraction.enumerator;
   }

    check(){
        if (this.result == this.guess){
            console.log("genau");
            this.emit('correct');  
          }
        else{
          console.log("leider nicht");
          console.log(this)
          this.emit('wrong');  
        }
      }
}
